import React from 'react'
import { Breadcrumb } from '..'
import { PageHeaderContainer, PageName } from './styles'

function PageHeader({ children }) {
  return (
    <PageHeaderContainer>
      <PageName>{children}</PageName>
      <Breadcrumb />
    </PageHeaderContainer>
  )
}

export default PageHeader
