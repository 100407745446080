import { useState } from 'react'
import { Controller, ControllerProps, UseFormReturn } from 'react-hook-form'
import { Switch, Theme, Typography } from '@mui/material'
import { AutoTableVirtualizedProps, Checkbox, CircularProgress, TextField, Tooltip } from '@stationkim/front-ui'
import { RowEditionController } from './components/rowEditionController'
import { OperatorStatusChip } from './components/styles'
import { CopyableField } from './components/copyableField/copyableField'
import { EProcessType } from '@/main/enums'

const NoValueFallBack = () => (
  <Typography variant='label3' sx={(theme: Theme) => ({ color: theme.palette.grey[500] })}>
    N/A
  </Typography>
)

export const useBenefitsTable = ({ form, isLoading }: { form: UseFormReturn; isLoading: boolean }) => {
  const [rowUnderEdition, setRowUnderEdition] = useState<number | null>(null)
  const handleStartEdition = ({ rowIndex }: { rowIndex: number }) => {
    setRowUnderEdition(rowIndex)
  }
  const handleCancelEdition = () => {
    form.reset()
    setRowUnderEdition(null)
  }

  const handleEditableColumns = ({
    rowIndex,
    type,
    value: externalValue,
    rowUnderEdition,
    alwaysField = true,
    name,
    rules,
    render = () => null,
  }: {
    name: string
    rowIndex: number
    type: EProcessType
    value: number | string | boolean
    rowUnderEdition: number
    alwaysField?: boolean
    rules?: ControllerProps['rules']
    render?: (params: any) => React.ReactElement<any, any>
  }) => {
    const value = form.getValues()?.[rowIndex]?.[name] ?? externalValue
    const isBroker = type === EProcessType.Broker
    const isEditing = rowUnderEdition === rowIndex
    const hasValue = typeof value === 'boolean' || value
    if (
      (!hasValue && !isEditing) ||
      (!hasValue && !isBroker) ||
      (!isBroker && ['login', 'password', 'clientCode'].includes(name))
    )
      return <NoValueFallBack />
    else if (hasValue && !isEditing && !alwaysField) return value
    return (
      <Controller
        control={form.control}
        name={`${rowIndex}.${name}`}
        defaultValue={value}
        rules={isEditing ? rules : undefined}
        render={({ field, fieldState: { error } }) => {
          const hasError = Boolean(error?.message)
          return (
            <Tooltip title={error?.message} disableHoverListener>
              {render({ field, disabled: !isEditing, hasError })}
            </Tooltip>
          )
        }}
      />
    )
  }

  const columns: AutoTableVirtualizedProps['columns'] = [
    {
      key: 'status',
      dataKey: 'status',
      title: '',
      width: 60,
      resizable: false,
      alwaysOpen: true,
      frozen: 'left',
      dataGetter: ({ rowData, rowIndex }) => {
        return handleEditableColumns({
          value: rowData.status,
          name: 'status',
          rowIndex,
          rowUnderEdition,
          type: rowData.type,
          render: ({ field: { value, onChange }, disabled }) => (
            <Checkbox checked={value} onChange={onChange} disabled={disabled} />
          ),
        })
      },
    },
    {
      key: 'operator',
      dataKey: 'operator',
      title: 'Operadora',
      width: 150,
    },
    {
      key: 'cardName',
      dataKey: 'cardName',
      title: 'Cartão',
      width: 150,
      frozen: 'left',
    },
    {
      key: 'clientCode',
      dataKey: 'clientCode',
      title: 'Cod Cliente',
      width: 120,
      minWidth: 120,
      dataGetter: ({ rowData, rowIndex }) => {
        return handleEditableColumns({
          value: rowData.clientCode,
          name: 'clientCode',
          rowIndex,
          rowUnderEdition,
          type: rowData.type,
          alwaysField: false,
          render: ({ field: { value, onChange }, disabled }) => (
            <TextField
              value={value}
              onChange={onChange}
              disabled={disabled}
              inputProps={{ autoComplete: 'off' }}
              sx={{ padding: '8px 4px', height: '32px' }}
            />
          ),
        })
      },
    },
    {
      key: 'allowProjection',
      dataKey: 'allowProjection',
      title: 'Projeção saldo',
      width: 150,
      alwaysOpen: true,
      dataGetter: ({ rowData, rowIndex }) => {
        return handleEditableColumns({
          value: rowData.allowProjection,
          name: 'allowProjection',
          rowIndex,
          rowUnderEdition,
          type: rowData.type,
          render: ({ field: { value, onChange }, disabled }) => (
            <Switch checked={value} color='success' onChange={onChange} disabled={disabled} />
          ),
        })
      },
    },
    {
      key: 'operatorStatus',
      dataKey: 'status',
      title: 'Status',
      width: 100,
      dataGetter: ({ rowData }) => {
        return (
          <OperatorStatusChip
            label={rowData.status ? 'Ativo' : 'Inativo'}
            status={rowData.status ? 'active' : 'inactive'}
          />
        )
      },
    },
    {
      key: 'login',
      dataKey: 'login',
      title: 'Login/Usuário',
      width: 200,
      minWidth: 140,
      dataGetter: ({ rowData, rowIndex }) => {
        return handleEditableColumns({
          value: rowData.login,
          name: 'login',
          rowIndex,
          rowUnderEdition,
          type: rowData.type,
          rules: {
            validate: (_, values) => {
              return values[rowIndex]['password']?.length > 0 && values[rowIndex]['login']?.length === 0
                ? 'Campo de preenchimento obrigatório'
                : true
            },
          },
          render: ({ field: { value, onChange, ref }, disabled, hasError }) => (
            <CopyableField
              value={value}
              onChange={(e) => {
                onChange(e)
                form.trigger()
              }}
              disabled={disabled}
              autoComplete='new-login'
              error={hasError}
              ref={ref}
            />
          ),
        })
      },
    },
    {
      key: 'password',
      dataKey: 'password',
      title: 'Senha',
      width: 200,
      minWidth: 140,
      dataGetter: ({ rowData, rowIndex }) => {
        return handleEditableColumns({
          value: rowData.password,
          name: 'password',
          rowIndex,
          rowUnderEdition,
          type: rowData.type,
          rules: {
            validate: (_, values) => {
              return values[rowIndex]['login']?.length > 0 && values[rowIndex]['password']?.length === 0
                ? 'Campo de preenchimento obrigatório'
                : true
            },
          },
          render: ({ field: { value, onChange, ref }, disabled, hasError }) => (
            <CopyableField
              value={value}
              onChange={(e) => {
                onChange(e)
                form.trigger()
              }}
              disabled={disabled}
              error={hasError}
              autoComplete='new-password'
              ref={ref}
              isPassword
            />
          ),
        })
      },
    },
    {
      key: 'site',
      dataKey: 'site',
      title: 'Site/Plataforma Operadora',
      width: 240,
      dataGetter: ({ rowData }) => {
        if (!rowData.site) return <NoValueFallBack />
        return (
          <a href={rowData.site} target='_blank' rel='noreferrer'>
            {rowData.site}
          </a>
        )
      },
    },
    {
      key: 'edition',
      dataKey: 'edition',
      title: '',
      width: 80,
      resizable: false,
      align: 'right',
      frozen: 'right',
      dataGetter: ({ rowIndex }) => {
        const isEditing = rowUnderEdition === rowIndex
        const shouldBeVisible = isEditing || rowUnderEdition === null

        return !shouldBeVisible ? null : (
          <>
            {isLoading ? (
              <CircularProgress size='24px' />
            ) : (
              <RowEditionController
                isEditing={isEditing}
                onClick={() => handleStartEdition({ rowIndex })}
                onCancel={() => handleCancelEdition()}
                onEdit={() => null}
              />
            )}
          </>
        )
      },
    },
  ]

  return {
    columns,
    isEditing: rowUnderEdition !== null,
    rowUnderEdition,
    setRowUnderEdition,
  }
}
