import { forwardRef } from 'react'
import { InputBaseProps } from '@mui/material'
import { InputBase, FieldWrapper, InputLabel } from '@/presentation/components/atoms'
import { IFieldStatus } from '@/main/interfaces'
export interface TextFieldProps extends InputBaseProps, IFieldStatus {
  label?: string
  helperText?: string
  fullWidth?: boolean
}

export const TextField = forwardRef<HTMLElement, TextFieldProps>(
  ({ id, label, error = false, success = false, blocked, helperText, fullWidth = true, ...props }, ref) => {
    return (
      <FieldWrapper error={error} success={success} helperText={helperText}>
        <InputLabel htmlFor={id} label={label}>
          <InputBase
            id={id}
            ref={ref as any}
            error={error}
            success={success}
            blocked={blocked}
            fullWidth={fullWidth}
            {...props}
          />
        </InputLabel>
      </FieldWrapper>
    )
  },
)
