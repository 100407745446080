import { Box, Card, Skeleton, Typography } from '@/presentation/components/atoms'
import { useMediaQuery, useTheme } from '@mui/material'
import { Area, AreaChart, ResponsiveContainer } from 'recharts'
import { BalanceProjectionGridProps } from '../balanceProjectionGrid'

interface BalanceProjectionChartProps extends BalanceProjectionGridProps {
  title: string
  subtitle?: string
  valueDecorator?: (value: string | number) => string | number
}
const cardStyle = {
  display: 'flex',
  flexDirection: 'column',
  padding: '8px 16px',
  minHeight: '100%',
}

export const BalanceProjectionChart = ({
  title,
  subtitle,
  isMonth,
  data,
  selectedDate,
  isLoading,
  valueDecorator = (value: string | number) => value,
}: BalanceProjectionChartProps) => {
  const theme = useTheme()
  const isSmallerThanLg = useMediaQuery(theme.breakpoints.down('lg'))
  const dataForGrid = data ? data.filter((month) => month.month !== '') : []

  if (isLoading)
    return (
      <Card sx={cardStyle}>
        <Skeleton variant='rounded' height='142px' width='100%' />
      </Card>
    )

  const selectedData = data.find((monthData) => monthData.month === selectedDate.month)
  const isPositivePercentual = selectedData.percentualOverLastMonth > 0
  return (
    <Card sx={cardStyle}>
      <Typography sx={{ fontHeight: '24px', fontWeight: 500 }}>{title}</Typography>
      <Typography variant='text2' sx={{ opacity: '60%' }}>
        {subtitle}
      </Typography>
      <Box
        sx={{
          marginTop: '24px',
          display: 'flex',
          gap: '16px',
          justifyContent: 'space-between',
          alignItems: { xs: 'flex-start', lg: 'center' },
          flexDirection: {
            xs: 'column',
            lg: 'row',
          },
        }}
      >
        <Box>
          <Typography variant='label3' sx={{ display: 'flex', gap: '8px', whiteSpace: 'nowrap' }}>
            <Typography
              sx={{
                color: isPositivePercentual ? theme.palette.success.dark : theme.palette.error.dark,
                fontWeight: 500,
              }}
            >
              {isPositivePercentual ? '+' : ''} {selectedData.percentualOverLastMonth}%
            </Typography>
            no último
            {isMonth ? ' mês' : ' ano'}
          </Typography>
          <Typography variant='h2'>{valueDecorator(selectedData.value)}</Typography>
        </Box>
        <ResponsiveContainer width={isSmallerThanLg ? '100%' : '50%'} aspect={5}>
          <AreaChart data={dataForGrid}>
            <defs>
              <linearGradient id='colorNegative' x1='0' y1='0' x2='0' y2='1'>
                <stop offset='5%' stopColor={theme.palette.error.main} stopOpacity={0.8} />
                <stop offset='95%' stopColor={theme.palette.error.light} stopOpacity={0} />
              </linearGradient>
              <linearGradient id='colorPositive' x1='0' y1='0' x2='0' y2='1'>
                <stop offset='5%' stopColor={theme.palette.success.main} stopOpacity={0.8} />
                <stop offset='95%' stopColor={theme.palette.success.light} stopOpacity={0} />
              </linearGradient>
            </defs>
            <Area
              type='linear'
              dataKey='value'
              stroke={isPositivePercentual ? theme.palette.success.main : theme.palette.error.main}
              fillOpacity={0.5}
              fill={`url(#${isPositivePercentual ? 'colorPositive' : 'colorNegative'})`}
            />
          </AreaChart>
        </ResponsiveContainer>
      </Box>
    </Card>
  )
}
