import React from 'react'
import { Body, Title, SessionButton, Close } from './style'
import { Button, Icon, Input } from '@/presentation/components'
import 'react-responsive-modal/styles.css'
import Modal from 'react-responsive-modal'
import { useForm, Controller } from 'react-hook-form'
import { orderStatus } from '@/main/utils/options'
const MODAL_STYLE = {
  borderRadius: '5px',
  modalContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    position: 'relative',
  },
  modal: {
    maxWidth: '380px',
    height: 'calc(100vh - 90px)',
    margin: '0',
    padding: '0',
    overflow: 'initial',
  },
}

const OrdersFilter = ({ actualFilters, close, resetFilters, confirm, open }) => {
  const { control, handleSubmit } = useForm({
    defaultValues: {
      code: actualFilters?.code,
      orderDate: actualFilters?.orderDate,
      status: actualFilters?.status,
    },
  })

  const onSubmit = (data) => {
    confirm(data)
  }

  return (
    <Modal open={open} onClose={close} showCloseIcon={false} styles={MODAL_STYLE}>
      <Body>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Title>Filtrar</Title>
          <Close onClick={close}>
            <Icon name='x' />
          </Close>
        </div>
        <p>Complete com as informações dos filtros que deseja aplicar.</p>
        <form id='filterOrders' onSubmit={handleSubmit(onSubmit)}>
          <label>Código do Pedido</label>
          <Controller
            control={control}
            name='code'
            render={({ field: { onChange, value } }) => <Input.Masked onChange={onChange} value={value} />}
          />
          <label>Data do Pedido</label>
          <Controller
            control={control}
            name='orderDate'
            render={({ field: { onChange, value, ref } }) => (
              <Input.Date
                value={new Date(value?.replaceAll('-', '/'))}
                placeholder='00/00/0000'
                maxDate={new Date()}
                getInputRef={ref}
                onChange={(e) => onChange(e !== '' ? e?.toISOString().split('T')[0] : e)}
              />
            )}
          />
          <label>Status</label>
          <Controller
            control={control}
            name='status'
            render={({ field: { onChange, value } }) => (
              <Input.Select
                placeholder='Selecione'
                onChange={(e) => onChange(e.value)}
                options={orderStatus}
                value={orderStatus.find((option) => option.value === value)}
              />
            )}
          />
        </form>

        <SessionButton>
          <Button
            variant='outlined'
            width='200px'
            onClick={() => {
              resetFilters()
              close()
            }}
          >
            Limpar
          </Button>
          <div style={{ paddingLeft: '15px' }} />
          <Button width='250px' form='filterOrders'>
            Filtrar
          </Button>
        </SessionButton>
      </Body>
    </Modal>
  )
}

export default OrdersFilter
