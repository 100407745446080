import { useContext } from 'react'
import { faPencil } from '@fortawesome/free-solid-svg-icons'
import { Grid, Icon, IconButton, Typography } from '@/presentation/components/atoms'
import { FormContext } from '../form/form'

export const FormHeader = ({ title }: { title?: string }) => {
  const { isEditable, isLoading, gridProps, onEditButtonClick } = useContext(FormContext)
  return (
    <Grid item sm={12}>
      <Grid container spacing={gridProps.spacing} alignItems='center'>
        {title && (
          <Grid item xs='auto'>
            <Typography variant='subtitle1'>{title}</Typography>
          </Grid>
        )}
        {!isEditable && !isLoading && (
          <Grid item xs={true}>
            <IconButton type='button' size='sm' color='primary' onClick={onEditButtonClick}>
              <Icon icon={faPencil} />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}
