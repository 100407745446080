import React, { useState } from 'react'
import { Body, SessionButton, Close } from './style'
import 'react-responsive-modal/styles.css'
import Modal from 'react-responsive-modal'
import { Col, Row, setConfiguration } from 'react-grid-system'
import { benefitsTaxCalculationBy } from '@/main/utils/options'
import convertStringToNumber from 'convert-string-to-number'

import { Button, ErrorMessage, Icon, Input, Title } from '@/presentation/components'

setConfiguration({ gutterWidth: 24 })

const styles = {
  borderRadius: '5px',
  modal: {
    width: '60%',
    margin: '0',
    padding: '0',
  },
}

const AddTaxModal = ({
  cancel,
  confirm,
  open,
  taxType,
  toEdit,
  dataInfo,
  setData,
  info,
  setObjectArray,
  category,
  close,
}) => {
  const [error1, setError1] = useState(false)
  const [error2, setError2] = useState(false)
  const [error3, setError3] = useState(false)
  const [error4, setError4] = useState(false)
  const [error5, setError5] = useState(false)

  const [object, setObject] = useState({
    benefitType: info?.benefitType,
    calculationType: info?.calculationType,
    endRange: info?.endRange,
    id: info?.id,
    itemType: info?.itemType,
    quantityType: info?.quantityType,
    startRange: info?.startRange,
    value: info?.value,
    valueType: info?.valueType,
  })

  const saveItem = async () => {
    if (!object.benefitType) {
      setError1(true)
      return
    } else {
      setError1(false)
    }

    if (!object.calculationType) {
      setError2(true)
      return
    } else {
      setError2(false)
    }

    if (!object.startRange && object.startRange !== 0) {
      setError4(true)
      return
    } else {
      setError4(false)
    }

    if (!object.endRange) {
      setError3(true)
      return
    } else {
      setError3(false)
    }

    if (!object.value) {
      setError5(true)
      return
    } else {
      setError5(false)
    }

    setObjectArray(object, category, 2)
    close()
  }

  const typeTax = (type) => {
    if (type === 1) {
      return 'Administrativa'
    } else if (type === 2) {
      return 'de Entrega'
    } else if (type === 3) {
      return 'de Aproveitamento de Saldo'
    } else {
      return null
    }
  }

  const benefit = [
    { label: 'Vale Transporte Eletrônico', value: 1 },
    { label: 'Vale Alimentação', value: 2 },
    { label: 'Vale Refeição', value: 3 },
    { label: 'Vale Combustivel', value: 4 },
    { label: 'Vale Transporte Papel', value: 5 },
  ]

  const setBenf = (ev) => {
    setObject({ ...object, benefitType: ev.value })
  }

  const setCalc = (ev) => {
    setObject({ ...object, calculationType: ev.value })
  }

  const teste = async (value) => {
    const alter = await value.replace(',', '.')
    const te = convertStringToNumber(alter)
    if (value) {
      setObject({ ...object, value: te })
    }
  }

  return (
    <Modal center open={open} onClose={cancel} showCloseIcon={false} styles={styles}>
      <Body>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Title.SubTitle>
            {info ? 'Editar Taxa' : 'Adicionar Taxa'} {typeTax(info?.benefitType)}
          </Title.SubTitle>
          <Close onClick={cancel}>
            <Icon name='x' />
          </Close>
        </div>

        <Row style={{ marginTop: '15px' }}>
          <Col sm={12}>
            <label>Beneficio associado</label>
            <Input.Select placeholder='Beneficio associado' onChange={(ev) => setBenf(ev)} options={benefit} />
            {error1 && <ErrorMessage>Campo obrigatório</ErrorMessage>}
          </Col>
        </Row>
        <Row style={{ marginTop: '15px' }}>
          <Col sm={12}>
            <label>Cálculo por</label>
            <Input.Select placeholder='Cálculo por' onChange={(ev) => setCalc(ev)} options={benefitsTaxCalculationBy} />
            {error2 && <ErrorMessage>Campo obrigatório</ErrorMessage>}
          </Col>
        </Row>
        <Row style={{ marginTop: '15px' }}>
          <Col sm={4}>
            <label>Range Inicial</label>
            <Input.Masked
              value={object.startRange}
              placeholder='0000000'
              onChange={(ev) => setObject({ ...object, startRange: Number(ev.target.value) })}
            />
            {error4 && <ErrorMessage>Campo obrigatório</ErrorMessage>}
          </Col>
          <Col sm={4}>
            <label>Range Final</label>
            <Input.Masked
              value={object.endRange}
              placeholder='0000000'
              onChange={(ev) => setObject({ ...object, endRange: Number(ev.target.value) })}
            />
            {error3 && <ErrorMessage>Campo obrigatório</ErrorMessage>}
          </Col>
          <Col sm={4}>
            <label>Valor Taxa</label>
            <Input.Decimal
              required={true}
              placeholder='00,00'
              value={object?.value}
              onChange={(ev) => teste(ev.target.value)}
            />
            {error5 && <ErrorMessage>Campo obrigatório</ErrorMessage>}
          </Col>
        </Row>
        <SessionButton>
          <Button variant='outlined' width='200px' onClick={cancel}>
            Fechar
          </Button>
          <div style={{ paddingLeft: '15px' }} />
          <Button width='250px' onClick={saveItem}>
            Salvar
          </Button>
        </SessionButton>
      </Body>
    </Modal>
  )
}

export default AddTaxModal
