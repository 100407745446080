import styled from 'styled-components'

export const Background = styled.div`
  background-color: #70707057;
  align-items: center;
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  z-index: 200;
`
