import React from 'react'
import { Container, IconContainer, OptionContainer, OptionTitle } from './styles'

function Option({ children, icon, iconColor, title, ...props }) {
  return (
    <Container {...props}>
      <OptionContainer>
        <IconContainer iconColor={iconColor}>{icon}</IconContainer>
        <OptionTitle>{title}</OptionTitle>
      </OptionContainer>
      {children}
    </Container>
  )
}

export default Option
