import styled, { css } from 'styled-components'
import { DropdownButton } from '@/presentation/components'
import { BREAK_POINTS } from '@/main/utils/constants'

export const PaddingBox = styled.div`
  height: 100%;
  width: 100%;
  padding: 32px;
  position: relative;
`

export const HeaderFilter = styled.div`
  background-color: var(--color-primary-05);
  display: flex;
  height: 45px;
  width: 100%;
  cursor: pointer;

  ${({ activeFilter }) => css`
    & > :nth-child(${activeFilter}) {
      color: var(--color-primary-01);
      font-weight: var(--font-weight-semi-bold);
      border-bottom: 3px solid var(--color-primary-01);
    }
  `}
`

export const FilterOption = styled.div`
  color: var(--color-primary-04);
  font-size: 1rem;
  letter-spacing: 0.01625rem;
  padding: 14px 16px;
  height: 100%;
  box-sizing: border-box;
  white-space: nowrap;
`

export const CustomDropdownButton = styled(DropdownButton)`
  margin-right: 32px;
  min-width: 300px;
  max-width: 300px;
  @media (max-width: ${BREAK_POINTS.md}) {
    margin: 32px 0;
  }
`

export const Body = styled.div`
  background-color: #fff;
  width: 500px;
  overflow: hidden;
`

export const TitleModal = styled.p`
  color: var(--color-primary-01);
  font-weight: bold;
`

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Close = styled.p`
  cursor: pointer;
  text-align: right;
  font-size: 20px;
  margin-top: -5px;
`

export const Subtitle = styled.p`
  color: var(--color-primary-04);
  font-weight: 600;
  font-size: 16px;
  margin-top: 15px;
`

export const Options = styled.div`
  padding: 12px;
  border-top: 1px solid #efe7e7;
  color: #757576;
  display: flex;
`

export const Label = styled.div`
  margin-bottom: 10px;
  white-space: nowrap;
  text-align: left;
  font-size: 17px;
`

export const Error = styled.p`
  color: red;
  font-size: 14px;
  text-align: left;
`

export const RadioWrapper = styled.div`
  margin-bottom: 30px;
  display: flex;
  font-size: 1rem;
  p {
    margin: 0 8px;
  }
`
