import { Icon } from '@/presentation/components/atoms'
import { styled } from '@mui/material'

export const CustomIcon = styled(Icon)<{ noHover?: boolean }>(({ noHover }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '8px',
  height: '20px',
  width: '20px',
  margin: 'auto',
  transition: 'all .25s ease-in-out',
  '&:hover': noHover
    ? {}
    : {
        backgroundColor: 'white',
        boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.15)',
      },
}))
