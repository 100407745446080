import api from '@/infra/api'
import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import axios from 'axios'

interface IViaCepResponse {
  cep: string
  logradouro: string
  complemento: string
  bairro: string
  localidade: string
  uf: string
  ibge: string
  gia: string
  ddd: string
  siafi: string
}

interface ICitiesResponse {
  data: Array<{
    code: number
    name: string
    state: string
    ibge: number
  }>
}

interface IUseAddressesInfos {
  config?: UseQueryOptions<any>
  cep: string | null
}

export const useAddressesInfos = (params: IUseAddressesInfos) => {
  const {
    cep,
    config = {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  } = params

  const { data: viaCep, isFetching: isLoadingViaCep } = useQuery({
    queryKey: ['viacep', cep],
    queryFn: () => axios.get<IViaCepResponse>(`https://viacep.com.br/ws/${cep}/json/ `),
    enabled: cep?.length >= 8,
    ...config,
  })

  const { data: cityCode, isFetching: isLoadingCityCode } = useQuery({
    queryKey: ['cityCode', viaCep?.data?.localidade, viaCep?.data?.ibge],
    queryFn: () =>
      api.get<ICitiesResponse>(`resale-bff/address/cities?name=${viaCep?.data?.localidade}&ibge=${viaCep?.data?.ibge}`),
    enabled: Boolean(viaCep?.data?.localidade) && Boolean(viaCep?.data?.ibge),
    ...config,
  })

  const cepData = viaCep
    ? {
        ...viaCep?.data,
        cityCode: cityCode?.data?.data?.[0]?.code,
      }
    : null

  return {
    cepData: cepData,
    isLoading: isLoadingViaCep || isLoadingCityCode,
  }
}
