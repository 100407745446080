export const Platform = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M14.5 4C15.3125 4 16 4.6875 16 5.5V8.5C16 9.34375 15.3125 10 14.5 10H12.75V11.25H21.25C21.6562 11.25 22 11.5938 22 12C22 12.4375 21.6562 12.75 21.25 12.75H17.75V14H19.5C20.3125 14 21 14.6875 21 15.5V18.5C21 19.3438 20.3125 20 19.5 20H14.5C13.6562 20 13 19.3438 13 18.5V15.5C13 14.6875 13.6562 14 14.5 14H16.25V12.75H7.75V14H9.5C10.3125 14 11 14.6875 11 15.5V18.5C11 19.3438 10.3125 20 9.5 20H4.5C3.65625 20 3 19.3438 3 18.5V15.5C3 14.6875 3.65625 14 4.5 14H6.25V12.75H2.75C2.3125 12.75 2 12.4375 2 12C2 11.5938 2.3125 11.25 2.75 11.25H11.25V10H9.5C8.65625 10 8 9.34375 8 8.5V5.5C8 4.6875 8.65625 4 9.5 4H14.5ZM14.5 5.5H9.5V8.5H14.5V5.5ZM4.5 18.5H9.5V15.5H4.5V18.5ZM19.5 15.5H14.5V18.5H19.5V15.5Z'
        fill='#616161'
      />
    </svg>
  )
}
