import { all, takeLatest, put, call } from 'redux-saga/effects'
import { pedidosSuccess, pedidosFailure } from './actions'
import api from '@/infra/api'
// import { queryBuilder } from "@/utils/functionUtils";

function* requestOrders({ payload }) {
  try {
    const response = yield call(api.get, `/resale-bff/orders${payload.query}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    const pedidos = response.data

    yield put(pedidosSuccess(pedidos))
  } catch (error) {
    yield put(pedidosFailure())
  }
}

export default all([takeLatest('@pedidos/PEDIDOS_REQUEST', requestOrders)])
