import React, { useState } from 'react'
import { faPenToSquare, faTrashCan } from '@fortawesome/free-regular-svg-icons'
import { Modal } from '@/presentation/components'
import { ACTIONS, BENEFIT_TYPE, CALCULATION_TYPE, VALUE_TYPE } from '@/main/utils/constants'
import { formatMoney } from '@/main/utils/functionUtils'
import { CustomTable, IconContainer } from '../../style'
import { Tooltip } from '@/presentation/components/atoms/tooltip'
import { Icon } from '@/presentation/components/atoms/Icon'

const GROUPS = [
  BENEFIT_TYPE.TRANSPORT,
  BENEFIT_TYPE.FOOD,
  BENEFIT_TYPE.SNACK,
  BENEFIT_TYPE.FUEL,
  BENEFIT_TYPE.TRANSPORT_PAPER,
]

function TaxesTable({ data, setAction, onDelete, editable }) {
  const [feeId, setFeeId] = useState(null)
  const generateTableHeader = () => (
    <CustomTable.Tr>
      <CustomTable.Th>Produto</CustomTable.Th>
      <CustomTable.Th>Cálculo por</CustomTable.Th>
      <CustomTable.Th>Range</CustomTable.Th>
      <CustomTable.Th>Valor</CustomTable.Th>
    </CustomTable.Tr>
  )

  const generateTableRows = (fees) =>
    fees.map((item) => {
      if (BENEFIT_TYPE.TRANSPORT !== item.benefitType) return null
      const tax = item.valueType === VALUE_TYPE.VALUE ? `R$ ${formatMoney(item.value || 0)}` : `${item.value}%`
      return (
        <CustomTable.Tr key={item.id}>
          <CustomTable.Td>{BENEFIT_TYPE[item.benefitType]}</CustomTable.Td>
          <CustomTable.Td>{CALCULATION_TYPE[item.calculationType]}</CustomTable.Td>
          <CustomTable.Td>{`${item.startRange}-${item.endRange}`}</CustomTable.Td>
          <CustomTable.Td>{tax}</CustomTable.Td>
          {editable && (
            <CustomTable.Td>
              <IconContainer>
                <Tooltip title='Editar'>
                  <Icon icon={faPenToSquare} onClick={() => setAction({ item, action: ACTIONS.EDIT })} />
                </Tooltip>
                <Tooltip title='Remover taxa' sx={{ marginLeft: '16px' }}>
                  <Icon icon={faTrashCan} onClick={() => setFeeId(item.id)} />
                </Tooltip>
              </IconContainer>
            </CustomTable.Td>
          )}
        </CustomTable.Tr>
      )
    })

  const generateTableRowsByGroups = () => {
    const groupedFees = GROUPS.reduce((all, group) => {
      const filtered = data.filter((fee) => fee.benefitType === group)
      return [...all, ...generateTableRows(filtered)]
    }, [])
    return groupedFees
  }

  return (
    <>
      <CustomTable>
        <thead>{generateTableHeader()}</thead>
        <tbody>{generateTableRowsByGroups()}</tbody>
      </CustomTable>
      {feeId && (
        <Modal
          open={!!feeId}
          cancel={() => {
            setFeeId(null)
          }}
          confirm={() => onDelete(feeId)}
        >
          Deseja realmente apagar essa taxa?
        </Modal>
      )}
    </>
  )
}

export default TaxesTable
