import { faPix } from '@fortawesome/free-brands-svg-icons'
import { faBarcode, faHandHoldingDollar, faWallet } from '@fortawesome/free-solid-svg-icons'

export const docIcons = ({ paymentType }: { paymentType: 'Boleto' | 'Pix' | 'Carteira Digital' | 'Gratuito' }) => {
  switch (paymentType) {
    case 'Boleto': {
      return faBarcode
    }
    case 'Pix': {
      return faPix
    }
    case 'Carteira Digital': {
      return faWallet
    }
    case 'Gratuito': {
      return faHandHoldingDollar
    }
  }
}
