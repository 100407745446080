import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Icon,
  Link,
  Tooltip,
  Typography,
} from '@/presentation/components/atoms'
import { faChevronDown, faFileArrowDown } from '@fortawesome/free-solid-svg-icons'
import { useLocation, useNavigate, useRouteError } from 'react-router-dom'
import { CopyIcon, ErrorCopyPositionBox, StyledAccordion, StyledBox, StyledIcon, StyledPaper } from './styles'
import { faCopy, faFaceDizzy } from '@fortawesome/free-regular-svg-icons'

export const ErrorPage = () => {
  const error = useRouteError()
  const location = useLocation()
  const navigate = useNavigate()

  const errorToSend = `path: ${location.pathname}` + '\n' + error.message + '\n' + error.stack

  const handleCopyClick = (e) => {
    e.stopPropagation()
    navigator.clipboard.writeText(errorToSend)
  }

  const saveText = (fileName, value) => {
    const link = document.createElement('a')
    const file = new Blob([value], { type: 'text/plain' })
    link.href = URL.createObjectURL(file)
    link.download = fileName
    link.click()
    URL.revokeObjectURL(link.href)
  }

  const returnToApp = () => navigate('/')

  return (
    <StyledBox>
      <StyledPaper>
        <StyledIcon icon={faFaceDizzy} />
        <Typography variant='h2' sx={{ marginBottom: '16px' }}>
          Ocorreu um erro no sistema!
        </Typography>
        <Typography sx={{ textAlign: 'center' }}>
          Desculpe pelo inconveniente! Siga estes passos para solucionar:
        </Typography>
        <Typography>
          1 - Faça o download do erro no botão <strong>Salvar erros</strong>
          <br />2 - Abra um chamado na central de suporte e anexe o arquivo baixado
        </Typography>
        <Link
          href='https://kimmaistecnologia.atlassian.net/servicedesk/customer/portal/1'
          target='_blank'
          rel='noreferrer'
        >
          Abrir chamado
        </Link>
        <ErrorCopyPositionBox>
          <Tooltip title='Copiar erro'>
            <CopyIcon icon={faCopy} onClick={handleCopyClick} />
          </Tooltip>
          <StyledAccordion>
            <AccordionSummary
              expandIcon={<Icon icon={faChevronDown} />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Typography>Detalhes do erro</Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                maxHeight: '200px',
                overflow: 'auto',
              }}
            >
              <Typography>{error.message}</Typography>
              <Typography>{error.stack}</Typography>
            </AccordionDetails>
          </StyledAccordion>
        </ErrorCopyPositionBox>
        <Box
          sx={{
            display: 'flex',
            gap: '32px',
            '& button': { width: '200px' },
          }}
        >
          <Button
            variant='outlined'
            endIcon={<Icon icon={faFileArrowDown} />}
            onClick={() => saveText('Erro.txt', errorToSend)}
          >
            Salvar erros
          </Button>
          <Button variant='contained' onClick={returnToApp}>
            Voltar
          </Button>
        </Box>
      </StyledPaper>
    </StyledBox>
  )
}
