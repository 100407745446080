import { Chip, styled } from '@mui/material'
import { Box } from '@stationkim/front-ui'

export const OperatorStatusChip = styled(Chip)<{ status?: 'inactive' | 'active' }>(({ theme, status = 'active' }) => {
  const variants = {
    inactive: {
      backgroundColor: theme.palette.grey[100],
      color: theme.palette.grey[700],
    },
    active: {
      backgroundColor: theme.palette.success.dark + '0D',
      color: theme.palette.success.dark,
    },
  }

  return {
    borderRadius: '4px',
    padding: '4px 6px',
    ...variants[status],
    '& span': {
      padding: 0,
    },
  }
})

export const IconContainer = styled(Box)<{ color?: 'standard' | 'confirm' | 'cancel' }>(
  ({ theme, color = 'standard' }) => {
    const containerColor = {
      standard: {
        background: theme.palette.grey[700] + '1A',
        color: theme.palette.grey[700],
      },
      confirm: {
        background: theme.palette.success.dark + '1A',
        color: theme.palette.success.dark,
      },
      cancel: {
        background: theme.palette.error.dark + '1A',
        color: theme.palette.error.dark,
      },
    }
    return {
      height: '20px',
      width: '24px',
      marginLeft: 'auto',
      borderRadius: '2px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      ...containerColor[color],
      '& svg': {
        height: '12px',
        width: '12px',
      },
    }
  },
)
