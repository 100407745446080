import { EMessageType } from '@/main/store'

export const useModifyCompanyConfig = ({ notification, navigate, queryClient, companyId }) => ({
  config: {
    approve: {
      onSuccess: () => {
        notification.push({ content: 'Empresa aprovada com sucesso!', type: EMessageType.Success })
        queryClient.invalidateQueries({ queryKey: ['company', companyId] })
      },
    },
    reprove: {
      onSuccess: () => {
        notification.push({ content: 'Empresa reprovada com sucesso!', type: EMessageType.Success })
        navigate('/empresas')
      },
    },
  },
})
