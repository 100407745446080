const ACTION = '@notifications/'

export const ACTIONS = {
  PUSH_NOTIFICATION: `${ACTION}PUSH_NOTIFICATION`,
  POP_NOTIFICATION: `${ACTION}POP_NOTIFICATION`,
}

export function pushNotification(message) {
  return {
    type: ACTIONS.PUSH_NOTIFICATION,
    payload: message,
  }
}

export function popNotification() {
  return {
    type: ACTIONS.POP_NOTIFICATION,
  }
}
