import api from '@/infra/api'
import { useQuery } from '@tanstack/react-query'
import { useAtom } from 'jotai'
import { companyIdAtom } from '../store/url/shared'
import { IFetchHook } from '../interfaces/iFetchHook'

const defaultValue = {
  data: [],
  pageNumber: 1,
  pageSize: 10,
  totalItens: 0,
  totalPages: 1,
}
export const useCompanyEmployees = ({ filter, pagination, immutableQuery }: IFetchHook) => {
  const [companyId] = useAtom(companyIdAtom)
  const { data, ...rest } = useQuery({
    queryKey: ['companyEmployees', companyId, filter, pagination, immutableQuery],
    queryFn: ({ queryKey }) =>
      api.get(`/resale-bff/companies/${queryKey[1]}/benefit`, {
        params: { ...pagination, ...filter, ...immutableQuery },
      }),
  })

  return {
    employees: data?.data || defaultValue,
    ...rest,
  }
}
