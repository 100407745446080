import React, { useState } from 'react'
import { Table, OrderIcon } from '@/presentation/components'
import { Wrapper, StatusDot } from './styles'
import EditUser from '../edit'

const UsersTable = ({ changeOrder, rechargesInfo, loading, refresh }) => {
  const [edit, setEdit] = useState(false)
  const [data, setData] = useState(null)

  const editUser = (value) => {
    setData(value)
    setEdit(true)
  }

  function generateHeaders() {
    return (
      <>
        <Table.Tr>
          <Table.Th>
            <Wrapper>
              Nome
              <OrderIcon clickUp={() => changeOrder('name')} clickDown={() => changeOrder('name', false)} />
            </Wrapper>
          </Table.Th>
          <Table.Th>
            <Wrapper>
              E-mail
              <OrderIcon clickUp={() => changeOrder('email')} clickDown={() => changeOrder('email', false)} />
            </Wrapper>
          </Table.Th>
          <Table.Th>
            <Wrapper>
              Status
              <OrderIcon clickUp={() => changeOrder('active')} clickDown={() => changeOrder('active', false)} />
            </Wrapper>
          </Table.Th>
        </Table.Tr>
      </>
    )
  }

  function generateRows(usersInfo) {
    return usersInfo?.data?.length
      ? usersInfo.data.map((users, index) => (
          <Table.Tr key={index} onClick={() => editUser(users)} style={{ cursor: 'pointer' }}>
            <Table.Td>{users.name}</Table.Td>
            <Table.Td>{users.email}</Table.Td>
            <Table.Td>
              <StatusDot status={users.active} />
              {users.active ? 'Ativo' : 'Inativo'}
            </Table.Td>
          </Table.Tr>
        ))
      : null
  }

  return (
    <>
      <Table loading={loading}>
        <thead>{generateHeaders()}</thead>
        <tbody>{rechargesInfo && generateRows(rechargesInfo)}</tbody>
      </Table>
      {edit && <EditUser open={edit} close={() => setEdit(false)} data={data} refresh={refresh} />}
    </>
  )
}

export default UsersTable
