import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'
import { faInstagram, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { Logo } from '@/presentation/components/atoms/logo'
import { Footer } from '@/presentation/components/atoms/footer'
import { Contact } from '@/presentation/components/molecules/contact'
import { SocialMedias } from '@/presentation/components/molecules/socialMedias'

import { Box } from '@/presentation/components/atoms/box'
import { isUrlValid } from '@/main/utils/functionUtils'
import { AccountPersistentStore } from '@/main/store/account'
import { useAtomWithStorageObserver } from '@/main/hooks'

export const PageFooter = () => {
  const [user] = useAtomWithStorageObserver({ atomWithStorage: AccountPersistentStore, storageName: 'account' })

  const medias = [
    { link: user?.resale?.linkedin, icon: faLinkedin },
    { link: user?.resale?.instagram, icon: faInstagram },
    { link: user?.resale?.twitter, icon: faTwitter },
  ]

  const mediasToRender = medias.filter((media) => isUrlValid(media.link))
  return (
    <Footer>
      <Logo
        src={user?.resale?.images?.footer}
        sx={{
          display: { xs: 'none', md: 'initial' },
        }}
      />
      <Box
        sx={{
          display: 'flex',
          gap: { xs: '4px', md: '40px' },
          flexDirection: { xs: 'column', md: 'row' },
          marginRight: '16px',
        }}
      >
        {user?.resale?.phone ? (
          <Contact icon={faPhone} name='Telefone' value={user?.resale?.phone} type='phone' />
        ) : null}
        {user?.resale?.email ? <Contact icon={faEnvelope} name='E-mail' value={user?.resale?.email} /> : null}
      </Box>
      <SocialMedias medias={mediasToRender} sx={{ display: { xs: 'none', sm: 'flex' } }} />
    </Footer>
  )
}
