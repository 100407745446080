import {
  faBars,
  faCartShopping,
  faDollarSign,
  faHouse,
  faPaperclip,
  faSuitcase,
} from '@fortawesome/free-solid-svg-icons'
import { Header } from '@/presentation/components/atoms/header'
import { NavigationBar } from '@/presentation/components/molecules/navigationBar'
import { UserIdentification } from '@/presentation/components/molecules/userIdentification'

import { Icon } from '@/presentation/components/atoms/Icon'
import { SideNavigationMenu } from '@/presentation/components/molecules/sideNavigationMenu'
import { useState } from 'react'
import { Logo } from '@/presentation/components/atoms/logo'
import { UserMenu } from '@/presentation/components/molecules/userMenu'
import Notification from '@/presentation/components/notification'
import { AccountPersistentStore } from '@/main/store/account'
import { useAtomWithStorageObserver, useDocumentTitle } from '@/main/hooks'
import { DialogsContainer } from '../../dialogs/dialogsContainer/dialogsContainer'

const destinations = [
  {
    pathname: '/',
    icon: faHouse,
    displayName: 'Início',
  },
  {
    pathname: '/empresas',
    icon: faSuitcase,
    displayName: 'Empresas',
  },
  {
    pathname: '/pedidos',
    icon: faCartShopping,
    displayName: 'Pedidos',
  },
  {
    pathname: '/relatorios',
    icon: faPaperclip,
    displayName: 'Relatórios',
  },
  {
    pathname: '/financeiro',
    icon: faDollarSign,
    displayName: 'Financeiro',
  },
]

export const PageHeader = () => {
  const [user] = useAtomWithStorageObserver({ atomWithStorage: AccountPersistentStore, storageName: 'account' })
  const [anchorEl, setAnchorEl] = useState(null)
  const [openDrawer, setOpenopenDrawer] = useState(false)
  useDocumentTitle({ config: { autoChange: true } })

  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleDrawerOpen = () => {
    setOpenopenDrawer(true)
  }

  const handleDrawerClose = () => {
    setOpenopenDrawer(false)
  }

  return (
    <Header>
      <Notification />
      <DialogsContainer />

      <Icon
        icon={faBars}
        onClick={handleDrawerOpen}
        sx={{
          height: '36px',
          marginRight: '32px',
          display: { xs: 'initial', sm: 'none' },
        }}
      />
      <Logo src={user?.resale?.images?.header} sx={{ marginRight: '44px' }} />
      <NavigationBar destinations={destinations} />
      <SideNavigationMenu destinations={destinations} open={openDrawer} onClose={handleDrawerClose} />
      <UserIdentification
        userName={user?.user?.name || 'Usuário'}
        companyName={user?.resale?.displayName || 'Empresa'}
        profilePicSrc={user?.user?.urlPhoto}
        onClick={handleClick}
      />
      <UserMenu anchorEl={anchorEl} id='account-menu' open={open} onClose={handleClose} onClick={handleClose} />
    </Header>
  )
}
