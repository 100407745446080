import styled from '@emotion/styled'

export const Tabs = styled.div`
  width: 275px;
  height: 710px;
  border-right: 1px solid var(--color-secundary-04);
  padding: 8px 16px;

  ul {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`

export const Tab = styled.li<{ open: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 48px;
  padding: 8px 16px;
  cursor: pointer;
  background-color: ${({ open }) => (open ? 'rgba(25, 118, 210, 0.08)' : '')};

  div {
    display: flex;
    align-items: center;

    p {
      color: var(--Common-Black, #060606);
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 500;
      margin-left: 16px;
    }
  }
`

export const Option = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  padding-left: 56px;
  margin-top: 16px;
  width: 100%;
  height: 34px;
  background-color: ${({ selected }) => (selected ? 'rgba(25, 118, 210, 0.08)' : '')};
  cursor: pointer;
`
