import { Typography } from '@mui/material'
import { Icon } from '@/presentation/components/atoms/Icon'
import { Navbar } from '@/presentation/components/atoms/navbar'
import { NavbarItem } from '@/presentation/components/atoms/navbarItem'
import { Tooltip } from '@/presentation/components/atoms/tooltip'
import { Fragment } from 'react'
import { StyledDivider } from './styles'

export function NavigationBar({ destinations = [], ...props }) {
  return (
    <Navbar {...props} sx={{ display: { xs: 'none', sm: 'flex' } }}>
      {destinations.map((destination, index) => (
        <Fragment key={`${index}_menu_option`}>
          {index === 0 ? null : <StyledDivider />}
          <NavbarItem to={destination.pathname}>
            <Tooltip title={destination.displayName} sx={{ display: { xs: 'initial', lg: 'none' } }}>
              <Icon icon={destination.icon} />
            </Tooltip>
            <Icon icon={destination.icon} sx={{ display: { xs: 'none', lg: 'initial' } }} />
            <Typography sx={{ display: { xs: 'none', lg: 'initial' } }}>{destination.displayName}</Typography>
          </NavbarItem>
        </Fragment>
      ))}
    </Navbar>
  )
}
