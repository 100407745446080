import { formatMoney, getLocaleDateString } from '@/main/utils/functions'
import { OrderActions } from '../components/orderActions/orderActions'
import { ORDER_STATUS, ORDER_TYPES, PURCHASE_STATUS } from '@/main/utils/enumsMapping'
import { EStatusCompra, EStatusPedido } from '@/main/enums'
import { PaymentIcons } from '../components/paymentIcons/paymentIcons'

export const getTableConfiguration = ({ updateFilter }) => {
  const tableConfiguration = [
    {
      columnDisplayName: 'Código',
      columnValue: ({ row }) => row.code,
      onOrder: ({ order }) => updateFilter({ OrdenarPor: 'NumeroPedido', OrdenarDecrescente: !order }),
    },
    {
      columnDisplayName: 'Empresa',
      columnValue: ({ row }) => row.companyName,
    },
    {
      columnDisplayName: 'Data Pedido',
      columnValue: ({ row }) => getLocaleDateString({ date: row.orderDate }),
      onOrder: ({ order }) => updateFilter({ OrdenarPor: 'DataCriacao', OrdenarDecrescente: !order }),
    },
    {
      columnDisplayName: 'Valor',
      columnValue: ({ row }) => `R$ ${formatMoney(row.totalValue)}`,
    },
    {
      columnDisplayName: 'Tipo de Pedido',
      columnValue: ({ row }) => ORDER_TYPES[row.orderType],
    },
    {
      columnDisplayName: 'Tipo Pgto',
      columnValue: ({ row }) => <PaymentIcons paymentTypes={row?.paymentTypes} />,
    },
    {
      columnDisplayName: 'Status',
      columnValue: ({ row }) => {
        if (
          ![EStatusPedido.Cancelado, EStatusPedido.Cancelando].includes(row.status) &&
          [EStatusCompra.AguardandoPagamento, EStatusCompra.ProcessandoFormaPagamento].includes(row.purchaseStatus)
        )
          return PURCHASE_STATUS[row.purchaseStatus]
        return ORDER_STATUS[row.status]
      },
    },
    {
      columnDisplayName: 'Ações',
      columnValue: () => '',
      onColumnClick: ({ event }) => {
        event.stopPropagation()
      },
      preventLink: true,
      customRender: ({ row }) => <OrderActions order={row} />,
    },
  ]

  return tableConfiguration
}
