import { BENEFIT_TYPE, PRODUCT_STATUS } from '@/main/utils/constants'
import { formatMoney } from '@/main/utils/functionUtils'
import { Box, ColorDot } from '@/presentation/components/atoms'

export const companyCardsTableConfiguration = ({ updateFilter }) => {
  return [
    {
      columnDisplayName: 'Nome Cartão',
      columnValue: ({ row }) => row.benefitName,
      onOrder: ({ order }) => updateFilter({ sort: 'benefitName', desc: !order }),
    },
    {
      columnDisplayName: 'N° Cartão',
      columnValue: ({ row }) => row.number,
    },
    {
      columnDisplayName: 'Colaborador',
      columnValue: ({ row }) => row.fullname,
      onOrder: ({ order }) => updateFilter({ sort: 'fullname', desc: !order }),
    },
    {
      columnDisplayName: 'Matrícula',
      columnValue: ({ row }) => row.registration,
    },
    {
      columnDisplayName: 'Benefício',
      columnValue: ({ row }) => BENEFIT_TYPE[row.benefitType],
    },
    {
      columnDisplayName: 'Valor diário',
      columnValue: ({ row }) => `R$ ${formatMoney(row.dailyValue || 0)}`,
      onOrder: ({ order }) => updateFilter({ sort: 'dailyValue', desc: !order }),
    },
    {
      columnDisplayName: 'Saldo',
      columnValue: ({ row }) => `R$ ${formatMoney(row.balance || 0)}`,
    },
    {
      columnDisplayName: 'Via',
      columnValue: ({ row }) => `${row.copy || '?'}ª Via`,
      onOrder: ({ order }) => updateFilter({ sort: 'copy', desc: !order }),
    },
    {
      columnDisplayName: 'Status',
      columnValue: ({ row }) => row.status,
      customRender: ({ row }) => {
        let dotColor = 'success'
        if (row.status === PRODUCT_STATUS.BLOCKED) dotColor = 'error'
        if (row.status === PRODUCT_STATUS.INACTIVE) dotColor = 'grey'
        return (
          <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center', textTransform: 'capitalize' }}>
            <ColorDot color={dotColor} status={PRODUCT_STATUS[row.status]} />
            {PRODUCT_STATUS[row.status]?.toLowerCase()}
          </Box>
        )
      },
    },
  ]
}
