import { useAtom } from 'jotai'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { Theme, useMediaQuery, useTheme } from '@mui/material'
import { CompanyMigrationErrorPersistentStore } from '@/main/store/companyMigrationError'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  Skeleton,
  Typography,
} from '@/presentation/components/atoms'
import { useMigrationRerun } from '../hooks/useMigrationRerun'

interface MigrationErrorDialogProps {
  open: boolean
  onClose: () => void
  error: {
    message: string
    migrationId: string
    companyId: string
  }
}

const MigrationErrorDialogSkeleton = () => {
  return (
    <Box>
      <Skeleton width='96px' height='96px' variant='circular' sx={{ margin: '0 auto 48px' }} />
      <Skeleton width='200px' variant='rounded' height='32px' sx={{ margin: '0 auto 48px' }} />
      <Skeleton width='372px' variant='rounded' />
      <Box sx={{ display: 'flex', gap: '16px', margin: '8px 0' }}>
        <Skeleton width='178px' variant='rounded' />
        <Skeleton width='178px' variant='rounded' />
      </Box>
      <Skeleton width='372px' variant='rounded' />

      <Box sx={{ display: 'flex', gap: '32px', marginTop: '48px' }}>
        <Skeleton width='170px' height='42px' variant='rounded' />
        <Skeleton width='170px' height='42px' variant='rounded' />
      </Box>
    </Box>
  )
}

export const MigrationErrorDialog = ({ open, error, onClose }: MigrationErrorDialogProps) => {
  const theme = useTheme()
  const isLowerThanSm = useMediaQuery(theme.breakpoints.down('sm'))
  const [records, setRecords] = useAtom(CompanyMigrationErrorPersistentStore)
  const closeModal = () => {
    const newRecords = { ...records }
    newRecords[error.companyId] = { migrationId: error.migrationId }
    setRecords(newRecords)
    onClose && onClose()
  }

  const { rerun, isLoading } = useMigrationRerun({
    onSuccess: closeModal,
  })

  const reprocess = () => rerun({ migrationId: error.migrationId })

  return (
    <Dialog open={open} maxWidth='md'>
      {isLoading ? (
        <MigrationErrorDialogSkeleton />
      ) : (
        <>
          <Box
            sx={(theme: Theme) => ({
              height: '96px',
              width: '96px',
              backgroundColor: theme.palette.grey[50],
              borderRadius: '50%',
              margin: 'auto',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            })}
          >
            <Icon
              icon={faExclamationCircle}
              color='red'
              sx={{
                height: '52px',
                width: '52px',
              }}
            />
          </Box>
          <DialogTitle sx={{ margin: '48px auto 0px' }}>Erro de migração: {error.message}</DialogTitle>
          <DialogContent sx={{ marginTop: '24px' }}>
            <Typography
              variant='label2'
              sx={(theme: Theme) => ({
                color: theme.palette.grey[700],
                fontWeight: 400,
              })}
            >
              Foi encontrado um erro durante a migração desta empresa, verifique os dados informados,
              <br /> reprocesse a migração e aguarde até que o processo seja concluído.
            </Typography>
          </DialogContent>
          <DialogActions
            sx={{
              marginTop: '48px',
              display: 'flex',
              gap: '16px',
              flexDirection: isLowerThanSm ? 'column-reverse' : 'row',
              '& button': { marginLeft: '0 !important' },
            }}
          >
            <Button variant='outlined' size='medium' sx={{ filter: 'grayScale(100%)' }} onClick={closeModal} fullWidth>
              Voltar
            </Button>
            <Button variant='contained' size='medium' color='error' fullWidth onClick={reprocess}>
              Reprocessar migração
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}
