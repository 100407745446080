import {
  DatePicker,
  DatePickerProps,
  FieldWrapper,
  FieldWrapperProps,
  InputLabel,
} from '@/presentation/components/atoms'
import { forwardRef } from 'react'

export interface DatePickerFieldProps<TDate> extends DatePickerProps<TDate> {
  label?: string
}

export const DatePickerField = forwardRef(
  <TDate,>(
    {
      id,
      label,
      error = false,
      success = false,
      blocked,
      disabled,
      helperText,
      ...props
    }: DatePickerFieldProps<TDate> & FieldWrapperProps,
    ref,
  ) => {
    return (
      <FieldWrapper helperText={helperText} error={error} success={success}>
        <InputLabel htmlFor={id} label={label}>
          <DatePicker
            slotProps={{ textField: { inputRef: ref } }}
            blocked={blocked}
            disabled={blocked || disabled}
            {...props}
          />
        </InputLabel>
      </FieldWrapper>
    )
  },
)
