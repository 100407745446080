import React from 'react'
import { Body, SessionButton, Close } from './style'
import { Button, ErrorMessage, ErrorWrapper, Input, Title } from '@/presentation/components'
import 'react-responsive-modal/styles.css'
import Modal from 'react-responsive-modal'
import { Controller, useForm } from 'react-hook-form'
import { benefitsTaxCalculationModes, benefitsTaxCalculationBy, benefitsTypes } from '@/main/utils/options'
import { Col, Container, Row } from 'react-grid-system'
import { BENEFIT_TYPE } from '@/main/utils/constants'
import { faX } from '@fortawesome/free-solid-svg-icons'
import { Icon } from '@/presentation/components/atoms/Icon'

const styles = {
  borderRadius: '5px',
  modal: {
    maxWidth: '700px',
    margin: '0',
    padding: '0',
  },
}

function TaxModal({ cancel, confirm, open, action, ACTIONS }) {
  const editMode = action.action === ACTIONS.EDIT

  const defaultValues = {
    ...action.item,
    calculationType: benefitsTaxCalculationBy.find((item) => Number(item.value) === action.item.calculationType),
    valueType: benefitsTaxCalculationModes.find((item) => Number(item.value) === action.item.valueType),
    benefitType:
      benefitsTypes.find((item) => Number(item.value) === action.item.benefitType) || action.item.benefitType,
  }

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ defaultValues })

  function onSubmit(data) {
    data.calculationType = Number(data.calculationType.value)
    data.valueType = Number(data.valueType.value)
    data.benefitType = Number(data.benefitType.value) || data.benefitType
    data.value = typeof data.value === 'string' ? parseFloat(data.value.replace(',', '.')) : data.value
    confirm(data)
  }

  return (
    <Modal center open={open} onClose={cancel} showCloseIcon={false} styles={styles}>
      <Body>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Title.SubTitle>{action.action === ACTIONS.CREATE ? 'Adicionar' : 'Editar'} Taxa </Title.SubTitle>
          <Close onClick={cancel}>
            <Icon icon={faX} />
          </Close>
        </div>
        {editMode && <p>{BENEFIT_TYPE[action.item.benefitType]}</p>}
        <form id='addTax' onSubmit={handleSubmit(onSubmit)}>
          <Container fluid>
            {!editMode && (
              <Row>
                <Col sm={6}>
                  <ErrorWrapper error={errors.benefitType}>
                    <label>Produto</label>
                    <Controller
                      control={control}
                      name='benefitType'
                      {...register('benefitType', { required: true })}
                      render={({ field: { onChange, value, ref } }) => (
                        <div>
                          <Input.Select
                            placeholder='Selecione o tipo de produto'
                            value={value}
                            inputRef={ref}
                            onChange={onChange}
                            options={benefitsTypes}
                            isClearable={false}
                          />
                        </div>
                      )}
                    />
                    <ErrorMessage>{errors.benefitType && 'Campo requerido'}</ErrorMessage>
                  </ErrorWrapper>
                </Col>
              </Row>
            )}
            <Row>
              <Col sm={6}>
                <ErrorWrapper error={errors.calculationType}>
                  <label>Cálculo por</label>
                  <Controller
                    control={control}
                    name='calculationType'
                    {...register('calculationType', { required: true })}
                    render={({ field: { onChange, value, ref } }) => (
                      <div>
                        <Input.Select
                          placeholder='Selecione o tipo de cálculo'
                          value={value}
                          inputRef={ref}
                          onChange={onChange}
                          options={benefitsTaxCalculationBy}
                          isClearable={false}
                        />
                      </div>
                    )}
                  />
                  <ErrorMessage>{errors.calculationType && 'Campo requerido'}</ErrorMessage>
                </ErrorWrapper>
              </Col>
              <Col sm={3}>
                <ErrorWrapper error={errors.startRange}>
                  <label>Range Inicial</label>
                  <Controller
                    control={control}
                    name='startRange'
                    {...register('startRange', { required: true })}
                    render={({ field: { onChange, value, ref } }) => (
                      <Input.Masked value={value} placeholder='0000000' getInputRef={ref} onChange={onChange} />
                    )}
                  />
                  <ErrorMessage>{errors.startRange && 'Campo requerido'}</ErrorMessage>
                </ErrorWrapper>
              </Col>
              <Col sm={3}>
                <ErrorWrapper error={errors.endRange}>
                  <label>Range Final</label>
                  <Controller
                    control={control}
                    name='endRange'
                    {...register('endRange', { required: true })}
                    render={({ field: { onChange, value, ref } }) => (
                      <Input.Masked value={value} placeholder='0000000' getInputRef={ref} onChange={onChange} />
                    )}
                  />
                  <ErrorMessage>{errors.endRange && 'Campo requerido'}</ErrorMessage>
                </ErrorWrapper>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <ErrorWrapper error={errors.valueType}>
                  <label>Categoria</label>
                  <Controller
                    control={control}
                    name='valueType'
                    {...register('valueType', { required: true })}
                    render={({ field: { onChange, value, ref } }) => (
                      <div>
                        <Input.Select
                          placeholder='Selecione o tipo'
                          value={value}
                          inputRef={ref}
                          onChange={onChange}
                          options={benefitsTaxCalculationModes}
                          isClearable={false}
                        />
                      </div>
                    )}
                  />
                  <ErrorMessage>{errors.valueType && 'Campo requerido'}</ErrorMessage>
                </ErrorWrapper>
              </Col>
              <Col sm={3}>
                <ErrorWrapper error={errors.value}>
                  <label>Valor Taxa</label>
                  <Controller
                    control={control}
                    name='value'
                    {...register('value', { required: true })}
                    render={({ field: { onChange, value, ref } }) => (
                      <Input.Decimal value={value} placeholder='00,00' getInputRef={ref} onChange={onChange} />
                    )}
                  />
                  <ErrorMessage>{errors.value && 'Campo requerido'}</ErrorMessage>
                </ErrorWrapper>
              </Col>
            </Row>
          </Container>
        </form>

        <SessionButton>
          <Button variant='outlined' width='200px' onClick={cancel}>
            Fechar
          </Button>
          <div style={{ paddingLeft: '15px' }} />
          <Button width='250px' form='addTax'>
            Salvar
          </Button>
        </SessionButton>
      </Body>
    </Modal>
  )
}

export default TaxModal
