import styled from 'styled-components'
import { Container as RGSContainer } from 'react-grid-system'
import { Table } from '@/presentation/components'

export const Container = styled(RGSContainer)`
  padding: 32px;
  > div + div {
    margin-top: 32px;
  }
`

export const SubGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Label = styled.div`
  margin-bottom: 10px;
`

export const BeneficiesContainer = styled.div`
  display: grid;
  grid-template-columns: 33% 33% 33%;
  grid-row-gap: 18px;
  max-width: 900px;
  margin: 32px 0;

  label {
    margin-left: 8px;
  }
`

export const BeneficiesText = styled.p`
  margin-top: 32px;
`

export const ButtonContainer = styled.div`
  width: 100%;
  flex-direction: column;
  text-align: center;
  font-size: 1rem;
  letter-spacing: 0.26px;
  margin-bottom: 32px;

  p {
    margin: 64px 0 32px 0;
    font-weight: var(--font-weight-semi-bold);
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: ${({ isValidating }) => (isValidating ? 'center' : 'flex-end')};
    width: 100%;
  }

  button {
    max-width: 250px;
  }

  button + button {
    margin-left: 32px;
  }
`

export const CustomTable = styled(Table)`
  border-collapse: collapse;

  tr {
    width: 100%;
  }

  td,
  th {
    min-width: 200px;
    padding: 0 16px;
  }

  td:nth-of-type(1),
  th:nth-of-type(1) {
    padding-left: 32px;
  }

  td:nth-of-type(4),
  th:nth-of-type(4),
  td:nth-of-type(5),
  th:nth-of-type(5) {
    text-align: right;
  }

  td:nth-of-type(5),
  th:nth-of-type(5) {
    padding-right: 32px;
  }

  td:nth-of-type(4) {
    * {
      justify-content: flex-end;
    }
  }

  th:nth-of-type(4) {
    text-align: right;
  }

  tr > th {
    color: var(--color-primary-01);
  }

  tbody > tr {
    background-color: var(--color-secundary-05);
    border-left: 2px solid var(--color-primary-01);
    border-bottom: 20px solid white;
    height: 60px;
  }

  tbody > tr:first-child {
    height: 50px;
  }

  tbody > tr:last-child {
    height: 50px;
    border-bottom: none;
  }
`

export const DownloadsContainer = styled.div`
  > div {
    margin-top: 32px;
  }

  > div:last-child {
    margin-bottom: 32px;
  }
`

export const InfoLink = styled.a`
  display: flex;
  align-items: center;
  color: var(--color-primary-01);
  font-size: 1.1875rem;
  font-weight: var(--font-weight-semi-bold);
  cursor: pointer;
  margin-top: 32px;

  + a {
    margin-top: 16px;
  }

  :hover {
    text-decoration: underline;
  }

  svg {
    height: 20px;
    width: 20px;
    margin-right: 8px;
    fill: var(--color-primary-01);
  }
`

export const IconContainer = styled.div`
  display: inline-flex;

  span:nth-of-type(2) {
    margin-left: 8px;
  }
`
