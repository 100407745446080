import { Table, Theme, styled } from '@mui/material'

const variants = {
  default: (theme: Theme) => ({
    '& td, & th': {
      border: 0,
      whiteSpace: 'nowrap',
      lineHeight: '32px',
      fontSize: '16px',
      padding: '4px 8px',
      '& td:first-of-type': {
        paddingLeft: 8,
      },
      '& td:last-of-type': {
        paddingRight: 8,
      },
    },

    '& tr:first-of-type th': {
      fontSize: '1rem !important',
      fontWeight: 700,
    },

    '& tbody tr:nth-of-type(odd)': {
      backgroundColor: theme.palette.grey[100],
    },
    '& tbody tr > td:first-of-type': {
      borderRadius: '8px 0 0 8px',
    },
    '& tbody tr > td:last-of-type': {
      borderRadius: '0 8px 8px 0',
    },
    '& tbody tr > td:only-of-type': {
      borderRadius: '8px',
    },
  }),
  report: (theme: Theme) => ({
    '& td, & th': {
      height: '40px',
      padding: '8px',
      border: 0,
      whiteSpace: 'nowrap',
      fontSize: '16px',
      '& td:first-of-type': {
        paddingLeft: 8,
      },
      '& td:last-of-type': {
        paddingRight: 8,
      },
    },

    '& thead tr:first-of-type': {
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
    '& tr:first-of-type th': {
      fontSize: '0.875rem !important',
      fontWeight: 600,
    },
  }),
}

export interface StyledTableProps {
  variant?: keyof typeof variants
}

export const StyledTable = styled(Table)<StyledTableProps>(({ theme, variant = 'default' }) => variants[variant](theme))
