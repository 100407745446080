import { faX } from '@fortawesome/free-solid-svg-icons'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Icon } from '@/presentation/components/atoms'
import { TextField } from '@/presentation/components/molecules'
import { Controller, useForm } from 'react-hook-form'

const fields = [
  {
    name: 'displayName',
    label: 'Nome da empresa',
    rules: {
      required: {
        value: true,
        message: 'Campo requerido',
      },
    },
  },
  {
    name: 'digitalAccountName',
    label: 'Nome da conta digital',
    rules: {
      required: {
        value: true,
        message: 'Campo requerido',
      },
    },
  },
]

export const AccountInfoEditionDialog = ({ open, initialValue = {}, onSave, onClose }) => {
  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm({
    defaultValues: initialValue,
  })

  const onSubmit = (data) => {
    if (isDirty) onSave(data)
    else onClose()
  }

  return (
    <Dialog open={open} maxWidth='lg' onClose={onClose}>
      <DialogTitle>
        Editar Informações de Conta
        <Icon icon={faX} onClick={onClose} sx={{ marginLeft: '130px' }} />
      </DialogTitle>
      <DialogContent>
        <form
          id='accountInfoEditForm'
          onSubmit={handleSubmit(onSubmit)}
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '32px',
            margin: '32px 0',
          }}
        >
          {fields.map((field, index) => (
            <Controller
              control={control}
              name={field.name}
              rules={field.rules}
              key={`${index}_field_info_edit`}
              render={({ field: { value, onChange }, fieldState: { error, isDirty } }) => (
                <TextField
                  label={field.label}
                  value={value}
                  onChange={onChange}
                  helperText={error?.message}
                  error={!!error}
                />
              )}
            />
          ))}
        </form>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button onClick={onClose}>Voltar</Button>
        <Button variant='contained' sx={{ width: '248px' }} type='submit' form='accountInfoEditForm'>
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
