import { useEffect, useRef, useState } from 'react'
import TaxesTable from './taxesTable'
import { Col, Container, Row } from 'react-grid-system'
import { BenefitsContainer, IssCheckboxContainer, SectionTitle } from './styles'
import { Spacing, Input, Line, Spoiler, Label, ModalV2, TextDecoration, Spinner } from '@/presentation/components'
import {
  ACTIONS,
  COMPANY_STATUS,
  DESK_FEE_ITEM_TYPE,
  NOTIFICATION_TYPE,
  PARAMETER_STATUS,
  QUANTITY_TYPE,
} from '@/main/utils/constants'
import { issIncidences } from '@/main/utils/options'
import { CustomTable, ButtonContainer, ModalText } from '../style'
import { useDispatch } from 'react-redux'
import { pushNotification } from '@/store/modules/notification/actions'
import TaxModal from './taxModal'
import { Box, Button, CircularProgress } from '@/presentation/components/atoms'
import { deleteRejectCompany, getCompany, putCompanyDeskFees } from '@/services/companiesServices'
import useService from '@/main/hooks/useService'
import api from '@/infra/api'
import { useAtom } from 'jotai'
import { companyIdAtom } from '@/main/store/url/shared'
import successHandler from '@/main/utils/successHandler'
import { infoCompanyRequest } from '@/store/modules/infoCompany/actions'
import { useNavigate } from 'react-router-dom'
import { Multiselect } from '@/presentation/components/organisms/multiselect'
import RejectionModal from './rejectionModal'
import errorHandler from '@/main/utils/errorHandler'

export const CompanyParameters = () => {
  const [fetchCompanyInfoState, fetchCompanyInfoRequest] = useService(getCompany, {})
  const company = fetchCompanyInfoState.response?.data
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [companyId] = useAtom(companyIdAtom)
  const [isFeesEditionModalVisible, setIsFeesEditionModalVisible] = useState(false)
  const [isWithMigrationError, setIsWithMigrationError] = useState(false)
  const [allowDepartmentChecked, setAllowDepartmentChecked] = useState(false)
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false)
  const [isIssChecked, setIsIssChecked] = useState(false)
  const isIssConfigurationAvailable =
    company?.status === COMPANY_STATUS.APPROVED || company?.status === COMPANY_STATUS.WAITING_APPROVAL
  const [slipFee, setSlipFee] = useState('0')

  const [action, setAction] = useState(null)
  const [operatorsCard, setOperatorsCard] = useState([])
  const [loading, setLoading] = useState(false)
  const [deskFees, setDeskFees] = useState([])
  const [successFee, setSuccessFee] = useState('0')
  const issChosedIncidences = useRef(0)
  const isIssAvailable = company?.issInfo?.issStatus === PARAMETER_STATUS.ACTIVE

  const [rejectCompanyState, rejectCompanyRequest] = useService(deleteRejectCompany, {
    onCompleted: () => {
      dispatch(pushNotification(successHandler('Empresa rejeitada.')))
      navigate('/empresas')
    },
  })

  const [editFeesState, requestEditFees] = useService(putCompanyDeskFees, {
    onCompleted: async () => {
      setLoading(true)
      try {
        await api.patch(`/resale-bff/companies/${companyId}/operatorcards`, operatorsCard)

        await api.patch(`/resale-bff/companies/${companyId}/departmentData`, {
          allowDefaultDepartmentOrder: allowDepartmentChecked,
        })
        dispatch(pushNotification(successHandler('Alterações realizadas com sucesso!')))
        dispatch(infoCompanyRequest(companyId))
      } catch (error) {
        dispatch(pushNotification(errorHandler(error?.response)))
      }
      setLoading(false)
      setIsFeesEditionModalVisible(false)
    },
  })

  const validateFeeRange = (feeToValidate) => {
    const comparsionGroup = deskFees.filter(
      (fee) =>
        fee.itemType === feeToValidate.itemType &&
        fee.benefitType === feeToValidate.benefitType &&
        fee.id !== feeToValidate.id,
    )
    const rangeValidation = !comparsionGroup.some((fee) => {
      const startRangeConflict =
        Number(feeToValidate.startRange) >= Number(fee.startRange) &&
        Number(feeToValidate.startRange) <= Number(fee.endRange)
      const endRangeConflict =
        Number(feeToValidate.endRange) >= Number(fee.startRange) &&
        Number(feeToValidate.endRange) <= Number(fee.endRange)
      return startRangeConflict || endRangeConflict
    })

    !rangeValidation &&
      dispatch(
        pushNotification({
          type: NOTIFICATION_TYPE.WARNING,
          content: 'O range definido gera conflito com outro já estabelecido',
        }),
      )
    return rangeValidation
  }

  const defineAction = (e, feeType) => {
    e.stopPropagation()
    setAction({
      item: {
        itemType: feeType,
        quantityType: QUANTITY_TYPE.QUANTITY,
      },
      action: ACTIONS.CREATE,
    })
  }

  const createNewFee = async (newFee) => {
    if (!validateFeeRange(newFee)) return
    setDeskFees((actual) => [...actual, { ...newFee }])
    setAction(null)
  }

  const editFee = async (feeToEdit) => {
    if (!validateFeeRange(feeToEdit)) return
    const index = deskFees.findIndex((fee) => fee.id === feeToEdit.id)
    setDeskFees((actual) => {
      const newFee = [...actual]
      newFee[index] = feeToEdit
      return newFee
    })
    setAction(null)
  }

  const deleteFee = async (feeId) => {
    setDeskFees((actual) => actual.filter((fee) => fee.id !== feeId))
  }

  const issFeeEnumSum = () => {
    const selectedFees = issChosedIncidences.current
    if (Array.isArray(selectedFees))
      return selectedFees.reduce((total, actual) => {
        return total + actual
      }, 0)
    return 0
  }

  const editFees = async () => {
    if (!companyId) return
    let payload = {
      slipFee,
      successFee: successFee,
      fees: deskFees,
    }
    if (isIssAvailable)
      payload = {
        ...payload,
        issFee: issFeeEnumSum(),
        issActive: isIssChecked,
      }
    requestEditFees(companyId, payload)
  }

  useEffect(() => {
    fetchCompanyInfoRequest(companyId)
  }, [])

  useEffect(() => {
    if (company) {
      setSlipFee(company?.slipFee)
      setSuccessFee(company?.successFee)
      setIsWithMigrationError(company?.status === COMPANY_STATUS.MIGRATION_ERROR)
      setOperatorsCard(company?.operatorsCard)
    }
  }, [company])

  useEffect(() => {
    if (company) {
      setDeskFees([
        ...company.administrateFees.map((fee) => ({
          ...fee,
          itemType: DESK_FEE_ITEM_TYPE.ADMINISTRATIVE,
        })),
        ...company.deliveryFees.map((fee) => ({
          ...fee,
          itemType: DESK_FEE_ITEM_TYPE.DELIVERY,
        })),
        ...company.reuseFees.map((fee) => ({
          ...fee,
          itemType: DESK_FEE_ITEM_TYPE.BALANCE_REUSE,
        })),
      ])
      setIsIssChecked(company?.issInfo?.active)
      setOperatorsCard(company?.operatorsCard)
      setAllowDepartmentChecked(company?.allowDefaultDepartmentOrder)
    }
  }, [company])

  return fetchCompanyInfoState.loading || !fetchCompanyInfoState.response ? (
    <Box sx={{ padding: '64px 16px', display: 'flex', justifyContent: 'center' }}>
      <CircularProgress />
    </Box>
  ) : (
    <>
      {isIssAvailable && (
        <BenefitsContainer>
          <SectionTitle>Descontos</SectionTitle>
          <IssCheckboxContainer>
            <Input.CheckBox
              checked={isIssChecked}
              controlled
              onClick={() => setIsIssChecked((state) => !state)}
              disabled={!isIssConfigurationAvailable}
            />
            <label>Retenção ISS</label>
          </IssCheckboxContainer>
          {isIssChecked && (
            <Container fluid style={{ padding: ' 0 0 16px 0' }}>
              <Row>
                <Col md={2}>
                  <Label>Cidade</Label>
                  <Input disabled value={company.issInfo.customName} style={{ height: 48 }} />
                </Col>
                <Col md={2}>
                  <Label>Valor (%)</Label>
                  <Input.Decimal disabled value={company.issInfo.iss} suffix=' %' style={{ height: 48 }} />
                </Col>
                <Col md={3}>
                  <Label style={{ display: 'block' }}>Taxa</Label>
                  <Multiselect
                    options={issIncidences}
                    disabled={!isIssConfigurationAvailable}
                    initialValue={company?.issInfo?.fees}
                    onChange={(selecteds) => {
                      issChosedIncidences.current = selecteds
                    }}
                  />
                </Col>
              </Row>
            </Container>
          )}
        </BenefitsContainer>
      )}
      <Line />
      <Spoiler
        label='Taxa Administrativa'
        add={!isWithMigrationError}
        click={!isWithMigrationError ? (e) => defineAction(e, DESK_FEE_ITEM_TYPE.ADMINISTRATIVE) : null}
      >
        <TaxesTable
          editable={company?.status !== COMPANY_STATUS.MIGRATION_ERROR}
          data={deskFees.filter((fee) => fee.itemType === DESK_FEE_ITEM_TYPE.ADMINISTRATIVE)}
          setAction={setAction}
          onDelete={deleteFee}
        />
      </Spoiler>
      <Spacing top='16px' />
      <Spoiler label='Taxa de Sucesso'>
        <CustomTable>
          <thead>
            <CustomTable.Tr>
              <CustomTable.Th>Produto</CustomTable.Th>
              <CustomTable.Th>Valor</CustomTable.Th>
            </CustomTable.Tr>
          </thead>
          <tbody>
            <CustomTable.Tr>
              <CustomTable.Td>Percentual</CustomTable.Td>
              <CustomTable.Td>
                <Input.Decimal
                  value={successFee}
                  variant={!isWithMigrationError ? '' : 'invisible'}
                  suffix='%'
                  placeholder='00,00%'
                  allowNegative={false}
                  displayType={!isWithMigrationError ? 'input' : 'text'}
                  disabled={isWithMigrationError}
                  onChange={(e) => {
                    setSuccessFee(Number(e.target.value.replace('%', '').replace('.', '').replace(',', '.')))
                  }}
                  style={{ minWidth: '100px', width: '20%' }}
                />
              </CustomTable.Td>
            </CustomTable.Tr>
          </tbody>
        </CustomTable>
      </Spoiler>
      <Spacing top='32px' />
      <ButtonContainer>
        <Button variant='outlined' onClick={() => navigate('/empresas')}>
          Voltar
        </Button>
        <Button variant='contained' onClick={() => setIsFeesEditionModalVisible(true)}>
          Salvar
        </Button>
      </ButtonContainer>
      {action && (
        <TaxModal
          open={!!action}
          cancel={() => {
            setAction(null)
          }}
          action={action}
          confirm={(data) => {
            action.action === ACTIONS.CREATE && createNewFee(data)
            action.action === ACTIONS.EDIT && editFee(data)
          }}
          ACTIONS={ACTIONS}
        />
      )}
      {isFeesEditionModalVisible && (
        <ModalV2.TwoOptions
          open={isFeesEditionModalVisible}
          rightText='Salvar'
          leftText='Cancelar'
          onClose={() => setIsFeesEditionModalVisible(false)}
          onLeftClick={() => setIsFeesEditionModalVisible(false)}
          onRightClick={editFees}
          hideButton={editFeesState.loading || loading}
        >
          {editFeesState.loading || loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Spinner />
            </Box>
          ) : (
            <ModalText>
              Tem certeza que gostaria de <br />
              <TextDecoration>salvar as alterações</TextDecoration> feitas?
            </ModalText>
          )}
        </ModalV2.TwoOptions>
      )}
      {isRejectModalOpen && (
        <RejectionModal
          onClose={() => setIsRejectModalOpen(false)}
          onConfirm={() => rejectCompanyRequest(companyId)}
          loading={rejectCompanyState.loading}
        />
      )}
    </>
  )
}
