import React, { useEffect, useState } from 'react'
import { atomWithHash } from 'jotai-location'
import { useForm } from 'react-hook-form'
import dayjs from 'dayjs'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import useDebounce from '@/main/hooks/useDebouce'
import { useFilter, usePagination } from '@/main/hooks'
import { orderStatus, orderTypes } from '@/main/utils/options'
import { Multiselect } from '@/presentation/components/organisms/multiselect'
import { FormField } from '@/presentation/components/organisms'
import { Panel, TableFooterPagination, PageHeader, Search, AutoTable } from '@/presentation/components/molecules'
import { Box, Button, Grid, Icon } from '@/presentation/components/atoms'
import { useOrders } from './hooks/useOrders'
import { useOperators } from './hooks/useOperators'
import { FilterDialog } from './components/filterDialog/filterDialog'
import { useSetAtom } from 'jotai'
import { orderIdAtom } from '@/main/store'
import { ORDER_TYPES } from '@/main/utils/constants'
import { getTableConfiguration } from './utils/getTableConfiguration'

const initialFilter = { sort: 'code', desc: true }
const filterAtom = atomWithHash<object>('ordersFilter', initialFilter)
const paginationAtom = atomWithHash<object>('ordersPagination', null)
const defaultValues = {
  status: '',
  orderType: '',
  basketCode: '',
  orderDate: '',
}

export const OldOrdersList = ({ listSelector }: { listSelector: React.ReactNode }) => {
  const [searchFilter, setSearchFilter] = useState('')
  const debounce = useDebounce()
  const today = dayjs(new Date())
  const [selectedOperators, setSelectedOperators] = useState()
  const { filter, resetFilter, updateFilter } = useFilter({ filterAtom, initialValue: initialFilter })
  const { pagination, goToPage, setPageSize } = usePagination({ paginationAtom })
  const form = useForm({
    defaultValues: filter || defaultValues,
  })
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const { orders, isLoading } = useOrders({ filter, pagination })
  const { operators } = useOperators()

  const setOrderId = useSetAtom(orderIdAtom)

  const tableConfiguration = getTableConfiguration({ updateFilter })

  const onFilterSubmit = (data) => {
    const searchParams = {
      ...data,
      orderDate: data.orderDate ? dayjs(data.orderDate).format('YYYY/MM/DD') : '',
    }
    goToPage(1)
    updateFilter(searchParams)
    setIsFilterOpen(false)
  }

  const onFilterClear = () => {
    const resetTo = {
      ...defaultValues,
      OperatorCardId: filter.OperatorCardId,
      sort: filter.sort,
      desc: filter.desc,
    }
    resetFilter({ filterValues: resetTo })
    form.reset(resetTo)
    setIsFilterOpen(false)
  }

  useEffect(() => {
    if (filter.OperatorCardId) {
      const ids = filter.OperatorCardId.slice(1, -1).split(',')
      setSelectedOperators(ids)
    }
    setSearchFilter(filter.filterValue)
    form.reset(filter)
  }, [filter])
  return (
    <Panel noPadding>
      <PageHeader pageTitle='Pedidos' />
      <Grid container spacing='16px' sx={{ padding: '16px 32px 32px 32px' }}>
        <Grid item xs={12} md={5}>
          <Search
            onSearch={(value) =>
              updateFilter({
                text: value,
              })
            }
            placeholder='Buscar na tabela abaixo ...'
            value={searchFilter}
            onChange={(e) => {
              setSearchFilter(e)
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md='auto'>
          <Button
            variant='outlined'
            startIcon={<Icon icon={faFilter} />}
            fullWidth
            onClick={() => setIsFilterOpen(true)}
          >
            Filtrar
          </Button>
        </Grid>
        <Grid item>
          <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <Multiselect
              label='Operadora:'
              labelProps={{
                position: 'left',
                sx: {
                  fontWeight: '700',
                  color: 'black',
                  alignItems: 'center',
                  justifyContent: 'center',
                },
              }}
              value={selectedOperators}
              options={operators}
              getOptionLabel={(option) => option?.operatorCardName}
              getOptionValue={(option) => option?.operatorCardId}
              onChange={(value) => {
                debounce(() => {
                  const filterToSend = value.join(',')
                  setSelectedOperators(value)
                  updateFilter({
                    OperatorCardId: filterToSend !== '' ? `[${filterToSend}]` : filterToSend,
                  })
                }, 500)
              }}
            />
          </Box>
        </Grid>
        {listSelector && (
          <Grid item sx={{ marginLeft: 'auto' }}>
            {listSelector}
          </Grid>
        )}
        <Grid item xs={12}>
          <AutoTable
            onRowClick={({ row }) => setOrderId(row.id)}
            link={({ row }) => {
              const isCard = row.orderType < ORDER_TYPES.RECHARGE
              return `/pedidos/${isCard ? 'cartao' : 'recarga'}#orderId="${row.id}"`
            }}
            columns={tableConfiguration as any}
            rows={orders?.data}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
      <TableFooterPagination
        totalItems={orders.totalItems}
        onRowsPerPageChange={(e) => setPageSize(e.target.value)}
        rowsPerPage={orders.pageSize}
        totalPages={orders.totalPages}
        page={orders.page}
        onPageChange={(e, value) => goToPage(value)}
      />

      <FilterDialog
        open={isFilterOpen}
        form={form}
        onClose={() => setIsFilterOpen(false)}
        onSubmit={onFilterSubmit}
        onClear={onFilterClear}
      >
        <FormField
          type='select'
          options={[{ label: 'Todos', value: '' }, ...orderStatus]}
          name='status'
          label='Status'
          gridProps={{ xs: 12 }}
          noStatusBorder
        />
        <FormField
          type='select'
          options={[{ label: 'Todos', value: '' }, ...orderTypes]}
          name='orderType'
          label='Tipo de Pedido'
          gridProps={{ xs: 12 }}
          noStatusBorder
        />
        <FormField
          type='maskedInput'
          mask={Number}
          name='basketCode'
          label='N° Carrinho'
          placeholder='Insira o número do carrinho'
          inputProps={{ maxLength: 18 }}
          gridProps={{ xs: 12 }}
          noStatusBorder
        />
        <FormField
          type='date'
          name='orderDate'
          label='Data do pedido'
          maxDate={today}
          gridProps={{ xs: 12 }}
          noStatusBorder
        />
      </FilterDialog>
    </Panel>
  )
}
