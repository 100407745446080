import { Row } from 'react-grid-system'
import styled from 'styled-components'
import { BREAK_POINTS } from '@/main/utils/constants'

export const CustomRow = styled(Row)`
  & + & {
    margin-top: 16px;
  }
`

export const Label = styled.label`
  display: block;
  margin: 16px 0;
  white-space: nowrap;
`

export const CheckBoxWrapper = styled.div`
  display: flex;
  span {
    margin-left: 8px;
  }

  @media (max-width: ${BREAK_POINTS.md}) {
    margin-top: 16px;
  }
`

export const ModalTitle = styled.div`
  font-size: 1.25rem;
  font-weight: var(--font-weight-bold);
`

export const InputWrapper = styled.div`
  min-width: 200px;
`

export const ButtonWrapper = styled.div`
  > button {
    width: 100%;
  }
`
