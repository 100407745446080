import { CARD_STATUS, DATE_TO_IGNORE } from '@/main/utils/constants'
import Moment from 'react-moment'
import { AutoTable, Search, TableFooterPagination } from '@/presentation/components/molecules'
import { Grid } from '@/presentation/components/atoms'
import { formatMoney } from '@/main/utils/functionUtils'
import { atomWithHash } from 'jotai-location'
import { useCompanyBlockedCards, useFilter, usePagination } from '@/main/hooks'

const filterAtom = atomWithHash<object>('companyBlockedCardsFilter', null)
const paginationAtom = atomWithHash('companyBlockedCardsPagination', null)
const CompanyBlockedCards = () => {
  const { filter, updateFilter } = useFilter({
    filterAtom,
    initialValue: {
      sort: 'number',
    },
  })
  const { pagination, goToPage, setPageSize } = usePagination({ paginationAtom })

  const { cards, isLoading } = useCompanyBlockedCards({
    filter,
    pagination,
    immutableQuery: {
      status: CARD_STATUS.BLOCKED,
    },
  })
  const cardsData = cards?.data

  const columnsConfiguration = [
    {
      columnDisplayName: 'Data do bloqueio',
      columnValue: ({ row }) => row.blockDate,
      onOrder: ({ order }) => updateFilter({ sort: 'blockDate', desc: !order }),
      customRender: ({ row }) =>
        row.blockDate && !row.blockDate.includes(DATE_TO_IGNORE) && <Moment format='DD/MM/YYYY' date={row.blockDate} />,
    },
    {
      columnDisplayName: 'Nome Cartão',
      columnValue: ({ row }) => row.benefitName,
      onOrder: ({ order }) => updateFilter({ sort: 'benefitName', desc: !order }),
    },
    {
      columnDisplayName: 'N° Cartão',
      columnValue: ({ row }) => row.number,
    },
    {
      columnDisplayName: 'Colaborador',
      columnValue: ({ row }) => row.fullname,
    },
    {
      columnDisplayName: 'Matrícula operadora',
      columnValue: ({ row }) => row.employeeIntegrationCode,
    },
    {
      columnDisplayName: 'Motivo do Bloqueio',
      columnValue: ({ row }) => row.blockingReason,
    },
    {
      columnDisplayName: 'Saldo',
      columnValue: ({ row }) => `R$ ${formatMoney(row.balance || 0)}`,
    },
  ]

  return (
    <>
      <Grid container spacing='16px' sx={{ padding: '16px' }}>
        <Grid item xs={12} md={6} lg={5}>
          <Search
            onSearch={(value) => {
              goToPage(1)
              updateFilter({
                filterValue: value.trim(),
                filterProps: 'number,fullname,employeeIntegrationCode',
              })
            }}
            placeholder='Buscar por número, colaborador ou matrícula'
          />
        </Grid>
        <Grid item xs={12}>
          <AutoTable<{
            number: string
            fullname: string
            registration: string
            blockDate: string
            blockingReason: string
            balance: number
          }>
            columns={columnsConfiguration}
            rows={cardsData}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>

      <TableFooterPagination
        totalItems={cards.totalItens}
        onRowsPerPageChange={(e) => setPageSize(e.target.value)}
        rowsPerPage={cards.pageSize}
        totalPages={cards.totalPages}
        page={cards.pageNumber}
        onPageChange={(e, value) => goToPage(value)}
      />
    </>
  )
}

export default CompanyBlockedCards
