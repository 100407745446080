import { BoxProps, SxProps, useTheme } from '@mui/material'
import { StyledBox } from './styles'

export interface ColorfulBox extends BoxProps {
  bkgColor?: string
  sx?: SxProps
}

export const ColorfulBox = (params: ColorfulBox) => {
  const theme = useTheme()
  const { children, bkgColor = theme.palette.grey[100], sx } = params

  return (
    <StyledBox bkgColor={bkgColor} sx={sx}>
      {children}
    </StyledBox>
  )
}
