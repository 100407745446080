import api from '../infra/api'
import apiCallHandler from './apiCallHandler'

export const postLogin = (payload) => apiCallHandler(() => api.post('resale-bff/Login', payload))

export const postChangePassword = (payload) => apiCallHandler(() => api.post('resale-bff/password', payload))

export const postResetPassword = (payload) => apiCallHandler(() => api.post(`resale-bff/password/forgot`, payload))

export const getLoginTheme = (resaleName) => apiCallHandler(() => api.get(`resale-bff/Session?resale=${resaleName}`))
