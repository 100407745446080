import React, { useState } from 'react'
import Dropzone from 'react-dropzone'
import { CommonText, Container, InfoRow, InputFile, HighlightedText, Wrapper, DownloadLink, Atention } from './style'
import { Button, Icon, Spacing, Spinner, Title } from '@/presentation/components'
import { minTwoDigits } from '@/main/utils/functionUtils'
import { getSettlePaymentReport } from '@/services/reportsServices'
import { useDispatch } from 'react-redux'
import { pushNotification } from '@/store/modules/notification/actions'
import errorHandler from '@/main/utils/errorHandler'
import blobToJson from '@/main/utils/blobToJson'
import blobDownload from '@/main/utils/blobDownload'

const ImportResult = ({ response, onDrop, ...props }) => {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const { settlePaymentId } = response
  const hasError = response?.errors > 0
  const getReport = async () => {
    setLoading(true)
    const { response, error } = await getSettlePaymentReport(settlePaymentId)
    if (response) {
      const blob = new Blob([response.data], { type: 'text/plain' })
      const filename = `Relatório de conciliação bancária.pdf`
      blobDownload(blob, filename)
    } else
      dispatch(
        pushNotification(
          errorHandler({
            ...error.response,
            data: await blobToJson(error.response.data),
          }),
        ),
      )
    setLoading(false)
  }

  return (
    <>
      <Spacing top='45px' />
      <Title.SubTitle>Resultado da Importação</Title.SubTitle>
      <Container>
        <InfoRow>
          <span>
            <HighlightedText>{minTwoDigits(response?.processed)}</HighlightedText>
            <CommonText>Total de Pedidos</CommonText>
          </span>
          <span>
            <HighlightedText>{minTwoDigits(response?.errors)}</HighlightedText>
            <CommonText>Pedidos não processados</CommonText>
          </span>
          <span>
            <HighlightedText style={{ color: 'var(--color-primary-01)' }}>{minTwoDigits(response.new)}</HighlightedText>
            <CommonText>Pedidos processados</CommonText>
          </span>
        </InfoRow>
        {!hasError && (
          <div style={{ textAlign: 'center' }}>
            <Atention>Conciliação efetuada com sucesso!</Atention>
          </div>
        )}
        {hasError && (
          <div style={{ textAlign: 'center' }}>
            <Atention>Atenção</Atention>
            <CommonText>Os arquivos contendo erro não serão importados.</CommonText>
            <CommonText>Verifique os erros apontados no relatório de erros e importe novamente.</CommonText>
            {response?.errorReport && (
              <DownloadLink>
                <a download='erros_importacao.txt' href={`data:text/txt;base64,${response?.errorReport}`}>
                  Ver relatório de erros <Icon name='external-link' />
                </a>
              </DownloadLink>
            )}
          </div>
        )}
        <Wrapper>
          {loading ? (
            <Spinner variant='sm' />
          ) : !hasError ? (
            <Button.DropDown
              label='Baixar Relatório'
              arrow
              icon='download'
              variant='action'
              disabled={!settlePaymentId}
            >
              <li onClick={getReport}>PDF</li>
            </Button.DropDown>
          ) : null}
        </Wrapper>
        {hasError && (
          <Dropzone accept='.ret, .RET' onDrop={onDrop} multiple={false} {...props}>
            {({ getRootProps, getInputProps }) => (
              <>
                <InputFile {...getRootProps()}>
                  <Icon name='upload' /> Reenviar Modelo Preenchido
                </InputFile>
                <input {...getInputProps()} />
              </>
            )}
          </Dropzone>
        )}
      </Container>
    </>
  )
}

export default ImportResult
