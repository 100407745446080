import { useState } from 'react'

const useRange = (min = null, max = null) => {
  const [minValue, setMinValue] = useState(min)
  const [maxValue, setMaxValue] = useState(max)

  const redefineMinValue = (newValue) => {
    if (newValue > maxValue && minValue !== null) return
    if (newValue === '') newValue = null
    setMinValue(newValue)
  }

  const redefineMaxValue = (newValue) => {
    if (newValue < minValue && maxValue !== null) return
    if (newValue === '') newValue = null
    setMaxValue(newValue)
  }

  return {
    initialRangeValue: {
      get: minValue,
      set: redefineMinValue,
    },
    finalRangeValue: {
      get: maxValue,
      set: redefineMaxValue,
    },
  }
}

export default useRange
