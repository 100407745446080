import api from '../infra/api'
import apiCallHandler from './apiCallHandler'

export const getUsers = () => apiCallHandler(() => api.get('resale-bff/users'))

export const postCreateUser = (payload) => apiCallHandler(() => api.post('resale-bff/users/create/', payload))

export const postCreateUserProfile = (payload) =>
  apiCallHandler(() => api.post('resale-bff/profile/CreateUser/', payload))

export const postCreateUserService = (payload) => apiCallHandler(() => api.post('users-service/data/create', payload))

export const putEditUser = (payload) => apiCallHandler(() => api.put('resale-bff/users/edit/', payload))

export const userId = (id) => apiCallHandler(() => api.get(`resale-bff/users/${id}`))
