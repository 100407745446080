import { Row } from 'react-grid-system'
import styled from 'styled-components'

export const CustomRow = styled(Row)`
  & + & {
    margin-top: 32px;
  }
`

export const Label = styled.label`
  display: block;
  margin-bottom: 16px;
`
