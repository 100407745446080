import styled, { css } from 'styled-components'

export const Content = styled.div`
  min-height: 200px;
`

export const SectionTitle = styled.span`
  height: 1.125rem;
  font-size: 1.125rem;
  font-weight: var(--font-weight-bold);
`

export const BenefitsContainer = styled.div`
  margin-top: 32px;
`
export const CompanyStatus = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  width: fit-content;
  padding: 12px;
  padding-left: 28px;
  margin-left: auto;
  border-radius: 4px;
  position: relative;

  :before {
    content: ' ';
    height: 8px;
    width: 8px;
    border-radius: 50%;
    position: absolute;
    left: 12px;
    top: 20px;
  }

  ${({ status = 'green' }) => {
    const variants = {
      green: css`
        background-color: #e9fff5;
        :before {
          background-color: #00e077;
        }
      `,
      red: css`
        background-color: #ffe9e3;
        :before {
          background-color: #e02e00;
        }
      `,
      yellow: css`
        background-color: #fff8e1;
        :before {
          background-color: #fecd2b;
        }
      `,
    }

    return variants[status]
  }}
`

export const IssCheckboxContainer = styled.div`
  label:last-child {
    margin: 16px 8px;
    margin-right: 0px;
    display: inline-block;
  }
`
