import { useMediaQuery, useTheme } from '@mui/material'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Cell, ResponsiveContainer } from 'recharts'
import { Typography } from '@/presentation/components/atoms'
import { CustomizedAxisTick } from '../customizedAxisTick'
import { CustomTooltip } from '../customTooltip'
import { monetaryAbbreviator } from '@/main/utils/functions'
import { IDashboardMonthlyEarnings } from '../../../../hooks/useDashboardInterfaces'

interface EarningsBarChartProps {
  data: Array<IDashboardMonthlyEarnings>
  selectedMonth?: string
  onBarClick?: (e: any) => void
  lastActiveMonth?: string | null
}

export const EarningsBarChart = ({ data, selectedMonth, onBarClick, lastActiveMonth }: EarningsBarChartProps) => {
  const theme = useTheme()
  const isSmallerThanSm = useMediaQuery(theme.breakpoints.down('sm'))
  const lastActiveMonthIndex = data.findIndex((entry: IDashboardMonthlyEarnings) => entry.month === lastActiveMonth)

  const handleBarClick = (e: any) => {
    onBarClick && onBarClick(e)
  }

  const renderBars = ({ dataKey, data, isFirst }: { dataKey: string; data: any; isFirst?: boolean }) => {
    const minHeightForZeroValue = isFirst ? 20 : 10
    const shadeForGreyBar = isFirst ? 500 : 300
    const selectFillColor = ({ isFirst, reverse }: { isFirst: boolean; reverse?: boolean }) => {
      if (reverse) return isFirst ? theme.palette.benefits.snack.main : theme.palette.success.main
      else return isFirst ? theme.palette.success.main : theme.palette.benefits.snack.main
    }

    return (
      <Bar dataKey={dataKey} stackId='a' fill={selectFillColor({ isFirst })} onClick={handleBarClick}>
        {data.map((entry: IDashboardMonthlyEarnings, index: number) => {
          const isValueBiggerThanZero = Number(entry[dataKey]) > 0
          const isSelected = entry.month === selectedMonth
          const isActiveBar = index <= lastActiveMonthIndex || !lastActiveMonth
          const props: any = {
            fill: isActiveBar
              ? selectFillColor({ isFirst, reverse: !isValueBiggerThanZero })
              : theme.palette.grey[shadeForGreyBar],
            style: {
              cursor: isActiveBar && onBarClick ? 'pointer' : '',
            },
            stroke: isSelected ? theme.palette.primary.dark : '',
            strokeWidth: '3px',
          }
          if (entry.total <= 0 || !isActiveBar) props.height = -minHeightForZeroValue
          return <Cell key={entry[dataKey]} {...props} />
        })}
      </Bar>
    )
  }

  return (
    <ResponsiveContainer height={248} width={'100%'}>
      <BarChart
        data={data}
        margin={{
          top: 20,
          right: 0,
          left: 0,
          bottom: 5,
        }}
        maxBarSize={24}
      >
        <CartesianGrid vertical={false} stroke={theme.palette.grey[100]} />
        <XAxis
          dataKey='month'
          fontSize={12}
          interval={0}
          axisLine={false}
          tickLine={null}
          tick={<CustomizedAxisTick />}
        />
        {!isSmallerThanSm && (
          <YAxis
            fontSize={12}
            tickFormatter={(value) => `R$ ${monetaryAbbreviator({ value })}`}
            axisLine={false}
            tickSize={16}
            tickLine={null}
            interval={0}
            width={80}
          />
        )}
        <Tooltip
          allowEscapeViewBox={{ x: true, y: true }}
          cursor={{ fill: theme.palette.grey[100] }}
          content={(props) => {
            const tooltipMonthIndex = data.findIndex((entry: IDashboardMonthlyEarnings) => entry.month === props.label)
            return (
              <CustomTooltip
                containerId='earningsBarChartContainer'
                active={props.active}
                label={props.label}
                payload={props.payload}
                isMonthActive={tooltipMonthIndex <= lastActiveMonthIndex || !lastActiveMonth}
              />
            )
          }}
        />
        <Legend
          align={isSmallerThanSm ? 'center' : 'left'}
          iconSize={8}
          formatter={(value: string) => (
            <Typography variant='text2' sx={{ color: theme.palette.grey[700], textTransform: 'capitalize' }}>
              {value}
            </Typography>
          )}
          wrapperStyle={{ left: isSmallerThanSm ? 0 : 80 }}
          width={isSmallerThanSm ? null : 200}
        />
        {renderBars({
          data,
          dataKey: 'dealer',
          isFirst: true,
        })}
        {renderBars({
          data,
          dataKey: 'broker',
        })}
      </BarChart>
    </ResponsiveContainer>
  )
}
