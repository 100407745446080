import { styled } from '@mui/material/styles'
import { Icon } from '@/presentation/components/atoms/Icon'

export const ColorContainer = styled('div')(({ selected }) => ({
  height: 120,
  width: 120,
  borderRadius: '50%',
  border: `${selected ? '4px solid #1976D2' : '2px solid #F5F6FA'}`,
  cursor: 'pointer',
  position: 'relative',
  '& svg:first-of-type': {
    top: 0,
    left: '66.58%',
  },
  '& svg:nth-of-type(2)': {
    top: '36.99%',
    left: '83.84%',
  },
}))

export const ColorViewer = styled('div')(() => ({
  height: '100%',
  width: '100%',
  borderRadius: '50%',
  overflow: 'hidden',
}))

export const Color = styled('div')(({ color }) => ({
  height: '100%',
  width: '50%',
  display: 'inline-block',
  background: color,
}))

export const Check = styled(Icon)(() => ({
  color: 'white',
  height: 30,
  padding: 5,
  borderRadius: '50%',
  background: '#1976D2',
  position: 'absolute',
}))

export const Pencil = styled(Icon)(() => ({
  color: '#1976D2',
  height: 16,
  width: 16,
  padding: 10,
  borderRadius: '50%',
  border: '2px solid #1976D2',
  background: 'white',
  position: 'absolute',
}))
