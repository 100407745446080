import _ from 'lodash'
import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableProps,
  TableRow,
} from '@/presentation/components/atoms'
import { OrderSelector } from '../orderSelector'
import { TableLink } from './styles'

export interface AutoTableProps<T> {
  isLoading?: boolean
  noRowsText?: string
  noValueText?: string
  link?: ({ row }: { row: T }) => string
  onRowClick?: ({ row, event }: { row: T; event: any }) => void
  variant?: TableProps['variant']
  columns: Array<{
    columnDisplayName: string
    columnValue: ({ row }: { row: T }) => any
    customRender?: ({ row }: { row: T }) => React.ReactNode | React.ReactElement
    onColumnClick?: ({ row, event }: { row: T; event: any }) => void
    onOrder?: ({ order }: { order: boolean }) => void
    noValueText?: string
    align?: 'center' | 'inherit' | 'justify' | 'left' | 'right'
    preventLink?: boolean
  }>
  rows: Array<T>
}

export const AutoTable = <T,>({
  columns,
  rows,
  isLoading,
  noValueText = '-',
  noRowsText = 'Sem registros',
  variant = 'default',
  link,
  onRowClick,
}: AutoTableProps<T>) => {
  const renderTableHead = () => {
    const header = columns.map((column) => (
      <TableCell key={_.uniqueId()}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: column.align || 'inherit', gap: '8px' }}>
          {column.columnDisplayName}
          {column.onOrder && (
            <OrderSelector
              onClickUp={() => column.onOrder && column.onOrder({ order: true })}
              onClickDown={() => column.onOrder && column.onOrder({ order: false })}
            />
          )}
        </Box>
      </TableCell>
    ))
    return (
      <TableHead>
        <TableRow>{header}</TableRow>
      </TableHead>
    )
  }

  const renderTableBody = () => {
    if (!isLoading && rows?.length > 0)
      return (
        <TableBody>
          {rows.map((row) => (
            <TableRow key={_.uniqueId()}>
              {columns.map((column) => {
                const hasValue = Boolean(column.columnValue({ row }))
                const columnValue = hasValue ? column.columnValue({ row }) : column.noValueText || noValueText
                const itemToBeRendered = column.customRender ? column.customRender({ row }) : columnValue
                return (
                  <TableCell
                    key={_.uniqueId()}
                    align={column.align || 'inherit'}
                    sx={{
                      textAlign: hasValue ? 'inherit' : 'center',
                      cursor: column.onColumnClick || onRowClick ? 'pointer' : 'initial',
                      padding: link ? 0 : '4px 8px',
                    }}
                    onClick={(e) => {
                      if (onRowClick && !column.onColumnClick) onRowClick({ row, event: e })
                      if (column.onColumnClick) column.onColumnClick({ row, event: e })
                    }}
                  >
                    {!link || column.preventLink ? (
                      itemToBeRendered
                    ) : (
                      <TableLink to={link({ row })}>{itemToBeRendered}</TableLink>
                    )}
                  </TableCell>
                )
              })}
            </TableRow>
          ))}
        </TableBody>
      )
    else
      return (
        <TableBody>
          <TableRow>
            <TableCell colSpan={columns.length} sx={{ textAlign: 'center' }}>
              {isLoading ? (
                <Box sx={{ padding: '32px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <CircularProgress />
                </Box>
              ) : (
                noRowsText
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      )
  }

  return (
    <TableContainer>
      <Table variant={variant}>
        {renderTableHead()}
        {renderTableBody()}
      </Table>
    </TableContainer>
  )
}
