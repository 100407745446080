import { Breadcrumb, Content, Panel, Spacing, Title } from '@/presentation/components'

const Home = () => {
  return (
    <Content>
      <Title>Início</Title>
      <Spacing top='9px' bottom='9px'>
        <Breadcrumb />
      </Spacing>
      <Panel></Panel>
    </Content>
  )
}

export default Home
