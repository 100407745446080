import { Input } from '@/presentation/components'
import React, { useState } from 'react'
import { Col, Row } from 'react-grid-system'
import { Controller } from 'react-hook-form'
import { Label } from './styles'

const PeriodFields = ({ control, disabled = false }) => {
  const [maxDate, setMaxDate] = useState(new Date())
  const [minDate, setMinDate] = useState(null)

  return (
    <>
      <Row>
        <Col sm={6}>
          <Label>De</Label>
          <Controller
            control={control}
            name='initialDate'
            render={({ field: { onChange, value, ref } }) => (
              <Input.Date
                placeholder='00/00/0000'
                maxDate={maxDate}
                getInputRef={ref}
                value={new Date(value?.replaceAll('-', '/'))}
                disabled={disabled}
                onChange={(e) => {
                  onChange(e !== '' ? e?.toISOString().split('T')[0] : e)
                  setMinDate(e)
                }}
              />
            )}
          />
        </Col>
        <Col sm={6}>
          <Label>Até</Label>
          <Controller
            control={control}
            name='finalDate'
            render={({ field: { onChange, value, ref } }) => (
              <Input.Date
                placeholder='00/00/0000'
                maxDate={new Date()}
                minDate={minDate}
                getInputRef={ref}
                value={new Date(value?.replaceAll('-', '/'))}
                disabled={disabled}
                onChange={(e) => {
                  onChange(e !== '' ? e?.toISOString().split('T')[0] : e)
                  setMaxDate(e)
                }}
              />
            )}
          />
        </Col>
      </Row>
    </>
  )
}

export default PeriodFields
