import { ButtonProps as MUIButtonProps } from '@mui/material'
import { StyledButton } from './styles'

export interface ButtonProps extends Omit<MUIButtonProps, 'variant'> {
  variant?: any
}

export function Button({ size = 'large', ...props }: ButtonProps) {
  return <StyledButton size={size} {...props} />
}
