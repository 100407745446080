import _ from 'lodash'
import { useTheme } from '@mui/material'
import { Box, Typography } from 'presentation/components/atoms'
import { ValueContainer } from './styles'
import { formatMoney } from '@/main/utils/functions'

interface IValue {
  label: string
  value: number
  color?: 'green' | 'red' | 'black'
  isMonetary?: boolean
  isNegative?: boolean
  isBold?: boolean
  size?: 'sm' | 'md'
  hideZero?: boolean
}

export interface ValuesSummaryProps {
  values: Array<IValue | null>
}

const variantForSize = ({ size }: { size: string }): 'label3' | undefined => {
  switch (size) {
    case 'sm':
      return 'label3'
    default:
      return undefined
  }
}

const Value = ({
  label,
  value,
  color,
  isMonetary = true,
  isNegative = false,
  isBold = false,
  hideZero = false,
  size = 'sm',
}: IValue) => {
  const theme = useTheme()
  const fontWeight = isBold ? 600 : 400
  if (hideZero && value === 0) return null

  let colorToBeUsed = theme.palette.grey[700]
  if (color === 'green' && value !== 0) colorToBeUsed = theme.palette.success.dark
  else if (color === 'red' && value !== 0) colorToBeUsed = theme.palette.error.dark
  else if (color === 'black' && value !== 0) colorToBeUsed = theme.palette.common.black

  return (
    <ValueContainer>
      <Typography variant={variantForSize({ size })} sx={{ fontWeight: fontWeight }}>
        {label}
      </Typography>
      <Typography
        variant={variantForSize({ size })}
        sx={{
          fontWeight: fontWeight,
          color: colorToBeUsed,
        }}
      >
        {isNegative && value > 0 && '- '}
        {isMonetary && <span style={{ fontWeight: 400 }}>R$ </span>}
        {isMonetary ? formatMoney(value) : value}
      </Typography>
    </ValueContainer>
  )
}

export const ValuesSummary = ({ values }: ValuesSummaryProps) => {
  return <Box>{values.map((value: IValue | null) => (value ? <Value key={_.uniqueId()} {...value} /> : null))}</Box>
}
