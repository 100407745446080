import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const useRouteRestriction = (allowedRolesArray, redirectionPath = '/') => {
  const navigate = useNavigate()
  const user = useSelector((state) => state.auth.user)
  const userRoles = user.claims
  const isRoleAllowed = () =>
    allowedRolesArray.some((allowedRole) => userRoles.find((role) => role.value === allowedRole))
  return () => !isRoleAllowed() && navigate(redirectionPath)
}

export default useRouteRestriction
