import api from '@/infra/api'
import { useMutation } from '@tanstack/react-query'
import { useAtomValue } from 'jotai'
import { companyIdAtom } from '../store/url/shared'
interface IApprove {
  cei: string
  deskFees: Array<any>
  slipFee: number
  successFee: number
  benefits: any
}

interface IUseModifyCompany {
  config?: {
    generalData?: object
    addresses?: object
    addAddress?: object
    contacts?: object
    addContact?: object
    approve?: object
    reprove?: object
  }
}

export const useModifyCompany = (params: IUseModifyCompany = {}) => {
  const {
    config = {
      generalData: {},
      addresses: {},
      contacts: {},
      approve: {},
      reprove: {},
    },
  } = params
  const companyId = useAtomValue(companyIdAtom)

  const generalData = useMutation({
    mutationFn: (payload: object) => api.patch(`resale-bff/companies/${companyId}/generaldata`, payload),
    ...config.generalData,
  })

  const addresses = useMutation({
    mutationFn: (payload: object) => api.put(`resale-bff/companies/${companyId}/address`, payload),
    ...config.addresses,
  })

  const addAddress = useMutation({
    mutationFn: (payload: object) => api.post(`resale-bff/companies/${companyId}/address`, payload),
    ...config.addAddress,
  })

  const contacts = useMutation({
    mutationFn: (payload: object) => api.put(`resale-bff/companies/${companyId}/contact`, payload),
    ...config.contacts,
  })

  const addContact = useMutation({
    mutationFn: (payload: object) => api.post(`resale-bff/companies/${companyId}/contact`, payload),
    ...config.contacts,
  })

  const approve = useMutation({
    mutationFn: (payload: IApprove) =>
      api.patch(`/companies-service/${companyId}/approval`, {
        ...payload,
        approved: true,
        issqnFree: true,
        irDeduction: true,
        pisDeduction: true,
      }),
    ...config.approve,
  })

  const reprove = useMutation({
    mutationFn: () => api.delete(`resale-bff/companies/${companyId}/reject`),
    ...config.reprove,
  })

  return {
    generalData,
    addresses,
    addAddress,
    contacts,
    addContact,
    approve,
    reprove,
  }
}
