import { Button, ErrorMessage, ErrorWrapper, Input, ModalV2, Spinner } from '@/presentation/components'
import { getCompaniesForReportFilter } from '@/services/reportsServices'
import { pushNotification } from '@/store/modules/notification/actions'
import { useDispatch } from 'react-redux'
import errorHandler from '@/main/utils/errorHandler'
import React, { useEffect, useState } from 'react'
import { Col, Container } from 'react-grid-system'
import { Controller, useForm } from 'react-hook-form'
import { ButtonWrapper, CheckBoxWrapper, CustomRow, InputWrapper, Label, ModalTitle } from './styles'
import { applyMask } from '@/main/utils/functionUtils'

const GenericCompanyReportFilter = ({ open, onClose, requestReport, reportName, loading }) => {
  const [companyId, setCompanyId] = useState(null)
  const [companies, setCompanies] = useState([])
  const [isClientCodeInvalid, setIsClientCodeInvalid] = useState(false)
  const [loadingData, setLoadingData] = useState(false)
  const dispatch = useDispatch()

  const { handleSubmit, control, formState } = useForm({
    mode: 'onChange',
    defaultValues: {
      rhCNPJ: null,
      tradeName: null,
      clientCode: '',
      cardIds: [],
      cardStatus: null,
    },
  })

  const isButtonDisabled =
    (!formState.dirtyFields.tradeName && !formState.dirtyFields.rhCNPJ && !formState.dirtyFields.clientCode) ||
    isClientCodeInvalid

  useEffect(() => {
    ;(async () => {
      setLoadingData(true)
      const { error, response } = await getCompaniesForReportFilter()
      if (response) setCompanies(response.data)
      else dispatch(pushNotification(errorHandler(error.response)))
      setLoadingData(false)
    })()
  }, [dispatch])

  const onSubmit = (data) => {
    if (!data) return
    requestReport(companyId, companies.find((company) => company.id === companyId).tradeName)
  }

  return (
    <ModalV2 open={open} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)} id='GenericCompanyReportFilterForm'>
        <Container fluid style={{ padding: 0 }}>
          {loading ? (
            <Spinner />
          ) : (
            <>
              <CustomRow>
                <Col>
                  <ModalTitle>{reportName}</ModalTitle>
                </Col>
              </CustomRow>
              <CustomRow>
                <Col sm={12}>
                  <Label>Empresa</Label>
                  <InputWrapper>
                    <Controller
                      control={control}
                      name='tradeName'
                      render={({ field: { onChange, value } }) => (
                        <Input.Select
                          placeholder='Selecione o RH'
                          value={value}
                          onChange={(e) => {
                            onChange(e)
                            setCompanyId(e?.id ? e.id : null)
                          }}
                          isDisabled={formState.dirtyFields.rhCNPJ || formState.dirtyFields.clientCode}
                          getOptionValue={(option) => option.id}
                          getOptionLabel={(option) => option.tradeName}
                          options={companies}
                          isLoadingData={loadingData}
                        />
                      )}
                    />
                  </InputWrapper>
                </Col>
                <Col sm={12} md={6}>
                  <Label>CNPJ</Label>
                  <InputWrapper>
                    <Controller
                      control={control}
                      name='rhCNPJ'
                      render={({ field: { onChange, value } }) => (
                        <Input.Select
                          placeholder='Selecione o RH'
                          value={value}
                          onChange={(e) => {
                            onChange(e)
                            setCompanyId(e?.id ? e.id : null)
                          }}
                          isDisabled={formState.dirtyFields.tradeName || formState.dirtyFields.clientCode}
                          getOptionValue={(option) => option.id}
                          getOptionLabel={(option) => applyMask('##.###.###/####-##', option.cnpj)}
                          options={companies}
                          isLoadingData={loadingData}
                        />
                      )}
                    />
                  </InputWrapper>
                </Col>
                <Col sm={12} md={6}>
                  <ErrorWrapper error={isClientCodeInvalid}>
                    <Label>Cód. Cliente</Label>
                    <InputWrapper>
                      <Controller
                        control={control}
                        name='clientCode'
                        render={({ field: { onChange, value } }) => (
                          <Input.Masked
                            placeholder='Insira o Cód. Cliente'
                            disabled={formState.dirtyFields.tradeName || formState.dirtyFields.rhCNPJ}
                            onChange={(e) => {
                              setIsClientCodeInvalid(true)
                              const company = companies.find((company) => String(company.code) === e.target.value)
                              if (!company || e.target.value === '') {
                                setIsClientCodeInvalid(true)
                                setCompanyId(null)
                              } else {
                                setCompanyId(company.id)
                                setIsClientCodeInvalid(false)
                              }
                              e.target.value === '' && setIsClientCodeInvalid(false)
                              onChange(e)
                            }}
                          />
                        )}
                      />
                    </InputWrapper>
                    {isClientCodeInvalid && <ErrorMessage>Cód. Cliente inexistente</ErrorMessage>}
                  </ErrorWrapper>
                </Col>
              </CustomRow>
              <CustomRow>
                <Col sm={2} md={2}>
                  <CheckBoxWrapper>
                    <Input.CheckBox checked controlled />
                    <span>PDF</span>
                  </CheckBoxWrapper>
                </Col>
              </CustomRow>
              <CustomRow justify='end'>
                <Col sm={12} md={5}>
                  <ButtonWrapper>
                    <Button disabled={isButtonDisabled}>Gerar Relatório</Button>
                  </ButtonWrapper>
                </Col>
              </CustomRow>
            </>
          )}
        </Container>
      </form>
    </ModalV2>
  )
}

export default GenericCompanyReportFilter
