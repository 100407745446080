import { Typography } from '@mui/material'
import { OrderStatusComponentsProps } from '../orderStatusComponentsProps'
import { ORDER_TYPES } from 'main/utils/constants'

export const Finished = ({ orderType = 0 }: OrderStatusComponentsProps) => {
  let finalText = ''
  if ([ORDER_TYPES.FIRST_COPY, ORDER_TYPES.SECOND_COPY].includes(orderType))
    finalText = ' e os produtos já foram retirados'
  else if ([ORDER_TYPES.RECHARGE, ORDER_TYPES.BROKER].includes(orderType)) finalText = ' e a recarga já foi efetivada'
  return (
    <Typography variant='h3' sx={{ fontWeight: 500 }}>
      Seu pedido foi concluído{finalText}.
    </Typography>
  )
}
