import produce from 'immer'

const INITIAL_STATE = {
  data: null,
  loading: false,
  error: false,
}

export default function companys(state = INITIAL_STATE, action) {
  const { type, payload } = action

  return produce(state, (draft) => {
    switch (type) {
      case '@company/COMPANY_REQUEST': {
        draft.loading = true
        break
      }

      case '@company/COMPANY_SUCCESS': {
        draft.loading = false
        draft.data = payload.companys
        break
      }

      case '@company/COMPANY_ERROR': {
        draft.loading = false
        draft.error = true
        break
      }

      default:
    }
  })
}
