import styled from '@emotion/styled'

export const ContainerTable = styled.div`
  table {
    tr:last-child {
      td {
        font-weight: 700;
      }
    }
  }
`
