import { useAtom, useAtomValue } from 'jotai'
import { atomWithHash } from 'jotai-location'
import { ButtonSelector, ButtonSelectorOption } from '@stationkim/front-ui'
import { OldOrdersList } from './oldOrdersList/oldOrdersList'
import { ListOrders as NewList } from './listOrders/listOrders'
import { AccountPersistentStore } from '@/main/store'

const lists = [NewList, OldOrdersList]
const listSelectorAtom = atomWithHash('selectedList', 0)

export const ListOrders = () => {
  const [visibleList, setVisibleList] = useAtom(listSelectorAtom)
  const account = useAtomValue(AccountPersistentStore)
  const isMotiva = account.resale.site === 'motiva' && process.env.REACT_APP_ENV !== 'development'
  const listSelector = (
    <ButtonSelector value={visibleList} onChange={({ targetIndex }) => setVisibleList(targetIndex)}>
      <ButtonSelectorOption>Broker</ButtonSelectorOption>
      <ButtonSelectorOption>Dealer</ButtonSelectorOption>
    </ButtonSelector>
  )

  const Component = isMotiva ? lists[1] : lists[visibleList]
  return <Component listSelector={isMotiva ? null : listSelector} />
}
