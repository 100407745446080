import { Typography } from '@mui/material'

export const AvailablePickUp = ({ address }: { address?: string }) => {
  return (
    <>
      <Typography variant='h3' sx={{ fontWeight: 500 }}>
        Seu pedido já está disponível para a retirada:
      </Typography>
      <Typography variant='h5' sx={{ fontWeight: 400, marginTop: '32px', display: 'inline-block' }}>
        Retire seu pedido no local:
      </Typography>
      <Typography variant='h5' sx={{ display: 'inline-block', marginLeft: '8px' }}>
        {address ?? 'Endereço padrão'}.
      </Typography>
    </>
  )
}
