import { Box, Card, ColorfulBox, Typography } from '@/presentation/components/atoms'
import { AutoTable } from '@/presentation/components/molecules'
import { IUseDashboardReturn } from '../../hooks/useDashboardInterfaces'
import { YearAndMonth } from '../../dashboard'
import { ContainerTable } from './styles'

interface OrdersTableCardProps {
  data: IUseDashboardReturn['dashboardData']
  selectedDate: YearAndMonth
  isLoading?: boolean
}

export const FinancialStation = ({ data, selectedDate, isLoading }: OrdersTableCardProps) => {
  const selectedMonthData = data?.contractualBillings?.find((monthData) => monthData.month === selectedDate.month)

  return (
    <Card sx={{ minWidth: '100%' }}>
      <ColorfulBox bkgColor='#672ED7'>
        <Box sx={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
          <Typography variant='subtitle1' color='#FCFDFF'>
            Financeiro Station K
          </Typography>
        </Box>
      </ColorfulBox>
      <ContainerTable>
        <AutoTable
          isLoading={isLoading}
          variant='report'
          columns={[
            {
              columnDisplayName: 'INDICADOR',
              columnValue: ({ row }) => row.indicatorName,
            },
            {
              columnDisplayName: 'TIPO / VALOR / QTD',
              columnValue: ({ row }) => row.valueType,
            },
            {
              columnDisplayName: 'TOTAL (R$)',
              columnValue: ({ row }) => row.total,
            },
          ]}
          rows={selectedMonthData?.data}
        />
      </ContainerTable>
    </Card>
  )
}
