import { Box, Typography } from '@/presentation/components/atoms'
import { useMediaQuery, useTheme } from '@mui/material'

export const TotalOverlay = ({ ordersFilter, ordersTotal }: { ordersFilter: string; ordersTotal: number }) => {
  const theme = useTheme()
  const isSmallerThanMd = useMediaQuery(theme.breakpoints.down('md'))

  const filterLabel = {
    total: 'Recarga / 1ª Via / 2ª Via',
    firstCopy: ' 1ª Via',
    secondCopy: ' 2ª Via',
    recharge: 'Recarga',
  }

  return (
    <Box
      sx={{
        position: 'absolute',
        top: '50px',
        left: isSmallerThanMd ? '50%' : '50px',
        transform: isSmallerThanMd ? 'translateX(-50%)' : '',
        width: '85px',
        height: '85px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1,
        '&:before': {
          content: `"${filterLabel[ordersFilter]}"`,
          position: 'absolute',
          bottom: '-60px',
          left: '50%',
          transform: 'translateX(-50%)',
          whiteSpace: 'noWrap',
          color: theme.palette.grey[700],
          textAlign: 'center',
          fontSize: '12px',
          fontWeight: 400,
        },
      }}
    >
      <Typography variant='text2'>Total</Typography>
      <Typography variant='h2'>{ordersTotal}</Typography>
    </Box>
  )
}
