import _ from 'lodash'
import { EMPLOYEE_OPERATOR_INTEGRATION, EMPLOYEE_STATUS } from '@/main/utils/constants'
import { MenuItem } from '@/presentation/components/atoms'

interface IBenefit {
  id: string
  name: string
}

interface IBenefitType {
  benefits: Array<IBenefit>
  id: string | number
  name: string
}

interface IEmployeesFilterData {
  filterData: {
    benefitTypes: Array<IBenefitType>
    departments: Array<{ id: string; name: string }>
    status: string | number
    integrationsStatus: string | number
  }
}

export const employeesFilterConfiguration = ({ filterData }: IEmployeesFilterData) => {
  const { benefitTypes, departments } = filterData

  const operatorsCards = Array.isArray(benefitTypes)
    ? benefitTypes.reduce((cards, type) => [...cards, ...type.benefits], [])
    : []

  return [
    {
      type: 'multiSelect',
      name: 'benefitType',
      props: {
        label: 'Benefícios',
        fullWidth: true,
        variant: 'contained',
        options: benefitTypes,
        getOptionLabel: (option: any) => option.name,
        getOptionValue: (option: any) => option.id,
      },
    },
    {
      type: 'multiSelect',
      name: 'OperatorCardId',
      props: {
        label: 'Nome Cartão',
        fullWidth: true,
        variant: 'contained',
        options: operatorsCards,
        getOptionLabel: (option: any) => option.name,
        getOptionValue: (option: any) => option.id,
      },
    },
    {
      type: 'multiSelect',
      name: 'departmentId',
      props: {
        label: 'Departamento',
        fullWidth: true,
        variant: 'contained',
        options: departments,
        getOptionLabel: (option: any) => option.name,
        getOptionValue: (option: any) => option.id,
      },
    },
    {
      type: 'select',
      name: 'status',
      props: {
        label: 'Status',
        fullWidth: true,
        children: [
          <MenuItem key={_.uniqueId()} value={EMPLOYEE_STATUS.ACTIVE}>
            Ativo
          </MenuItem>,
          <MenuItem key={_.uniqueId()} value={EMPLOYEE_STATUS.INACTIVE}>
            Inativo
          </MenuItem>,
        ],
      },
    },
    {
      type: 'select',
      name: 'integrationsStatus',
      props: {
        label: 'Status de Integração',
        fullWidth: true,
        isClearable: true,
        children: [
          <MenuItem key={_.uniqueId()} value={EMPLOYEE_OPERATOR_INTEGRATION.OK}>
            Sincronizado
          </MenuItem>,
          <MenuItem key={_.uniqueId()} value={EMPLOYEE_OPERATOR_INTEGRATION.RUNNING}>
            Aguardando sincronismo
          </MenuItem>,
          <MenuItem key={_.uniqueId()} value={EMPLOYEE_OPERATOR_INTEGRATION.ERROR}>
            Erro de sincronismo
          </MenuItem>,
        ],
      },
    },
  ]
}
