import { styled } from '@mui/material'
import { NavLink } from '@/presentation/components/atoms/navLink'

export const StyledNavLink = styled(NavLink)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 32,
  height: 40,
  margin: '8px 0',
  padding: '0 16px',
  color: theme.palette.grey[500],
  transition: `background-color ${theme.transitions.duration.short}ms ${theme.transitions.easing.easeInOut}`,
  '&:hover:not(.active)': {
    backgroundColor: theme.palette.grey[100],
  },
  '&.active': {
    cursor: 'default',
    color: theme.palette.primary.main,
  },
}))
