import { faCircleCheck } from '@fortawesome/free-regular-svg-icons'
import { faDownload, faFileExport, faUpload, faXmark } from '@fortawesome/free-solid-svg-icons'
import { Dialog, DialogContent, DialogTitle, Icon, Button, Skeleton } from '@/presentation/components/atoms'

export const BrokerOptionsDialog = ({
  isOpen,
  onClose,
  onOrderDownload,
  onOrderExport,
  onAttachSlip,
  onPaymentConfirm,
  isLoading,
  isRunning,
}) => {
  const closeIfNotWaiting = isLoading ? null : onClose

  return (
    <Dialog open={isOpen} onClose={closeIfNotWaiting}>
      <DialogTitle>
        Ações sobre pedido
        <Icon icon={faXmark} onClick={closeIfNotWaiting} />
      </DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          marginTop: '32px',
          padding: isLoading ? '48px' : 0,
        }}
      >
        {isLoading ? (
          <>
            <Skeleton variant='rounded' height='48px' width='295px' />
            <Skeleton variant='rounded' height='48px' width='295px' />
            <Skeleton variant='rounded' height='48px' width='295px' />
            <Skeleton variant='rounded' height='48px' width='295px' />
          </>
        ) : (
          <>
            <Button variant='contained' onClick={onOrderDownload} startIcon={<Icon icon={faDownload} />}>
              Download de pedido
            </Button>
            <Button variant='outlined' onClick={onOrderExport} startIcon={<Icon icon={faFileExport} />}>
              Exportar arquivo de pedido
            </Button>
            <Button disabled={isRunning} variant='outlined' startIcon={<Icon icon={faUpload} />} onClick={onAttachSlip}>
              Anexar boleto gerado
            </Button>
            <Button
              disabled={!isRunning}
              variant='outlined'
              startIcon={<Icon icon={faCircleCheck} />}
              onClick={onPaymentConfirm}
            >
              Confirmar pagamento
            </Button>
          </>
        )}
      </DialogContent>
    </Dialog>
  )
}
