import { useQuery } from '@tanstack/react-query'
import { useAtomValue } from 'jotai'
import api from '@/infra/api'
import { companyIdAtom } from '../store/url/shared'
import { IFetchHook } from '../interfaces/iFetchHook'

const defaultValue = {
  data: [],
  pageNumber: 1,
  pageSize: 10,
  totalItens: 0,
  totalPages: 1,
}

export const useCompanyCardsCopyHistoric = ({ filter, pagination, immutableQuery }: IFetchHook) => {
  const companyId = useAtomValue(companyIdAtom)
  const { data, ...rest } = useQuery({
    queryKey: ['companyCardsCopyHistoric', companyId, filter, pagination, immutableQuery],
    queryFn: ({ queryKey }) =>
      api.get(`resale-bff/companies/${queryKey[1]}/cards/history`, {
        params: { ...pagination, ...filter, ...immutableQuery },
      }),
  })
  return {
    historic: data?.data || defaultValue,
    ...rest,
  }
}
