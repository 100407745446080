import styled, { css } from 'styled-components'

export const ContentWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  min-height: 645px;
`

export const Categories = styled.ul`
  margin-top: 56px;
`

export const LeftColumnWrapper = styled.div`
  width: min(315px, 45%);
  padding: 26px 0;
  border-right: 1px solid var(--color-secundary-05);
  h3 {
    margin-left: 32px;
  }
`

export const Category = styled.li`
  height: 50px;
  font-size: 1.25rem;
  padding-left: 32px;

  display: flex;
  align-items: center;
  cursor: pointer;

  ${({ active }) =>
    active &&
    `
      background-color: var(--color-secundary-07);
      font-weight: var(--font-weight-semi-bold);
      color: var(--color-primary-05);
      cursor:default;
    `}
`

export const RightColumnWrapper = styled.div`
  width: 100%;
  padding: 26px 12px;
  border-right: 1px solid var(--color-secundary-05);
  display: flex;
  flex-direction: column;
`

export const Group = styled.div`
  flex: 1;
`

export const SubTitle = styled.h3`
  font-size: 1.625rem;
  font-weight: var(--font-weight-bold);
  border: none;
  cursor: initial;
  line-height: 44px;
  vertical-align: middle;
`

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`

export const Tbody = styled.tbody``

export const Tr = styled.tr`
  & + & > td {
    border-top: 1px solid rgba(0, 0, 0, 0.45);
  }
`

export const Td = styled.td`
  height: 50px;
  &:first-child {
    padding-left: 20px;
  }
`

export const ParameterValue = styled.span`
  color: var(--color-primary-01);
  font-weight: var(--font-weight-semi-bold);
  ${({ active }) =>
    !active &&
    css`
      opacity: 0.3;
    `}
`

export const FlexLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 20px;
  margin-bottom: 56px;
`

export const ModalTitle = styled.h3`
  color: var(--color-primary-01);
  margin-bottom: 48px;
`

export const Form = styled.form`
  label {
    margin-bottom: 8px;
    display: inline-block;
  }
`

export const NoResult = styled.div`
  margin: auto;
  text-align: center;
`

export const LoadingWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
