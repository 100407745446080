import { faCheck, faPencil } from '@fortawesome/free-solid-svg-icons'
import { colord } from 'colord'
import { Box } from '@/presentation/components/atoms/box'
import { Button } from '@/presentation/components/atoms/button'
import { ColorPicker } from '@/presentation/components/atoms/colorPicker'
import { Popover } from '@/presentation/components/atoms/popover'
import { useRef, useState } from 'react'
import { Check, Color, ColorContainer, ColorViewer, Pencil } from './styles'

const chooseComplementaryColor = (hsvColor) => {
  const { h, s, v } = hsvColor
  if (v < 35) return colord('#c4c4c4').toHsv()
  let newSaturation
  if (s >= 50) newSaturation = s - 50
  else if (s === 0) newSaturation = 51
  else newSaturation = s + 50
  return { h, s: newSaturation, v }
}

export const DualColorSelector = ({
  initialColors,
  value,
  selected,
  editable,
  onChange = () => null,
  onClick = () => null,
  onClose = () => null,
  onConfirm = () => null,
}) => {
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false)
  const [internalState, setInternalState] = useState(initialColors)

  const colors = value || internalState
  const setColors = value ? onChange : setInternalState

  const colorPickerAnchor = useRef(null)

  const handleChange = (color) => {
    let mainColor = colord(color)
    let complementaryColor = colord(chooseComplementaryColor(color))
    const isPrimaryDarkerThanSecondary = color.s >= complementaryColor.toHsv().s || complementaryColor.toHsv().s === 0

    if (!isPrimaryDarkerThanSecondary) [mainColor, complementaryColor] = [complementaryColor, mainColor]
    const newColors = {
      primary: {
        main: mainColor.toHex(),
        light: mainColor.lighten(0.2).toHex(),
        dark: mainColor.darken(0.1).toHex(),
      },
      secondary: {
        main: complementaryColor.toHex(),
        light: complementaryColor.lighten(0.2).toHex(),
        dark: complementaryColor.darken(0.1).toHex(),
      },
    }
    if (onChange) onChange(newColors)
    setColors(newColors)
  }

  const handleConfirm = () => {
    setIsColorPickerOpen(false)
    if (onConfirm) onConfirm(colors)
  }

  const handleClose = () => {
    setIsColorPickerOpen(false)
    if (onClose) onClose()
  }

  return (
    <ColorContainer selected={selected} onClick={() => onClick(colors)}>
      <ColorViewer>
        <Color color={colord(colors?.secondary?.main).toHex()} />
        <Color color={colord(colors?.primary?.main).toHex()} />
      </ColorViewer>
      {!selected ? null : <Check icon={faCheck} />}
      {!editable ? null : <Pencil icon={faPencil} ref={colorPickerAnchor} onClick={() => setIsColorPickerOpen(true)} />}
      <Popover
        open={isColorPickerOpen}
        anchorEl={colorPickerAnchor.current}
        onClose={() => {
          setIsColorPickerOpen(false)
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box
          sx={{
            padding: '16px',
          }}
        >
          <ColorPicker initialColor={colord(colors?.primary?.main).toHex()} onChange={handleChange} />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              margin: '16px 0px',
            }}
          >
            <Button onClick={handleClose}>Cancelar</Button>
            <Button variant='contained' onClick={handleConfirm}>
              Confirmar
            </Button>
          </Box>
        </Box>
      </Popover>
    </ColorContainer>
  )
}
