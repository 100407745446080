import { styled, Typography } from '@mui/material'

export const Body = styled('div')(({ validated, theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100vh',
  background: `radial-gradient(${theme.palette.primary.dark}, ${theme.palette.primary.main})`,
}))

export const Content = styled('div')(({ theme }) => ({
  borderRadius: 16,
  backgroundColor: theme.palette.common.white,
  padding: 32,
  button: {
    width: '100%',
  },
  '& form': {
    width: '100%',
    maxWidth: 370,
  },
  '& p': {
    maxWidth: 370,
  },
}))

export const Title = styled(Typography)(({ theme }) => ({
  marginBottom: 24,
  width: '100%',
  textAlign: 'center',
}))

export const HelpText = styled('div')(({ theme }) => ({
  textAlign: 'center',
  marginBottom: 32,
}))

export const PasswordRequirementList = styled('div')(({ visible, theme }) => ({
  maxHeight: visible ? 350 : 0,
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  margin: '16px 0',
  transition: 'all 0.25s linear',
}))

export const PasswordRequirement = styled('div')(({ validated, theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontSize: '0.875em',
  width: 200,
  position: 'relative',
  '&:not(&:first-child)': {
    margiLeft: '32px',
    '&::before': {
      content: "'•'",
      position: 'absolute',
      left: '-16px',
    },
    color: validated ? 'limegreen' : 'red',
  },
  '& > svg': {
    maxWidth: 10,
    maxHeight: 10,
  },
}))
