import styled from 'styled-components'
import { NavLink as Link } from 'react-router-dom'

export const Container = styled.div`
  margin: auto;
  margin-top: 80px;
  width: 70%;
`

export const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 80px;
  width: 100%;
`

export const HighlightedText = styled.p`
  font-size: 3.125rem;
  font-weight: var(--font-weight-bold);
  letter-spacing: 0.05rem;
  width: 100%;
  text-align: center;
`

export const Atention = styled.p`
  font-size: 1.125rem;
  font-weight: var(--font-weight-bold);
  margin-bottom: 8px;
`

export const CommonText = styled.p`
  font-size: 1rem;
  font-weight: var(--font-weight-regular);
  letter-spacing: 0.01625rem;
  margin-top: 8px;
`

export const InputFile = styled.label`
  background-color: var(--color-primary-01);
  border-radius: 24px;
  color: var(--color-primary-05);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  margin: auto;
  margin-top: 145px;
  margin-bottom: 40px;
  letter-spacing: 0.01625rem;
  height: 44px;
  width: 370px;

  * + & {
    margin-top: 20px;
  }

  & > svg {
    margin-right: 8px;
  }

  &:hover {
    background-color: var(--color-primary-02);
  }
`

export const Wrapper = styled.div`
  text-align: center;
  max-width: 250px;
  margin: auto;
`

export const NavLink = styled(Link)`
  color: var(--color-primary-01);
  display: block;
  font-size: 1.125rem;
  font-weight: var(--font-weigth-semi-bold);
  letter-spacing: 0.018125rem;
  margin: auto;
  margin-top: 56px;
  text-decoration: underline;
`

export const DownloadLink = styled.div`
  display: flex;
  color: var(--color-primary-01);
  font-weight: var(--font-weight-semi-bold);
  justify-content: center;
  align-items: center;
  margin: 16px 0;
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }

  > svg {
    margin-left: 8px;
  }

  a {
    color: var(--color-primary-01);
  }
`
