import React, { useState } from 'react'
import Modal from 'react-responsive-modal'
import { Content } from '../styles'

function ModalV2({ open, onClose, closeIcon, children }) {
  const [openModal, setOpen] = useState(false)
  const onCloseModal = () => setOpen(onClose || false)

  return (
    <Modal open={open || openModal} onClose={onCloseModal} center closeIcon={closeIcon}>
      <Content>{children}</Content>
    </Modal>
  )
}

export default ModalV2
