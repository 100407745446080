export function infoCompanyRequest(id) {
  return {
    type: '@infoCompany/INFOCOMPANY_REQUEST',
    payload: { id },
  }
}

export function infoCompanySuccess(info) {
  return {
    type: '@infoCompany/INFOCOMPANY_SUCCESS',
    payload: { info },
  }
}

export function infoCompanyFailure() {
  return {
    type: '@infoCompany/INFOCOMPANY_ERROR',
  }
}
