import { formFieldsConfiguration } from '@/main/utils/formFieldsConfiguration/formFieldsConfiguration'
import { IUseCompanyData, useContactTypes, useModifyCompany } from '@/main/hooks'
import { Form, FormArray, FormField, FormHeader } from '@/presentation/components/organisms'
import { useContext, useEffect, useState } from 'react'
import { EMessageType } from '@/main/store'
import { CompanyGeneralDataContext } from '../../companyGeneralData'
import { useForm } from 'react-hook-form'

export const ContactsForm = () => {
  const { company, companyId, notification, queryClient, isLoadingCompany } = useContext(CompanyGeneralDataContext)
  const [isEditing, setIsEditing] = useState<boolean>(false)

  const form = useForm<any>({
    defaultValues: { contacts: company?.contacts },
    shouldFocusError: true,
    mode: 'onChange',
  })

  useEffect(() => {
    !isLoadingCompany &&
      form.reset({
        contacts: [company?.contacts[0]],
      })
  }, [isLoadingCompany])

  const onSuccess = () => {
    notification.push({ content: 'Contato editado com sucesso!', type: EMessageType.Success })
    queryClient.invalidateQueries({ queryKey: ['company', companyId] })
    setIsEditing(false)
  }

  const modifyCompany = useModifyCompany({
    config: {
      contacts: { onSuccess },
      addContact: { onSuccess },
    },
  })

  const { contactTypes, isLoading: isLoadingContactTypes } = useContactTypes()

  const isLoading =
    isLoadingCompany || isLoadingContactTypes || modifyCompany.contacts.isLoading || modifyCompany.addContact.isLoading

  const handleContactsSubmit = (data: IUseCompanyData) => {
    const dataToBeUsed = {
      id: data.contacts[0].id,
      contactTypeCode: data.contacts[0].contactTypeCode,
      fullName: data.contacts[0].fullName,
      email: data.contacts[0].email,
      phone: data.contacts[0].phone,
      extension: data.contacts[0].extension,
      cpf: data.contacts[0].cpf,
    }

    if (dataToBeUsed.id) modifyCompany?.contacts.mutate(dataToBeUsed)
    else {
      delete dataToBeUsed.id
      modifyCompany?.addContact.mutate(dataToBeUsed)
    }
  }

  return (
    <Form
      form={form}
      onSubmit={(data) => handleContactsSubmit(data)}
      isLoading={isLoading}
      isEditable={isEditing}
      onEditButtonClick={() => setIsEditing(true)}
      onCancel={() => setIsEditing(false)}
    >
      <FormHeader title='Informações de contato' />
      <FormArray
        name='contacts'
        maxAmount={1}
        render={({ indexName }) => (
          <>
            <FormField
              type='maskedInput'
              name={indexName + 'cpf'}
              gridProps={{ xs: 12, md: 4 }}
              {...formFieldsConfiguration.contacts.cpf}
              rules={formFieldsConfiguration.contacts.cpf.rules({ isRequired: true })}
            />
            <FormField
              type='input'
              name={indexName + 'fullName'}
              gridProps={{ xs: 12, md: 4 }}
              {...formFieldsConfiguration.contacts.fullName}
              rules={formFieldsConfiguration.contacts.fullName.rules({ isRequired: true })}
              inputProps={{ maxLength: 130 }}
            />
            <FormField
              type='input'
              name={indexName + 'email'}
              gridProps={{ xs: 12, md: 4 }}
              {...formFieldsConfiguration.contacts.email}
              rules={formFieldsConfiguration.contacts.email.rules({ isRequired: true })}
            />
            <FormField
              type='select'
              name={indexName + 'contactTypeCode'}
              gridProps={{ xs: 12, md: 4 }}
              inputProps={{ maxLength: 130 }}
              label={'Área de contato'}
              options={contactTypes}
              getOptionLabel={(option) => option.description}
              getOptionValue={(option) => option.code}
            />
            <FormField
              type='maskedInput'
              name={indexName + 'phone'}
              gridProps={{ xs: 12, sm: 6, md: 4, lg: 2 }}
              {...formFieldsConfiguration.contacts.phone}
              rules={formFieldsConfiguration.contacts.phone.rules({ isRequired: true })}
            />
            <FormField
              type='maskedInput'
              name={indexName + 'extension'}
              gridProps={{ xs: 12, sm: 3, md: 2 }}
              {...formFieldsConfiguration.contacts.extension}
            />
          </>
        )}
      />
    </Form>
  )
}
