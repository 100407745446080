import { all, takeLatest, call, put } from 'redux-saga/effects'
import { ACTIONS, emittingBanksRequestFailure, emittingBanksRequestSuccess } from './actions'
import { getEmittingBanks } from '@/services/emittingBanksServices'

function* fetchEmittingBanks({ payload }) {
  const { response, error } = yield call(getEmittingBanks)
  if (response) yield put(emittingBanksRequestSuccess(response.data))
  else yield put(emittingBanksRequestFailure(error.response))
}

export default all([takeLatest(ACTIONS.GET_EMITTING_BANKS_REQUEST, fetchEmittingBanks)])
