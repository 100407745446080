import ActionIcon from '@/presentation/components/icon/actionIcon'
import React, { useState } from 'react'
import { CustomTable, IconContainer } from '../styles'
import AddTaxModal from './addTaxModal'
import { Input } from '@/presentation/components'
import { Tooltip } from '@/presentation/components/atoms/tooltip'
import { Icon } from '@/presentation/components/atoms/Icon'
import { faCheckCircle, faPenToSquare } from '@fortawesome/free-regular-svg-icons'

const TaxesTable = ({ data, setData, disabled }) => {
  const [addTax] = useState(null)
  const [taxToEdit] = useState(null)
  const [editSlip, setEditSlip] = useState(false)

  const generateTableHeader = () => {
    return (
      <CustomTable.Tr>
        <CustomTable.Th>Produto</CustomTable.Th>
        <CustomTable.Th>Valor</CustomTable.Th>
        <CustomTable.Th
          style={{
            textAlign: 'right',
            paddingRight: '20px',
          }}
        >
          Ações
        </CustomTable.Th>
      </CustomTable.Tr>
    )
  }

  const convertValue = (value) => {
    if (value) {
      const newValue = value.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      })
      return newValue
    } else {
      return '--'
    }
  }

  return (
    <CustomTable>
      <thead>{generateTableHeader()}</thead>
      <tbody>
        <tr>
          <th
            style={{
              textAlign: 'left',
              fontWeight: 'initial',
              color: '#000',
            }}
          >
            Boleto
          </th>
          <th
            style={{
              textAlign: 'left',
              fontWeight: 'initial',
              color: '#000',
            }}
          >
            {!editSlip ? (
              convertValue(data?.slipFee)
            ) : (
              <Input.Decimal
                required={true}
                placeholder='00,00'
                value={data?.slipFee}
                onChange={(ev) => setData({ ...data, slipFee: ev.target.value })}
                style={{ width: '17%' }}
              />
            )}
          </th>
          <th
            style={{
              textAlign: 'right',
              fontWeight: 'initial',
              color: '#000',
              marginRight: '10px',
            }}
          >
            {!disabled && (
              <IconContainer>
                {!editSlip ? (
                  <Tooltip>
                    <Icon icon={faPenToSquare} onClick={() => setEditSlip(true)} sx={{ cursor: 'pointer' }} />
                  </Tooltip>
                ) : (
                  <Tooltip title='Editar'>
                    <Icon icon={faCheckCircle} onClick={() => setEditSlip(false)} sx={{ cursor: 'pointer' }} />
                  </Tooltip>
                )}
              </IconContainer>
            )}
          </th>
        </tr>
      </tbody>

      {(addTax || taxToEdit) && <AddTaxModal toEdit={taxToEdit} />}
    </CustomTable>
  )
}

export default TaxesTable
