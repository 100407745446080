import { Divider, Grid, Pagination, TablePagination, TablePaginationProps } from '@/presentation/components/atoms'

interface TableFooterPaginationProps extends TablePaginationProps {
  totalItems: number
  onRowsPerPageChange: (e: any) => void
  onPageChange: (e: any, value: any) => void
  padding?: string
  spacing?: string
  hideSinglePage?: boolean
}

export const TableFooterPagination = ({
  totalItems,
  onRowsPerPageChange,
  rowsPerPage,
  totalPages,
  page,
  onPageChange,
  padding = '16px',
  spacing = '16px',
  displayText,
  counterVariant,
  rowsPerPageOptions,
  hideSinglePage = false,
}: TableFooterPaginationProps) => {
  return (
    <Grid container spacing={spacing} sx={{ padding, paddingTop: 0 }}>
      <Grid item xs={12}>
        <Divider overflow />
      </Grid>
      <Grid item xs={12} sm sx={{ order: { xs: 3, sm: 'initial' } }}>
        <TablePagination
          count={totalItems}
          page={page}
          totalPages={totalPages}
          rowsPerPageOptions={rowsPerPageOptions}
          onRowsPerPageChange={onRowsPerPageChange}
          rowsPerPage={rowsPerPage || 10}
          sx={{ margin: { xs: 'auto', sm: 'initial' } }}
          displayText={displayText}
          counterVariant={counterVariant}
        />
      </Grid>
      {(!hideSinglePage || totalPages > 1) && (
        <Grid item xs={12} sm='auto'>
          <Pagination count={totalPages || 1} page={page || 1} onChange={onPageChange} color='primary' />
        </Grid>
      )}
    </Grid>
  )
}
