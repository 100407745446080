import { Icon } from '@/presentation/components/atoms/Icon'
import { StyledBox } from './styles'

export function SocialMedias({ medias, ...props }) {
  if (!medias) return null
  return (
    <StyledBox {...props}>
      {medias.map((socialMedia, index) => (
        <a
          key={`${index}_social_media`}
          href={socialMedia.link.includes('://') ? socialMedia.link : `//${socialMedia.link}`}
          target='_blank'
          rel='noreferrer'
        >
          <Icon icon={socialMedia.icon} />
        </a>
      ))}
    </StyledBox>
  )
}
