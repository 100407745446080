import { useTheme } from '@mui/material'
import { faPlay, faPlus } from '@fortawesome/free-solid-svg-icons'
import { ORDER_STATUS, ORDER_TYPES } from '@/main/utils/constants'
import { CustomIcon } from './styles'
import { Box, CircularProgress, Tooltip } from '@/presentation/components/atoms'
import { useOrderMutations } from '../../hooks/useOrderMutations'
import { useState } from 'react'
import { OrderMenu } from '../orderMenu/orderMenu'
import { useDialogs } from '@/main/hooks'
import { useFilesDownload } from '../../hooks/useFilesDownload'

interface OrderActionsProps {
  order: any
}

export const OrderActions = ({ order }: OrderActionsProps) => {
  const { setDialog } = useDialogs()
  const [menuAnchor, setMenuAnchor] = useState()
  const isDealer = order.orderType !== ORDER_TYPES.BROKER
  const isAttendingBroker =
    !isDealer && ![ORDER_STATUS.FINISHED, ORDER_STATUS.CANCELED, ORDER_STATUS.CANCELING].includes(order.orderStatus)
  const isWaitingProcess = order.orderStatus === ORDER_STATUS.WAITING_FOR_PROCESSING
  const shouldShowPlusIcon =
    order.orderStatus === ORDER_STATUS.AVAILABLE_PICKUP ||
    (!isDealer && [ORDER_STATUS.FINISHED, ORDER_STATUS.PROCESSING, ORDER_STATUS.RUNNING].includes(order.orderStatus))

  const theme = useTheme()
  const { startBrokerOrderProcessing, sendBrokerOrderProjectionFile } = useOrderMutations({
    config: {
      sendBrokerOrderProjectionFile: {
        onSuccess: (res) =>
          setDialog({
            dialog: 'brokerProjectionResponse',
            props: { data: res, orderId: order?.id },
          }),
      },
    },
  })

  const { getBrokerOrderLayout, isGettingBrokerOrderLayout, getBrokerOrderXlsx, isGettingBrokerOrderXlsx } =
    useFilesDownload({
      orderId: order.id,
      orderCode: order.code,
      identifier: 'optionDialog',
    })

  const { sendBrokerOrderSlip, sendBrokerOrderPaymentProof } = useOrderMutations({
    config: {
      sendBrokerOrderSlip: {
        onSuccess: () => {
          setDialog({
            dialog: 'brokerOrderOptions',
            props: {
              order: { ...order, orderStatus: 6 },
              isLoading: false,
              onOrderDownload: () => getBrokerOrderXlsx(),
              onOrderExport: () => getBrokerOrderLayout(),
              onAttachSlip: () =>
                setDialog({
                  dialog: 'attachBrokerOrderSlip',
                  props: {
                    order,
                    onConfirm: (data) => {
                      sendBrokerOrderSlip.mutate({ orderId: order.id, payload: data })
                    },
                  },
                }),
              onPaymentConfirm: () =>
                setDialog({
                  dialog: 'attachBrokerOrderPaymentProof',
                  props: {
                    order,
                    onConfirm: (data) => {
                      sendBrokerOrderPaymentProof.mutate({ orderId: order.id, payload: data })
                      setDialog({ dialog: null })
                    },
                  },
                }),
            },
          })
        },
      },
    },
  })

  const isLoading =
    isGettingBrokerOrderXlsx ||
    isGettingBrokerOrderLayout ||
    sendBrokerOrderSlip.isLoading ||
    sendBrokerOrderPaymentProof.isLoading

  const handlePlusIconClick = (e) => {
    if ([ORDER_STATUS.AVAILABLE_PICKUP, ORDER_STATUS.FINISHED].includes(order.orderStatus))
      return setMenuAnchor(e.currentTarget)
    else
      setDialog({
        dialog: 'brokerOrderOptions',
        props: {
          order,
          isRunning: ORDER_STATUS.RUNNING === order.orderStatus,
          isLoading: isLoading,
          onOrderDownload: () => getBrokerOrderXlsx(),
          onOrderExport: () => getBrokerOrderLayout(),
          onAttachSlip: () =>
            setDialog({
              dialog: 'attachBrokerOrderSlip',
              props: {
                order,
                onConfirm: (data) => {
                  sendBrokerOrderSlip.mutate({ orderId: order.id, payload: data })
                },
              },
            }),
          onPaymentConfirm: () =>
            setDialog({
              dialog: 'attachBrokerOrderPaymentProof',
              props: {
                order,
                onConfirm: (data) => {
                  sendBrokerOrderPaymentProof.mutate({ orderId: order.id, payload: data })
                  setDialog({ dialog: null })
                },
              },
            }),
        },
      })
  }

  const handlePlayIconClick = () => {
    if (order.useBalanceProjection)
      setDialog({
        dialog: 'brokerOrderUploadProjection',
        props: {
          order,
          onConfirm: (data) => {
            sendBrokerOrderProjectionFile.mutate({ orderId: order.id, payload: data })
            setDialog({ dialog: '' })
          },
        },
      })
    else startBrokerOrderProcessing.mutate({ orderId: order.id })
  }

  if (startBrokerOrderProcessing.isLoading || sendBrokerOrderProjectionFile.isLoading)
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress size='20px' />
      </Box>
    )

  return (
    <>
      {shouldShowPlusIcon && (
        <CustomIcon icon={faPlus} color={theme.palette.primary.dark} onClick={handlePlusIconClick} />
      )}
      {isAttendingBroker && !shouldShowPlusIcon && (
        <Tooltip title={isWaitingProcess ? 'Iniciar atendimento' : null}>
          <CustomIcon
            icon={faPlay}
            color={isWaitingProcess ? theme.palette.success.main : theme.palette.grey[500]}
            noHover={!isWaitingProcess}
            onClick={isWaitingProcess ? () => handlePlayIconClick() : null}
          />
        </Tooltip>
      )}
      <OrderMenu order={order} isOpen={Boolean(menuAnchor)} onClose={() => setMenuAnchor(null)} anchorEl={menuAnchor} />
    </>
  )
}
