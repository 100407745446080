import React, { useState, useRef, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { FiCalendar } from 'react-icons/fi'
import Calendar from 'react-calendar'

import Moment from 'react-moment'
import { CalendarIconWrapper, CalendarWrapper, DatePickerContainer, DateLabel } from './styles'
import useOnClickOutside from '@/main/hooks/useOnClickOutside'

function DatePicker({ onChange = () => null, initialDate, limitByMonth = 0, ...props }) {
  const now = new Date()
  const [date, setDate] = useState(initialDate)
  const [isCalendarVisible, setIsCalendarVisible] = useState(false)
  const [position, setPosition] = useState({})
  const positionRef = useRef(null)

  const clickOutside = useOnClickOutside((e) => {
    if (!positionRef.current.contains(e.target) && isCalendarVisible) {
      setIsCalendarVisible(false)
    }
  })

  useEffect(() => {
    onChange(date)
    // eslint-disable-next-line
  }, [date])

  const recalculatePosition = () => {
    const distances = positionRef.current.getBoundingClientRect()
    setPosition({
      top: distances.top + window.scrollY + 32,
      left: distances.left,
    })
  }

  const handleOnClick = (e) => {
    e.currentTarget.contains(e.target) && setIsCalendarVisible((state) => !state)
    recalculatePosition()
  }

  const handleDatePick = (e) => {
    setDate(e)
    setIsCalendarVisible(false)
  }

  return (
    <DatePickerContainer ref={positionRef} onClick={handleOnClick} {...props}>
      <CalendarIconWrapper>
        <FiCalendar />
      </CalendarIconWrapper>

      <DateLabel>{date ? <Moment format='DD/MM/YYYY'>{date}</Moment> : '00/00/0000'}</DateLabel>
      {ReactDOM.createPortal(
        <CalendarWrapper visible={isCalendarVisible} position={position} ref={clickOutside}>
          <Calendar
            onChange={handleDatePick}
            value={date}
            weekStartDay={0}
            returnValue='start'
            calendarType='US'
            minDetail='decade'
            navigationLabel={({ label, view }) => `${view === 'month' ? label.match(/[^ ]*/) : label}`}
            maxDate={now}
          />
        </CalendarWrapper>,
        document.querySelector('#root'),
      )}
    </DatePickerContainer>
  )
}

export default DatePicker
