import React from 'react'
import { IoWarningOutline } from 'react-icons/io5'
import { ModalV2, Spinner } from '@/presentation/components'
import { Button, ButtonWrapper, IconContainer, Text, WarningTitle } from './styles'

function RejectionModal({ onClose, onConfirm, loading }) {
  return (
    <ModalV2 open onClose={onClose}>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <IconContainer>
            <IoWarningOutline />
          </IconContainer>
          <WarningTitle>ATENÇÃO</WarningTitle>
          <Text>
            Ao negar a solicitação de uma empresa você estará
            <br />
            excluindo todos os dados já cadastrados.
          </Text>
          <Text>
            Caso deseje prosseguir, clique em <strong>&quot;confirmar&quot;</strong>.
          </Text>
          <ButtonWrapper>
            <Button onClick={onClose}>Cancelar</Button>
            <Button red onClick={onConfirm}>
              Confirmar
            </Button>
          </ButtonWrapper>
        </>
      )}
    </ModalV2>
  )
}

export default RejectionModal
