import { Chip, styled } from '@mui/material'

export const StyledChip = styled(Chip)(({ theme, textColor, tagColor }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  gap: 'var(--space-4)',
  backgroundColor: tagColor || theme.palette.primary.light,
  color: textColor || theme.palette.common.white,
  fontWeight: theme.typography.fontWeightBold,

  whiteSpace: 'nowrap',
  userSelect: 'none',
  '& svg': {
    color: `${theme.palette.common.white} !important`,
    opacity: 0.7,
  },
}))
