import { ModalV2, Input, ErrorWrapper, ErrorMessage, Button, Spinner } from '@/presentation/components'
import { ModalTitle, Form } from './style'
import { Col, Container, Row } from 'react-grid-system'
import Label from '@/presentation/components/label'
import { Controller, useForm } from 'react-hook-form'
import useService from '@/main/hooks/useService'
import { getBrazilianCitiesForIssCreation, patchChangeIssParametersValue } from '@/services/parametersServices'
import useDebounce from '@/main/hooks/useDebouce'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { pushNotification } from '@/store/modules/notification/actions'
import successHandler from '@/main/utils/successHandler'

export const ParameterModal = ({ open, onClose, states, isEditing, parameter }) => {
  const debounce = useDebounce()
  const dispatch = useDispatch()
  const [brazilianCities, setBrazilianCities] = useState()
  const [fetchBrazilianCitiesState, fetchBrazilianCitiesRequest] = useService(getBrazilianCitiesForIssCreation, {
    onCompleted: (response) => {
      const orderedCities = [...response.data]
      orderedCities.sort((a, b) => (a.cityName > b.cityName ? 1 : a.cityName < b.cityName ? -1 : 0))
      setBrazilianCities(orderedCities)
    },
  })

  const [changeIssParameterValueState, changeIssParameterValueRequest] = useService(patchChangeIssParametersValue, {
    onCompleted: () => {
      const successMessage = isEditing ? 'Valor do parâmetro alterado com sucesso!' : 'Parâmetro adicionado!'
      dispatch(pushNotification(successHandler(successMessage)))
      onClose(true)
    },
  })

  const {
    control,
    formState: { isDirty, error },
    handleSubmit,
  } = useForm({
    defaultValues: parameter,
  })

  const onSubmit = (data) => {
    if (isDirty) {
      const issValue = data.iss.replace('%', '').replace(',', '.')
      changeIssParameterValueRequest(data.cityId, issValue)
    }
  }

  useEffect(() => {
    if (parameter?.cityName)
      setBrazilianCities([
        {
          cityName: parameter?.cityName,
          cityId: parameter?.cityId,
        },
      ])
    // eslint-disable-next-line
  }, [])

  return (
    <ModalV2 open={open} onClose={() => onClose()}>
      <ModalTitle>{isEditing ? 'Editar' : 'Adicionar'} Retenção ISS </ModalTitle>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {changeIssParameterValueState.loading ? (
          <Spinner.Box>
            <Spinner />
          </Spinner.Box>
        ) : (
          <Container fluid style={{ padding: 0 }}>
            <Row style={{ marginBottom: 32 }}>
              <Col>
                <ErrorWrapper error={error?.stateId}>
                  <Label>Estado</Label>
                  <Controller
                    control={control}
                    name='stateId'
                    render={({ field: { onChange, value } }) => (
                      <Input.Select
                        value={states?.find((option) => option.id === value)}
                        onChange={(option) => {
                          onChange(option.id)
                          debounce(fetchBrazilianCitiesRequest(option.id), 500)
                        }}
                        options={states}
                        placeholder='Selecione ...'
                        isDisabled={isEditing || !states}
                        isLoading={!states}
                        isClearable={false}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                      />
                    )}
                  />
                  {error?.stateId && <ErrorMessage>{error.stateId.message}</ErrorMessage>}
                </ErrorWrapper>
              </Col>
            </Row>
            <Row style={{ marginBottom: 32 }}>
              <Col>
                <ErrorWrapper error={error?.cityId}>
                  <Label>Cidade</Label>
                  <Controller
                    control={control}
                    name='cityId'
                    render={({ field: { onChange, value } }) => (
                      <Input.Select
                        value={brazilianCities?.find((option) => option.cityId === value)}
                        onChange={(option) => onChange(option.cityId)}
                        options={brazilianCities}
                        placeholder='Selecione ...'
                        isDisabled={!brazilianCities || fetchBrazilianCitiesState.loading || isEditing}
                        isLoading={fetchBrazilianCitiesState.loading}
                        isClearable={false}
                        getOptionLabel={(option) => option.cityName}
                        getOptionValue={(option) => option.cityId}
                      />
                    )}
                  />
                  {error?.cityId && <ErrorMessage>{error.cityId.message}</ErrorMessage>}
                </ErrorWrapper>
              </Col>
            </Row>
            <Row style={{ marginBottom: 32 }}>
              <Col>
                <ErrorWrapper error={error?.iss}>
                  <Label>Valor (%)</Label>
                  <Controller
                    control={control}
                    name='iss'
                    render={({ field: { onChange, value } }) => (
                      <Input.Decimal
                        value={value}
                        onChange={onChange}
                        allowNegative={false}
                        isAllowed={({ value }) => value <= 100}
                        placeholder='0,00%'
                        suffix='%'
                      />
                    )}
                  />
                  {error?.iss && <ErrorMessage>{error.iss.message}</ErrorMessage>}
                </ErrorWrapper>
              </Col>
            </Row>
            <Row justify='between'>
              <Col>
                <Button variant='action outlined' onClick={() => onClose()}>
                  Voltar
                </Button>
              </Col>
              <Col>
                <Button variant='action'>Continuar</Button>
              </Col>
            </Row>
          </Container>
        )}
      </Form>
    </ModalV2>
  )
}
