import { useAtomWithStorageObserver } from '@/main/hooks'
import { AccountPersistentStore } from '@/main/store/account'
import { Navigate } from 'react-router-dom'

type VerifyAuthenticationProps = {
  isPublic?: boolean
  element: any
}

export const VerifyAuthentication = ({ element, isPublic = false }: VerifyAuthenticationProps) => {
  const [account] = useAtomWithStorageObserver({ atomWithStorage: AccountPersistentStore, storageName: 'account' })
  const isAuthenticated = Boolean(account.auth?.accessToken)
  const loginRoute = `${account?.resale?.site ?? ''}/login`
  if (isAuthenticated && isPublic) return <Navigate to='/' />
  if ((isAuthenticated && !isPublic) || (!isAuthenticated && isPublic)) return element
  return <Navigate to={loginRoute} />
}
