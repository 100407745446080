import React, { useState, useMemo } from 'react'
import { Container, Label } from './styles'
import { Col, Row } from 'react-grid-system'
import TaxesTable from './taxesTable'
import TaxesSlip from './taxesTable/slip'
import api from '@/infra/api'
import AddTaxModal from './taxesTable/addTaxModal'
import Delivery from './taxesTable/delivery'
import { Spacing, Input, Spoiler, Spinner, Modal, Message, PageHeader } from '@/presentation/components'
import UseOfBalance from './taxesTable/useOfBalance'
import { Panel } from '@/presentation/components/molecules'

const TaxasBalcao = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)
  const [addTax, setAddTax] = useState(false)
  const [addCat, setAddCat] = useState(null)
  const [edit, setEdit] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [itemTax, setItemTax] = useState(null)
  const [confirm, setConfirm] = useState(false)
  const [error, setError] = useState(false)
  const [index, setIndex] = useState(null)

  const getTaxes = async () => {
    setLoading(true)
    try {
      const response = await api.get('/resales-service/DeskFees')
      setData(response.data)
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }

  useMemo(() => {
    getTaxes()
  }, [])

  const openAddTax = (category) => {
    setAddCat(category)
    setAddTax(true)
  }

  const confirmDelete = async () => {
    setLoading(true)
    let array = data.fees

    if (itemTax?.id) {
      try {
        await api.delete(`/resales-service/DeskFees/Items/${itemTax.id}`)

        const response = await array.find((element) => element.id === itemTax.id)

        const getIndex = await array.findIndex((element) => element.id === response.id)

        await array.splice(array.indexOf(getIndex), 1)

        setData({ ...data, fees: array })
        setLoading(false)
        setConfirm(true)
      } catch (e) {
        setLoading(false)
        setError(true)
      }
    } else {
      await array.splice(array.indexOf(index), 1)

      setData({ ...data, fees: array })
      setLoading(false)
      setConfirm(true)
    }
  }

  const removeItem = async (value, index) => {
    setIndex(index)
    setOpenDelete(true)
    setItemTax(value)
  }

  const setObjectArray = async (value, category, valueType) => {
    let array = data.fees

    if (value?.id) {
      const response = await array.find((element) => element.id === value.id)

      const getIndex = await array.findIndex((element) => element.id === response.id)

      await array.splice(array.indexOf(getIndex), 1)

      array.push(value)

      setData({ ...data, fees: array })
    } else {
      value.itemType = category
      value.valueType = valueType
      value.quantityType = 1

      array.push(value)
      setData({ ...data, fees: array })
    }
  }

  const save = async () => {
    setLoading(true)
    const fees = {
      ...data,
      successFee: Number(String(data.successFee).replace(',', '.')),
      slipFee: Number(String(data.slipFee).replace(',', '.')),
      aliquotIss: Number(String(data.aliquotIss).replace(',', '.')),
    }
    try {
      await api.put('/resales-service/DeskFees', fees)
      setLoading(false)
      setEdit(false)
    } catch (e) {
      setLoading(false)
    }
  }

  return (
    <Panel noPadding>
      {!data ? null : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <PageHeader>Taxas Balcão</PageHeader>
          <p
            style={{
              color: '#5e39ca',
              fontWeight: '600',
              cursor: 'pointer',
              marginRight: '32px',
            }}
            onClick={edit ? () => save() : () => setEdit(true)}
          >
            {edit ? 'Salvar Taxa Balcão' : 'Editar Taxa Balcão'}
          </p>
        </div>
      )}
      <Container fluid style={{ padding: '32px' }}>
        {loading ? (
          <Spinner.Box>
            <Spinner />
          </Spinner.Box>
        ) : (
          <>
            <Row justify='between'>
              <Col sm='content' style={{ display: 'flex' }}>
                <p style={{ fontWeight: 700 }}>Cadastro de Taxas Balcão</p>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <Row>
                  <Col sm={4}>
                    <Label>Descrição</Label>
                    <Input
                      type='text'
                      required={true}
                      value={data?.description}
                      disabled={!edit ? true : false}
                      onChange={(ev) => setData({ ...data, description: ev.target.value })}
                    />
                  </Col>
                  <Col sm={4}>
                    <Label>Process. Financeiro de Pedido</Label>
                    <Input.Masked
                      format='##'
                      required={true}
                      value={data?.orderProcessingDays}
                      disabled={!edit ? true : false}
                      onChange={(ev) =>
                        setData({
                          ...data,
                          orderProcessingDays: Number(ev.target.value),
                        })
                      }
                    />
                  </Col>
                </Row>
                <div style={{ marginTop: '40px' }} />
                <Row>
                  <Col sm={12}>
                    <Spoiler label='Boleto'>
                      <TaxesSlip data={data} setData={setData} disabled={!edit ? true : false} />
                    </Spoiler>
                    <Spacing top='16px' />
                    <Spoiler label='Taxas Administrativas' add={edit ? true : false} click={() => openAddTax(1)}>
                      <TaxesTable
                        data={data}
                        setData={setData}
                        setObjectArray={setObjectArray}
                        category={1}
                        removeItem={removeItem}
                        disabled={!edit ? true : false}
                      />
                    </Spoiler>
                    <Spacing top='16px' />
                    <Spoiler label='Taxa de Entrega' add={edit ? true : false} click={() => openAddTax(2)}>
                      <Delivery
                        data={data}
                        setData={setData}
                        setObjectArray={setObjectArray}
                        category={2}
                        removeItem={removeItem}
                        disabled={!edit ? true : false}
                      />
                    </Spoiler>
                    <Spacing top='16px' />
                    <Spoiler label='Taxa de Aproveitamento de Saldo'>
                      <UseOfBalance data={data} setData={setData} disabled={!edit ? true : false} />
                    </Spoiler>
                    <Spacing top='32px' />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row justify='between' direction='row'></Row>
          </>
        )}
      </Container>
      {addTax && (
        <AddTaxModal
          open={addTax}
          cancel={() => {
            setAddTax(false)
          }}
          setObjectArray={setObjectArray}
          category={addCat}
          close={() => setAddTax(false)}
        />
      )}
      {openDelete && (
        <Modal open={openDelete} cancel={() => setOpenDelete(false)} confirm={() => confirmDelete(itemTax)}>
          Deseja realmente apagar essa taxa?
        </Modal>
      )}
      {confirm && (
        <Message type='ok' onXClick={() => setConfirm(false)}>
          {' '}
          Taxa removida com sucesso
        </Message>
      )}
      {error && (
        <Message type='error' onXClick={() => setError(false)}>
          Não foi possível remover a taxa
        </Message>
      )}
    </Panel>
  )
}

export default TaxasBalcao
