import { Chip, styled } from '@mui/material'

export const StyledChip = styled(Chip)(({ status = 'active', theme }) => {
  const color = {
    success: {
      chip: theme.palette.success.main + '26',
      dot: theme.palette.success.main,
    },
    grey: {
      chip: theme.palette.grey[200],
      dot: theme.palette.grey[500],
    },
    error: {
      chip: theme.palette.error.main + '26',
      dot: theme.palette.error.main,
    },
    warning: {
      chip: theme.palette.warning.main + '26',
      dot: theme.palette.warning.main,
    },
  }
  return {
    backgroundColor: color[status].chip,
    borderRadius: 4,
    padding: 8,
    fontSize: '1rem',
    fontWeight: 500,
    color: theme.palette.common.black,
    position: 'relative',
    '&:before': {
      content: "' '",
      position: 'relative',
      height: 8,
      width: 8,
      backgroundColor: color[status].dot,
      borderRadius: '50%',
    },
  }
})
