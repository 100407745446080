import React, { useRef } from 'react'
import { Row } from 'react-grid-system'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useDispatch } from 'react-redux'
import useService from '@/main/hooks/useService'
import { postCreateUser } from '@/services/usersServices'
import { Body, TitleModal, Flex, Label } from './styles'
import { ModalV2, Input, Button, Spinner, ErrorWrapper, ErrorMessage } from '@/presentation/components'
import { NOTIFICATION_TYPE } from '@/main/utils/constants'
import { pushNotification } from '@/store/modules/notification/actions'

function CreateUser({ open, close, refresh }) {
  const dispatch = useDispatch()
  const shouldClose = useRef(true)

  const schema = yup
    .object({
      name: yup.string().required().min(3),
      email: yup.string().required().email(),
    })
    .required()

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: null,
      email: null,
    },
  })

  const [createUserState, createUserRequest] = useService(postCreateUser, {
    onCompleted: () => {
      dispatch(
        pushNotification({
          title: 'Usuário Criado',
          content: 'Peça ao colaborador para cadastrar a senha pelo email informado.',
          type: NOTIFICATION_TYPE.SUCCESS,
        }),
      )
      refresh()
      reset({})
      shouldClose.current && close()
    },
  })

  const submit = async (data) => {
    data.active = true
    createUserRequest(data)
  }

  return (
    <ModalV2 open={open} onClose={close}>
      <Body>
        <Flex>
          <TitleModal>Adicionar Usuário</TitleModal>
        </Flex>
        <Row style={{ marginTop: '20px', marginLeft: '0px' }}>
          {createUserState.loading ? (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                padding: '32px',
              }}
            >
              <Spinner />
            </div>
          ) : (
            <form style={{ width: '97%' }} onSubmit={handleSubmit(submit)} id='formCreate'>
              <ErrorWrapper error={errors.name}>
                <Label>Nome</Label>
                <Input type='text' placeholder='Nome do colaborador' {...register('name')} />
                {errors.name && <ErrorMessage>O nome deve conter no mínimo 3 caracteres</ErrorMessage>}
              </ErrorWrapper>
              <div style={{ marginTop: '30px' }} />
              <ErrorWrapper error={errors.email}>
                <Label>Email</Label>
                <Input type='email' placeholder='E-mail do colaborador' {...register('email')} />
                {errors.email && (
                  <ErrorMessage>Para prosseguir você deve preencher este campo com E-mail válido</ErrorMessage>
                )}
              </ErrorWrapper>
              <div style={{ marginTop: '30px' }} />
            </form>
          )}

          <Flex>
            <Button
              width='50%'
              variant='outlined'
              style={{ marginRight: '10px' }}
              onClick={() => (shouldClose.current = false)}
              form='formCreate'
            >
              Adicionar e Criar Outro
            </Button>
            <Button
              width='50%'
              variant='action'
              type='submit'
              form='formCreate'
              onClick={() => (shouldClose.current = true)}
            >
              Adicionar Usuário
            </Button>
          </Flex>
        </Row>
      </Body>
    </ModalV2>
  )
}

export default CreateUser
