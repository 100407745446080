import { useState } from 'react'
import { colord } from 'colord'
import useDebounce from '@/main/hooks/useDebouce'
import {
  StyledColorInput,
  StyledColorInputLabel,
  StyledColorInputsContainer,
  StyledColorPicekrContainer,
  StyledColorPicker,
} from './styles'

export function ColorPicker({ initialColor = '#ffffff', value, onChange = () => null }) {
  const debounce = useDebounce()
  const initialValue = colord(value || initialColor)

  const [state, setState] = useState(initialValue.toHsv())
  const [hexInputValue, setHexInputValue] = useState(initialValue.toHex())

  const color = value === undefined ? state : colord(value).toHsv()
  const setColor = (e) => {
    debounce(() => {
      if (value === undefined) setState(e)
      onChange(e)
    }, 50)
  }

  const onHexInputChange = (e) => {
    const hexColor = e.target.value
    const isAnInvalidHex = hexColor.match(/^[#].*[^a-f\d]/g) || hexColor === ''
    if (isAnInvalidHex) return
    setHexInputValue(hexColor)
    setColor(colord(hexColor).toHsv())
  }

  const onRgbInputChange = (channel, inputValue) => {
    const value = Number(inputValue)
    if (!(value < 256) || !(value > -1)) return
    const rgbColor = colord(color).toRgb()
    rgbColor[channel] = value
    setColor(colord(rgbColor).toHsv())
  }

  return (
    <StyledColorPicekrContainer>
      <StyledColorPicker
        color={color}
        onChange={(newColor) => {
          setColor(newColor)
          setHexInputValue(colord(newColor).toHex())
        }}
      />
      <StyledColorInputsContainer>
        <StyledColorInputLabel>
          Hex
          <StyledColorInput value={hexInputValue} onChange={onHexInputChange} maxLength={7} />
        </StyledColorInputLabel>

        <StyledColorInputLabel>
          R
          <StyledColorInput
            value={colord(color).toRgb().r}
            onChange={(e) => {
              onRgbInputChange('r', e.target.value)
            }}
            maxLength={3}
          />
        </StyledColorInputLabel>

        <StyledColorInputLabel>
          G
          <StyledColorInput
            value={colord(color).toRgb().g}
            onChange={(e) => {
              onRgbInputChange('g', e.target.value)
            }}
            maxLength={3}
          />
        </StyledColorInputLabel>

        <StyledColorInputLabel>
          B
          <StyledColorInput
            value={colord(color).toRgb().b}
            onChange={(e) => {
              onRgbInputChange('b', e.target.value)
            }}
            maxLength={3}
          />
        </StyledColorInputLabel>
      </StyledColorInputsContainer>
    </StyledColorPicekrContainer>
  )
}
