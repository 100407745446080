import React, { useState } from 'react'
import { Container, Row } from 'react-grid-system'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useDispatch } from 'react-redux'
import Confirm from './confirm'
import { Body, TitleModal, Flex, Label, RadioWrapper } from './styles'
import { ModalV2, Input, Button, Spinner, ErrorWrapper, ErrorMessage } from '@/presentation/components'
import { pushNotification } from '@/store/modules/notification/actions'
import successHandler from '@/main/utils/successHandler'
import useService from '@/main/hooks/useService'
import { putEditUser } from '@/services/usersServices'

const CHANGES = {
  status: 'status',
  info: 'info',
  nothing: 'nothing',
}

function EditUser({ open, close, data, refresh }) {
  const dispatch = useDispatch()
  const [disable, setDisable] = useState(true)
  const [confirm, setConfirm] = useState(false)
  const [values, setValues] = useState({
    id: data.id,
    name: data.name,
    email: data.email,
    active: data.active ? 'true' : 'false',
  })

  const schema = yup
    .object({
      name: yup.string().required('Nome é um campo requerido').min(3, 'O nome deve conter no mínimo 3 caracteres'),
      email: yup
        .string()
        .required('Email é um campo requerido')
        .email('Para prosseguir você deve preencher este campo com Emailválido'),
    })
    .required()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      id: values.id,
      name: values.name,
      email: values.email,
      active: values.active,
    },
    resolver: yupResolver(schema),
  })

  const [editUserState, editUserRequest] = useService(putEditUser, {
    onCompleted: () => {
      dispatch(pushNotification(successHandler('Usuário editado com sucesso')))
      refresh()
      close()
    },
  })

  const submit = async () => {
    const data = values
    editUserRequest(data)
  }

  const whatHasChanged = (newData) => {
    if (newData.active !== data.active) return CHANGES.status
    if (newData.email !== data.email || newData.name !== data.name) return CHANGES.info
    return CHANGES.nothing
  }

  const handleConfirm = (formData) => {
    const newData = {
      id: formData.id,
      name: formData.name,
      email: formData.email,
      active: formData.active === 'true',
    }
    if (whatHasChanged(newData) === CHANGES.nothing) return
    setConfirm(true)
    setValues(newData)
  }

  return (
    <>
      <ModalV2 open={open} onClose={close} blockScroll>
        {editUserState.loading ? (
          <Spinner.Box>
            <Spinner />
          </Spinner.Box>
        ) : (
          <Body>
            <Flex>
              <TitleModal>Dados do Usuário</TitleModal>
            </Flex>
            <Container fluid style={{ padding: 0 }}>
              <Row style={{ marginTop: '20px', marginLeft: '0px' }}>
                <form style={{ width: '97%' }} onSubmit={handleSubmit(handleConfirm)} id='formEdit'>
                  <ErrorWrapper error={errors.name}>
                    <Label>Nome</Label>
                    <Input placeholder='Nome do colaborador' {...register('name')} disabled={disable} />
                    {errors.name && <ErrorMessage>{errors.name.message}</ErrorMessage>}
                  </ErrorWrapper>

                  <div style={{ marginTop: '30px' }} />
                  <ErrorWrapper error={errors.email}>
                    <Label>Email</Label>
                    <Input type='email' placeholder='E-mail do colaborador' {...register('email')} disabled={disable} />
                    {errors.email && <ErrorMessage>{errors.email.message}</ErrorMessage>}
                  </ErrorWrapper>
                  <div style={{ marginTop: '30px' }} />
                  <Label>Status</Label>
                  <RadioWrapper>
                    <Input.RadioButton
                      {...register('active')}
                      onChange={(e) => setValues({ ...values, active: e.target.value })}
                      disabled={disable}
                      checked={values.active === 'true'}
                      value='true'
                    />
                    <p>Ativo</p>
                    <Input.RadioButton
                      {...register('active')}
                      onChange={(e) => setValues({ ...values, active: e.target.value })}
                      disabled={disable}
                      checked={values.active === 'false'}
                      value='false'
                    />
                    <p>Inativo</p>
                  </RadioWrapper>
                </form>
                <Flex style={{ width: '98%' }}>
                  {disable && (
                    <>
                      <Button
                        width='50%'
                        variant='outlined action'
                        style={{ marginRight: '10px' }}
                        onClick={() => setDisable(false)}
                      >
                        Editar
                      </Button>
                      <Button width='50%' variant='action' onClick={() => close()}>
                        Voltar
                      </Button>
                    </>
                  )}
                  {!disable && (
                    <Button width='50%' variant='action' style={{ marginRight: '10px' }} type='submit' form='formEdit'>
                      Salvar
                    </Button>
                  )}
                </Flex>
              </Row>
            </Container>
          </Body>
        )}
      </ModalV2>

      {confirm && (
        <Confirm
          open={confirm}
          close={() => setConfirm(false)}
          confirm={submit}
          alertType={whatHasChanged(values)}
          newStatus={values.active}
        />
      )}
    </>
  )
}

export default EditUser
