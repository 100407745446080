import { faCheck, faPencil, faX } from '@fortawesome/free-solid-svg-icons'
import { Box, Icon } from '@stationkim/front-ui'
import { IconContainer } from './styles'

interface RowEditionControllerProps {
  isEditing: boolean
  onCancel: () => void
  onEdit: () => void
  onClick: () => void
}

export const RowEditionController = ({ isEditing, onCancel, onClick, onEdit }: RowEditionControllerProps) => {
  return !isEditing ? (
    <IconContainer onClick={onClick}>
      <Icon icon={faPencil} />
    </IconContainer>
  ) : (
    <Box sx={{ display: 'flex', gap: '8px' }}>
      <IconContainer onClick={onCancel} color={'cancel'}>
        <Icon icon={faX} />
      </IconContainer>
      <IconContainer onClick={onEdit} color={'confirm'}>
        <button style={{ all: 'unset' }} type='submit'>
          <Icon icon={faCheck} />
        </button>
      </IconContainer>
    </Box>
  )
}
