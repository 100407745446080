import { routesAlias } from '@/main/utils/routesAlias'
import { Crumb, CrumbsContainer } from './style'
import { useLocation, useNavigate } from 'react-router-dom'

function Breadcrumb({ props }) {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const pathnames = pathname.split('/').filter((x) => x)
  function generateCrumbs(pathnames) {
    return pathnames.map((name, index) => {
      const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`
      const isLast = index === pathnames.length - 1
      return (
        <Crumb
          key={`${index}_link`}
          isLast={isLast}
          onClick={() => {
            !isLast && navigate(routeTo)
          }}
        >
          {routesAlias[routeTo] ? routesAlias[routeTo] : name.charAt(0).toUpperCase() + name.slice(1)}
        </Crumb>
      )
    })
  }

  return (
    <CrumbsContainer {...props}>
      <Crumb
        onClick={() => {
          navigate('/')
        }}
      >
        Início
      </Crumb>
      {generateCrumbs(pathnames)}
    </CrumbsContainer>
  )
}

export default Breadcrumb
