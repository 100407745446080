import { useState } from 'react'
import { Button, Grid, Icon } from '@/presentation/components/atoms'
import { AutoTable, Search, TableFooterPagination } from '@/presentation/components/molecules'
import { useCompanyCards } from '@/main/hooks/useCompanyCards'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import { atomWithHash } from 'jotai-location'
import { useFilter } from '@/main/hooks/useFilter'
import { usePagination } from '@/main/hooks/usePagination'
import { companyCardsTableConfiguration } from './utils/companyCardsTableConfiguration'
import { companyCardsFilterConfigurations } from './utils/companyCardsFilterConfigurations'
import { Filter } from '@/presentation/components/organisms/filter'
import { useCompanyCardsFilter } from '@/main/hooks/useCompanyCardsFilter'

const filterAtom = atomWithHash<object>('companyAllCardsFilter', null)
const paginationAtom = atomWithHash('companyAllCardsPagination', null)
export interface ICardsTableColumns {
  cardName: string
  number: string
  fullname: string
  registration: string
  type: string | number
  dayValue: number
  balance: number
  copy: string | number
  status: string | number
}

export const CompanyAllCards = () => {
  const [isFilterOpen, setIsFilterOpen] = useState(false)

  const { filter, resetFilter, updateFilter } = useFilter({ filterAtom, initialValue: { sort: 'fullname' } })
  const { pagination, goToPage, setPageSize } = usePagination({ paginationAtom })
  const { cards, isLoading } = useCompanyCards({ filter, pagination })
  const cardsData = cards?.data

  const { cardsFilterOptions } = useCompanyCardsFilter({
    config: {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  })

  const operatorsCards = !cardsFilterOptions?.benefitTypes
    ? []
    : cardsFilterOptions?.benefitTypes.reduce((actual: Array<object>, type: any) => [...actual, ...type.benefits], [])
  const columnsConfiguration = companyCardsTableConfiguration({ updateFilter })
  const filterConfiguration = companyCardsFilterConfigurations({
    benefitTypes: cardsFilterOptions?.benefitTypes,
    operatorsCards: operatorsCards,
    operators: cardsFilterOptions?.operators,
  })
  return (
    <>
      <Grid container spacing='16px' sx={{ padding: '16px' }}>
        <Grid item xs={12} md={6} lg={5}>
          <Search
            onSearch={(value) => {
              goToPage(1)
              updateFilter({
                filterValue: value.trim(),
                filterProps: 'benefitName,number,fullname,registration',
              })
            }}
            placeholder='Buscar por nome cartão, número, colaborador ou matrícula'
          />
        </Grid>
        <Grid item xs={12} md='auto'>
          <Button
            variant='outlined'
            fullWidth
            onClick={() => setIsFilterOpen(true)}
            startIcon={<Icon icon={faFilter} />}
          >
            Filtrar
          </Button>
        </Grid>
        <Grid item xs={12}>
          <AutoTable<ICardsTableColumns> columns={columnsConfiguration} rows={cardsData} isLoading={isLoading} />
        </Grid>
      </Grid>

      <TableFooterPagination
        totalItems={cards.totalItens}
        onRowsPerPageChange={(e) => setPageSize(e.target.value)}
        rowsPerPage={cards.pageSize}
        totalPages={cards.totalPages}
        page={cards.pageNumber}
        onPageChange={(e, value) => goToPage(value)}
      />

      <Filter
        fields={filterConfiguration}
        isLoading={isLoading}
        onClean={() => resetFilter()}
        onFilter={(value) => {
          updateFilter({ ...value, page: 1 })
          setIsFilterOpen(false)
        }}
        onClose={() => setIsFilterOpen(false)}
        open={isFilterOpen}
        allowEmptyFilter
      />
    </>
  )
}
