import React, { useEffect, useRef, useState } from 'react'
import FloatingContainer from '../floatingContainer'
import Input from '../input'
import { AutoCompleteContainer, Option } from './styles'

function AutoComplete({
  list = [],
  getOptionsLabel = [() => null],
  getOptionValue = (option) => option.value,
  onValueChange = () => null,
  matches = () => null,
  masks = [],
  ...props
}) {
  const [filteredItems, setFilteredItems] = useState([])
  const [arrowNavigation, setArrowNavigation] = useState(0)
  const [value, setValue] = useState(null)
  const [inputValue, setInputValue] = useState('')
  const inputRef = useRef(null)
  const isListVisible = filteredItems.length > 0 && !!inputValue && value === null

  useEffect(() => {
    if (filteredItems.length === 1 && inputRef.current.value === filteredItems[0].display)
      setValue(filteredItems[0].value)
  }, [filteredItems])

  useEffect(() => {
    onValueChange(value)
  }, [value, onValueChange])
  useEffect(() => {
    matches(filteredItems.length, inputValue)
  }, [filteredItems, matches, inputValue])
  useEffect(() => {
    setArrowNavigation(0)
  }, [filteredItems])

  const filter = (searchedString) => {
    const possibilitie = []
    for (let i = 0; i < getOptionsLabel.length; i++) {
      list.forEach((item) => {
        const localString = String(getOptionsLabel[i](item))
        const localStringMasked = masks[i] ? masks[i](localString) : localString
        const localStringToComparsion = localStringMasked.toLocaleLowerCase()
        const inputString = searchedString.toLocaleLowerCase()
        const inputStringMasked = masks[i] ? masks[i](inputString) : inputString
        if (localStringToComparsion.startsWith(inputStringMasked)) {
          possibilitie.push({
            display: localStringMasked,
            value: getOptionValue(item),
          })
        }
      })
    }
    return possibilitie
  }

  const handleOnClick = (value) => {
    setFilteredItems([value])
    setInputValue(value.display)
  }

  const handleOnChange = (e) => {
    setValue(null)
    setInputValue(e.target.value)
    if (e.target.value === '') {
      setFilteredItems([])
      return
    }
    setFilteredItems(filter(e.target.value))
  }

  const inputAction = {
    ArrowUp: () => setArrowNavigation((state) => (state - 1 < 0 ? 0 : state - 1)),
    ArrowDown: () =>
      setArrowNavigation((state) => (state + 1 < filteredItems.length ? state + 1 : filteredItems.length - 1)),
    Enter: () => {
      if (!isListVisible || !filteredItems[arrowNavigation]) return
      setFilteredItems([filteredItems[arrowNavigation]])
      setInputValue(filteredItems[arrowNavigation].display)
    },
  }

  return (
    <AutoCompleteContainer>
      <Input
        ref={inputRef}
        value={inputValue}
        onChange={handleOnChange}
        onKeyDown={(e) => {
          if (inputAction[e.key] && !!inputValue) {
            e.preventDefault()
            inputAction[e.key]()
          }
        }}
        {...props}
      />
      <FloatingContainer visible={isListVisible}>
        {filteredItems.map((item, index) => (
          <Option
            key={Math.random()}
            onClick={() => handleOnClick(item)}
            onMouseEnter={() => setArrowNavigation(index)}
            selected={index === arrowNavigation}
          >
            {item.display}
          </Option>
        ))}
      </FloatingContainer>
    </AutoCompleteContainer>
  )
}

export default AutoComplete
