import { AutoComplete, Button, ErrorMessage, ErrorWrapper, Input, ModalV2, Spinner } from '@/presentation/components'
import useDownloadReport from '@/main/hooks/useDownloadReport'
import { getBalanceProjectionOrderReport, getCompaniesForReportFilter } from '@/services/reportsServices'
import { pushNotification } from '@/store/modules/notification/actions'
import errorHandler from '@/main/utils/errorHandler'
import { applyMask, removeObjectEmptyItems } from '@/main/utils/functionUtils'
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-grid-system'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { CustomContainer, ModalTitle } from '../styles'
import { CheckBoxWrapper, Label } from './styles'
import objectToQueryString from '@/main/utils/objectToQuerystring'

const BalanceProjectionOrderReportFilter = ({ open, title = 'Relatorio de Pedido', onClose }) => {
  const [download, requestDownload] = useDownloadReport(async (...args) => {
    try {
      const response = await getBalanceProjectionOrderReport(...args)

      return response
    } catch (e) {
      return
    }
  })

  const [maxDate, setMaxDate] = useState(new Date())
  const [minDate, setMinDate] = useState(null)
  const [initialDateMinDate, setInitialDateMinDate] = useState(null)
  const [finalDateMaxDate, setFinalDateMaxDate] = useState(new Date())
  const [companyId, setCompanyId] = useState(null)
  const [companies, setCompanies] = useState([])
  const [isCompanyPresent, setIsCompanyPresent] = useState(true)

  const dispatch = useDispatch()

  useEffect(() => {
    ;(async () => {
      const { error, response } = await getCompaniesForReportFilter()
      if (response) setCompanies(response.data)
      else dispatch(pushNotification(errorHandler(error.response)))
    })()
  }, [dispatch])

  const {
    control,
    handleSubmit,
    formState: { dirtyFields },
    reset,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      code: '',
      startDate: null,
      endDate: null,
    },
  })

  const invertDateString = (string) => {
    const dateArray = string.split('-')
    return `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`
  }

  const onSubmit = (data) => {
    const reportName = data.code
      ? `Relatório de Pedido de Recarga Com Projeção de Saldo #${data.code}.pdf`
      : `Relatório de Pedido de Recarga Com Projeção de Saldo - De ${invertDateString(
          data.startDate,
        )} Até ${invertDateString(data.endDate)}.pdf`
    requestDownload(
      reportName,
      objectToQueryString(
        removeObjectEmptyItems({
          companyId,
          code: data.code ? Number(data.code) : null,
          startDate: data.startDate,
          endDate: data.endDate,
        }),
      ),
    )
    reset()
  }

  return (
    <ModalV2 open={open} onClose={onClose}>
      {download.loading ? (
        <Spinner />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} id='balanceProjectionOrderReportFilterForm'>
          <CustomContainer fluid>
            <Row>
              <Col>
                <ModalTitle>{title}</ModalTitle>
              </Col>
            </Row>
            <Row>
              <Col>
                <ErrorWrapper error={!isCompanyPresent}>
                  <Label>Empresa</Label>
                  <AutoComplete
                    placeholder='Digite aqui o nome, CNPJ ou codigo da empresa'
                    getOptionsLabel={[(option) => option.tradeName, (option) => option.cnpj, (option) => option.code]}
                    masks={[null, (option) => applyMask('##.###.###/####-##', option), null]}
                    getOptionValue={(option) => option.id}
                    list={companies}
                    matches={(matches, inputValue) =>
                      matches === 0 && inputValue !== '' ? setIsCompanyPresent(false) : setIsCompanyPresent(true)
                    }
                    onValueChange={(value) => setCompanyId(value)}
                  />
                  {!isCompanyPresent && <ErrorMessage>Empresa não encontrada</ErrorMessage>}
                </ErrorWrapper>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <Label>N° do Pedido</Label>

                <Controller
                  control={control}
                  name='code'
                  render={({ field: { onChange, value, ref } }) => (
                    <Input.Masked
                      placeholder='Insira o número do pedido'
                      getInputRef={ref}
                      onChange={onChange}
                      allowNegative={false}
                      value={value}
                      disabled={dirtyFields.startDate || dirtyFields.endDate || !companyId}
                    />
                  )}
                />
              </Col>
            </Row>
            <Row>
              <Col>Período</Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Label>De</Label>
                <Controller
                  control={control}
                  name='startDate'
                  render={({ field: { onChange, value, ref } }) => (
                    <Input.Date
                      placeholder='00/00/0000'
                      maxDate={maxDate}
                      minDate={initialDateMinDate}
                      getInputRef={ref}
                      value={new Date(value?.replaceAll('-', '/'))}
                      disabled={dirtyFields.code || !companyId}
                      onChange={(e) => {
                        if (e !== '') {
                          const inputDate = new Date(e)
                          const today = new Date()
                          const inputDatePlusSixMonths = new Date(inputDate.setMonth(inputDate.getMonth() + 6))
                          const datefinal = inputDatePlusSixMonths > today ? today : inputDatePlusSixMonths
                          setFinalDateMaxDate(datefinal)

                          onChange(e?.toISOString().split('T')[0])
                          setMinDate(e)
                        } else {
                          onChange(null)
                          setMinDate(null)
                        }
                      }}
                    />
                  )}
                />
              </Col>
              <Col sm={6}>
                <Label>Até</Label>
                <Controller
                  control={control}
                  name='endDate'
                  render={({ field: { onChange, value, ref } }) => (
                    <Input.Date
                      placeholder='00/00/0000'
                      maxDate={finalDateMaxDate}
                      minDate={minDate}
                      getInputRef={ref}
                      value={new Date(value?.replaceAll('-', '/'))}
                      disabled={dirtyFields.code || !companyId}
                      onChange={(e) => {
                        if (e !== '') {
                          const inputDate = new Date(e)
                          setInitialDateMinDate(new Date(inputDate.setMonth(inputDate.getMonth() - 6)))
                          onChange(e?.toISOString().split('T')[0])
                          setMaxDate(e)
                        } else {
                          onChange(null)
                          setMaxDate(null)
                        }
                      }}
                    />
                  )}
                />
              </Col>
            </Row>

            <Row>
              <Col sm={2}>
                <CheckBoxWrapper>
                  <Input.CheckBox checked controlled />
                  <span>PDF</span>
                </CheckBoxWrapper>
              </Col>
            </Row>
            <Row justify='end'>
              <Col sm='content'>
                <Button
                  disabled={((!dirtyFields.startDate || !dirtyFields.endDate) && !dirtyFields.code) || !companyId}
                >
                  Gerar Relatório
                </Button>
              </Col>
            </Row>
          </CustomContainer>
        </form>
      )}
    </ModalV2>
  )
}

export default BalanceProjectionOrderReportFilter
