export const routesAlias = {
  '/financeiro': 'Gestão Financeira',
  '/financeiro/cadastrar_banco_emissor': 'Cadastro de Banco Emissor',
  '/financeiro/conciliacao_de_pagamentos': 'Conciliação de Pagamentos',
  '/empresas/consulta_de_empresas': 'Consulta de Empresas',
  '/empresas': 'Empresas Clientes',
  '/empresas/consulta_de_empresas/informacoes_de_pedidos': 'Informações de Pedidos',
  '/empresas/consulta_de_empresa': 'Consulta de Empresa',
  '/empresas/taxas_balcao': 'Taxas Balcão',
  '/financeiro/extrato': 'Extrato Conta Digital',
  '/parametros_gerais': 'Parâmetros Gerais',
}
