import { useAtom } from 'jotai'
import { atomWithHash } from 'jotai-location'
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import { Box, Card, ColorfulBox, Icon, Tooltip, Typography } from '@/presentation/components/atoms'
import { AutoTable, ButtonSelector, ButtonSelectorOption } from '@/presentation/components/molecules'
import { IUseDashboardReturn } from '../../hooks/useDashboardInterfaces'
import { YearAndMonth } from '../../dashboard'
import { formatMoney } from '@/main/utils/functions'
import { Theme } from '@mui/material'

interface OrdersTableCardProps {
  data: IUseDashboardReturn['dashboardData']
  selectedDate: YearAndMonth
  isLoading?: boolean
}

const formatToBrMoney = (value: number) => `R$ ${formatMoney(value)}`

const ordersTableVisualizationAtom = atomWithHash('ordersTableVisualization', 0)
export const OrdersTableCard = ({ data, selectedDate, isLoading }: OrdersTableCardProps) => {
  const isMonth = selectedDate.month !== ''
  const [ordersTableVisualization, setOrdersTableVisualization] = useAtom(ordersTableVisualizationAtom)
  const selectedMonthData = data?.monthlyOrdersPaymentInfo?.find((monthData) => monthData.month === selectedDate.month)

  const isQuantity = ordersTableVisualization === 1
  return (
    <Card sx={{ minWidth: '100%' }}>
      <ColorfulBox>
        <Box sx={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
          <Typography variant='subtitle1'>Pedidos - {isMonth ? 'mês/' : ''}ano</Typography>
          <Tooltip title='Abaixo você pode ver os pedidos realizados (pagos e não pagos) no mês/ano e suas formas de pagamento.'>
            <Icon icon={faCircleInfo} sx={(theme: Theme) => ({ color: theme.palette.info.main })} />
          </Tooltip>
        </Box>
        <ButtonSelector value={ordersTableVisualization} onChange={setOrdersTableVisualization}>
          <ButtonSelectorOption>R$</ButtonSelectorOption>
          <ButtonSelectorOption>Qtd</ButtonSelectorOption>
        </ButtonSelector>
      </ColorfulBox>
      <AutoTable
        isLoading={isLoading || !data?.monthlyOrdersPaymentInfo}
        variant='report'
        columns={[
          {
            columnDisplayName: 'Tipo',
            columnValue: ({ row }) => row.type.charAt(0).toUpperCase() + row.type.slice(1),
          },
          {
            columnDisplayName: 'QTD (n°)',
            columnValue: ({ row }) => row.quantity,
          },
          {
            columnDisplayName: 'BOLETO',
            columnValue: ({ row }) =>
              isQuantity
                ? `${row.slipOrdersQuantity} (${row.slipOrdersPercentual}%)`
                : formatToBrMoney(row.totalSlipValue),
          },
          {
            columnDisplayName: 'PIX',
            columnValue: ({ row }) =>
              isQuantity
                ? `${row.pixOrdersQuantity} (${row.pixOrdersPercentual}%)`
                : formatToBrMoney(row.totalPixValue),
          },
          {
            columnDisplayName: 'SALDO STATION',
            columnValue: ({ row }) =>
              isQuantity
                ? `${row.walletOrdersQuantity} (${row.walletOrdersPercentual}%)`
                : formatToBrMoney(row.totalWalletValue),
          },
          {
            columnDisplayName: 'TOTAL (R$)',
            columnValue: ({ row }) => formatToBrMoney(row.total),
          },
        ]}
        rows={selectedMonthData?.data}
      />
    </Card>
  )
}
