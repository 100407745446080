import { InputBase, styled } from '@mui/material'
import { Box } from '@stationkim/front-ui'

export const CopyableFieldContainer = styled(Box)<{ error?: boolean }>(({ error, theme }) => ({
  position: 'relative',
  border: error ? `1px solid ${theme.palette.error.main}` : 'none',
  borderRadius: '4px',
  '& .MuiInputBase-root': error
    ? {
        backgroundColor: theme.palette.error.dark + '0D',
      }
    : {},
}))

export const CopyableFieldIconsContainer = styled(Box)(() => ({
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  right: '4px',
  top: '8px',
  width: '48px',
  '& svg': {
    cursor: 'pointer',
  },
  '& *:only-child': {
    marginLeft: 'auto',
  },
}))

export const CopyableFieldInput = styled(InputBase)<{ isPassword?: boolean }>(({ theme, isPassword, disabled }) => ({
  height: '32px',
  background: disabled ? theme.palette.grey[50] : theme.palette.common.white,
  border: '1px solid',
  borderColor: disabled ? theme.palette.grey[50] : theme.palette.grey[400],
  color: theme.palette.grey[700],
  padding: `8px ${isPassword ? 56 : 26}px 8px 4px`,
  borderRadius: '4px',
  '& input': {
    padding: 0,
  },
}))
