import React from 'react'
import { Button, ButtonIcon } from './style'

function Simple({ children, icon, size, variant, width, ...props }) {
  return (
    <Button size={size} variant={variant} width={width} {...props}>
      {icon && <ButtonIcon className='icon' name={icon} />}
      {children}
    </Button>
  )
}

export default Simple
