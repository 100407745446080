import { useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import api from '@/infra/api'
import { GlobalThemePersistentStore } from '../store'
import { useAtomWithStorageObserver } from './useAtomWithStorageObserver'
import { defaultTheme } from '@/config/themes/defaultTheme'

export const useSession = (resaleSite: string, shouldUpdateTheme?: boolean) => {
  const [, setTheme] = useAtomWithStorageObserver({
    atomWithStorage: GlobalThemePersistentStore,
    storageName: 'globalTheme',
  })

  const { data, ...rest } = useQuery({
    queryKey: ['session', resaleSite],
    queryFn: ({ queryKey }) => api.get(`/resale-bff/Session?resale=${queryKey[1]}`),
  })

  useEffect(() => {
    if (data && shouldUpdateTheme) {
      const theme = data.data.resale.theme
      const customTheme = JSON.parse(theme)?.palette?.brand
      if (customTheme) defaultTheme.palette.brand = customTheme
      setTheme(defaultTheme)
    }
  }, [data, setTheme, shouldUpdateTheme])

  return {
    sessionData: data?.data,
    ...rest,
  }
}
