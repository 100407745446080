import { Divider } from '@/presentation/components/atoms/divider'
import { Drawer } from '@/presentation/components/atoms/drawer'
import { Icon } from '@/presentation/components/atoms/Icon'
import { Typography } from '@/presentation/components/atoms/typography'
import { Fragment } from 'react'
import { StyledNavLink } from './styles'

export function SideNavigationMenu({ destinations = [], open, onClose, ...props }) {
  return (
    <Drawer anchor='left' open={open} onClose={onClose}>
      {destinations.map((destination, index) => (
        <Fragment key={`${index}_sideMenuItem`}>
          <StyledNavLink to={destination.pathname} exact onClick={onClose}>
            <Icon icon={destination.icon} />
            <Typography>{destination.displayName}</Typography>
          </StyledNavLink>
          {index !== 0 ? null : <Divider />}
        </Fragment>
      ))}
    </Drawer>
  )
}
