import api from '@/infra/api'
import { IFetchHook } from '@/main/interfaces'
import { IPaginatedRequest } from '@/main/interfaces/iPaginatedRequest'
import { useQuery } from '@tanstack/react-query'

export interface IUseOrdersReturn {
  orders: IPaginatedRequest<{
    code: number
    companyName: string
    dueDate: null
    id: string
    orderDate: string
    orderStatus: number
    orderType: number
    totalValue: number
    useBalanceProjection: boolean
  }>
  isLoading: boolean
}
interface IApiReturn {
  data: Array<{
    code: number
    companyName: string
    dueDate: null
    id: string
    orderDate: string
    orderStatus: number
    orderType: number
    totalValue: number
    useBalanceProjection: boolean
  }>
  pageNumber: number
  pageSize: number
  totalItens: number
  totalPages: number
}

const defaultValue: IUseOrdersReturn['orders'] = {
  data: [],
  page: 1,
  pageSize: 10,
  totalItems: 0,
  totalPages: 1,
}

export const useOrders = ({ filter, pagination, immutableQuery }: IFetchHook): IUseOrdersReturn => {
  const { data, ...rest } = useQuery({
    queryKey: ['orders', filter, pagination, immutableQuery],
    queryFn: () =>
      api.get<IApiReturn>(`/resale-bff/orders`, {
        params: { ...pagination, ...filter, ...immutableQuery },
      }),
    refetchOnWindowFocus: true,
  })

  /* //REMOVE AS SOON AS POSSIBLE - DOWN
  const { data: injectionData } = useQuery<any>({
    queryKey: ['injectedOrders', filter, pagination, immutableQuery],
    queryFn: () =>
      api.get(`/rh-bff/brokerapi/pedidos?situacaoPedido=5`, {
        params: { ...pagination, ...filter, ...immutableQuery },
      }),
    refetchOnWindowFocus: false,
  })

  const _injectTempRowsForTest = (): any[] => {
    const dataToInject = injectionData?.data?.pedidos
    if (dataToInject) {
      return dataToInject.map((injData) => {
        return {
          code: injData.codigo,
          companyName: 'Empresa Teste',
          dueDate: null,
          id: injData.codigo,
          orderDate: new Date().toISOString(),
          orderStatus: injData.situacao,
          orderType: 5,
          totalValue: 1,
          useBalanceProjection: false,
          fakeOrder: true,
        }
      })
    }
    return []
  }
  //REMOVE AS SOON AS POSSIBLE - UP */

  const organizeData = (): IUseOrdersReturn['orders'] => {
    const rawData = data?.data
    if (!rawData) return defaultValue
    return {
      data: rawData.data,
      page: rawData.pageNumber,
      pageSize: rawData.pageSize,
      totalItems: rawData.totalItens,
      totalPages: rawData.totalPages,
    }
  }

  return {
    orders: organizeData(),
    ...rest,
  }
}
