import blobDownload from '@/main/utils/blobDownload'
import blobToJson from '@/main/utils/blobToJson'

import { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { pushNotification } from '@/store/modules/notification/actions'
import errorHandler from '@/main/utils/errorHandler'

const initialState = {
  loading: false,
}

const useDownloadReport = (reportService) => {
  const [state, setState] = useState({ ...initialState })
  const dispatch = useDispatch()

  const requestReport = useCallback(
    async (fileName, ...args) => {
      try {
        setState({ ...initialState, loading: true })
        const response = await reportService(...args)

        if (response?.response?.data && !response?.error) {
          const blob = new Blob([response?.response?.data], { type: 'text/plain' })
          const filename = fileName
          blobDownload(blob, filename)
        } else {
          dispatch(
            pushNotification(
              errorHandler({
                ...response?.error,
                data: await blobToJson(response?.error?.response?.data),
              }),
            ),
          )
        }

        setState({ ...initialState, loading: false })
      } catch (e) {
        setState({ ...initialState, loading: false })
        return
      }
    },
    [dispatch, reportService],
  )

  return [state, requestReport]
}

export default useDownloadReport
