import { Button, styled } from '@mui/material'
import { ButtonProps } from '.'

export const StyledButton = styled(Button)<ButtonProps>(() => {
  return {
    '& svg': {
      height: '16px',
    },
  }
})
