import React from 'react'
import Modal from 'react-responsive-modal'
import { Button } from '@/presentation/components'
import { ButtonsWrapper, Content } from '../styles'

function TwoOptions({
  hideButton,
  open,
  onClose,
  onLeftClick,
  leftText = 'Não',
  onRightClick,
  rightText = 'Sim',
  children,
}) {
  return (
    <Modal open={open} onClose={onClose} center>
      <Content>
        {children}
        {!hideButton && (
          <ButtonsWrapper>
            <Button variant='outlined' onClick={onLeftClick}>
              {leftText}
            </Button>
            <Button onClick={onRightClick}>{rightText}</Button>
          </ButtonsWrapper>
        )}
      </Content>
    </Modal>
  )
}

export default TwoOptions
