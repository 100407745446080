import { Box } from '@/presentation/components/atoms'
import { styled } from '@mui/material'

export const DropZone = styled(Box)<{ hasFile: any }>(({ theme, isRejected, hasFile }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  gap: '8px',
  marginBottom: '16px',
  cursor: 'pointer',
  height: 'fit-content',
  padding: '16px',
  borderRadius: '4px',
  border: `dashed 1px ${
    isRejected ? theme.palette.error.light : hasFile ? theme.palette.success.light : theme.palette.grey[200]
  }`,
  backgroundColor: isRejected
    ? theme.palette.error.light + '1A'
    : hasFile
    ? theme.palette.success.light + '1A'
    : 'initial',
  '&:hover': {
    backgroundColor: theme.palette.primary.light + '1A',
    border: `dashed 1px ${theme.palette.primary.light}`,
  },

  '&:hover div:nth-of-type(1)': {
    backgroundColor: theme.palette.primary.light + '33',
    '& svg': {
      color: theme.palette.primary.main,
    },
  },
  '& span': {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
  },
  transition: '0.25s all ease-in-out',
  overflow: 'hidden',
}))

export const DropZoneIconWrapper = styled(Box)<{ hasFile: any }>(({ theme, hasFile }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  minHeight: '40px',
  minWidth: '40px',
  transition: '0.25s all ease-in-out',
  backgroundColor: hasFile ? theme.palette.success.dark + '33' : theme.palette.primary.light + '33',
  '& svg': {
    height: '24px',
    width: '24px',
    color: hasFile ? theme.palette.success.dark : theme.palette.primary.main,
    transition: '0.25s all ease-in-out',
  },
}))
