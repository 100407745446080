import { TabsProps as MUITabsProps } from '@mui/material'
import { StyledTabs } from './styles'

export interface TabsProps extends MUITabsProps {
  asGroupButtons?: boolean
}

export function Tabs({
  scrollButtons = 'auto',
  variant = 'scrollable',
  allowScrollButtonsMobile = true,
  ...props
}: TabsProps) {
  return (
    <StyledTabs
      scrollButtons={scrollButtons}
      variant={variant}
      allowScrollButtonsMobile={allowScrollButtonsMobile}
      {...props}
    />
  )
}
