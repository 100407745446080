import { forwardRef, useEffect, useState } from 'react'
import { Icon, InputBaseProps, Tooltip } from '@stationkim/front-ui'
import { faCopy, faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons'
import { CopyableFieldContainer, CopyableFieldIconsContainer, CopyableFieldInput } from './styles'

interface CopyableFieldProps extends InputBaseProps {
  isPassword?: boolean
  disabled?: boolean
  error?: boolean
}

export const CopyableField = forwardRef(
  ({ value = '', autoComplete, isPassword, error, ...props }: CopyableFieldProps, ref) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false)
    const [showTooltip, setShowTooltip] = useState(false)

    const sendToClipboard = () => {
      navigator.clipboard.writeText(String(value))
      setShowTooltip(true)
    }

    useEffect(() => {
      if (showTooltip) setTimeout(() => setShowTooltip(false), 700)
    }, [showTooltip])

    return (
      <CopyableFieldContainer error={error}>
        <CopyableFieldInput
          inputRef={ref}
          type={isPassword && !isPasswordVisible ? 'password' : 'text'}
          isPassword={isPassword}
          value={value}
          inputProps={{ autoComplete: autoComplete }}
          autoComplete={autoComplete}
          {...props}
        />
        <CopyableFieldIconsContainer>
          {isPassword && (
            <>
              {isPasswordVisible ? (
                <Icon icon={faEye} onClick={() => setIsPasswordVisible(false)} />
              ) : (
                <Icon icon={faEyeSlash} onClick={() => setIsPasswordVisible(true)} />
              )}
            </>
          )}
          <Tooltip title='Copiado!' disableHoverListener open={showTooltip} placement='top'>
            <Icon icon={faCopy} onClick={sendToClipboard} />
          </Tooltip>
        </CopyableFieldIconsContainer>
      </CopyableFieldContainer>
    )
  },
)
