import React from 'react'
import Modal from 'react-responsive-modal'
import { Button } from '@/presentation/components'
import { ButtonWrapper, Content } from '../styles'

function OneOption({ open, onClose, onOptionClick, optionText = 'Ok', children }) {
  return (
    <Modal open={open} onClose={onClose} center>
      <Content>
        {children}
        <ButtonWrapper>
          <Button onClick={onOptionClick}>{optionText}</Button>
        </ButtonWrapper>
      </Content>
    </Modal>
  )
}

export default OneOption
