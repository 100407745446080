import { faX } from '@fortawesome/free-solid-svg-icons'
import { colord } from 'colord'
import { Button } from '@/presentation/components/atoms/button'
import { Dialog } from '@/presentation/components/atoms/dialog'
import { DialogActions } from '@/presentation/components/atoms/dialogActions'
import { DialogContent } from '@/presentation/components/atoms/dialogContent'
import { DialogTitle } from '@/presentation/components/atoms/dialogTitle'
import { Grid } from '@/presentation/components/atoms/grid'
import { Icon } from '@/presentation/components/atoms/Icon'
import { useState } from 'react'
import { DualColorSelector } from '../dualColorSelector'
import { GlobalThemePersistentStore } from '@/main/store/globalTheme'
import { useAtomWithStorageObserver } from '@/main/hooks'

const generateTones = (hexColor) => {
  const color = colord(hexColor)
  return {
    main: color.toHex(),
    light: color.lighten(0.2).toHex(),
    dark: color.darken(0.1).toHex(),
  }
}

const createColors = (colorDuos) => {
  return colorDuos.map((duo) => ({
    primary: generateTones(duo.primary),
    secondary: generateTones(duo.secondary),
  }))
}

export const ColorSelectionDialog = ({ open, onChange, onClose, onConfirm }) => {
  const [theme] = useAtomWithStorageObserver({
    atomWithStorage: GlobalThemePersistentStore,
    storageName: 'globalTheme',
  })

  const defaultColors = [
    {
      primary: theme.palette?.brand?.primary,
      secondary: theme.palette?.brand?.secondary,
    },
    ...createColors([
      { primary: '#432184', secondary: '#672ED7' },
      { primary: '#2C2D5C', secondary: '#FCFDFF' },
      { primary: '#5AB9C1', secondary: '#B7E1E4' },
      { primary: '#660D33', secondary: '#9F144F' },
      { primary: '#78BB7B', secondary: '#C4E1C5' },
    ]),
  ]

  const [selectedColorSelector, setSelectedColorSelector] = useState(0)

  const handleColorSelectorClick = (colors, index) => {
    setSelectedColorSelector(index)
    onChange(colors)
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll='body'
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      sx={{
        '& .MuiBackdrop-root': { opacity: '0 !important' },
      }}
    >
      <DialogTitle id='alert-dialog-title'>
        Editar cor e tema
        <Icon icon={faX} onClick={onClose} />
      </DialogTitle>
      <DialogContent sx={{ margin: '64px 0' }}>
        <Grid container columns={{ xs: 3 }} spacing='32px'>
          {defaultColors.map((color, index) => {
            return (
              <Grid key={`${index}_colorGridItem`} item xs={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                <DualColorSelector
                  key={`${index}_colorPicker`}
                  initialColors={color}
                  editable={index === 0}
                  selected={selectedColorSelector === index}
                  onChange={onChange}
                  onClick={(colors) => {
                    handleColorSelectorClick(colors, index)
                  }}
                />
              </Grid>
            )
          })}
        </Grid>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button onClick={onClose}>Cancelar</Button>
        <Button onClick={onConfirm} autoFocus variant='contained' sx={{ width: '248px' }}>
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
