import { faPencil } from '@fortawesome/free-solid-svg-icons'
import { Typography } from '@mui/material'
import { TitleAndIconButton } from '@/presentation/components/molecules/titleAndIconButton'

export function Account({ informations = [], onClick }) {
  return (
    <>
      <TitleAndIconButton title='Personalização da Conta' onClick={onClick} icon={faPencil} />
      {informations.map((information) => (
        <>
          <Typography variant='h4'>{information.label}</Typography>
          <Typography variant='body1'>{information.value}</Typography>
        </>
      ))}
    </>
  )
}
