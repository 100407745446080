import { useEffect, useState } from 'react'
import { Notification } from '@/presentation/components'
import { useDispatch } from 'react-redux'
import { Body, Content, Title, PasswordRequirementList, PasswordRequirement, HelpText } from './styles'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import schema from './schema'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faLock, faX } from '@fortawesome/free-solid-svg-icons'
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons'
import useService from '@/main/hooks/useService'
import { postChangePassword } from 'services/auth'
import successHandler from '@/main/utils/successHandler'
import { Spinner } from '@/presentation/components'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { pushNotification } from 'store/modules/notification/actions'
import { TextField } from '@/presentation/components/molecules'
import { Button, Icon } from '@/presentation/components/atoms'

const REQUERIMENTS = [
  {
    label: 'Ao menos 8 caracteres',
    regex: /.{8,}$/,
  },
  {
    label: 'Um carácter maiúsculo',
    regex: /[A-Z]/,
  },
  {
    label: 'Um carácter minúsculo',
    regex: /[a-z]/,
  },
  {
    label: 'Um carácter especial',
    regex: /[^A-Za-z0-9]/,
  },
  {
    label: 'Um carácter numérico',
    regex: /[0-9]/,
  },
]

const PasswordDefinition = () => {
  const dispatch = useDispatch()
  const { resaleSite } = useParams()
  const [searchParams] = useSearchParams()
  const isKeycloak = searchParams.get('isKeycloak')
  const token = searchParams.get('token')
  const email = searchParams.get('email')
  const [isNewPassVisible, setIsNewPassVisible] = useState(false)
  const [isConfirmNewPassVisible, setIsConfirmNewPassVisible] = useState(false)
  const [isRequerimentsVisible, setIsRequerimentsVisible] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    !token && navigate('/')
  }, [token])

  const [changePasswordState, changePasswordRequest] = useService(postChangePassword, {
    onCompleted: () => {
      dispatch(pushNotification(successHandler('Senha definida com sucesso!')))
      navigate(resaleSite ? `/${resaleSite}/login` : '/login')
    },
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
    mode: 'onChange',
    resolver: yupResolver(schema),
  })

  const [passwordValue] = watch(['password'])

  const onSubmit = (data) => {
    const payload = { ...data, isKeycloak: Boolean(isKeycloak) }
    payload.token = token?.replaceAll(' ', '+')
    payload.email = email?.replaceAll(' ', '+')
    payload.site = resaleSite

    changePasswordRequest(payload)
  }

  const validateByRegex = (regex, string) => {
    if (!string || !regex) return
    const matchResult = string.match(regex)
    return matchResult?.length > 0
  }

  const renderPasswordRequeriments = () => {
    return REQUERIMENTS.map((requeriment, index) => {
      const isValid = validateByRegex(requeriment.regex, passwordValue)
      return (
        <PasswordRequirement validated={isValid} key={'requeriment_' + index}>
          {requeriment.label}
          {isValid ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faX} />}
        </PasswordRequirement>
      )
    })
  }

  const onBlurCapture = () => {
    const dontMeetARule = REQUERIMENTS.some((requeriment) => !validateByRegex(requeriment.regex, passwordValue))
    if (passwordValue === '' || !dontMeetARule) setIsRequerimentsVisible(false)
  }
  return (
    <>
      <Notification />
      <Body>
        <Content>
          {changePasswordState.loading ? (
            <Spinner.Box>
              <Spinner />
            </Spinner.Box>
          ) : (
            <>
              <Title variant='h2'>Cadastrar nova senha</Title>
              <HelpText>
                Informe a nova senha que será usada para
                <br /> acessar a plataforma.
              </HelpText>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                  control={control}
                  name='password'
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      value={value}
                      onChange={onChange}
                      type={isNewPassVisible ? 'text' : 'password'}
                      placeholder='Informe a senha'
                      maxLength={100}
                      autoComplete={false}
                      onFocus={() => {
                        setIsRequerimentsVisible(true)
                      }}
                      onBlurCapture={onBlurCapture}
                      label='Nova senha'
                      startAdornment={<Icon icon={faLock} />}
                      endAdornment={
                        <Icon
                          icon={isNewPassVisible ? faEyeSlash : faEye}
                          onClick={() => {
                            setIsNewPassVisible(!isNewPassVisible)
                          }}
                          sx={{ cursor: 'pointer' }}
                        />
                      }
                      error={!!errors.password?.message}
                      helperText={errors.password?.message}
                    />
                  )}
                />
                <PasswordRequirementList visible={isRequerimentsVisible}>
                  <PasswordRequirement>Sua senha deve conter:</PasswordRequirement>
                  {renderPasswordRequeriments()}
                </PasswordRequirementList>

                <Controller
                  control={control}
                  name='confirmPassword'
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      value={value}
                      onChange={onChange}
                      type={isConfirmNewPassVisible ? 'text' : 'password'}
                      placeholder='Informe a senha'
                      maxLength={100}
                      autoComplete={false}
                      label='Confirme a nova senha'
                      startAdornment={<Icon icon={faLock} />}
                      endAdornment={
                        <Icon
                          icon={isConfirmNewPassVisible ? faEyeSlash : faEye}
                          onClick={() => {
                            setIsConfirmNewPassVisible(!isConfirmNewPassVisible)
                          }}
                          sx={{ cursor: 'pointer' }}
                        />
                      }
                      error={!!errors.confirmPassword?.message}
                      helperText={errors.confirmPassword?.message}
                    />
                  )}
                />
                <Button variant='contained' style={{ marginTop: '32px' }} type='submit'>
                  Salvar senha
                </Button>
              </form>
            </>
          )}
        </Content>
      </Body>
    </>
  )
}

export default PasswordDefinition
