import { SxProps } from '@mui/material'
import { StyledTabPanel } from './styles'

interface TabPanelProps {
  index: number
  value: number
  children?: React.ReactNode
  id?: string
  ariaLabel?: string
  sx?: SxProps
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <StyledTabPanel role='tabpanel' hidden={value !== index} {...other}>
      {value === index && children}
    </StyledTabPanel>
  )
}
