import { useSetAtom } from 'jotai'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@/presentation/components/atoms'
import { companyTab } from '@/presentation/pages/companies/consultCompany/consultCompany'

export const MissingDataDialog = ({ open, onClose }: { open: number | null; onClose: () => void }) => {
  const setValue = useSetAtom(companyTab)
  const parameterTab = 1

  const handleConfirm = () => {
    setValue(parameterTab)
  }

  const baseMessage = (
    <>
      Para aprovar essa empresa é necessário que ela tenha ao <br /> menos{' '}
    </>
  )
  const variableMessage = {
    1: (
      <>
        <strong>uma taxa configurada</strong> e <strong>um benefício associado.</strong>
      </>
    ),
    2: <strong>uma taxa configurada</strong>,
    3: <strong>um benefício associado.</strong>,
  }

  return (
    <Dialog open={open}>
      <DialogTitle
        id='alert-dialog-title'
        sx={(theme) => ({ margin: 'auto auto 32px auto', color: theme.palette.grey[700] })}
      >
        <Typography variant='h2'>Configuração necessária</Typography>
      </DialogTitle>
      <DialogContent sx={{ marginBottom: '32px' }}>
        <DialogContentText id='alert-dialog-description'>
          {baseMessage}
          {variableMessage[open]}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ gap: '16px', justifyContent: 'space-between' }}>
        <Button variant='outlined' onClick={onClose}>
          Cancelar
        </Button>
        <Button variant='contained' onClick={handleConfirm} autoFocus>
          Configurar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
