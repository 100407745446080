import { Box, ColorDot } from '@/presentation/components/atoms'
import { AutoTableProps, IconWithPopper } from '@/presentation/components/molecules'
import { EMPLOYEE_OPERATOR_INTEGRATION, EMPLOYEE_STATUS } from '@/main/utils/constants'
import { IEmployeesTableColumns } from '../companyEmployees'
import { BenefitsIcons } from '../../utils/benefitsIcons/benefitsIcons'
import { faCircleExclamation, faClockRotateLeft } from '@fortawesome/free-solid-svg-icons'

export const employeesTableConfiguration = ({ updateFilter, theme }) => {
  return [
    {
      columnDisplayName: 'Colaborador',
      columnValue: ({ row }) => row.fullName,
      onOrder: ({ order }) => updateFilter({ sort: 'fullName', desc: !order }),
      customRender: ({ row }) => {
        const isWaitingSync = row.integrationsStatus === EMPLOYEE_OPERATOR_INTEGRATION.RUNNING
        const color = isWaitingSync ? theme.palette.warning.main : theme.palette.error.main
        return (
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '16px' }}>
            {row.fullName}
            {row.integrationsStatus === EMPLOYEE_OPERATOR_INTEGRATION.OK ? null : (
              <IconWithPopper
                color={color}
                icon={isWaitingSync ? faClockRotateLeft : faCircleExclamation}
                sx={() => ({ color })}
              >
                {isWaitingSync ? 'Aguardando Sincronismo' : 'Erro de Sincronismo'}
              </IconWithPopper>
            )}
          </Box>
        )
      },
    },
    {
      columnDisplayName: 'Matrícula',
      columnValue: ({ row }) => row.registration,
    },
    {
      columnDisplayName: 'Departamento',
      columnValue: ({ row }) => row.departmentName,
      onOrder: ({ order }) => updateFilter({ sort: 'departmentName', desc: !order }),
    },
    {
      columnDisplayName: 'Benefício',
      align: 'center',
      columnValue: ({ row }) => row.benefits,
      customRender: ({ row }) => <BenefitsIcons benefits={row.benefits} />,
    },
    {
      columnDisplayName: 'Status',
      columnValue: ({ row }) => row.status,
      customRender: ({ row }) => {
        let dotColor = 'grey'
        if (row.status === EMPLOYEE_STATUS.ACTIVE) dotColor = 'success'
        return (
          <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center', textTransform: 'capitalize' }}>
            <ColorDot color={dotColor} status={EMPLOYEE_STATUS[row.status]} />
            {EMPLOYEE_STATUS[row.status]?.toLowerCase()}
          </Box>
        )
      },
    },
  ] as AutoTableProps<IEmployeesTableColumns>['columns']
}
