import { InputLabelProps as MUIInputLabelProps } from '@mui/material'
import { StyledInputLabel } from './styles'

export type LabelPosition = 'top' | 'bottom' | 'left' | 'right'
export interface InputLabelProps extends MUIInputLabelProps {
  label?: any
  position?: LabelPosition
}

export function InputLabel({ children, label, ...props }: InputLabelProps) {
  return (
    <StyledInputLabel {...props}>
      {label}
      {children}
    </StyledInputLabel>
  )
}
