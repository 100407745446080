import { Account } from './account'
import { Branding } from './branding'
import { Colors } from './colors'
import { Contacts } from './contacts'
import { StyledBox } from './style'

const emptyValue = 'Não informado.'
const defaultData = {
  contacts: {
    displayName: emptyValue,
    digitalAccountName: emptyValue,
    email: emptyValue,
    phone: emptyValue,
    linkedin: emptyValue,
    instagram: emptyValue,
    twitter: emptyValue,
  },
  images: {
    header: '',
    footer: '',
    login: '',
  },
  site: 'null',
}

export function PlatformPersonalization({ companyData = defaultData, configuration }) {
  return (
    <StyledBox>
      <Account
        onClick={configuration.dialogsSetters.account}
        informations={[
          { label: 'Nome da Empresa', value: companyData.contacts.displayName },
          {
            label: 'Nome da Conta digital',
            value: companyData.contacts.digitalAccountName,
          },
          {
            label: 'URL Station',
            value: `https://${companyData.site}.stationkim.com.br`,
          },
        ]}
      />
      <Colors onClick={configuration.dialogsSetters.colors} value={configuration.lastSelectedColors} />
      <Branding
        onClick={configuration.dialogsSetters.branding}
        images={[
          { label: 'Logotipo cabeçalho', src: companyData.images.header },
          { label: 'Logotipo rodapé', src: companyData.images.footer },
          { label: 'Logotipo tela de login', src: companyData.images.login },
        ]}
      />
      <Contacts
        onClick={configuration.dialogsSetters.contacts}
        contacts={[
          { label: 'E-mail', value: companyData.contacts.email },
          { label: 'Telefone', value: companyData.contacts.phone },
          { label: 'LinkedIN', value: companyData.contacts.linkedin },
          { label: 'Instagram', value: companyData.contacts.instagram },
          { label: 'Twitter', value: companyData.contacts.twitter },
        ]}
      />
    </StyledBox>
  )
}
