interface IFieldInfo {
  fieldName?: string
  maxLength?: number
  minLength?: number
}

export const requiredMessage = (params: IFieldInfo = {}) => `${params.fieldName ?? 'Esse'} é um campo obrigatório`
export const compositeNameMessage = (params: IFieldInfo = {}) => `${params.fieldName ?? 'Esse campo'} deve ser composto`
export const invalidMessage = (params: IFieldInfo = {}) =>
  `O valor informado para ${params.fieldName ?? 'esse campo'} é invalido`
export const minLengthMessage = ({ fieldName = 'Esse campo', minLength }: IFieldInfo) =>
  `${fieldName} deve ter no mínimo ${minLength} caracteres`
export const maxLengthMessage = ({ fieldName = 'Esse campo', maxLength }: IFieldInfo) =>
  `${fieldName} é limitado a ${maxLength} caracteres`
export const rangeMessage = ({ fieldName, initial, final }: { fieldName: string; initial: number; final: number }) =>
  `${fieldName} deve ter entre ${initial} e ${final} caracteres`
