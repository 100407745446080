import { Icon } from '@/presentation/components/atoms/Icon'
import { IconButton } from '@/presentation/components/atoms/iconButton'
import { Typography } from '@/presentation/components/atoms/typography'
import { StyledBox } from './styles'

export function TitleAndIconButton({ title, icon, onClick }) {
  return (
    <StyledBox>
      <Typography variant='h2'>{title}</Typography>
      <IconButton onClick={onClick}>
        <Icon icon={icon} />
      </IconButton>
    </StyledBox>
  )
}
