const ACTION = '@emittingBanks/'

export const ACTIONS = {
  GET_EMITTING_BANKS_REQUEST: `${ACTION}GET_EMITTING_BANKS_REQUEST`,
  EMITTING_BANKS_REQUEST_SUCCESS: `${ACTION}EMITTING_BANKS_REQUEST_SUCCESS`,
  EMITTING_BANKS_REQUEST_FAILURE: `${ACTION}EMITTING_BANKS_REQUEST_FAILURE`,
}

export function getEmittingBanksRequest() {
  return {
    type: ACTIONS.GET_EMITTING_BANKS_REQUEST,
  }
}

export function emittingBanksRequestSuccess(data) {
  return {
    type: ACTIONS.EMITTING_BANKS_REQUEST_SUCCESS,
    payload: { data },
  }
}

export function emittingBanksRequestFailure(data) {
  return {
    type: ACTIONS.EMITTING_BANKS_REQUEST_FAILURE,
    payload: { data },
  }
}
