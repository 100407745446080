import React from 'react'
import { Body, TextConfirm, SessionButton, Close } from './style'
import { Button, Icon } from '@/presentation/components'
import 'react-responsive-modal/styles.css'
import { Modal as ModalBox } from 'react-responsive-modal'

function Modal({
  cancel,
  cancelText,
  confirm,
  confirmText,
  children,
  message,
  open,
  onlyConfirm,
  close,
  closeOnConfirm = true,
}) {
  const handleConfirm = () => {
    confirm()
    closeOnConfirm && cancel()
  }

  return (
    <ModalBox center open={open} onClose={close || cancel} showCloseIcon={false} styles={{ borderRadius: '5px' }}>
      <Body>
        <Close onClick={close || cancel}>
          <Icon name='x' />
        </Close>
        {children ? <TextConfirm>{children}</TextConfirm> : <TextConfirm>{message}</TextConfirm>}
        <SessionButton>
          {onlyConfirm ? (
            <Button width='250px' onClick={confirm}>
              Ok
            </Button>
          ) : (
            <>
              <Button variant='outlined' width='200px' onClick={cancel}>
                {cancelText || 'Não'}
              </Button>
              <div style={{ paddingLeft: '15px' }} />
              <Button width='250px' onClick={handleConfirm}>
                {confirmText || 'Sim'}
              </Button>
            </>
          )}
        </SessionButton>
      </Body>
    </ModalBox>
  )
}

export default Modal
