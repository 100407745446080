import React from 'react'
import { Spinner } from '@/presentation/components'
import { CustomTable, TableContainer } from './style.js'

function Table({ children, loading, maxHeight = '522px', ...props }) {
  return (
    <TableContainer maxHeight={maxHeight}>
      {loading ? (
        <Spinner.Box style={{ height: maxHeight }}>
          <Spinner />
        </Spinner.Box>
      ) : (
        <CustomTable {...props}>{children}</CustomTable>
      )}
    </TableContainer>
  )
}

export default Table
