import React, { useEffect, useRef, useState } from 'react'

import { Content, Breadcrumb, Panel, Title, Spacing, DotMenu, Button, Spinner } from '@/presentation/components'

import {
  Category,
  Categories,
  Group,
  SubTitle,
  Table,
  Tbody,
  Tr,
  Td,
  ParameterValue,
  FlexLine,
  RightColumnWrapper,
  LeftColumnWrapper,
  ContentWrapper,
  NoResult,
  LoadingWrapper,
} from './style'

import _ from 'lodash'
import { pushNotification } from '@/store/modules/notification/actions'
import { NOTIFICATION_TYPE, PARAMETER_STATUS } from '@/main/utils/constants'
import { useDispatch } from 'react-redux'
import { ParameterModal } from './parameterModal'
import { InfoModal } from '@/presentation/compositions'
import { BsExclamationTriangle } from 'react-icons/bs'
import useService from '@/main/hooks/useService'
import successHandler from '@/main/utils/successHandler'
import {
  getBrazilianStatesForIssCreation,
  getIssParameters,
  patchChangeIssParametersStatus,
} from '@/services/parametersServices'

const PARAMETERS_CATEGORIES = ['Retenção ISS']

const GeneralParameters = () => {
  const dispatch = useDispatch()
  const [filterBy, setFilterBy] = useState('Retenção ISS')
  const [isParameterModalOpen, setIsParameterModalOpen] = useState(false)
  const [isInactivationConfirmOpen, setIsInactivationConfirmOpen] = useState(false)
  const isEditing = useRef(false)
  const parameterToEdit = useRef(null)

  const [fetchBrazilianStatesState, fetchBrazilianStatesRequest] = useService(getBrazilianStatesForIssCreation)
  const brazilianStates = fetchBrazilianStatesState.response?.data

  const [fetchIssParametersState, fetchIssParametersRequest] = useService(getIssParameters)
  const issParameters = fetchIssParametersState.response?.data.sort((a, b) =>
    a.cityName > b.cityName ? 1 : a.cityName < b.cityName ? -1 : 0,
  )

  const [changeIssParameterStatusState, changeIssParameterStatusRequest] = useService(patchChangeIssParametersStatus, {
    onCompleted: () => {
      dispatch(pushNotification(successHandler('Status do parâmetro alterado com sucesso!')))
      fetchIssParametersRequest()
    },
  })

  const hideCategory = (category) => filterBy !== category && filterBy !== null

  const editParameter = (param) => {
    const isParameterActive = param.issStatus === PARAMETER_STATUS.ACTIVE
    if (!isParameterActive) {
      dispatch(
        pushNotification({
          type: NOTIFICATION_TYPE.WARNING,
          content: 'Para editar um parâmetro é necessário que ele esteja ativo.',
        }),
      )
      return
    }
    parameterToEdit.current = {
      ...param,
      stateId: brazilianStates.find((state) => state.uf === param.stateUf).id,
    }
    isEditing.current = true
    setIsParameterModalOpen(true)
  }

  const changeParameterStatus = (param) => {
    if (param.issStatus === PARAMETER_STATUS.ACTIVE) {
      parameterToEdit.current = param
      setIsInactivationConfirmOpen(true)
    } else {
      changeIssParameterStatusRequest(param.cityId, PARAMETER_STATUS.ACTIVE)
    }
  }

  const inactiveParameter = () => {
    changeIssParameterStatusRequest(parameterToEdit.current.cityId, PARAMETER_STATUS.INACTIVE)
    setIsInactivationConfirmOpen(false)
  }

  useEffect(() => {
    fetchIssParametersRequest()
    fetchBrazilianStatesRequest()
    // eslint-disable-next-line
  }, [])
  return (
    <>
      <Content>
        <Title>Parâmetros Gerais</Title>
        <Spacing top='9px' bottom='9px'>
          <Breadcrumb />
        </Spacing>
        <Panel noPadding>
          <ContentWrapper>
            <LeftColumnWrapper>
              <SubTitle>Categorias</SubTitle>
              <Categories>
                {PARAMETERS_CATEGORIES.map((category, index) => (
                  <Category
                    key={`parameter_category_${index}`}
                    active={filterBy === category}
                    onClick={() => setFilterBy(category)}
                  >
                    {category}
                  </Category>
                ))}
              </Categories>
            </LeftColumnWrapper>
            <RightColumnWrapper>
              <FlexLine>
                <SubTitle>Parâmetros</SubTitle>
                <Button
                  variant='action'
                  width={226}
                  onClick={() => {
                    isEditing.current = false
                    setIsParameterModalOpen(true)
                  }}
                >
                  Novo Parâmetro
                </Button>
              </FlexLine>
              <Group hidden={hideCategory('Retenção ISS')}>
                {fetchIssParametersState.loading || changeIssParameterStatusState.loading ? (
                  <LoadingWrapper>
                    <Spinner />
                  </LoadingWrapper>
                ) : (
                  <Table>
                    <Tbody>
                      {issParameters?.length > 0 ? (
                        issParameters.map((param) => {
                          const isParamActive = param.issStatus === PARAMETER_STATUS.ACTIVE
                          return (
                            <Tr key={_.uniqueId()}>
                              <Td>{param.customName}</Td>
                              <Td>
                                <ParameterValue active={isParamActive}>
                                  {isParamActive ? Number(param.iss).toFixed(2) + '%' : 'INATIVO'}
                                </ParameterValue>
                              </Td>
                              <Td>
                                <DotMenu>
                                  <DotMenu.Option onClick={() => editParameter(param)}>Editar</DotMenu.Option>
                                  <DotMenu.Option onClick={() => changeParameterStatus(param)}>
                                    {isParamActive ? 'Inativar' : 'Ativar'}
                                  </DotMenu.Option>
                                </DotMenu>
                              </Td>
                            </Tr>
                          )
                        })
                      ) : (
                        <NoResult>Sem parâmetros configurados</NoResult>
                      )}
                    </Tbody>
                  </Table>
                )}
              </Group>
            </RightColumnWrapper>
          </ContentWrapper>
        </Panel>
      </Content>
      {isParameterModalOpen && (
        <ParameterModal
          open={isParameterModalOpen}
          onClose={(hasAddededOrEdited) => {
            setIsParameterModalOpen(false)
            parameterToEdit.current = null
            if (hasAddededOrEdited === true) fetchIssParametersRequest()
          }}
          isEditing={isEditing.current}
          states={brazilianStates}
          parameter={parameterToEdit.current}
        />
      )}
      {isInactivationConfirmOpen && (
        <InfoModal onClose={() => setIsInactivationConfirmOpen(false)} open={isInactivationConfirmOpen}>
          <InfoModal.IconContainer color='var(--color-status-02)'>
            <BsExclamationTriangle />
          </InfoModal.IconContainer>
          <InfoModal.Title color='var(--color-status-02)'>ATENÇÃO</InfoModal.Title>
          <InfoModal.Content>
            Você está inativando um ISS para esta cidade. Caso deseje utiliza-lo futuramente, será necessário
            reativa-lo.
          </InfoModal.Content>
          <InfoModal.ButtonsWrapper>
            <Button variant='outlined action' onClick={() => setIsInactivationConfirmOpen(false)}>
              Cancelar
            </Button>
            <Button variant='action' onClick={inactiveParameter}>
              Confirmar
            </Button>
          </InfoModal.ButtonsWrapper>
        </InfoModal>
      )}
    </>
  )
}

export default GeneralParameters
