import ActionIcon from '@/presentation/components/icon/actionIcon'
import React, { useState } from 'react'
import { CustomTable, IconContainer } from '../styles'
import AddTaxModal from './addTaxModal'
import { Tooltip } from '@/presentation/components/atoms/tooltip'
import { Icon } from '@/presentation/components/atoms/Icon'
import { faPenToSquare, faTrashCan } from '@fortawesome/free-regular-svg-icons'

const TaxesTable = ({ data, setData, setObjectArray, category, removeItem, disabled }) => {
  const [addTax, setAddTax] = useState(false)
  const [taxToEdit] = useState(null)
  const [itemEdit, setItemEdit] = useState(null)

  const generateTableHeader = () => {
    return (
      <CustomTable.Tr>
        <CustomTable.Th>Produto</CustomTable.Th>
        <CustomTable.Th>Cálculo por</CustomTable.Th>
        <CustomTable.Th>Range</CustomTable.Th>
        <CustomTable.Th>Taxa Administrativa</CustomTable.Th>
        <CustomTable.Th>Ações</CustomTable.Th>
      </CustomTable.Tr>
    )
  }

  const clickEdit = (item) => {
    setItemEdit(item)
    setAddTax(true)
  }

  const returnType = (tipo) => {
    if (tipo === 1) {
      return 'Vale Transporte Eletrônico'
    } else if (tipo === 2) {
      return 'Vale Alimentação'
    } else if (tipo === 3) {
      return 'Vale Refeição'
    } else if (tipo === 4) {
      return 'Vale Combustivel'
    } else if (tipo === 5) {
      return 'Vale Transporte Papel'
    } else {
      return null
    }
  }

  const returnCalType = (tipo) => {
    if (tipo === 1) return 'Por pedido'
    if (tipo === 2) return 'Por colaborador'
  }

  const returnRow = (item, index) => {
    if (item.itemType === 1) {
      return (
        <tr>
          <th
            style={{
              textAlign: 'left',
              fontWeight: 'initial',
              color: '#000',
            }}
          >
            {returnType(item.benefitType)}
          </th>
          <th
            style={{
              textAlign: 'left',
              fontWeight: 'initial',
              color: '#000',
            }}
          >
            {returnCalType(item.calculationType)}
          </th>
          <th
            style={{
              textAlign: 'left',
              fontWeight: 'initial',
              color: '#000',
            }}
          >
            {item.startRange + ' - ' + item.endRange}
          </th>
          <th
            style={{
              textAlign: 'right',
              fontWeight: 'initial',
              color: '#000',
            }}
          >
            {item.value + '%'}
          </th>
          <th>
            <IconContainer>
              {!disabled && (
                <>
                  <Tooltip title='Excluir'>
                    <Icon icon={faTrashCan} onClick={() => removeItem(item, index)} sx={{ cursor: 'pointer' }} />
                  </Tooltip>
                  <Tooltip title='Editar'>
                    <Icon
                      icon={faPenToSquare}
                      onClick={() => clickEdit(item)}
                      sx={{ cursor: 'pointer', marginLeft: '8px' }}
                    />
                  </Tooltip>
                </>
              )}
            </IconContainer>
          </th>
        </tr>
      )
    }
  }

  return (
    <CustomTable>
      <thead>{generateTableHeader()}</thead>
      <tbody>{data?.fees?.map((info, i) => returnRow(info, i))}</tbody>

      {(addTax || taxToEdit) && (
        <AddTaxModal
          open={addTax || taxToEdit}
          cancel={() => {
            setAddTax(false)
          }}
          dataInfo={data}
          setData={setData}
          info={itemEdit}
          setObjectArray={setObjectArray}
          category={category}
          close={() => setAddTax(false)}
        />
      )}
    </CustomTable>
  )
}

export default TaxesTable
