import api from '@/infra/api'
import { useQuery } from '@tanstack/react-query'
import { useAtom } from 'jotai'
import { companyIdAtom } from '../store/url/shared'

interface IUseCompanyCardsFilter {
  config?: object
}

export const useCompanyCardsFilter = (params: IUseCompanyCardsFilter = {}) => {
  const { config } = params
  const [companyId] = useAtom(companyIdAtom)
  const { data, ...rest } = useQuery({
    queryKey: ['companyCardsFilter', companyId],
    queryFn: ({ queryKey }) => api.get(`/resale-bff/companies/${queryKey[1]}/cards/filter`),
    ...config,
  })

  return {
    cardsFilterOptions: data?.data || [],
    ...rest,
  }
}
