import { useLocation, useNavigate } from 'react-router-dom'
import SubTitle from '@/presentation/components/title/subTitle'
import {
  Atention,
  ButtonWrapper,
  CommonText,
  Container,
  InfoRow,
  HighlightedText,
  Wrapper,
  DownloadLink,
} from './styles'
import { Button, Icon, Panel } from '@/presentation/components'
import { minTwoDigits } from '@/main/utils/functionUtils'

const ImportResult = () => {
  const navigate = useNavigate()
  const { state } = useLocation()
  const { response } = state

  return (
    <Panel>
      <SubTitle style={{ margin: '32px 0px 0 32px', textAlign: 'center' }}>Resultado da Importação</SubTitle>
      <Container>
        <InfoRow>
          <span>
            <HighlightedText style={{ color: 'var(--color-primary-01)' }}>
              {minTwoDigits(response?.nCardsInOrder)}
            </HighlightedText>
            <CommonText>Colaboradores no pedido</CommonText>
          </span>
          <span>
            <HighlightedText style={{ color: 'var(--color-secundary-01)' }}>
              {minTwoDigits(response?.errorsCount)}
            </HighlightedText>
            <CommonText>Erros na importação</CommonText>
          </span>
        </InfoRow>
        <div style={{ textAlign: 'center' }}>
          <Atention>Atenção!</Atention>
          <CommonText>Os arquivos contendo erro não serão importados.</CommonText>
          <CommonText>Verifique os erros apontados no relatório de erros e importe novamente.</CommonText>
          {response?.base64 && (
            <DownloadLink>
              <a download='erros_importacao.txt' href={`data:text/txt;base64,${response?.base64}`}>
                Ver relatório de erros <Icon name='external-link' />
              </a>
            </DownloadLink>
          )}
        </div>
        <Wrapper>
          <ButtonWrapper>
            <Button variant='contained' onClick={() => navigate('/pedidos')}>
              Voltar
            </Button>
          </ButtonWrapper>
        </Wrapper>
      </Container>
    </Panel>
  )
}

export default ImportResult
