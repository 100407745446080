import api from '@/infra/api'
import { useQuery } from '@tanstack/react-query'
import { useAtom } from 'jotai'
import { companyIdAtom } from '../store/url/shared'

export const useCompanyEmployeesFilter = () => {
  const [companyId] = useAtom(companyIdAtom)
  const { data, ...rest } = useQuery({
    queryKey: ['companyEmployeesFilter', companyId],
    queryFn: ({ queryKey }) => api.get(`/resale-bff/companies/${queryKey[1]}/filter`),
  })

  return {
    employeesFilterOptions: data?.data || [],
    ...rest,
  }
}
