import styled from 'styled-components'
import { Container } from 'react-grid-system'

export const ModalTitle = styled.div`
  font-size: 1.25rem;
  font-weight: var(--font-weight-bold);
`

export const CustomContainer = styled(Container)`
  padding-left: 0 !important;
  padding-right: 0 !important;
  > div + div {
    margin-top: 16px;
  }
`

export const InputWrapper = styled.div`
  min-width: 250px;
`
