import { Grid, Skeleton } from '@/presentation/components/atoms'

export const FormSkeleton = () => {
  return (
    <Grid container spacing='16px'>
      {Array(7)
        .fill('')
        .map((_, index) => (
          <Grid item key={index} xs={12} sm={[0, 4].includes(index) ? 6 : 3} md={[0, 3].includes(index) ? 8 : 4}>
            <Skeleton sx={{ height: '48px' }} />
          </Grid>
        ))}
    </Grid>
  )
}
