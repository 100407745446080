import { combineReducers } from 'redux'

import user from './user/reducer'
import companys from './company/reducer'
import emittingBanks from './emittingBanks/reducer'
import infoCampany from './infoCompany/reducer'
import orders from './pedidos/reducer'
import notifications from './notification/reducer'
import cardOrder from './orders/cards/one/reducer'
import cardsOrders from './orders/cards/all/reducer'
import recharge from './recharge/reducer'
import users from './users/reducer'

export default combineReducers({
  user,
  companys,
  emittingBanks,
  infoCampany,
  orders,
  notifications,
  cardOrder,
  cardsOrders,
  recharge,
  users,
})
