import { ReactElement, ReactNode, useState } from 'react'
import { Box, Popper } from '@/presentation/components/atoms'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { PopperPlacementType, SxProps } from '@mui/material'
import { StyledIcon } from './styles'

export interface IconWithPopperProps {
  color: string
  icon: IconProp
  placement?: PopperPlacementType
  children?: ReactElement | ReactNode
  sx?: SxProps
}
export const IconWithPopper = ({ color, icon, ...props }: IconWithPopperProps) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleMouseEnter = (e: MouseEvent) => {
    setAnchorEl(e.currentTarget)
  }

  const handleMouseLeave = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <Box onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <StyledIcon icon={icon} sx={{ color, cursor: props.children ? 'pointer' : 'initial' }} />
      {!props.children ? null : (
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          popperOptions={{
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 8],
                },
              },
            ],
          }}
          {...props}
        >
          {props.children}
        </Popper>
      )}
    </Box>
  )
}
