import { AxiosResponse } from 'axios'
import { useAtomValue } from 'jotai'
import { decode } from 'jsonwebtoken'
import api from '@/infra/api'
import { AccountPersistentStore } from '@/main/store'
import { useQuery } from '@tanstack/react-query'

type IApiReturn = {
  mensagens: string[]
  sucesso: boolean
  valor: Array<{
    corporateName: string
    id: string
    tradeName: string
  }>
}

export interface IUseOperatorsReturn {
  operators: IApiReturn['valor']
}

export const useOperators = (): IUseOperatorsReturn => {
  const account = useAtomValue(AccountPersistentStore)
  const { resaleId } = decode(account?.auth?.accessToken)
  const { data, ...rest } = useQuery<AxiosResponse<IApiReturn>>({
    queryKey: ['newOrdersOperators', resaleId],
    queryFn: () => api.get(`/revenda-pedidos-bff/operadora?IdRevenda=${resaleId}`),
    refetchOnWindowFocus: false,
  })

  return {
    operators: data?.data?.valor || [],
    ...rest,
  }
}
