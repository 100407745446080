import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { pushNotification } from '@/store/modules/notification/actions'
import errorHandler from '@/main/utils/errorHandler'

const initialState = {
  error: null,
  loading: false,
  response: null,
}

const defaultConfig = {
  silent: false,
}

const useService = (service: any, config: any = defaultConfig) => {
  const dispatch = useDispatch()
  const [state, setState] = useState(initialState)

  const request = async (...args) => {
    setState({ ...initialState, loading: true })
    const { error, response } = await service(...args)
    if (response) {
      setState({ ...initialState, response })
      if (config.onCompleted) config.onCompleted(response)
    } else {
      setState({ ...initialState, error })
      if (!config.silent) dispatch(pushNotification(errorHandler(error.response)))
      if (config.onError) config.onError(error)
    }
    setState((state) => ({ ...state, loading: false }))
  }

  return [state, request] as Array<any>
}

export default useService
