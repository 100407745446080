import { EStatusCompra, EStatusPedido } from '@/main/enums'
import { ORDER_STATUS, PURCHASE_STATUS } from '@/main/utils/enumsMapping'

export const optionFilter = {
  0: { status: '' },
  1: { status: EStatusPedido.NoCarrinho },
  2: { status: EStatusPedido.Efetivando },
  3: {
    statusCompra: EStatusCompra.ProcessandoFormaPagamento,
    status: EStatusPedido.Efetivado,
  },
  4: {
    statusCompra: EStatusCompra.AguardandoPagamento,
    status: EStatusPedido.Efetivado,
  },
  5: { status: EStatusPedido.AguardandoProcessamento },
  6: { status: EStatusPedido.EmProcessamento },
  7: { status: EStatusPedido.EmAndamento },
  8: { status: EStatusPedido.DisponivelRetirada },
  9: { status: EStatusPedido.Entrega },
  10: { status: EStatusPedido.Finalizado },
  11: { status: EStatusPedido.Cancelando },
  12: { status: EStatusPedido.Cancelado },
}

export const statusOptions = [
  {
    label: 'Todos',
    value: 0,
  },
  {
    label: PURCHASE_STATUS[EStatusCompra.AguardandoPagamento],
    value: 4,
  },
  {
    label: ORDER_STATUS[EStatusPedido.AguardandoProcessamento],
    value: 5,
  },
  {
    label: ORDER_STATUS[EStatusPedido.Cancelado],
    value: 12,
  },
  {
    label: ORDER_STATUS[EStatusPedido.Cancelando],
    value: 11,
  },
  {
    label: ORDER_STATUS[EStatusPedido.Finalizado],
    value: 10,
  },
  {
    label: ORDER_STATUS[EStatusPedido.DisponivelRetirada],
    value: 8,
  },
  {
    label: ORDER_STATUS[EStatusPedido.Efetivando],
    value: 2,
  },
  {
    label: ORDER_STATUS[EStatusPedido.Entrega],
    value: 9,
  },
  {
    label: ORDER_STATUS[EStatusPedido.EmProcessamento],
    value: 6,
  },
  {
    label: ORDER_STATUS[EStatusPedido.EmAndamento],
    value: 7,
  },
  {
    label: ORDER_STATUS[EStatusPedido.Efetivado],
    value: 3,
  },
  {
    label: ORDER_STATUS[EStatusPedido.NoCarrinho],
    value: 1,
  },
]
