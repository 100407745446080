import { useMutation, useQueryClient } from '@tanstack/react-query'
import api from '@/infra/api'
import { useNotifications } from '@/main/hooks'
import { EMessageType } from '@/main/store'

interface IPayload {
  id: string
  companyId: string
  operatorId: string
  operatorCardId: string
  username: string
  password: string
  balanceProjectionEnabled: boolean
  status: number
  clientCode: string
}

export const useManipulateCompanyBenefit = () => {
  const notifications = useNotifications()
  const queryClient = useQueryClient()
  const editBenefit = useMutation({
    mutationFn: (payload: IPayload) => api.patch(`/revenda-pedidos-bff/beneficios`, payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['companyBenefits'] })
      notifications.push({ content: 'Beneficio alterado com sucesso!', type: EMessageType.Success })
    },
  })

  return {
    editBenefit,
  }
}
