import { forwardRef } from 'react'
import { SelectProps as MUISelectProps } from '@mui/material'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { StyledSelect } from './styles'
import { InputLabel, MenuItem, Icon, FieldWrapper, InputLabelProps } from '@/presentation/components/atoms'
import { IFieldStatus } from '@/main/interfaces'

export interface SelectProps extends MUISelectProps, IFieldStatus {
  options: Array<any>
  getOptionLabel?: (option: any) => any
  getOptionValue?: (option: any) => any
  helperText?: string
  labelProps?: InputLabelProps
}

export const Select = forwardRef<HTMLElement, SelectProps>(
  (
    {
      label,
      labelProps,
      id,
      children,
      placeholder = 'Selecione',
      options = [],
      getOptionLabel = (option) => option.label,
      getOptionValue = (option) => option.value,
      fullWidth = true,
      error,
      success,
      helperText,
      blocked,
      disabled,
      ...props
    },
    ref,
  ) => {
    const renderOptions = () => {
      if (options.length !== 0)
        return options.map((option) => (
          <MenuItem key={getOptionValue(option)} value={getOptionValue(option)} disabled={option.disabled}>
            {getOptionLabel(option)}
          </MenuItem>
        ))
      return children
    }

    return (
      <FieldWrapper error={error} success={success} helperText={helperText}>
        <InputLabel htmlFor={id} label={label} {...labelProps}>
          <StyledSelect
            id={id}
            displayEmpty
            sx={{ width: fullWidth ? '100%' : 'initial' }}
            blocked={blocked}
            disabled={disabled || blocked}
            inputRef={ref}
            IconComponent={(iconProps) => (
              <Icon
                sx={{
                  right: '16px !important',
                  cursor: 'pointer',
                }}
                icon={faChevronDown}
                {...iconProps}
              />
            )}
            {...props}
          >
            <MenuItem value='' disabled sx={{ display: 'none' }}>
              {placeholder}
            </MenuItem>
            {renderOptions()}
          </StyledSelect>
        </InputLabel>
      </FieldWrapper>
    )
  },
)
