import React from 'react'
import { ReportContainer, ReportLink } from './styles'

export function Report({ children, generateReport }) {
  return (
    <ReportContainer>
      {children}
      {generateReport && <ReportLink onClick={generateReport}>Gerar Relatório</ReportLink>}
    </ReportContainer>
  )
}

export default Report
