import { ReactElement } from 'react'
import { Dialog, DialogContent, DialogTitle, Typography } from '@/presentation/components/atoms'
import { Form } from '@/presentation/components/organisms'

interface FilterDialogProps {
  title?: string
  subtitle?: string
  children?: ReactElement | Array<ReactElement>
  form: any
  open: boolean
  onClose?: () => void
  onSubmit: (data: any) => void
  onClear: () => void
}

export const FilterDialog = ({
  open,
  children,
  form,
  title = 'Filtrar',
  subtitle = 'Complete com as informações dos filtros que deseja aplicar.',
  onClose,
  onSubmit,
  onClear,
}: FilterDialogProps) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={'xs'}
      fullWidth
      sx={{
        '& .MuiDialog-container': {
          justifyContent: 'flex-end',
        },
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <Typography sx={{ marginTop: '16px' }}>{subtitle}</Typography>
      <DialogContent sx={{ marginTop: '32px' }}>
        <Form
          form={form}
          isLoading={false}
          isEditable={true}
          cancelText='Limpar'
          submitText='Filtrar'
          onSubmit={onSubmit}
          onCancel={onClear}
          buttonFullWidth
        >
          {children}
        </Form>
      </DialogContent>
    </Dialog>
  )
}
