import api from '@/infra/api'
import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'

export const useCompanyActivityBranches = () => {
  const { data, refetch, ...rest } = useQuery({
    queryKey: ['companyBranches'],
    queryFn: () => api.get(`resale-bff/register/branches`),
    enabled: false,
  })

  useEffect(() => {
    refetch()
  }, [refetch])

  return {
    branches: data?.data || [],
    ...rest,
  }
}
