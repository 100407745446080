import { useState } from 'react'
import Moment from 'react-moment'
import { useNavigate } from 'react-router-dom'
import { useAtom } from 'jotai'
import { atomWithHash } from 'jotai-location'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import Filter from './filter'
import { Button, Grid, Icon } from '@/presentation/components/atoms'
import { AutoTable, PageHeader, Panel, Search, TableFooterPagination } from '@/presentation/components/molecules'
import { companyIdAtom } from '@/main/store/url/shared'
import { COMPANY_STATUS } from '@/main/utils/constants'
import { useCompanies, useFilter, usePagination } from '@/main/hooks'
import { applyMask } from '@/main/utils/functions'

const filterAtom = atomWithHash<object>('companiesFilter', { sort: 'code', desc: true })
const paginationAtom = atomWithHash<object>('companiesPagination', null)
export const ListCompanies = () => {
  const navigate = useNavigate()
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const { filter, resetFilter, updateFilter } = useFilter({ filterAtom })
  const { pagination, goToPage, setPageSize } = usePagination({ paginationAtom })
  const { companies, isLoading } = useCompanies({ filter, pagination })
  const companiesData = companies?.data
  const [, setCompanyId] = useAtom(companyIdAtom)

  const columnsConfiguration = [
    {
      columnDisplayName: 'Cod. Cliente',
      columnValue: ({ row }) => row.code,
    },
    {
      columnDisplayName: 'Empresa',
      columnValue: ({ row }) => row.tradeName,
      onOrder: ({ order }) => updateFilter({ sort: 'tradeName', desc: !order }),
    },
    {
      columnDisplayName: 'CNPJ',
      columnValue: ({ row }) => applyMask({ mask: '##.###.###/####-##', text: row.cnpj }),
    },
    {
      columnDisplayName: 'Data Cadastro',
      columnValue: ({ row }) => row.creationDate,
      customRender: ({ row }) => <Moment format='DD/MM/YYYY'>{row.creationDate}</Moment>,
      onOrder: ({ order }) => updateFilter({ sort: 'creationDate', desc: !order }),
    },
    {
      columnDisplayName: 'Status',
      columnValue: ({ row }) => COMPANY_STATUS[row.status],
    },
  ]

  return (
    <Panel>
      <PageHeader pageTitle='Empresas' />
      <Grid container spacing='16px' sx={{ padding: '16px 32px 32px 32px' }}>
        <Grid item xs={12} md={5}>
          <Search
            onSearch={(value: string) => {
              goToPage(1)
              updateFilter({
                filterValue: String(value).replaceAll(/[./-]/g, ''),
                filterProps: 'code,cnpj,tradeName',
              })
            }}
            placeholder='Buscar na tabela abaixo ...'
          />
        </Grid>
        <Grid item xs={12} sm={6} md='auto'>
          <Button
            variant='outlined'
            startIcon={<Icon icon={faFilter} />}
            fullWidth
            onClick={() => setIsFilterOpen(true)}
          >
            Filtrar
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md='auto' sx={{ marginLeft: 'auto' }}>
          <Button variant='contained' fullWidth onClick={() => navigate('/empresas/taxas_balcao')}>
            Taxa Balcão
          </Button>
        </Grid>
        <Grid item xs={12}>
          <AutoTable<{
            id: string
            code: string
            tradeName: string
            cnpj: string
            creationDate: string
            status: number | string
          }>
            onRowClick={({ row }) => {
              setCompanyId(row.id)
            }}
            link={({ row }) => `/empresas/consulta_de_empresa#companyId="${row.id}"`}
            isLoading={isLoading}
            rows={companiesData}
            columns={columnsConfiguration}
          />
        </Grid>
      </Grid>
      <TableFooterPagination
        totalItems={companies.totalItens}
        onRowsPerPageChange={(e) => setPageSize(e.target.value)}
        rowsPerPage={companies.pageSize}
        totalPages={companies.totalPages}
        page={companies.pageNumber}
        onPageChange={(e, value) => goToPage(value)}
      />
      {isFilterOpen && (
        <Filter
          actualFilters={filter}
          open={isFilterOpen}
          onClose={() => setIsFilterOpen(false)}
          resetFilters={() => {
            resetFilter()
            setIsFilterOpen(false)
          }}
          filter={(queryObj) => {
            updateFilter(queryObj)
            setIsFilterOpen(false)
          }}
        />
      )}
    </Panel>
  )
}
