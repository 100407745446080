import { FunctionComponent } from 'react'
import { useTheme } from '@mui/material'
import { Text } from 'recharts'

export const CustomizedAxisTick: FunctionComponent<any> = (props: any) => {
  const { x, y, payload } = props
  const theme = useTheme()
  const isActual = payload.value.includes('(Atual)')
  return (
    <g transform={`translate(${x},${y})`}>
      <Text
        fontSize={12}
        width={10}
        textAnchor='middle'
        verticalAnchor='start'
        fill={isActual ? theme.palette.primary.main : theme.palette.grey[700]}
        fontWeight={isActual ? 500 : 400}
      >
        {payload.value}
      </Text>
    </g>
  )
}
