import { atomWithStorage } from 'jotai/utils'

interface MigrationErrorRecord {
  [companyId: string]: {
    migrationId: string
  }
}

export const CompanyMigrationErrorPersistentStore = atomWithStorage<MigrationErrorRecord>(
  'migrationErrorRecords',
  JSON.parse(localStorage.getItem('migrationErrorRecords')) ?? {},
)
