import { Menu, styled } from '@mui/material'

export const StyledMenu = styled(Menu)(({ theme }) => ({
  marginTop: 20,
  '& .MuiList-root': {
    padding: 0,
  },
  '& .MuiPaper-root': {
    width: 360,
  },
  '& svg': {
    height: 24,
    width: 24,
  },
}))
