import api from '@/infra/api'
import { UseQueryOptions, useQuery } from '@tanstack/react-query'

interface IUseAddressesCodes {
  config?: UseQueryOptions<any>
}

export const useAddressesCodes = (
  params: IUseAddressesCodes = {
    config: {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  },
) => {
  const { data: addressCodes, isLoading: isLoadingAddress } = useQuery({
    queryKey: ['addressesCodes'],
    queryFn: () => api.get(`resale-bff/address/address-types`),
    ...params.config,
  })

  const { data: laneCodes, isLoading: isLoadingLaneTypes } = useQuery({
    queryKey: ['lanesCodes'],
    queryFn: () => api.get(`resale-bff/address/lane-types`),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })

  return {
    addressTypes: addressCodes?.data?.data || [],
    laneTypes: laneCodes?.data?.data || [],
    isLoading: isLoadingAddress || isLoadingLaneTypes,
  }
}
