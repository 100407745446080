import api from '@/infra/api'
import { EStatusCompra, EStatusPedido } from '@/main/enums'
import { IFetchHook } from '@/main/interfaces'
import { IPaginatedRequest } from '@/main/interfaces/iPaginatedRequest'
import { AccountPersistentStore } from '@/main/store'
import { useQuery } from '@tanstack/react-query'
import { useAtomValue } from 'jotai'
import { decode } from 'jsonwebtoken'
import qs from 'qs'

export interface IUseOrdersReturn {
  orders: IPaginatedRequest<{
    id: string
    code: number
    orderDate: string
    operatorName: string
    status: EStatusPedido
    purchaseStatus: EStatusCompra
    orderType: number
    totalValue: number
    paymentTypes: number[]
    companyName: string
    companyId: string
  }>
  isLoading: boolean
}

interface IApiResponse {
  mensagens: string[]
  sucesso: boolean
  valor: {
    pageCount: number
    pageNumber: number
    pageSize: number
    totalItemCount: number
    pedidos: Array<{
      id: string
      numeroPedido: number
      dataCriacao: string
      operadora: string
      valor: number
      status: number
      statusCompra: number
      tipoPedido: number
      tiposPagamento: number[]
      nomeEmpresa: string
      guidEmpresa: string
    }>
  }
}

const defaultValue: IUseOrdersReturn['orders'] = {
  data: [],
  page: 1,
  pageSize: 10,
  totalItems: 0,
  totalPages: 1,
}

export const useOrders = ({ filter, pagination, immutableQuery }: IFetchHook): IUseOrdersReturn => {
  const account = useAtomValue(AccountPersistentStore)
  const accessToken = account.auth.accessToken
  const resaleId = decode(accessToken).resaleId
  const { data, ...rest } = useQuery({
    queryKey: [`newOrders`, resaleId, filter, pagination, immutableQuery],
    queryFn: () =>
      api.get<IApiResponse>(`revenda-pedidos-bff/pedido?idRevenda=${resaleId}`, {
        params: { ...pagination, ...filter, ...immutableQuery },
        paramsSerializer: (params) => {
          const newParams = { ...params }
          for (const key in newParams) {
            if ((!newParams[key] || newParams[key]?.length === 0) && newParams[key] !== 0) delete newParams[key]
          }
          return qs.stringify(newParams)
        },
      }),
    refetchOnWindowFocus: false,
  })

  const organizeData = (): IUseOrdersReturn['orders'] => {
    const rawData = data?.data
    if (!rawData) return defaultValue
    return {
      data: rawData.valor.pedidos.map((order) => {
        return {
          id: order.id,
          code: order.numeroPedido,
          companyName: order.nomeEmpresa,
          paymentTypes: order.tiposPagamento,
          orderDate: order.dataCriacao,
          operatorName: order.operadora,
          status: order.status,
          purchaseStatus: order.statusCompra,
          orderType: order.tipoPedido,
          totalValue: order.valor,
          companyId: order.guidEmpresa,
        }
      }),
      page: rawData.valor.pageNumber,
      pageSize: rawData.valor.pageSize,
      totalItems: rawData.valor.totalItemCount,
      totalPages: rawData.valor.pageCount,
    }
  }

  return {
    orders: organizeData(),
    ...rest,
  }
}
