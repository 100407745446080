import { useCallback, useState } from 'react'
import { Accept, useDropzone } from 'react-dropzone'
import { faArrowRightToBracket, faFileArrowUp, faPencil } from '@fortawesome/free-solid-svg-icons'
import { Box, Button, Icon, Typography } from '@/presentation/components/atoms'
import { DropZone, DropZoneIconWrapper, FileControl, StyledFileInfo } from './style'
import { LinearProgress } from '@mui/material'
import { bytesToHumanFileSize } from '@/main/utils/functions'

const convertAcceptToFormatsLabel = (accept: Accept) => {
  const extensions = []
  for (const format in accept) {
    accept[format].forEach((extension: string) => extensions.push(extension.toLocaleUpperCase().replace('.', '')))
  }
  return extensions.join(', ')
}

interface IFileUpload {
  accept?: Accept
  maxSize?: number
  label?: string
  initialValue?: File
  isEditable?: boolean
  fileUrl?: string
}

const FileInfo = ({ name, info, isEditing }: { name: string; info: number | string; isEditing?: boolean }) => {
  return (
    <StyledFileInfo sx={{ border: isEditing && 'none' }}>
      <DropZoneIconWrapper>
        <Icon icon={faFileArrowUp} />
      </DropZoneIconWrapper>
      <Box>
        <Typography>{name}</Typography>
        <Typography>{info}</Typography>
      </Box>
    </StyledFileInfo>
  )
}

const FileUploadOnEdit = ({
  getRootProps,
  getInputProps,
  isDragReject,
  accept,
  maxSize,
  handleCancelEditClick,
  file,
  label,
}) => {
  return (
    <>
      <DropZone {...getRootProps()} isRejected={isDragReject}>
        <input {...getInputProps()} />
        <DropZoneIconWrapper>
          <Icon icon={faFileArrowUp} />
        </DropZoneIconWrapper>
        {isDragReject ? (
          <Typography>Arquivo não suportado, utilize um dos formatos abaixo</Typography>
        ) : (
          <Typography>
            <span>Clique para enviar</span> ou arraste aqui
          </Typography>
        )}
        <Typography variant='body2' color='grey.600'>
          {convertAcceptToFormatsLabel(accept)}
          {maxSize !== Infinity && ` (max. ${bytesToHumanFileSize(maxSize)})`}
        </Typography>
      </DropZone>
      <FileControl sx={{ border: 'none' }}>
        <FileInfo
          name={label ?? file?.name}
          info={`${!label ? '' : file?.name} ${bytesToHumanFileSize(file?.size)}`}
          isEditing={true}
        />
        <LinearProgress variant='determinate' value={20} sx={{ maxWidth: '90%', margin: '0 72px' }} />
      </FileControl>
      <Box sx={{ display: 'flex', gap: '16px', justifyContent: 'flex-end', marginTop: '32px' }}>
        <Button variant='outlined' onClick={handleCancelEditClick}>
          Cancelar
        </Button>
        <Button variant='contained'>Salvar alterações</Button>
      </Box>
    </>
  )
}

export const FileUpload = (params?: IFileUpload) => {
  const { accept, maxSize = Infinity, label, initialValue, isEditable, fileUrl } = params

  const [file, setFile] = useState<File>(initialValue ?? null)
  const [isEditing, setIsEditing] = useState(false)

  const onDrop = useCallback((acceptedFiles: Array<File>) => {
    setFile(acceptedFiles[0])
  }, [])

  const { acceptedFiles, getRootProps, getInputProps, isDragReject } = useDropzone({
    onDrop,
    accept,
    maxFiles: 1,
    maxSize,
  })

  const handleEditClick = () => {
    setIsEditing(true)
  }

  const handleCancelEditClick = () => {
    setIsEditing(false)
    setFile(initialValue)
    acceptedFiles.splice(0, 1)
  }

  return (
    <>
      {isEditing ? (
        <FileUploadOnEdit
          getRootProps={getRootProps}
          getInputProps={getInputProps}
          isDragReject={isDragReject}
          accept={accept}
          maxSize={maxSize}
          handleCancelEditClick={handleCancelEditClick}
          file={acceptedFiles[0] ?? file}
          label={label}
        />
      ) : (
        <FileControl>
          <FileInfo
            name={label ?? file?.name}
            info={`${!label ? '' : file?.name} ${bytesToHumanFileSize(file?.size)}`}
          />
          <Box sx={{ padding: '0 16px', display: 'flex', gap: '64px' }}>
            {fileUrl && (
              <a href={fileUrl} target='_blank' rel='noreferrer'>
                <Button
                  disabled={!file}
                  variant='softText'
                  endIcon={<Icon icon={faArrowRightToBracket} sx={{ transform: 'rotate(90deg)' }} />}
                >
                  Baixar
                </Button>
              </a>
            )}
            {isEditable && (
              <Button variant='softText' endIcon={<Icon icon={faPencil} />} onClick={handleEditClick}>
                Editar
              </Button>
            )}
          </Box>
        </FileControl>
      )}
    </>
  )
}
