import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import api from '@/infra/api'
import { EBenefitType, EProcessType } from '@/main/enums'
import { IStandardResponse } from '@/main/interfaces'

export enum EStatusVinculo {
  Ativo = 1,
  Inativo = 2,
}
export interface IBenefit {
  operator: string
  type: EProcessType
  clientCode: string
  allowProjection: boolean
  active: boolean
  login: string
  password: string
  site: string
  id: string
  operatorId: string
  operatorCardId: string
  status: boolean
  cardName: string
}

interface IUseCompanyBenefitsReturn {
  benefits: IBenefit[]
  isLoading: boolean
}

interface ApiBenefit {
  id: string
  operatorId: string
  operatorCardId: string
  operatorCardCode: number
  operatorName: string
  operatorTradeName: string
  operatorCardName: string
  site: string
  username: string
  password: string
  status: EStatusVinculo
  active: boolean
  processType: EProcessType
  benefitType: EBenefitType
  balanceProjectionEnabled: boolean
  clientCode: string
}

export const useCompanyBenefits = ({ companyId }: { companyId: string }): IUseCompanyBenefitsReturn => {
  const { data, isLoading } = useQuery<AxiosResponse<IStandardResponse<ApiBenefit[]>>>({
    queryKey: ['companyBenefits', companyId],
    queryFn: () => api.get(`/revenda-pedidos-bff/beneficios?idEmpresa=${companyId}`),
    enabled: Boolean(companyId),
  })

  const organizedData = useMemo((): IUseCompanyBenefitsReturn['benefits'] => {
    const rawData = data?.data?.valor
    if (!rawData) return []
    const organizedBenefits: IBenefit[] = rawData.map((apiBenefit) => ({
      active: apiBenefit.active,
      allowProjection: apiBenefit.balanceProjectionEnabled,
      clientCode: apiBenefit.clientCode,
      id: apiBenefit.id,
      login: apiBenefit.username ?? '',
      password: apiBenefit.password ?? '',
      operator: apiBenefit.operatorTradeName,
      cardName: apiBenefit.operatorCardName,
      site: apiBenefit.site,
      type: apiBenefit.processType,
      operatorCardId: apiBenefit.operatorCardId,
      operatorId: apiBenefit.operatorId,
      status: apiBenefit.status === EStatusVinculo.Ativo,
    }))
    return organizedBenefits
  }, [data])

  return {
    benefits: organizedData,
    isLoading: isLoading,
  }
}
