import styled, { css } from 'styled-components'

export const PageSubTitle = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
`

export const Subtitle = styled.p`
  display: inline-block;
  color: var(--color-primary-04);
  font-weight: bold;
  ${({ withMargin }) =>
    withMargin &&
    css`
      margin: 32px 0;
    `}
`

export const StatementIcon = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  width: 28px;
  border-radius: 50%;
  background-color: #48f06b;
  margin-right: 16px;
  svg {
    min-height: 24px;
    min-width: 24px;
  }
`

export const BodyIcon = styled.div`
  background-color: #9a81cb;
  padding: 3px;
  border-radius: 50%;
  margin-top: -3px;
  margin-right: 10px;
  width: 26px;
  height: 27px;
`

export const Centralizer = styled.div`
  display: flex;
  align-items: center;
`

export const Balance = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
  font-weight: var(--font-weight-bold);
  font-size: 21px;
`

export const BalanceValue = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 130px;
  height: 26px;
  margin-left: 16px;
  color: transparent;
  background-color: var(--color-secundary-05);
  border-radius: 4px;
  transition: all 0.26s ease-in-out;
  ${({ visible }) =>
    visible &&
    css`
      color: initial;
      background-color: transparent;
    `}
`

export const BalanceVisibilitySelector = styled.div`
  display: flex;
  align-items: center;
  margin-left: 16px;
  cursor: pointer;
`

export const BalanceTableFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: var(--font-weight-semi-bold);
`

export const CustomTr = styled.tr`
  height: 32px;
  ${({ colored }) =>
    colored &&
    css`
      background-color: var(--color-secundary-05);
    `}
  ${({ underlined }) =>
    underlined &&
    css`
      border-bottom: 1px solid var(--color-secundary-05);
    `}
  :last-child {
    position: sticky;
    bottom: 0;
    left: 0;
    td:last-child {
      padding: 0 24px;
      vertical-align: middle;
    }
  }
`

export const CustomTd = styled.td`
  padding: 16px 24px;
  vertical-align: ${({ header }) => (header ? 'top' : 'middle')};
`
