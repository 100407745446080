import styled, { css } from 'styled-components'

export const AutoCompleteContainer = styled.div`
  position: relative;
`

export const Option = styled.div`
  cursor: pointer;
  align-items: center;
  color: var(--color-primary-04);
  height: 2.1875rem;
  padding: 8px 16px;
  ${({ selected }) =>
    selected &&
    css`
      background-color: aliceblue;
    `}
`
