import { Box, Card, ColorfulBox, Typography } from '@/presentation/components/atoms'
import { AutoTable } from '@/presentation/components/molecules'
import { IUseDashboardReturn } from '../../hooks/useDashboardInterfaces'
import { YearAndMonth } from '../../dashboard'
import { useAtomValue } from 'jotai'
import { AccountPersistentStore } from '@/main/store'

interface OrdersTableCardProps {
  data: IUseDashboardReturn['dashboardData']
  selectedDate: YearAndMonth
  isLoading?: boolean
}

export const FinancialTableCard = ({ data, selectedDate, isLoading }: OrdersTableCardProps) => {
  const selectedMonthData = data?.resaleFinancialInfo?.find((monthData) => monthData.month === selectedDate.month)
  const account = useAtomValue(AccountPersistentStore)
  return (
    <Card sx={{ minWidth: '100%' }}>
      <ColorfulBox>
        <Box sx={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
          <Typography variant='subtitle1'>Financeiro - {account.resale.displayName}</Typography>
        </Box>
      </ColorfulBox>
      <AutoTable
        isLoading={isLoading || !data?.monthlyOrdersPaymentInfo}
        variant='report'
        columns={[
          {
            columnDisplayName: 'INDICADOR',
            columnValue: ({ row }) => row.indicatorName,
          },
          {
            columnDisplayName: 'TIPO / VALOR / QTD',
            columnValue: ({ row }) => row.valueType,
          },
          {
            columnDisplayName: 'TOTAL (R$)',
            columnValue: ({ row }) => row.total,
          },
        ]}
        rows={selectedMonthData?.data}
      />
    </Card>
  )
}
