import { all } from 'redux-saga/effects'

import user from './user/sagas'
import companys from './company/sagas'
import emittingBanks from './emittingBanks/sagas'
import infoCampany from './infoCompany/sagas'
import orders from './pedidos/sagas'
import notifications from './notification/sagas'
import cardOrder from './orders/cards/one/sagas'
import cardsOrders from './orders/cards/all/sagas'
import recharge from './recharge/sagas'
import users from './users/sagas'

export default function* rootSaga() {
  return yield all([
    user,
    companys,
    emittingBanks,
    infoCampany,
    notifications,
    orders,
    cardOrder,
    cardsOrders,
    recharge,
    users,
  ])
}
