import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  CircularProgress,
} from '@/presentation/components/atoms'
import { useOrderMutations } from '../../../hooks/useOrderMutations'

export const FinishPickUpOrderDialog = ({ onClose, orderToFinish, open }) => {
  const { finalizeOrder } = useOrderMutations({
    config: {
      finalizeOrder: {
        onSuccess: () => {
          onClose()
        },
      },
    },
  })

  const isLoading = finalizeOrder.isLoading
  return (
    <Dialog open={open} onClose={isLoading ? null : () => onClose()}>
      <DialogTitle id='alert-dialog-title' sx={{ marginBottom: '32px' }}>
        Concluir pedido
      </DialogTitle>
      <DialogContent sx={{ padding: '32px', display: 'flex', justifyContent: isLoading ? 'center' : 'start' }}>
        {isLoading ? (
          <CircularProgress />
        ) : (
          'Ao concluir o pedido, ele será encerrado e essa ação não poderá ser desfeita. Deseja confirmar?'
        )}
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'space-between', marginTop: '32px' }}>
        <Button disabled={isLoading} variant='outlined' onClick={onClose}>
          Cancelar
        </Button>
        <Button
          disabled={isLoading}
          variant='contained'
          onClick={() => {
            finalizeOrder.mutate({ orderId: orderToFinish })
          }}
          autoFocus
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
