import { ORDER_STATUS } from '@/main/utils/constants'
import { Box, CircularProgress, Menu, MenuItem } from '@/presentation/components/atoms'
import { useState } from 'react'
import { useFilesDownload } from '../../hooks/useFilesDownload'

import { FinishPickUpOrderDialog } from './components/finishPickUpOrderDialog'

interface OrderMenuProps {
  order: any
  isOpen: boolean
  onClose: () => void
  anchorEl: any
}

export const OrderMenu = ({ order, isOpen, onClose, anchorEl }: OrderMenuProps) => {
  const {
    getBrokerOrderXlsx,
    isGettingBrokerOrderXlsx,
    getAttachedSlip,
    isGettingAttachedSlip,
    getPaymentProof,
    isGettingPaymentProof,
  } = useFilesDownload({
    orderCode: order?.code,
    orderId: order?.id,
  })
  const [pickupOrderToFinish, setPickupOrderToFinish] = useState(null)

  const menuOptionsForStatus = {
    [ORDER_STATUS.AVAILABLE_PICKUP]: [
      { label: 'Concluir pedido', action: (order) => setPickupOrderToFinish(order.id) },
    ],

    [ORDER_STATUS.FINISHED]: [
      {
        label: 'Baixar planilha de pedido finalizado',
        action: () => getBrokerOrderXlsx(),
        loading: isGettingBrokerOrderXlsx,
      },
      {
        label: 'Baixar comprovante de pagamento',
        action: () => getPaymentProof(),
        loading: isGettingPaymentProof,
      },
      {
        label: 'Baixar boleto anexado',
        action: () => getAttachedSlip(),
        loading: isGettingAttachedSlip,
      },
    ],
  }
  return (
    <>
      {isOpen && (
        <Menu open={isOpen} onClose={onClose} anchorEl={anchorEl}>
          {menuOptionsForStatus[order.orderStatus] &&
            menuOptionsForStatus[order.orderStatus].map((option, index) => (
              <MenuItem
                key={'menuOption' + index}
                onClick={() => {
                  !option.loading ? option.action(order) : null
                  onClose()
                }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                  {option.label}
                  {option.loading && <CircularProgress size='sm' />}
                </Box>
              </MenuItem>
            ))}
        </Menu>
      )}

      <FinishPickUpOrderDialog
        onClose={() => {
          setPickupOrderToFinish(null)
        }}
        orderToFinish={pickupOrderToFinish}
        open={pickupOrderToFinish}
      />
    </>
  )
}
