import api from '@/infra/api'
import { downloadFile } from '@/main/utils/functions'
import { useQuery } from '@tanstack/react-query'

export interface IFilesDownloadReturn {
  getBrokerOrderLayout: any
  isGettingBrokerOrderLayout: boolean
  getBrokerOrderXlsx: any
  isGettingBrokerOrderXlsx: boolean
  getAttachedSlip: any
  isGettingAttachedSlip: boolean
  getPaymentProof: any
  isGettingPaymentProof: boolean
}

export const useFilesDownload = ({
  orderId,
  orderCode,
  identifier = '',
}: {
  orderId: string
  orderCode: string
  identifier?: string
}): IFilesDownloadReturn => {
  const { refetch: getBrokerOrderLayout, isFetching: isGettingBrokerOrderLayout } = useQuery({
    queryKey: ['brokerOrderLayout', orderId, identifier],
    queryFn: () => api.get(`/resale-bff/orders/${orderId}/operator-import`),
    enabled: false,
    onSuccess: (res) =>
      downloadFile({
        download: `Layout do Pedido ${orderCode}`,
        href: `data:text/plain;charset=utf-8,${encodeURIComponent(String(res?.data))}`,
      }),
  })

  const { refetch: getBrokerOrderXlsx, isFetching: isGettingBrokerOrderXlsx } = useQuery({
    queryKey: ['brokerOrderXlsx', orderId, identifier],
    queryFn: () => api.get(`resale-bff/orders/${orderId}/exportexcelorderitems`, { responseType: 'blob' }),
    enabled: false,
    onSuccess: (res) => {
      const data = new Blob([res?.data as any], { type: 'text/xls' })
      const csvURL = window.URL.createObjectURL(data)
      downloadFile({
        href: csvURL,
        download: `Pedido ${orderCode}.xlsx`,
      })
    },
  })

  const { refetch: getAttachedSlip, isFetching: isGettingAttachedSlip } = useQuery({
    queryKey: ['brokerAttachedSlip', orderId, identifier],
    queryFn: () => api.get(`/resale-bff/orders/${orderId}/download/slip`),
    enabled: false,
    onSuccess: (res) => {
      window.open(res?.data?.temporaryLink, '_blank')
    },
  })

  const { refetch: getPaymentProof, isFetching: isGettingPaymentProof } = useQuery({
    queryKey: ['brokerPaymentProof', orderId, identifier],
    queryFn: () => api.get(`/resale-bff/orders/${orderId}/download/proof-Payment`),
    enabled: false,
    onSuccess: (res) => {
      window.open(res?.data?.temporaryLink, '_blank')
    },
  })

  return {
    getBrokerOrderLayout: getBrokerOrderLayout,
    isGettingBrokerOrderLayout: isGettingBrokerOrderLayout,
    getBrokerOrderXlsx,
    isGettingBrokerOrderXlsx,
    getAttachedSlip,
    isGettingAttachedSlip,
    getPaymentProof,
    isGettingPaymentProof,
  }
}
