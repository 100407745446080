export const Users = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M11.9688 14C10.1875 14 8.71875 12.5625 8.75 10.75C8.75 8.96875 10.1875 7.5 11.9688 7.5C13.75 7.5 15.2188 8.96875 15.2188 10.75C15.2188 12.5625 13.7812 14 11.9688 14ZM11.9688 9C11.0312 9 10.2188 9.8125 10.2188 10.75C10.2188 11.7188 11.0312 12.5 11.9688 12.5C12.9375 12.5 13.7188 11.7188 13.7188 10.75C13.7188 9.8125 12.9375 9 11.9688 9ZM18 9C16.5938 9 15.5 7.90625 15.5 6.5C15.5 5.125 16.5938 4 18 4C19.375 4 20.5 5.125 20.5 6.5C20.5 7.90625 19.375 9 18 9ZM13.5312 15C16 15 18 16.875 18 19.1875C18 19.6562 17.5938 20 17.0938 20H6.875C6.375 20 6 19.6562 6 19.1875C6 16.875 7.96875 15 10.4375 15H13.5312ZM7.5625 18.5H16.4062C16.0625 17.375 14.9062 16.5 13.5312 16.5H10.4062C9.03125 16.5 7.875 17.375 7.5625 18.5ZM19.2188 10C20.75 10 22 11.3125 22 12.9375C22 13.25 21.75 13.5 21.4375 13.5H15.1875C15.8438 12.7812 16.2188 11.8125 16.2188 10.75C16.2188 10.5938 16.1875 10.4375 16.1875 10.2812C16.5312 10.125 16.9062 10 17.3125 10H19.2188ZM7.71875 10.75C7.71875 11.8125 8.125 12.7812 8.78125 13.5H2.53125C2.21875 13.5 2 13.25 2 12.9375C2 11.3125 3.21875 10 4.75 10H6.65625C7.0625 10 7.4375 10.0938 7.78125 10.2812C7.75 10.4375 7.71875 10.5938 7.71875 10.75ZM6 9C4.59375 9 3.5 7.90625 3.5 6.5C3.5 5.125 4.59375 4 6 4C7.375 4 8.5 5.125 8.5 6.5C8.5 7.90625 7.375 9 6 9Z'
        fill='#616161'
      />
    </svg>
  )
}
