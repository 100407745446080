import { faLink, faXmark } from '@fortawesome/free-solid-svg-icons'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Icon,
  Button,
  DialogActions,
  Skeleton,
  Box,
  Typography,
} from '@/presentation/components/atoms'
import { minTwoDigits } from '@/main/utils/functionUtils'
import { useTheme } from '@mui/material'
import { StyledLink, Container, ProjectionInfo } from './styles'
import { useDialogs } from '@/main/hooks'
import { useOrderMutations } from '@/presentation/pages/orders/listOrders/oldOrdersList/hooks/useOrderMutations'

const NoErrorContent = ({ data, calculateBrokerOrderProjection, onClose }) => {
  return (
    <>
      <DialogTitle>
        Deseja confirmar o inicio do processamento do pedido?
        {!calculateBrokerOrderProjection.isLoading && <Icon icon={faXmark} onClick={onClose} />}
      </DialogTitle>
      <DialogContent sx={{ marginTop: '32px' }}>
        <Container>
          {calculateBrokerOrderProjection.isLoading ? (
            <>
              <Skeleton variant='rounded' height='42px' />
              <Box sx={{ display: 'flex', gap: '16px' }}>
                <Skeleton variant='rounded' height='48px' width='100%' />
                <Skeleton variant='rounded' height='48px' width='100%' />
              </Box>
            </>
          ) : (
            <>
              <ProjectionInfo>
                Cartões no pedido: <b>{data.nCardsInOrder}</b>
              </ProjectionInfo>
            </>
          )}
        </Container>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: 'space-between',
          marginTop: '16px',
          gap: '16px',
          '& button': { width: '50%' },
        }}
      >
        <Button variant='outlined' onClick={onClose}>
          Cancelar
        </Button>
        <Button variant='contained' onClick={calculateBrokerOrderProjection}>
          Confirmar
        </Button>
      </DialogActions>
    </>
  )
}

const Counter = ({ color, text, value }) => {
  return (
    <Box>
      <Typography
        sx={{
          color: color,
          fontWeight: 700,
          fontSize: '4rem',
          lineHeight: 'initial',
          display: 'block',
          textAlign: 'center',
        }}
      >
        {value}
      </Typography>
      <Typography
        sx={{
          fontWeight: 500,
          textAlign: 'center',
        }}
      >
        {text}
      </Typography>
    </Box>
  )
}

const ErrorContent = ({ data, onClose }) => {
  const theme = useTheme()
  const { goBack } = useDialogs()
  return (
    <>
      <DialogTitle>Resultado da Importação</DialogTitle>
      <DialogContent sx={{ marginTop: '32px', height: 'fit-content' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '32px' }}>
          <Counter
            color={theme.palette.primary.dark}
            text='Colaboradores no pedido'
            value={minTwoDigits(data?.nCardsInOrder)}
          />
          <Counter
            color={theme.palette.error.main}
            text='Erros na importação'
            value={minTwoDigits(data?.errorsCount)}
          />
        </Box>
        {data?.base64 && (
          <StyledLink download='erros_importacao.txt' href={`data:text/txt;base64,${data?.base64}`}>
            Ver relatório de erros <Icon icon={faLink} />
          </StyledLink>
        )}
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: 'space-between',
          marginTop: '16px',
          gap: '16px',
          '& button': { width: '50%' },
        }}
      >
        <Button variant='outlined' onClick={onClose}>
          Cancelar
        </Button>
        <Button variant='contained' onClick={() => goBack()}>
          Reenviar
        </Button>
      </DialogActions>
    </>
  )
}

export const ProjectionResponseDialog = ({
  data = {},
  isOpen,
  onClose,
  orderId,
}: {
  data: any
  isOpen: boolean
  onClose: () => void
  orderId: string
}) => {
  const { calculateBrokerOrderProjection } = useOrderMutations()
  const hasErrors = data.errorsCount > 0

  const closeIfNotLoading = calculateBrokerOrderProjection.isLoading ? null : onClose
  return (
    <Dialog open={isOpen} onClose={closeIfNotLoading}>
      {hasErrors ? (
        <ErrorContent data={data} onClose={onClose} />
      ) : (
        <NoErrorContent
          data={data}
          calculateBrokerOrderProjection={() => {
            calculateBrokerOrderProjection.mutate({ orderId })
            onClose()
          }}
          onClose={onClose}
        />
      )}
    </Dialog>
  )
}
