export function pedidosRequest(query) {
  return {
    type: '@pedidos/PEDIDOS_REQUEST',
    payload: { query },
  }
}

export function pedidosSuccess(pedidos) {
  return {
    type: '@pedidos/PEDIDOS_SUCCESS',
    payload: { pedidos },
  }
}

export function pedidosFailure() {
  return {
    type: '@pedidos/PEDIDOS_ERROR',
  }
}
