import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { useMediaQuery, useTheme } from '@mui/material'
import { CustomizedAxisTick } from '../customizedAxisTick'
import { monetaryAbbreviator } from '@/main/utils/functions'
import { CustomTooltip } from '../customTooltip'
import { Typography } from '@/presentation/components/atoms'
import { IDashboardMonthlyEarnings } from '../../../../hooks/useDashboardInterfaces'

interface EarningsLineChartProps {
  data: Array<IDashboardMonthlyEarnings>
}

export const EarningsLineChart = ({ data }: EarningsLineChartProps) => {
  const theme = useTheme()
  const isSmallerThanSm = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <ResponsiveContainer height={248} width={'100%'}>
      <LineChart width={730} height={250} data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid vertical={false} stroke={theme.palette.grey[100]} />
        <XAxis
          dataKey='month'
          fontSize={12}
          interval={0}
          axisLine={false}
          tickLine={null}
          tick={<CustomizedAxisTick />}
        />
        {!isSmallerThanSm && (
          <YAxis
            fontSize={12}
            tickFormatter={(value) => `R$ ${monetaryAbbreviator({ value })}`}
            axisLine={false}
            tickSize={16}
            tickLine={null}
            interval={0}
            width={80}
          />
        )}
        <Tooltip
          allowEscapeViewBox={{ x: true, y: true }}
          cursor={{ fill: theme.palette.grey[100] }}
          content={(props) => (
            <CustomTooltip
              containerId='earningsLineChartContainer'
              active={props.active}
              label={props.label}
              payload={props.payload}
            />
          )}
        />
        <Legend
          align={isSmallerThanSm ? 'center' : 'left'}
          iconSize={8}
          formatter={(value: string) => (
            <Typography variant='text2' sx={{ color: theme.palette.grey[700], textTransform: 'capitalize' }}>
              {value}
            </Typography>
          )}
          wrapperStyle={{ left: isSmallerThanSm ? 0 : 80 }}
          width={isSmallerThanSm ? null : 200}
          iconType='rect'
        />
        <Line
          type='linear'
          dataKey='dealer'
          stroke={theme.palette.success.main}
          fill={theme.palette.success.main}
          min={23}
        />
        <Line
          type='linear'
          dataKey='broker'
          stroke={theme.palette.benefits.snack.main}
          fill={theme.palette.benefits.snack.main}
        />
      </LineChart>
    </ResponsiveContainer>
  )
}
