import { CARD_STATUS } from '@/main/utils/constants'

export const companyCardsFilterConfigurations = ({ benefitTypes, operators, operatorsCards }) => {
  return [
    {
      type: 'multiSelect',
      name: 'benefitType',
      props: {
        label: 'Benefícios',
        fullWidth: true,
        variant: 'contained',
        options: benefitTypes,
        getOptionLabel: (option) => option.name,
        getOptionValue: (option) => option.id,
      },
    },
    {
      type: 'select',
      name: 'operatorId',
      props: {
        label: 'Operadora',
        fullWidth: true,
        options: operators,
        getOptionLabel: (option) => option.name,
        getOptionValue: (option) => option.id,
      },
    },
    {
      type: 'multiSelect',
      name: 'operatorCardId',
      props: {
        label: 'Nome Cartão',
        fullWidth: true,
        variant: 'contained',
        options: operatorsCards,
        getOptionLabel: (option) => option.name,
        getOptionValue: (option) => option.id,
      },
    },
    {
      type: 'select',
      name: 'status',
      props: {
        label: 'Status',
        fullWidth: true,
        options: [
          { label: 'Ativo', value: CARD_STATUS.ACTIVE },
          { label: 'Inativo', value: CARD_STATUS.INACTIVE },
          // { label: 'Bloqueado', value: CARD_STATUS.BLOCKED },
        ],
      },
    },
  ]
}
