import { useContext } from 'react'
import { Controller } from 'react-hook-form'
import { GridProps } from '@mui/material'
import { Grid } from '@/presentation/components/atoms'
import { CheckboxField, DatePickerField, MaskedTextField, Select, TextField } from '@/presentation/components/molecules'
import { FormContext } from '../form/form'

const fieldTypes = {
  input: TextField,
  maskedInput: MaskedTextField,
  select: Select,
  date: DatePickerField,
  checkbox: CheckboxField,
} as const

type FieldType = keyof typeof fieldTypes

interface FormFieldProps {
  name: string
  id?: string
  type?: FieldType
  rules?: object
  label?: string
  gridProps?: GridProps
  noStatusBorder?: boolean
}

export const FormField = ({
  id,
  name,
  type = 'input',
  rules = {},
  label,
  gridProps = { xs: 12, sm: 6, md: 4 },
  noStatusBorder,
  ...props
}: FormFieldProps & any) => {
  const { form, isEditable } = useContext(FormContext)

  return (
    <Grid item {...gridProps}>
      <Controller
        control={form.control}
        name={name}
        rules={isEditable ? rules : {}}
        render={({ field: { value, onChange }, fieldState: { isDirty, error } }) => {
          const Component = fieldTypes[type]
          const handleChange = (e) => {
            onChange(e)
            if (props.onChange) props.onChange(e)
          }
          if (['select', 'checkbox'].includes(type)) value = value ?? ''
          return (
            <Component
              {...props}
              id={id}
              label={label}
              value={value}
              onChange={handleChange}
              error={Boolean(error) && !noStatusBorder}
              success={isDirty && !error && !noStatusBorder ? true : false}
              helperText={error?.message}
              blocked={props.blocked || !isEditable}
            />
          )
        }}
      />
    </Grid>
  )
}
