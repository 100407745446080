import apiCallHandler from './apiCallHandler'
import api from '../infra/api'

export const getCompanies = (query = '') => apiCallHandler(() => api.get(`/companies-service/${query}`))

export const putCompanyDeskFees = (companyId, payload) =>
  apiCallHandler(() => api.put(`/companies-service/${companyId}/DeskFees`, payload))

export const postCompanyDeskFeesAddItem = (companyId, payload) =>
  apiCallHandler(() => api.post(`/companies-service/${companyId}/DeskFees/Itens`, payload))

export const getCompanyEmployees = (companyId, query = '') =>
  apiCallHandler(() => api.get(`resale-bff/companies/${companyId}/benefit${query}`))

export const getCompanyDepartments = (companyId, query = '') =>
  apiCallHandler(() => api.get(`resale-bff/companies/${companyId}/departments${query}`))

export const getCompanyCards = (companyId, query = '') =>
  apiCallHandler(() => api.get(`resale-bff/companies/${companyId}/cards-with-employees${query}`))

export const getCompanyCardOrders = (companyId, query = '') =>
  apiCallHandler(() => api.get(`resale-bff/companies/${companyId}/cards/orders${query}`))

export const getCompanyCardCopyHistoric = (companyId, query = '') =>
  apiCallHandler(() => api.get(`resale-bff/companies/${companyId}/cards/history${query}`))

export const getCompaniesWallets = (query = '') => apiCallHandler(() => api.get(`resale-bff/companies/wallet${query}`))

export const getAddressTypes = () => apiCallHandler(() => api.get('resale-bff/address/address-types'))

export const getActivityBranches = () => apiCallHandler(() => api.get('resale-bff/Register/Branches'))

export const getLaneTypes = () => apiCallHandler(() => api.get('resale-bff/address/lane-types'))

export const getCityCodes = (query = '') => apiCallHandler(() => api.get(`resale-bff/address/cities${query}`))

export const getContactTypes = (query = '') => apiCallHandler(() => api.get(`resale-bff/contact/contact-types${query}`))

export const patchEditCompanyGeneralData = (companyId, payload) =>
  apiCallHandler(() => api.patch(`resale-bff/companies/${companyId}/generaldata`, payload))

export const putEditCompanyAddress = (companyId, payload) =>
  apiCallHandler(() => api.put(`resale-bff/companies/${companyId}/address`, payload))

export const putEditCompanyContact = (companyId, payload) =>
  apiCallHandler(() => api.put(`resale-bff/companies/${companyId}/contact`, payload))

export const putEditCompany = (companyId, payload) =>
  apiCallHandler(() => api.put(`/companies-service/${companyId}`, payload))

export const deleteRejectCompany = (companyId) =>
  apiCallHandler(() => api.delete(`resale-bff/companies/${companyId}/reject`))

export const getCompany = (companyId) => apiCallHandler(() => api.get(`resale-bff/companies/${companyId}`))

export const getResaleBankAccount = (bankCode) =>
  apiCallHandler(() => api.get(`resale-bff/resales/Account?bankCode=${bankCode}`))
