import produce from 'immer'

const INITIAL_STATE = {
  data: null,
  loading: false,
  error: false,
}

export default function infoCompanys(state = INITIAL_STATE, action) {
  const { type, payload } = action

  return produce(state, (draft) => {
    switch (type) {
      case '@infoCompany/INFOCOMPANY_REQUEST': {
        draft.data = null
        draft.loading = true
        break
      }

      case '@infoCompany/INFOCOMPANY_SUCCESS': {
        draft.loading = false
        draft.data = payload.info
        break
      }

      case '@infoCompany/INFOCOMPANY_ERROR': {
        draft.loading = false
        draft.error = true
        break
      }

      default:
    }
  })
}
