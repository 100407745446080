import { useCallback, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { breadcrumbNameMap } from '../utils/breadcrumbNameMap'

interface IUseDocumentTitle {
  config?: {
    autoChange?: boolean
    baseTitle?: string
  }
}

const defaultConfig: IUseDocumentTitle = {
  config: {
    autoChange: false,
    baseTitle: 'Revenda',
  },
}

export const useDocumentTitle = (params: IUseDocumentTitle = defaultConfig) => {
  const {
    config: { autoChange, baseTitle },
  } = { config: { ...defaultConfig.config, ...params.config } }
  const location = useLocation()
  const getTitleByPath = useCallback((): string => {
    const pathNickname = breadcrumbNameMap[location.pathname]
    if (pathNickname) return `${baseTitle} - ${pathNickname}`
    const finalPath = location.pathname.split('/').pop()
    const pathAutoName = finalPath ? `- ${finalPath.charAt(0).toUpperCase() + finalPath.slice(1)}` : ''
    return `${baseTitle} ${pathAutoName}`
  }, [location, baseTitle])
  useEffect(() => {
    if (autoChange) document.title = getTitleByPath()
    return () => {
      document.title = baseTitle
    }
  }, [location, autoChange, getTitleByPath, baseTitle])

  const change = ({ title }: { title: string }) => {
    document.title = `${baseTitle} - ${title}`
  }

  const override = ({ title }: { title: string }) => {
    document.title = title
  }

  return {
    change,
    override,
  }
}
