import { Checkbox, FieldWrapper, InputLabel } from '@/presentation/components/atoms'

export const CheckboxField = ({ value, label, ...props }) => {
  return (
    <FieldWrapper sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
      <InputLabel label={label} position='right' sx={{ margin: '22px 0 0 0 !important' }}>
        <Checkbox checked={value} {...props} />
      </InputLabel>
    </FieldWrapper>
  )
}
