import { ORDER_PAYMENT_TYPES } from '@/main/utils/enumsMapping'
import { Box, Icon, Tooltip } from '@stationkim/front-ui'
import { ETipoPagamento } from '@/main/enums'
import { faPix } from '@fortawesome/free-brands-svg-icons'
import { faBarcode, faHandHoldingDollar, faWallet } from '@fortawesome/free-solid-svg-icons'
import { useAtomValue } from 'jotai'
import { AccountPersistentStore } from '@/main/store'

export interface PaymentIconsProps {
  paymentTypes: number[]
}

export const PaymentIcons = ({ paymentTypes }: PaymentIconsProps) => {
  const account = useAtomValue(AccountPersistentStore)

  const generateIcons = () => {
    const alreadyUsed = []

    return paymentTypes.map((payment) => {
      if (alreadyUsed.includes(payment)) return null
      alreadyUsed.push(payment)
      switch (payment) {
        case ETipoPagamento.Boleto: {
          return (
            <Tooltip title={ORDER_PAYMENT_TYPES[payment]}>
              <Icon icon={faBarcode} />
            </Tooltip>
          )
        }
        case ETipoPagamento.Pix: {
          return (
            <Tooltip title={ORDER_PAYMENT_TYPES[payment]}>
              <Icon icon={faPix} />
            </Tooltip>
          )
        }
        case ETipoPagamento.CarteiraSK: {
          return (
            <Tooltip title={account?.resale?.digitalAccountName || ORDER_PAYMENT_TYPES[payment]}>
              <Icon icon={faWallet} />
            </Tooltip>
          )
        }
        case ETipoPagamento.Gratuito: {
          return (
            <Tooltip title={ORDER_PAYMENT_TYPES[payment]}>
              <Icon icon={faHandHoldingDollar} />
            </Tooltip>
          )
        }
      }
    })
  }

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '8px',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {generateIcons()}
    </Box>
  )
}
