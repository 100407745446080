import { createContext, ComponentProps, useState, useRef, useEffect } from 'react'
import { StyledForm } from './styles'
import { Button, Grid } from '@/presentation/components/atoms'
import { GridProps } from '@mui/material'
import { FormSkeleton } from '../formSkeleton/formSkeleton'

interface FormProps extends ComponentProps<'form'> {
  form: any
  gridProps?: GridProps
  isEditable?: boolean
  onEditButtonClick?: () => void
  isLoading?: boolean
  onCancel?: () => void
  submitText?: string
  cancelText?: string
  buttonFullWidth?: boolean
}

export const FormContext = createContext<FormProps>({ form: {} })

export const Form = ({
  children,
  onSubmit,
  form,
  gridProps = { spacing: '16px' },
  isEditable,
  onEditButtonClick,
  isLoading,
  onCancel,
  submitText = 'Salvar',
  cancelText = 'Cancelar',
  buttonFullWidth = false,
}: FormProps & GridProps) => {
  const [innerIsEditable, setInnerIsEditable] = useState(false)
  const valuesBeforeEdit = useRef({})
  const isEditing = isEditable !== undefined ? isEditable : innerIsEditable

  const onEditClick = () => {
    if (onEditButtonClick) onEditButtonClick()
    if (isEditable === undefined) setInnerIsEditable(true)
  }

  useEffect(() => {
    valuesBeforeEdit.current = form.getValues()
  }, [form])

  const onCancelClick = () => {
    if (onCancel) onCancel()
    else form.reset(valuesBeforeEdit.current)
    if (isEditable === undefined) setInnerIsEditable(false)
  }

  const handleOnSubmit = (data) => {
    onSubmit(data)
    isEditable === undefined && setInnerIsEditable(false)
  }

  return (
    <FormContext.Provider
      value={{
        form,
        isEditable: isEditing,
        onEditButtonClick: onEditClick,
        isLoading,
        gridProps,
      }}
    >
      <StyledForm onSubmit={form.handleSubmit(handleOnSubmit)}>
        <Grid container {...gridProps}>
          {isLoading ? (
            <Grid item xs={12}>
              <FormSkeleton />{' '}
            </Grid>
          ) : (
            children
          )}
          {isEditing && !isLoading && (
            <Grid item xs={12}>
              <Grid
                container
                spacing='16px'
                justifyContent='flex-end'
                sx={{ flexDirection: { xs: 'column-reverse', sm: 'row' } }}
              >
                <Grid item xs={12} sm={6} md={buttonFullWidth ? 6 : 'auto'}>
                  <Button variant='outlined' fullWidth onClick={onCancelClick}>
                    {cancelText}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6} md={buttonFullWidth ? 6 : 'auto'}>
                  <Button variant='contained' type='submit' fullWidth>
                    {submitText}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </StyledForm>
    </FormContext.Provider>
  )
}
