import { useEffect, useMemo, useState } from 'react'
import { MenuItem } from '@mui/material'
import { RowSelectorContainer, SelectorLabel, StyledSelected } from './styles'

export interface VisibleRowsSelectorProps {
  value?: number
  onChange?: (value: number) => void
  label?: string
  initialValue?: number
  options?: number[]
  id?: string
  listSize?: number
  hideImpossibleSize?: boolean
}

const getAvailableOptions = ({
  hideImpossibleSize,
  listSize,
  options,
}: {
  options: number[]
  listSize: number
  hideImpossibleSize: boolean
}) => {
  if (!hideImpossibleSize || typeof listSize !== 'number') return options
  const lastTen = Math.floor(listSize / 10) * 10
  const availableOptions = options.filter((option) => option <= lastTen)
  return availableOptions.length > 0 ? availableOptions : [listSize]
}

const defaultOptions = [5, 10, 15, 20, 30, 50]

export const VisibleRowsSelector = ({
  value: externalValue,
  onChange = () => null,
  label = 'Linhas visíveis:',
  initialValue = 10,
  options = defaultOptions,
  id,
  listSize,
  hideImpossibleSize = true,
}: VisibleRowsSelectorProps) => {
  const [internalValue, setInternalValue] = useState<number>()
  const [availableOptions, setAvailableOptions] = useState<number[]>([])

  const isControlled = externalValue !== undefined

  const value = isControlled ? externalValue : internalValue
  const setValue = isControlled ? onChange : setInternalValue

  const renderOptions = useMemo(() => {
    return availableOptions.map((option, index) => (
      <MenuItem key={(id ?? 'selector_option_') + index} value={option} children={option} />
    ))
  }, [id, availableOptions])

  useEffect(() => {
    setAvailableOptions(getAvailableOptions({ options, listSize, hideImpossibleSize }))
  }, [options, listSize, hideImpossibleSize])

  useEffect(() => {
    if (availableOptions.length === 0) return
    if (availableOptions.includes(initialValue)) setValue(initialValue)
    else setValue(availableOptions[0])
  }, [availableOptions, initialValue, setValue])

  return (
    <RowSelectorContainer>
      <SelectorLabel>{label}</SelectorLabel>
      <StyledSelected value={value || ''} onChange={({ target }) => setValue(Number(target.value))}>
        {renderOptions}
      </StyledSelected>
    </RowSelectorContainer>
  )
}
