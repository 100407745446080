import { useAtom } from 'jotai'
import { atomWithHash } from 'jotai-location'
import { CompanyAllCards } from './tabs/companyAllCards'
import CompanyBlockedCards from './tabs/companyBlockedCards'
import CompanyCardCopyHistoric from './tabs/companyCardCopyHistoric'
import CompanyCardOrders from './tabs/companyCardOrders'
import { Tab, TabPanel, Tabs } from '@/presentation/components/atoms'

const companyCardsTab = atomWithHash('companyCardsTab', 0)

export const CompanyCards = () => {
  const [value, setValue] = useAtom(companyCardsTab)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <>
      <Tabs value={value} onChange={handleChange}>
        <Tab label='Todos' />
        <Tab label='Pedidos' />
        <Tab label='Bloqueados' />
        <Tab label='Histórico de Vias' />
      </Tabs>

      <TabPanel value={value} index={0}>
        <CompanyAllCards />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <CompanyCardOrders />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <CompanyBlockedCards />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <CompanyCardCopyHistoric />
      </TabPanel>
    </>
  )
}
