import React from 'react'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { Icon } from '../atoms/Icon'
import { Box } from '../atoms/box'

function OrderIcon({ clickUp, clickDown, props }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '8px',
        '& svg': {
          cursor: 'pointer',
          height: '12px',
        },
      }}
      {...props}
    >
      <Icon icon={faChevronUp} onClick={clickUp} />
      <Icon icon={faChevronDown} onClick={clickDown} />
    </Box>
  )
}

export default OrderIcon
