import { ORDER_STATUS } from '@/main/utils/constants'
import { Box, PortalHOC, Typography } from '@/presentation/components/atoms'
import { useTheme } from '@mui/material'
import { useEffect, useRef, useState } from 'react'

interface CustomTooltipProps {
  active: boolean
  payload: any
  containerId: string
}

export const CustomTooltip = ({ active, payload, containerId }: CustomTooltipProps) => {
  const theme = useTheme()
  const [cursorPosition, setCursorPosition] = useState<{ x: number; y: number }>({ y: 0, x: 0 })
  const timeoutRef = useRef(null)

  useEffect(() => {
    const trackMouse = (e) => {
      const x = e.clientX
      const y = e.clientY
      if (!timeoutRef.current) setCursorPosition({ x, y })
      clearTimeout(timeoutRef.current)
      timeoutRef.current = setTimeout(() => {
        setCursorPosition({ x, y })
        timeoutRef.current = null
      }, 50)
    }
    const chartContainerElement = document.getElementById(containerId)
    if (!chartContainerElement) return
    chartContainerElement.addEventListener('mousemove', trackMouse)
    return () => {
      chartContainerElement.removeEventListener('mousemove', trackMouse)
    }
  }, [])

  if (active && payload && payload.length) {
    const elementData = payload[0].payload
    return (
      <PortalHOC position={cursorPosition} offset={{ x: 10, y: 10 }}>
        <Box
          sx={{
            backgroundColor: theme.palette.common.white,
            padding: '8px',
            borderRadius: '4px',
            boxShadow: '0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 5px 5px -3px rgba(0, 0, 0, 0.20)',
          }}
        >
          <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <Box
              sx={{
                backgroundColor: elementData.fill,
                height: '8px',
                width: '20px',
              }}
            />
            <Typography variant='text2'>{ORDER_STATUS[elementData.payload.orderStatus] || 'Desconhecido'}</Typography>
          </Box>
          <Typography variant='text2' sx={{ color: theme.palette.grey[700] }}>
            {`${elementData.payload.value} (${elementData.payload.percentualValue}%)`}
          </Typography>
        </Box>
      </PortalHOC>
    )
  }
  return null
}
