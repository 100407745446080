import React, { useEffect, useState } from 'react'
import filesize from 'filesize'
import api from '@/infra/api'
import ImportResult from './importResults'
import {
  BillOption,
  BillType,
  ExplanationText,
  ProgressPosition,
  Wrapper,
  TempBankInfo,
  TempBankInfoGroup,
} from './style'
import { Panel, Dropzone, Modal } from '@/presentation/components'
import axios from 'axios'
import { TEN_MB } from '@/main/utils/constants'
import { useDispatch, useSelector } from 'react-redux'
//import { getEmittingBanksRequest } from "@/store/modules/emittingBanks/actions";
import { pushNotification } from '@/store/modules/notification/actions'
import errorHandler from '@/main/utils/errorHandler'
import useService from '@/main/hooks/useService'
import { getResaleBankAccount } from '@/services/companiesServices'
import { Skeleton } from '@/presentation/components/atoms/skeleton'

const PAYMENT_TYPE = {
  TO_PAY: 0,
  TO_RECEIVE: 1,
}

export const PaymentsConciliation = () => {
  const dispatch = useDispatch()
  const storeData = useSelector((state) => state.emittingBanks)
  const [paymentType, setPaymentType] = useState(PAYMENT_TYPE.TO_RECEIVE)
  const [uploadingFile, setUploadingFile] = useState({})
  const [response, setResponse] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    storeData.failure && dispatch(pushNotification(errorHandler(storeData.failure)))
    // eslint-disable-next-line
  }, [storeData])

  useEffect(() => {
    uploadingFile.send && processUpload(uploadingFile)
    // eslint-disable-next-line
  }, [uploadingFile])

  async function processUpload(file) {
    const data = Buffer.from(await file?.file.arrayBuffer())

    try {
      const { data: url } = await api.get('/resale-bff/resales/UploadUrl')

      const fileName = url.split('cnab/')?.[1]?.split('.ret')?.[0]

      await axios.put(`${url}`, data, {
        cancelToken: uploadingFile.cancelToken.token,
        onUploadProgress: (e) => {
          const progress = parseInt(Math.round((e.loaded * 100) / e.total))
          setUploadingFile({
            ...uploadingFile,
            percentage: progress,
            uploading: true,
            send: false,
          })
        },
      })

      const responseData = await api.post(`/settlepayments-service/import/${fileName}`)

      setUploadingFile({
        ...uploadingFile,
        uploaded: true,
        uploading: false,
        send: false,
      })

      return setResponse(responseData?.data)
    } catch (error) {
      setResponse(error.message === 'cancel' ? null : { errors: 1 })
      dispatch(pushNotification(errorHandler(error.response)))

      setUploadingFile({
        ...uploadingFile,
        uploaded: true,
        uploading: false,
        send: false,
      })
    }
  }

  function handleDrop(file) {
    if (file.length < 1) return
    const cancel = axios.CancelToken.source()
    setUploadingFile({
      file: file[0],
      name: file[0].name,
      readeableSize: filesize(file[0].size),
      percentage: 0,
      uploaded: false,
      cancelToken: cancel,
      error: false,
      send: true,
    })
    setResponse(null)
  }

  const [getResaleBankAccountState, getResaleBankAccountRequest] = useService(getResaleBankAccount, {
    silent: true,
  })

  const bankAccount = getResaleBankAccountState.response?.data

  useEffect(() => {
    const itauCode = 341 //unico banco atualmente
    getResaleBankAccountRequest(itauCode)
  }, [])

  const isLoadingAccount = getResaleBankAccountState.loading || !getResaleBankAccountState.response

  return (
    <>
      <Panel>
        <BillType>
          <BillOption
            active={paymentType === PAYMENT_TYPE.TO_RECEIVE}
            onClick={() => setPaymentType(PAYMENT_TYPE.TO_RECEIVE)}
          >
            Contas a Receber
          </BillOption>
        </BillType>
        {!uploadingFile.uploading && !response && (
          <>
            <Wrapper>
              <ExplanationText>Banco que fará a conciliação do pagamento:</ExplanationText>
              <TempBankInfoGroup>
                <TempBankInfo>Código: 341</TempBankInfo>
                <TempBankInfo>
                  Agência:&ensp;
                  {isLoadingAccount ? <Skeleton /> : bankAccount.bankAgency}
                </TempBankInfo>
                <TempBankInfo>Nome: Itaú</TempBankInfo>

                <TempBankInfo>
                  CC:&ensp;
                  {isLoadingAccount ? <Skeleton /> : `${bankAccount.account}-${bankAccount.accountDigit}`}
                </TempBankInfo>
              </TempBankInfoGroup>
            </Wrapper>
            <Dropzone
              accept='.ret'
              onDrop={handleDrop}
              formatsLabel='ret'
              multiple={false}
              maxSize={TEN_MB}
              setError={setError}
            />
          </>
        )}
        {uploadingFile?.uploading && !response && (
          <ProgressPosition>
            <Dropzone.Progress
              uploadingFile={uploadingFile}
              onCancel={() => uploadingFile?.cancelToken?.cancel('cancel')}
            />
          </ProgressPosition>
        )}
        {!!response && <ImportResult response={response} onDrop={handleDrop} />}
      </Panel>
      {error && (
        <Modal onlyConfirm open={error} confirm={() => setError(null)}>
          {error}
        </Modal>
      )}
    </>
  )
}
