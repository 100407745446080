import defaultLogo from '@/presentation/assets/defaultLogo.png'
import { useRef } from 'react'
import { StyledImage } from './styles'

export function Logo({ ...props }) {
  const ref = useRef()
  const handleError = () => {
    ref.current.src = defaultLogo
  }
  return <StyledImage ref={ref} onError={handleError} {...props} />
}
