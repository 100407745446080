import { formatMoney } from '@/main/utils/functionUtils'
import { Box, PortalHOC, Typography } from '@/presentation/components/atoms'
import { useTheme } from '@mui/material'
import { useEffect, useRef, useState } from 'react'

interface CustomTooltipProps {
  active: boolean
  payload: any
  label: string
  containerId: string
  isMonthActive?: boolean
}

export const CustomTooltip = ({ active, payload, label, containerId, isMonthActive = true }: CustomTooltipProps) => {
  const theme = useTheme()
  const [cursorPosition, setCursorPosition] = useState<{ x: number; y: number }>({ y: 0, x: 0 })
  const timeoutRef = useRef(null)

  useEffect(() => {
    const trackMouse = (e) => {
      const x = e.clientX
      const y = e.clientY
      if (!timeoutRef.current) setCursorPosition({ x, y })
      clearTimeout(timeoutRef.current)
      timeoutRef.current = setTimeout(() => {
        setCursorPosition({ x, y })
        timeoutRef.current = null
      }, 50)
    }
    const chartContainerElement = document.getElementById(containerId)
    if (!chartContainerElement) return
    chartContainerElement.addEventListener('mousemove', trackMouse)
    return () => {
      chartContainerElement.removeEventListener('mousemove', trackMouse)
    }
  }, [])

  if (active && payload && payload.length) {
    return (
      <PortalHOC position={cursorPosition} offset={{ x: 10, y: 10 }}>
        <Box
          sx={{
            backgroundColor: theme.palette.common.white,
            padding: '8px',
            borderRadius: '4px',
            boxShadow: '0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 5px 5px -3px rgba(0, 0, 0, 0.20)',
          }}
        >
          <Typography variant='text2'>{`${label}: R$ ${formatMoney(
            Number(payload[0].value) + Number(payload[1].value),
          )}`}</Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              marginTop: '12px',
            }}
          >
            {payload.map((pld: any, index) => {
              const greyShade = theme.palette.grey[index === 0 ? 500 : 300]
              return (
                <Box style={{ display: 'flex', alignItems: 'center', gap: '8px' }} key={`graphPayload${index}`}>
                  <Box
                    sx={{
                      backgroundColor: isMonthActive ? pld.fill : greyShade,
                      height: '8px',
                      width: '20px',
                    }}
                  />
                  <Typography variant='text2'>R$ {formatMoney(Number(pld.value))}</Typography>
                </Box>
              )
            })}
          </Box>
        </Box>
      </PortalHOC>
    )
  }
  return null
}
