import ActionIcon from '@/presentation/components/icon/actionIcon'
import React, { useState } from 'react'
import { CustomTable, IconContainer } from '../styles'
import AddTaxModal from './addTaxModal'
import { Input } from '@/presentation/components'
import { faCheckCircle, faPenToSquare } from '@fortawesome/free-regular-svg-icons'
import { Icon } from '@/presentation/components/atoms/Icon'
import { Tooltip } from '@/presentation/components/atoms/tooltip'

const UseOfBalance = ({ data, setData, disabled }) => {
  const [addTax] = useState(null)
  const [taxToEdit] = useState(null)
  const [editField, setEditField] = useState(false)

  const generateTableHeader = () => {
    return (
      <CustomTable.Tr>
        <CustomTable.Th>Produto</CustomTable.Th>
        <CustomTable.Th>Valor</CustomTable.Th>
        <CustomTable.Th
          style={{
            textAlign: 'right',
            paddingRight: '20px',
          }}
        >
          Ações
        </CustomTable.Th>
      </CustomTable.Tr>
    )
  }

  return (
    <CustomTable>
      <thead>{generateTableHeader()}</thead>
      <tbody>
        <tr>
          <th
            style={{
              textAlign: 'left',
              fontWeight: 'initial',
              color: '#000',
            }}
          >
            Percentual
          </th>
          <th
            style={{
              textAlign: 'left',
              fontWeight: 'initial',
              color: '#000',
            }}
          >
            {!editField ? (
              data.successFee ? (
                `${data.successFee}%`
              ) : (
                '0%'
              )
            ) : (
              <Input.Decimal
                suffix='%'
                required={true}
                placeholder='00,00%'
                value={data?.successFee}
                allowNegative={false}
                onChange={(ev) =>
                  setData({
                    ...data,
                    successFee: ev.target.value.replace('%', ''),
                  })
                }
                style={{ width: '17%', minWidth: '100px' }}
              />
            )}
          </th>
          <th
            style={{
              textAlign: 'right',
              fontWeight: 'initial',
              color: '#000',
              marginRight: '10px',
            }}
          >
            {!disabled && (
              <IconContainer>
                {!editField ? (
                  <Tooltip title='Editar'>
                    <Icon
                      icon={faPenToSquare}
                      onClick={() => setEditField(true)}
                      sx={{ cursor: 'pointer', marginLeft: '8px' }}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip title='Salvar'>
                    <Icon
                      icon={faCheckCircle}
                      onClick={() => setEditField(false)}
                      sx={{ cursor: 'pointer', marginLeft: '8px' }}
                    />
                  </Tooltip>
                )}
              </IconContainer>
            )}
          </th>
        </tr>
      </tbody>

      {(addTax || taxToEdit) && <AddTaxModal toEdit={taxToEdit} />}
    </CustomTable>
  )
}

export default UseOfBalance
