import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Icon,
} from '@/presentation/components/atoms'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import { IconCircularWrapper } from './styles'

interface IReproveDialog {
  open: boolean
  onClose: () => void
  onConfirm: () => void
}

export const ReproveDialog = ({ open, onClose, onConfirm }: IReproveDialog) => {
  return (
    <Dialog open={open}>
      <IconCircularWrapper>
        <Icon icon={faTriangleExclamation} sx={(theme: any) => ({ color: theme.palette.error.main, height: '64px' })} />
      </IconCircularWrapper>
      <DialogTitle id='alert-dialog-title' color='error' sx={{ margin: 'auto', marginBottom: '32px' }}>
        ATENÇÃO
      </DialogTitle>
      <DialogContent sx={{ marginBottom: '32px' }}>
        <DialogContentText id='alert-dialog-description' sx={{ textAlign: 'center' }}>
          Ao negar a solicitação de uma empresa você estará
          <br />
          excluindo todos os dados já cadastrados.
          <br /> <br />
          Caso deseje prosseguir, clique em <strong>&quot;confirmar&quot;</strong>.
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ gap: '32px', justifyContent: 'center' }}>
        <Button variant='outlined' onClick={onClose} color='error' autoFocus>
          Cancelar
        </Button>
        <Button variant='contained' onClick={onConfirm} color='error' autoFocus>
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
