import { Icon } from '@/presentation/components/atoms/Icon'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react'
import { StyledInput } from './styles'
import { SxProps } from '@mui/material'

interface SearchProps {
  value?: string
  searchOnChange?: boolean
  placeholder?: string
  onSearch?: (value: string) => void
  onChange?: (event: any) => void
  sx?: SxProps
  style?: object
  initialValue?: string
}

export const Search = ({
  value,
  initialValue,
  onSearch,
  searchOnChange,
  onChange,
  placeholder,
  ...props
}: SearchProps) => {
  const [internalValue, setInternalValue] = useState<string>(initialValue)

  const searchText = value !== undefined ? value : internalValue
  const setSearchText = value !== undefined ? onChange : setInternalValue

  const handleSearchIconClick = () => {
    onSearch(searchText)
  }

  const handleEnter = (e) => {
    if (e.key === 'Enter') {
      onSearch(searchText)
    }
  }

  return (
    <StyledInput
      value={value}
      onChange={(e) => {
        setSearchText(e.target.value)
        if (!value && onChange) onChange(e.target.value)
        if (searchOnChange) onSearch(e.target.value)
      }}
      placeholder={placeholder}
      startAdornment={<Icon icon={faMagnifyingGlass} onClick={handleSearchIconClick} />}
      onKeyDown={handleEnter}
      {...props}
    />
  )
}
