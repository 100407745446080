import styled from 'styled-components'

export const ReadFieldContainer = styled.span``

export const ReadFieldTitle = styled.span`
  font-weight: var(--font-weight-semi-bold);
  white-space: nowrap;
`

export const ReadFieldValue = styled.span`
  display: block;
  margin-top: 8px;
  font-size: 0.875rem;
  white-space: nowrap;
`
