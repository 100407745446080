import React from 'react'
import { Col, Row } from 'react-grid-system'
import { useForm } from 'react-hook-form'
import { Button, Input, ModalV2, Spinner } from '@/presentation/components'
import useDownloadReport from '@/main/hooks/useDownloadReport'
import { getCardOrdersReport } from '@/services/reportsServices'
import { CustomContainer, InputWrapper, ModalTitle } from '../styles'
import { CheckBoxWrapper, Label } from './styles'

function CardOrderReportFilter({ open, onClose }) {
  const [downloadState, download] = useDownloadReport(async (query) => {
    try {
      const response = await getCardOrdersReport(query)

      return response
    } catch (e) {
      return
    }
  })

  const { register, handleSubmit } = useForm({
    mode: 'onChange',
  })

  const onSubmit = (data) => {
    if (!data.cardOrder) return
    download(`Relatório de pedido de cartão ${data.cardOrder}.pdf`, data.cardOrder)
  }

  return (
    <ModalV2 open={open} onClose={onClose}>
      {downloadState.loading ? (
        <Spinner />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} id='cardOrderReportFilterForm'>
          <CustomContainer fluid>
            <Row>
              <Col>
                <ModalTitle> Relatório de Pedido de Cartão</ModalTitle>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <InputWrapper>
                  <Label>N° do Pedido</Label>
                  <Input type='number' placeholder='Insira o número do pedido' {...register('cardOrder')} />
                </InputWrapper>
              </Col>
            </Row>
            <Row>
              <Col sm={2}>
                <CheckBoxWrapper>
                  <Input.CheckBox checked controlled />
                  <span>PDF</span>
                </CheckBoxWrapper>
              </Col>
            </Row>
            <Row justify='end'>
              <Col sm='content'>
                <Button>Gerar Relatório</Button>
              </Col>
            </Row>
          </CustomContainer>
        </form>
      )}
    </ModalV2>
  )
}

export default CardOrderReportFilter
