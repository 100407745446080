import { Theme } from '@mui/material'
import { Box, Card, Skeleton, Typography } from '@/presentation/components/atoms'
import { YearAndMonth } from '../../dashboard'
import { OrdersPieChart } from './components/ordersPieChart/ordersPieChart'
import { Select } from '@/presentation/components/molecules'
import { useState } from 'react'
import { IUseDashboardReturn } from '../../hooks/useDashboardInterfaces'

interface OrdersChartCardProps {
  data: IUseDashboardReturn['dashboardData']
  selectedDate: YearAndMonth
  isLoading?: boolean
}

export const OrdersChartCard = ({ data, selectedDate, isLoading }: OrdersChartCardProps) => {
  const isMonth = selectedDate.month !== ''

  const selectedMonthData = data?.ordersCounts?.find((monthData) => {
    return monthData.month === selectedDate.month
  })

  const [selectedFilterOption, setSelectedFilterOption] = useState<'total' | 'firstCopy' | 'secondCopy' | 'recharge'>(
    'total',
  )
  const ordersTotal = selectedMonthData?.[selectedFilterOption + 'Sum']
  return (
    <Card sx={{ padding: '16px', minHeight: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant='label3' sx={(theme: Theme) => ({ color: theme.palette.grey[700], whiteSpace: 'nowrap' })}>
          Pedidos - {isMonth ? 'mês/' : ''}ano
        </Typography>
        <Select
          sx={{
            '& .MuiOutlinedInput-notchedOutline': { border: 0 },
            width: '120px',
            marginLeft: 'auto',
          }}
          value={selectedFilterOption}
          onChange={(e: any) => {
            setSelectedFilterOption(e.target.value)
          }}
          variant='standard'
          disableUnderline
          options={[
            { value: 'total', label: 'Todos' },
            { value: 'firstCopy', label: ' 1ª Via' },
            { value: 'secondCopy', label: ' 2ª Via' },
            { value: 'recharge', label: 'Recarga' },
          ]}
        />
      </Box>
      {isLoading || !data?.ordersCounts ? (
        <Skeleton variant='rounded' height='248px' sx={{ marginTop: '16px' }} />
      ) : (
        <>
          <Box sx={{ display: 'flex', gap: '32px', flexWrap: 'wrap' }}>
            <Typography variant='h2'>
              {`${ordersTotal} ${ordersTotal > 1 ? 'pedidos' : 'pedido'} - `}
              {isMonth && `${selectedDate.month}/`}
              {selectedDate.year}
            </Typography>
          </Box>
          <Typography variant='text2' sx={{ margin: '8px 0', display: 'inline-block' }}>
            Abaixo você pode ver detalhes do número de pedidos, seus tipos e formas de pagamento.
          </Typography>
          <Box
            sx={{ overflow: 'auto', padding: '8px', position: 'relative', maxHeight: '600px', minHeight: '200px' }}
            id='ordersPieContainer'
          >
            <OrdersPieChart
              data={selectedMonthData[selectedFilterOption]}
              ordersTotal={ordersTotal}
              ordersFilter={selectedFilterOption}
            />
          </Box>
        </>
      )}
    </Card>
  )
}
