import React, { useState } from 'react'
import { faChevronDown, faPlus } from '@fortawesome/free-solid-svg-icons'
import { Header, SpoilerContainer, Wrapper } from './style'
import { Icon } from '../atoms/Icon'
import { Tooltip } from '../atoms/tooltip'

function Spoiler({ label, children, add, click }) {
  const [isOpened, setIsOpened] = useState(false)

  return (
    <>
      <Header isOpened={isOpened}>
        <Wrapper onClick={() => setIsOpened(!isOpened)}>
          <p>{label}</p>
        </Wrapper>

        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          {add && (
            <Tooltip title='Adicionar Taxa'>
              <Wrapper
                style={{
                  border: '1px solid black',
                  borderRadius: '50%',
                  height: '24px !important',
                  width: '24px',
                }}
              >
                <Icon icon={faPlus} onClick={click} />
              </Wrapper>
            </Tooltip>
          )}
          <Icon icon={faChevronDown} sx={{ height: '24px !important' }} onClick={() => setIsOpened(!isOpened)} />
        </div>
      </Header>
      <SpoilerContainer isOpened={isOpened}>{children}</SpoilerContainer>
    </>
  )
}

export default Spoiler
