import { Divider, Grid, Typography } from '@/presentation/components/atoms'
import { COMPANY_DOCUMENT_TYPE } from '@/main/utils/constants'
import { FileUpload } from '@/presentation/components/molecules'
import { sortArrayByNestedValue } from '@/main/utils/functions'

export const CompanyDocuments = ({ documents }) => {
  return (
    documents?.length > 0 && (
      <>
        <Divider overflow sx={{ margin: '32px 0 16px 0' }} />
        <Grid container spacing='16px'>
          <Grid item xs='auto'>
            <Typography variant='subtitle1'>Documentos</Typography>
          </Grid>
          {(() => {
            const orderedDocs = sortArrayByNestedValue({
              array: documents,
              getValue: (option) => option.documentType,
            })
            return orderedDocs.map((document, index: number) => (
              <Grid item key={'companyDoc_' + index} xs={12}>
                <FileUpload
                  label={`${index + 1}. ${COMPANY_DOCUMENT_TYPE[document.documentType]}`}
                  accept={{
                    'image/png': ['.png', '.xs'],
                    'text/html': ['.html', '.htm'],
                  }}
                  initialValue={
                    new File([''], document.fileName, {
                      type: 'text/plain',
                    })
                  }
                  fileUrl={document.fileLink}
                  isEditable={false}
                />
              </Grid>
            ))
          })()}
        </Grid>
      </>
    )
  )
}
