import React, { useEffect, useRef, useState } from 'react'
import { BiCoinStack } from 'react-icons/bi'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import { Table, Spacing, OrderIcon } from '@/presentation/components'
import {
  Subtitle,
  StatementIcon,
  PageSubTitle,
  Centralizer,
  BalanceValue,
  BalanceVisibilitySelector,
  Balance,
  BalanceTableFooter,
  CustomTr,
  CustomTd,
} from './styles'
import 'moment/locale/pt-br'
import { Col, Container, Row, useScreenClass } from 'react-grid-system'
import usePaginator from '@/main/hooks/usePaginator'
import useService from '@/main/hooks/useService'
import { getCompaniesWallets } from '@/services/companiesServices'
import { applyMask, dateToApiStandards, formatMoney } from '@/main/utils/functionUtils'
import DatePicker from './datePicker'
import { Search } from '@/presentation/components/molecules/search'

export const DigitalAccountExtract = () => {
  const paginator = usePaginator()
  const [wallets, setWallets] = useState([])
  const [motivaCashTotalVisibility, setMotivaCashTotalVisibility] = useState(false)
  const motivaCashTotal = useRef(0)

  const [fetchWalletsState, fetchWalletsRequest] = useService(getCompaniesWallets, {
    onCompleted: (response) => {
      setWallets(response.data)
      const totalCash = response.data.reduce((total, actual) => (total += actual.balance), 0)
      motivaCashTotal.current = totalCash
    },
  })

  useEffect(() => {
    if (paginator.data.doRequest) {
      fetchWalletsRequest(paginator.buildQuery())
      paginator.preventRefresh()
    }
    // eslint-disable-next-line
  }, [paginator])

  const screenClass = useScreenClass()
  return (
    <>
      <PageSubTitle>
        <StatementIcon>
          <BiCoinStack />
        </StatementIcon>
        <Subtitle>Extrato Conta Digital</Subtitle>
      </PageSubTitle>

      <Container fluid style={{ padding: '0' }}>
        <Row>
          <Col sm='content'>
            <DatePicker
              initialDate={new Date()}
              onChange={(e) =>
                paginator.setFilters({
                  lastDate: dateToApiStandards(e),
                })
              }
            />
          </Col>
        </Row>
        <Spacing top='32px' />
        <Row justify={['xs', 'sm'].includes(screenClass) ? 'center' : 'between'} align='center'>
          <Col xs={12} sm={12} md={7} lg={6} xl={5}>
            <Search
              placeholder='Buscar na tabela abaixo...'
              onSearch={(value) =>
                paginator.setFilters({
                  filterValue: value.replaceAll('/', '').replaceAll('.', '').replaceAll('-', ''),
                  filterProps: 'cnpj,tradeName,code',
                })
              }
            />
          </Col>
          <Col sm='content'>
            <Balance>
              Saldo Total:
              <BalanceValue visible={motivaCashTotalVisibility}>
                {motivaCashTotalVisibility && formatMoney(motivaCashTotal.current)}
              </BalanceValue>
              <BalanceVisibilitySelector>
                {!motivaCashTotalVisibility ? (
                  <AiOutlineEye onClick={() => setMotivaCashTotalVisibility(true)} />
                ) : (
                  <AiOutlineEyeInvisible onClick={() => setMotivaCashTotalVisibility(false)} />
                )}
              </BalanceVisibilitySelector>
            </Balance>
          </Col>
        </Row>
        <Spacing top='32px' />
        <Row>
          <Col>
            <Table loading={fetchWalletsState.loading}>
              <thead>
                <Table.Tr>
                  <Table.Th>
                    <Centralizer>
                      Cod.Cliente
                      <OrderIcon
                        clickUp={() => paginator.setOrder('code')}
                        clickDown={() => paginator.setOrder('code', false)}
                      />
                    </Centralizer>
                  </Table.Th>
                  <Table.Th>
                    <Centralizer>
                      Empresa
                      <OrderIcon
                        clickUp={() => paginator.setOrder('tradeName')}
                        clickDown={() => paginator.setOrder('tradeName', false)}
                      />
                    </Centralizer>
                  </Table.Th>
                  <Table.Th>
                    <Centralizer>
                      CNPJ
                      <OrderIcon
                        clickUp={() => paginator.setOrder('cnpj')}
                        clickDown={() => paginator.setOrder('cnpj', false)}
                      />
                    </Centralizer>
                  </Table.Th>
                  <Table.Th>
                    <Centralizer>
                      Saldo
                      <OrderIcon
                        clickUp={() => paginator.setOrder('balance')}
                        clickDown={() => paginator.setOrder('balance', false)}
                      />
                    </Centralizer>
                  </Table.Th>
                </Table.Tr>
              </thead>
              <tbody>
                {wallets.map((companyWallet) => (
                  <Table.Tr key={companyWallet.code}>
                    <Table.Td>{companyWallet.code}</Table.Td>
                    <Table.Td>{companyWallet.tradeName}</Table.Td>
                    <Table.Td>{applyMask('##.###.###/####-##', companyWallet.cnpj)}</Table.Td>
                    <Table.Td>{formatMoney(companyWallet.balance)}</Table.Td>
                  </Table.Tr>
                ))}
                <CustomTr colored>
                  <CustomTd colSpan='4'>
                    <BalanceTableFooter>
                      Saldo Total:
                      <BalanceValue visible={motivaCashTotalVisibility}>
                        {motivaCashTotalVisibility && formatMoney(motivaCashTotal.current)}
                      </BalanceValue>
                      <BalanceVisibilitySelector>
                        {!motivaCashTotalVisibility ? (
                          <AiOutlineEye onClick={() => setMotivaCashTotalVisibility(true)} />
                        ) : (
                          <AiOutlineEyeInvisible onClick={() => setMotivaCashTotalVisibility(false)} />
                        )}
                      </BalanceVisibilitySelector>
                    </BalanceTableFooter>
                  </CustomTd>
                </CustomTr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </>
  )
}
