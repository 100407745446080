import { styled } from '@mui/material'
import { NavLink } from 'react-router-dom'

export const StyledNavbarItem = styled(NavLink)(({ theme }) => ({
  display: 'flex',
  gap: 8,
  padding: '12px 20px',
  color: theme.palette.common.white,
  fontSize: '1.125rem',
  whiteSpace: 'nowrap',
  transition: `opacity ${theme.transitions.duration.short}ms ${theme.transitions.easing.easeInOut}`,
  position: 'relative',
  opacity: 0.4,
  '&:hover, &.active': {
    opacity: 1,
  },
}))
