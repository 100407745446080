import { ReactElement, useRef } from 'react'
import _ from 'lodash'
import { Box } from '@/presentation/components/atoms'
import { createPortal } from 'react-dom'

interface PortalHOCProps {
  children: ReactElement
  position: { x: number; y: number }
  offset?: { x: number; y: number }
  zIndex?: number
}

export const PortalHOC = ({ children, position, offset = { x: 0, y: 0 }, zIndex = 50 }: PortalHOCProps) => {
  const { current: id } = useRef<string>(`portalHocWrapper_${_.uniqueId()}`)

  const avoidDisappear = () => {
    const targetPosition = {
      x: position.x + offset.x,
      y: position.y + offset.y,
    }
    const avoidEdgesMargin = 10

    const wrapperElement = document.getElementById(id)
    if (!wrapperElement) return targetPosition
    const wrapperRects = wrapperElement.getClientRects()
    const screenY = window.innerHeight
    const screenX = window.innerWidth

    if (screenY - targetPosition.y < wrapperRects[0].height)
      targetPosition.y -= wrapperRects[0].height + avoidEdgesMargin
    if (screenX - targetPosition.x < wrapperRects[0].width) targetPosition.x -= wrapperRects[0].width + avoidEdgesMargin

    return targetPosition
  }

  const targetPosition = avoidDisappear()
  if (!targetPosition) return null
  return createPortal(
    <Box
      sx={{
        position: 'fixed',
        top: targetPosition.y,
        left: targetPosition.x,
        transition: 'all .25s ease-out',
        zIndex: zIndex,
      }}
      id={id}
    >
      {children}
    </Box>,
    document.body,
  )
}
