import { useState } from 'react'
import { atomWithHash } from 'jotai-location'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import { Button, Grid, Icon } from '@/presentation/components/atoms'
import { AutoTable, Search, TableFooterPagination } from '@/presentation/components/molecules'
import { useCompanyCardsOrders } from '@/main/hooks/useCompanyCardsOrders'
import { useFilter, usePagination } from '@/main/hooks'
import OrdersFilter from './filters/ordersFilter'
import { companyCardOrderTableConfiguration } from './utils/companyCardOrderTableConfiguration'

const filterAtom = atomWithHash<object>('companyCardsOrdersFilter', null)
const paginationAtom = atomWithHash<object>('companyCardsOrdersPagination', { sort: 'orderDate', desc: true })

export interface ICardOrdersColumns {
  code: string
  cardsQuantity: string | number
  orderDate: string
  dueDate: string
  cardCopy: string | number
  status: string | number
  benefits: any[]
}

const CompanyCardOrders = () => {
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const { filter, resetFilter, updateFilter } = useFilter({ filterAtom })
  const { pagination, goToPage, setPageSize } = usePagination({ paginationAtom })
  const { orders, isLoading } = useCompanyCardsOrders({ filter, pagination })
  const ordersData = orders?.data

  const columnsConfiguration = companyCardOrderTableConfiguration({ updateFilter })

  return (
    <>
      <Grid container spacing='16px' sx={{ padding: '16px' }}>
        <Grid item xs={12} md={6} lg={5}>
          <Search
            onSearch={(value) => {
              goToPage(1)
              updateFilter({
                filterValue: value,
                filterProps: 'code',
              })
            }}
            placeholder='Buscar por código '
          />
        </Grid>
        <Grid item xs={12} md='auto'>
          <Button
            variant='outlined'
            onClick={() => setIsFilterOpen(true)}
            fullWidth
            startIcon={<Icon icon={faFilter} />}
          >
            Filtrar
          </Button>
        </Grid>
        <Grid item xs={12}>
          <AutoTable<ICardOrdersColumns> columns={columnsConfiguration} rows={ordersData} isLoading={isLoading} />
        </Grid>
      </Grid>
      <TableFooterPagination
        totalItems={orders.totalItens}
        onRowsPerPageChange={(e) => setPageSize(e.target.value)}
        rowsPerPage={orders.pageSize}
        totalPages={orders.totalPages}
        page={orders.pageNumber}
        onPageChange={(e, value) => goToPage(value)}
      />
      {isFilterOpen && (
        <OrdersFilter
          actualFilters={filter}
          open={isFilterOpen}
          close={() => setIsFilterOpen(false)}
          resetFilters={() => resetFilter()}
          confirm={(filters) => {
            updateFilter(filters)
            setIsFilterOpen(false)
          }}
        />
      )}
    </>
  )
}

export default CompanyCardOrders
