import React, { useState, forwardRef } from 'react'
import './style.css'

const Toggle = forwardRef(({ activeText, inactiveText, active, variant, ...props }, ref) => {
  const [isActive, setIsActive] = useState(active)

  function handleOnChange() {
    setIsActive(!isActive)
  }

  return (
    <label className='toggle'>
      <input ref={ref} type='checkbox' checked={isActive} onChange={handleOnChange} {...props} />
      <span className={`slider ${variant}`}>{isActive ? activeText : inactiveText}</span>
    </label>
  )
})

export default Toggle
