import { Grid } from '@/presentation/components/atoms'
import { YearAndMonth } from '../../dashboard'
import { formatMoney } from '@/main/utils/functions'
import { BalanceProjectionChart } from './components/balanceProjectionChart'

export interface BalanceProjectionGridProps {
  data: any
  selectedDate: YearAndMonth
  isMonth: boolean
  isLoading?: boolean
}

export const BalanceProjectionGrid = ({ data, selectedDate, isLoading, isMonth }: BalanceProjectionGridProps) => {
  return (
    <Grid container spacing='16px'>
      <Grid item xs={12} md={4}>
        <BalanceProjectionChart
          title='Economia gerada'
          subtitle='Valores referentes à economia gerada através da Projeção de saldo.'
          data={data?.lastMonthEconomy}
          selectedDate={selectedDate}
          isLoading={isLoading || !data?.lastMonthEconomy}
          isMonth={isMonth}
          valueDecorator={(value: string | number) => 'R$ ' + formatMoney(Number(value))}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <BalanceProjectionChart
          title='Pedidos com projeção'
          subtitle='Valores referentes à qtd. de pedidos com Projeção de saldo.'
          data={data?.ordersWithProjection}
          selectedDate={selectedDate}
          isLoading={isLoading || !data?.ordersWithProjection}
          isMonth={isMonth}
          valueDecorator={(value: string | number) => value + ' pedidos'}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <BalanceProjectionChart
          title='Média de economia'
          subtitle='Porcentagem da relação Valor dos Pedidos com P.S com Economia Gerada.'
          data={data?.averageSave}
          selectedDate={selectedDate}
          isLoading={isLoading || !data?.averageSave}
          isMonth={isMonth}
          valueDecorator={(value: string | number) => value + '%'}
        />
      </Grid>
    </Grid>
  )
}
