import styled from 'styled-components'

export const Title = styled.p`
  font-size: 1.5rem;
  font-weight: var(--font-weight-bold);
  color: ${({ color }) => color};
  text-align: center;
  margin: 32px 0;
  text-transform: uppercase;
`

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 124px;
  width: 124px;
  border-radius: 50%;
  background-color: #f5f6fa;
  margin: auto;
  margin-top: 40px;

  svg {
    height: 78px;
    width: 78px;
    color: ${({ color }) => color};
  }
`

export const ModalContent = styled.div`
  font-size: 1.125rem;
  text-align: center;
  max-width: 530px;
  height: 120px;
  color: #2c2d5c;
  margin: 20px auto 32px auto;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  button {
    max-width: 180px;
  }
  button + button {
    margin-left: 32px;
  }
`
