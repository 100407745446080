import { Notification } from '@/presentation/components'
import { useDispatch } from 'react-redux'
import { StyledBox, StyledPaper } from './styles'
import { Controller, useForm } from 'react-hook-form'
import useService from '@/main/hooks/useService'
import { postResetPassword } from 'services/auth'
import successHandler from '@/main/utils/successHandler'
import { Spinner } from '@/presentation/components'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Typography, Icon, Link, Button } from '@/presentation/components/atoms'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { validateEmail } from 'validations-br'
import { pushNotification } from 'store/modules/notification/actions'
import { TextField } from '@/presentation/components/molecules'

export const PasswordReset = () => {
  const { resaleSite } = useParams()
  const [searchParams] = useSearchParams()
  const isKeycloak = searchParams.get('isKeycloak')
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [resetPasswordState, resetPasswordRequest] = useService(postResetPassword, {
    onCompleted: () => {
      dispatch(
        pushNotification(
          successHandler('Acesse seu email de cadastro e entre no link enviado para cadastrar uma nova senha'),
        ),
      )
      navigate(resaleSite ? `/${resaleSite}/login` : '/login')
    },
  })

  const { control, handleSubmit } = useForm({
    defaultValues: {
      email: '',
    },
    mode: 'onChange',
  })
  const onSubmit = (data) => {
    resetPasswordRequest({ ...data, site: resaleSite, isKeycloak: Boolean(isKeycloak) })
  }

  const handleGoBack = (e) => {
    e.preventDefault()
    navigate('/')
  }
  return (
    <>
      <Notification />
      <StyledBox>
        <StyledPaper>
          {resetPasswordState.loading ? (
            <Spinner.Box>
              <Spinner />
            </Spinner.Box>
          ) : (
            <>
              <Typography
                variant='h1'
                sx={() => ({
                  textAlign: 'center',
                })}
              >
                Recuperar senha
              </Typography>
              <Typography variant='body1' sx={{ textAlign: 'center' }}>
                Informe abaixo seu e-mail cadastrado na plataforma para recuperar sua senha.
              </Typography>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                  control={control}
                  name='email'
                  rules={{
                    required: 'Campo requerido',
                    validate: {
                      isEmailInValid: (value) => validateEmail(value) || 'Esse não é um e-mail valido',
                    },
                  }}
                  render={({ field: { value, onChange }, fieldState: { error, isDirty } }) => (
                    <TextField
                      label='E-mail'
                      placeholder='exemplo@mail.com'
                      startAdornment={<Icon icon={faEnvelope} />}
                      value={value}
                      helperText={error?.message}
                      onChange={onChange}
                      error={!!error}
                      success={!error && isDirty}
                    />
                  )}
                />
                <Link to={'/'}>Acessar minha conta</Link>
                <Button variant='contained' type='submit'>
                  Recuperar senha
                </Button>
                <Button variant='outlined' onClick={handleGoBack}>
                  Voltar
                </Button>
              </form>
            </>
          )}
        </StyledPaper>
      </StyledBox>
    </>
  )
}
