import React from 'react'
import _ from 'lodash'
import { faBus, faGasPump, faShoppingCart, faUtensils } from '@fortawesome/free-solid-svg-icons'
import { BENEFIT_TYPE } from '@/main/utils/constants'
import { Box } from '@/presentation/components/atoms'
import { IconWithPopper } from '@/presentation/components/molecules'
import { BenefitTitle, CardInfo, CardsList } from './styles'

const benefitStyle = {
  1: {
    title: BENEFIT_TYPE[BENEFIT_TYPE.TRANSPORT],
    icon: faBus,
    color: 'benefits.transport.main',
  },
  2: {
    title: BENEFIT_TYPE[BENEFIT_TYPE.FOOD],
    icon: faShoppingCart,
    color: 'benefits.food.main',
  },
  3: {
    title: BENEFIT_TYPE[BENEFIT_TYPE.SNACK],
    icon: faUtensils,
    color: 'benefits.snack.main',
  },
  4: {
    title: BENEFIT_TYPE[BENEFIT_TYPE.FUEL],
    icon: faGasPump,
    color: 'benefits.fuel.main',
  },
  5: {
    title: BENEFIT_TYPE[BENEFIT_TYPE.TRANSPORT_PAPER],
    icon: faBus,
    color: 'benefits.transport.main',
  },
}

interface CardProps {
  cardName: string
  cardNumber: string | number
}

interface BenefitProps {
  benefitType: string | number
  cards: Array<CardProps>
}

interface BenefitsIconsProps {
  benefits: Array<BenefitProps>
  noCardMessage?: string | false
}

export const BenefitsIcons = ({ benefits, noCardMessage = 'Sem cartão' }: BenefitsIconsProps) => {
  if (!Array.isArray(benefits)) return null
  const benefitsToRender = benefits.map((benefit) => {
    const styleToUse = benefitStyle[benefit.benefitType]
    return (
      <React.Fragment key={_.uniqueId()}>
        <IconWithPopper
          icon={styleToUse.icon}
          color={styleToUse.color}
          placement='bottom-start'
          sx={{ marginTop: '24px' }}
        >
          <BenefitTitle>{BENEFIT_TYPE[benefit.benefitType]}</BenefitTitle>
          <CardsList>
            {benefit.cards.map((card) => (
              <CardInfo key={Math.random()}>
                {card.cardName} {(card.cardNumber || noCardMessage) && ' - '} {card.cardNumber || noCardMessage}
              </CardInfo>
            ))}
          </CardsList>
        </IconWithPopper>
      </React.Fragment>
    )
  })

  return <Box sx={{ display: 'flex', gap: '18px', justifyContent: 'center' }}>{benefitsToRender}</Box>
}
