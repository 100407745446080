import styled from 'styled-components'

export const BillType = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 45px;
  width: 390px;
  overflow: hidden;
`

export const BillOption = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 50%;
  border-bottom: ${({ active }) => (active ? '3px solid var(--color-primary-06)' : '3px solid transparent')};
  font-size: 1rem;
  font-weight: var(--font-weight-semi-bold);
  color: var(--color-primary-06);
  cursor: pointer;
`

export const Wrapper = styled.div`
  margin: 0 auto 32px auto;
  width: 50%;

  > p {
    margin-bottom: 32px;
  }

  > label {
    display: block;
    margin-bottom: 8px;
  }

  div {
    max-width: 465px;
  }
`

export const ExplanationText = styled.p`
  margin: auto;
  margin-top: 40px;
  line-height: 30px;
  letter-spacing: 0.29px;
  text-align: center;
  + p {
    margin-top: 0px;
    margin-bottom: 40px;
  }
`

export const TempBankInfoGroup = styled.div`
  width: 250px;
  margin: auto;
`

export const TempBankInfo = styled.span`
  display: inline-flex;
  width: 120px;
  white-space: nowrap;
  + span {
    margin-top: 8px;
  }
  & .MuiSkeleton-root {
    flex: 1;
  }
`

export const ProgressPosition = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 700px;
`
