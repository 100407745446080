import { Box } from '@/presentation/components/atoms'
import { styled } from '@mui/material'

export const DropZone = styled(Box)(({ theme, isRejected }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  gap: '8px',
  marginBottom: '16px',
  cursor: 'pointer',
  height: '148px',
  borderRadius: '4px',
  border: `dashed 1px ${isRejected ? theme.palette.error.light : theme.palette.grey[200]}`,
  backgroundColor: isRejected ? theme.palette.error.light + '1A' : 'initial',
  '&:hover': {
    backgroundColor: theme.palette.primary.light + '1A',
    border: `dashed 1px ${theme.palette.primary.light}`,
  },

  '& span': {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
  },
}))

export const DropZoneIconWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  height: '40px',
  width: '40px',
  backgroundColor: theme.palette.primary.light + '33',
  '& svg': {
    height: '24px',
    width: '24px',
    color: theme.palette.primary.main,
  },
}))

export const FileControl = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[200]}`,
  borderRadius: '4px',
}))

export const StyledFileInfo = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  padding: '16px',
  height: '76px',
  borderRadius: '4px',
  borderBottom: `1px solid ${theme.palette.grey[200]}`,
}))
