import { ReactElement } from 'react'
import { BrokerOptionsDialog } from '../brokerOptionsDialog/brokerOptionsDialog'
import { ProjectionResponseDialog } from '../projectionResponseDialog/projectionResponseDialog'
import { UploadDialog } from '../uploadDialog/uploadDialog'
import { useDialogs } from '@/main/hooks'

type DialogOption = {
  [key: string]: ({ props, onClose, open }: { props: any; onClose: any; open: boolean }) => ReactElement
}

const dialogs: DialogOption = {
  attachBrokerOrderSlip: ({ props, onClose, open }) => (
    <UploadDialog
      isOpen={open}
      onClose={onClose}
      title='Anexar boleto gerado'
      subtitle='Anexe o boleto para que o financeiro efetue o pagamento.'
      accept={{
        'application/pdf': ['.pdf'],
      }}
      {...props}
    />
  ),
  attachBrokerOrderPaymentProof: ({ props, onClose, open }) => (
    <UploadDialog
      isOpen={open}
      onClose={onClose}
      title='Comprovante de pagamento'
      subtitle='Para confirmar o pagamento é necessário que faça o upload do comprovante'
      helpText='Após confirmação do pagamento não será mais possível exportar o layout.'
      accept={{
        'application/pdf': ['.pdf'],
      }}
      {...props}
    />
  ),
  brokerOrderOptions: ({ props, onClose, open }) => <BrokerOptionsDialog onClose={onClose} isOpen={open} {...props} />,
  brokerOrderUploadProjection: ({ props, onClose, open }) => (
    <UploadDialog
      isOpen={open}
      onClose={onClose}
      title='Projeção de saldo'
      subtitle='Por favor, envie o arquivo para cálculo de projeção de saldo'
      accept={{
        'application/xls': ['.xls', '.xlsx'],
      }}
      {...props}
    />
  ),
  brokerProjectionResponse: ({ props, onClose, open }) => (
    <ProjectionResponseDialog isOpen={open} onClose={onClose} {...props} />
  ),
}

export type AvailableDialogs = keyof typeof dialogs

export const DialogsContainer = () => {
  const { actualDialog, setDialog } = useDialogs()

  if (!actualDialog.dialog) return null

  return dialogs[actualDialog.dialog]({
    props: actualDialog.props,
    onClose: () => setDialog({ dialog: null, props: {} }),
    open: true,
  })
}
