import styled from 'styled-components'

export const PositionRef = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`

export const Container = styled.div`
  position: absolute;
  top: ${({ position }) => `${position.top}px`};
  left: ${({ position }) => `${position.left}px`};
  background-color: var(--color-primary-05);
  border-radius: 0.5rem;
  box-shadow: 0 0.125rem 0.3125rem var(--color-secundary-04);
  color: var(--color-primary-04);
  text-align: left;
  overflow: hidden;
  height: ${({ height }) => `${height}px`};
  transition: height 0.26s ease-in-out;
  z-index: 2000;
  min-width: ${({ position }) => `${position.fieldSize}px`};
`
