import { useState } from 'react'
import { Content, PageHeader } from '@/presentation/components'
import { Grid } from '@/presentation/components/atoms/grid'
import { Option, Tab, Tabs } from '@/presentation/pages/configurations/styles'
import { Paper } from '@/presentation/components/atoms/paper'
import { Users } from '@/presentation/pages/configurations/utils/icons/Users'
import { Arrow } from '@/presentation/pages/configurations/utils/icons/Arrow'
import { Platform } from '@/presentation/pages/configurations/utils/icons/Platform'
import { tabs } from '@/presentation/pages/configurations/utils/tabs'
import { Box } from '@/presentation/components/atoms'
import { useAtomWithStorageObserver } from '@/main/hooks'
import { AccountPersistentStore } from '@/main/store'

export const Configurations = () => {
  const [open, setOpen] = useState<'users' | 'platform'>('platform')
  const [selectedTab, setSelectedTab] = useState(3)

  const [account] = useAtomWithStorageObserver({
    atomWithStorage: AccountPersistentStore,
    storageName: 'account',
  })

  return (
    <Content>
      <Paper>
        <PageHeader>Configurações</PageHeader>
        <Grid container sx={{ display: 'flex', flexWrap: 'nowrap' }}>
          <Box sx={{ maxWidth: '290px', padding: '0 16px' }}>
            <Tabs>
              <ul>
                {account?.resale?.allowUserMenu && account?.user?.level === 'admin' && (
                  <>
                    <Tab onClick={() => setOpen('users')} open={open === 'users'}>
                      <div>
                        <Users />
                        <p>Usuários</p>
                      </div>
                      <Arrow />
                    </Tab>
                    {open === 'users' && (
                      <>
                        <Option onClick={() => setSelectedTab(1)} selected={selectedTab === 1}>
                          Usuários cadastrados
                        </Option>
                        <Option onClick={() => setSelectedTab(2)} selected={selectedTab === 2}>
                          Perfis de acesso
                        </Option>
                      </>
                    )}
                  </>
                )}
                <Tab onClick={() => setOpen('platform')} open={open === 'platform'}>
                  <div>
                    <Platform />
                    <p>Plataforma</p>
                  </div>
                  <Arrow />
                </Tab>
                {open === 'platform' && (
                  <Option selected={selectedTab === 3} onClick={() => setSelectedTab(3)}>
                    Personalização
                  </Option>
                )}
              </ul>
            </Tabs>
          </Box>
          <Box sx={{ marginTop: 0, width: 'calc(100% - 290px)' }}>
            <div style={{ width: '100%' }}>{tabs[selectedTab]}</div>
          </Box>
        </Grid>
      </Paper>
    </Content>
  )
}
