import { styled } from '@mui/material'

export const BenefitTitle = styled('ul')(({ theme }) => ({
  marginBottom: '20px',
  color: theme.palette.grey[700],
  fontWeight: theme.typography.fontWeightSemiBold,
}))

export const CardsList = styled('ul')(({ theme }) => ({
  padding: '0 20px',
  color: theme.palette.grey[700],
  fontWeight: theme.typography.fontWeightSemiBold,
}))

export const CardInfo = styled('li')(() => ({
  listStyleType: 'disc',
  fontSize: '1rem',
  marginLeft: '8px',
  '& + li': {
    marginTop: '8px',
  },
}))
