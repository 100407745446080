import styled from 'styled-components'
import { Container as RGSContainer } from 'react-grid-system'

export const Container = styled(RGSContainer)`
  > div + div {
    margin-top: 32px;
  }
`

export const Icons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
