import { useNavigate } from 'react-router-dom'
import { faCog, faGlobe, faRightFromBracket } from '@fortawesome/free-solid-svg-icons'
import { Menu } from '@/presentation/components/atoms/menu'
import { MenuItem } from '@/presentation/components/atoms/menuItem'
import { Divider } from '@/presentation/components/atoms/divider'
import { Icon } from '@/presentation/components/atoms/Icon'
import { StyledNavLink } from './styles'
import { AccountPersistentStore } from '@/main/store/account'
import { useAtomWithStorageObserver } from '@/main/hooks'

const destinations = [
  {
    pathname: '/configuracoes',
    icon: faCog,
    displayName: 'Configurações',
  },
  {
    pathname: '/parametros_gerais',
    icon: faGlobe,
    displayName: 'Parâmetros gerais',
  },
]

export function UserMenu({ anchorEl, id, open, onClose, onClick }) {
  const navigate = useNavigate()
  const [account, setAccount] = useAtomWithStorageObserver({
    atomWithStorage: AccountPersistentStore,
    storageName: 'account',
  })

  const handleLogout = () => {
    const site = account.resale.site
    setAccount({})
    navigate(`/${site}/login`)
  }

  return (
    <Menu id={id} open={open} anchorEl={anchorEl} onClose={onClose} onClick={onClick}>
      {destinations.map((destination, index) => (
        <StyledNavLink key={`${index}_userMenuItem`} to={destination.pathname}>
          <MenuItem>
            <Icon icon={destination.icon} />
            {destination.displayName}
          </MenuItem>
        </StyledNavLink>
      ))}

      <Divider light />

      <MenuItem sx={(theme) => ({ color: theme.palette.error.main })} onClick={handleLogout}>
        <Icon icon={faRightFromBracket} />
        Sair
      </MenuItem>
    </Menu>
  )
}
