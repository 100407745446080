import { Col } from 'react-grid-system'
import oldStyled from 'styled-components'
import { Table } from '@/presentation/components'
import { Box } from '@/presentation/components/atoms'
import { styled } from '@mui/material'

export const ButtonContainer = oldStyled.div`
  width: 100%;
  text-align: center;
  font-size: 1rem;
  letter-spacing: 0.26px;
  margin-bottom: 32px;
  display: flex;
  justify-content: flex-end;

  p {
    margin: 64px 0 32px 0;
    font-weight: var(--font-weight-semi-bold);
  }

  button {
    max-width: 250px;
  }

  button + button {
    margin-left: 32px;
  }
`

export const CustomTable = oldStyled(Table)`
  border-collapse: collapse;

  tr {
    width: 100%;
  }

  td {
    input {
      background-color: var(--color-primary-05);
    }
  }

  td,
  th {
    min-width: 200px;
    padding: 0 16px;
  }

  td:nth-of-type(1),
  th:nth-of-type(1) {
    padding-left: 32px;
  }

  td:nth-of-type(4),
  th:nth-of-type(4),
  td:nth-of-type(5),
  th:nth-of-type(5) {
    text-align: right;
  }

  td:nth-of-type(5),
  th:nth-of-type(5) {
    padding-right: 32px;
  }

  td:nth-of-type(4) {
    * {
      justify-content: flex-end;
    }
  }

  th:nth-of-type(4) {
    text-align: right;
  }

  tr > th {
    color: var(--color-primary-01);
  }

  tbody > tr {
    background-color: var(--color-secundary-05);
    border-left: 2px solid var(--color-primary-01);
    border-bottom: 20px solid white;
    height: 60px;
  }

  tbody > tr:first-child {
    height: 50px;
  }

  tbody > tr:last-child {
    height: 50px;
    border-bottom: none;
  }
`

export const DownloadsContainer = oldStyled.div`
  > div {
    margin-top: 32px;
  }

  > div:last-child {
    margin-bottom: 32px;
  }
`

export const InfoLink = oldStyled.a`
  display: flex;
  align-items: center;
  color: var(--color-primary-01);
  font-size: 1.1875rem;
  font-weight: var(--font-weight-semi-bold);
  cursor: pointer;
  margin-top: 32px;

  + a {
    margin-top: 16px;
  }

  :hover {
    text-decoration: underline;
  }

  svg {
    height: 20px;
    width: 20px;
    margin-right: 8px;
    fill: var(--color-primary-01);
  }
`

export const IconContainer = oldStyled.div`
  display: inline-flex;

  span:nth-of-type(2) {
    margin-left: 8px;
  }
`

export const ModalText = oldStyled.p`
  font-size: 1.25rem;
  text-align: center;
`

export const StatusDot = oldStyled.div`
  height: 12px;
  width: 12px;
  border-radius: 50%;

  ${({ status }) => (status === 'ATIVO' ? 'background-color: var(--color-status-01)' : null)}
  ${({ status }) => (status === 'BLOQUEADO' ? 'background-color: var(--color-status-02)' : null)}
    ${({ status }) => (status === 'INATIVO' ? 'background-color: var(--color-status-06)' : null)}
`

export const CustomCol = oldStyled(Col)`
  margin-top: 32px;
`
export const Wrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
}))
