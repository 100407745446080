import { faImage, faX } from '@fortawesome/free-solid-svg-icons'
import { Button } from '@/presentation/components/atoms/button'
import { Dialog } from '@/presentation/components/atoms/dialog'
import { DialogActions } from '@/presentation/components/atoms/dialogActions'
import { DialogContent } from '@/presentation/components/atoms/dialogContent'
import { DialogTitle } from '@/presentation/components/atoms/dialogTitle'
import { Dropzone } from '@/presentation/components/molecules/dropzone'
import { Icon } from '@/presentation/components/atoms/Icon'
import { Controller, useForm } from 'react-hook-form'
import { Typography } from '@/presentation/components/atoms/typography'
import { Fragment } from 'react'

const uploadImages = [
  { label: 'Logotipo cabeçalho', recomendation: '160x60', name: 'header' },
  { label: 'Logotipo rodapé', recomendation: '160x60', name: 'footer' },
  { label: 'Logotipo tela de login', recomendation: '160x60', name: 'login' },
]

export function BrandingEditionDialog({ open, onClose, onConfirm, initialValues }) {
  const {
    control,
    handleSubmit,
    formState: { dirtyFields },
  } = useForm({
    defaultValues: initialValues,
  })

  const onSubmit = (data) => {
    const formData = new FormData()
    if (dirtyFields.header) formData.append('Header', data.header)
    if (dirtyFields.footer) formData.append('Footer', data.footer)
    if (dirtyFields.login) formData.append('Login', data.login)
    onConfirm(formData)
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll='body'
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>
        Editar Branding
        <Icon icon={faX} onClick={onClose} />
      </DialogTitle>
      <DialogContent
        sx={{
          margin: '32px 0',
          '& h4': { margin: '32px 0px 8px 0px' },
          '& h4:first-of-type': { marginTop: 0 },
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)} id='images-brand'>
          {uploadImages.map((image, index) => (
            <Fragment key={`${index}_logo_branding`}>
              <Typography variant='h4'>{image.label}</Typography>
              <Controller
                control={control}
                name={image.name}
                render={({ field: { value, onChange } }) => (
                  <Dropzone
                    accept={{
                      'image/png': ['.png'],
                    }}
                    recomendationText={image.recomendation}
                    value={value}
                    onChange={onChange}
                    defaultIcon={faImage}
                    maxSize={512000}
                  />
                )}
              />
            </Fragment>
          ))}
        </form>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button onClick={onClose}>Voltar</Button>
        <Button autoFocus type='submit' variant='contained' form='images-brand' sx={{ width: '248px' }}>
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
