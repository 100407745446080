import { useMutation, useQueryClient } from '@tanstack/react-query'
import api from '@/infra/api'

export const useMigrationRerun = ({ onSuccess }: { onSuccess: () => void }) => {
  const queryClient = useQueryClient()
  const { mutate, isLoading } = useMutation({
    mutationFn: ({ migrationId }: { migrationId: string }) => api.patch(`resale-bff/companies/rerun/${migrationId}`),
    onSuccess: () => {
      onSuccess()
      queryClient.invalidateQueries(['company'])
    },
  })

  return {
    rerun: mutate,
    isLoading,
  }
}
