import api from '@/infra/api'
import { useQuery } from '@tanstack/react-query'
import { IApiReturn, IUseDashboard, IUseDashboardReturn } from './useDashboardInterfaces'
import {
  getBalanceProjectionInfo,
  getFinancialStation,
  getMonthsData,
  getOrdersCountsData,
  getOrdersPaymentInfo,
  getResaleFinancialInfo,
} from './functions'

export const useDashboard = ({ initialDate, finalDate }: IUseDashboard): IUseDashboardReturn => {
  const { data, ...rest } = useQuery({
    queryKey: ['companyCards', initialDate, finalDate],
    queryFn: ({ queryKey }) =>
      api.get<IApiReturn>(
        `resale-bff/Dashboard/GetDashboard?StartYearMonth=${queryKey[1]}&EndYearMonth=${queryKey[2]}`,
      ),
    refetchOnWindowFocus: false,
  })

  const organizeData = (): IUseDashboardReturn['dashboardData'] => {
    const dataToOrganize = data?.data

    const financialStationDta = dataToOrganize?.contractualBillings?.filter(({ yearMonth }) =>
      String(yearMonth)
        .slice(0, 4)
        .includes(String(initialDate.slice(0, 4))),
    )

    if (!dataToOrganize) return null
    return {
      availableYears: dataToOrganize.yearWithValues.map((year: string | number) => ({
        label: year,
        value: year,
      })),
      growthRate: 0,
      monthlyEarnings: getMonthsData({ apiData: dataToOrganize.paidOrders }),
      ordersCounts: getOrdersCountsData({ data: dataToOrganize }),
      monthlyOrdersPaymentInfo: getOrdersPaymentInfo({ apiData: dataToOrganize }),
      balanceProjectionInfo: getBalanceProjectionInfo({ apiData: dataToOrganize.ordesBalanceProjection }),
      resaleFinancialInfo: getResaleFinancialInfo({ apiData: dataToOrganize.paidOrders }),
      contractualBillings: getFinancialStation({ apiData: financialStationDta }),
    }
  }

  return {
    dashboardData: organizeData(),
    ...rest,
  }
}
