import React, { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { Container, PositionRef } from './styles'

const FloatingContainer = React.forwardRef(({ children, hover, visible, ...props }, parentRef) => {
  const [position, setPosition] = useState({})
  const [innerHeight, setInnerHeight] = useState(0)
  const localRef = useRef(null)
  const positionRef = useRef(null)

  useEffect(() => {
    setInnerHeight(0)
    if (localRef.current !== null) {
      const childNode = localRef.current.childNodes
      let finalHeight = 0
      for (let i = 0; i < childNode.length; i++) {
        finalHeight += childNode[i].scrollHeight || 0
      }
      setInnerHeight(finalHeight)
    }
  }, [children])

  useEffect(() => {
    if (!positionRef.current) return
    const distances = positionRef.current.parentNode.getBoundingClientRect()
    setPosition({
      top: distances.top + document.querySelector('html').scrollTop + distances.height + 8,
      right: distances.right,
      left: distances.left,
      fieldSize: distances.width,
    })
  }, [children])

  return (
    <PositionRef ref={positionRef}>
      {ReactDOM.createPortal(
        <Container
          position={position}
          visible={visible}
          height={visible ? innerHeight : 0}
          ref={(ref) => {
            localRef.current = ref
            if (parentRef) parentRef.current = ref
          }}
          {...props}
        >
          {children}
        </Container>,
        document.querySelector('#root'),
      )}
    </PositionRef>
  )
})

export default FloatingContainer
