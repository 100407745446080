import apiCallHandler from './apiCallHandler'
import api from '../infra/api'

export const getCustomizationSetting = () => apiCallHandler(() => api.get('resale-bff/setting/customization'))

export const postEditBranding = (payload) =>
  apiCallHandler(() => api.post('resale-bff/setting/customization/branding', payload))

export const putEditTheme = (payload) =>
  apiCallHandler(() => api.put('resale-bff/setting/customization/edit/Theme', payload))

export const putEditAccountInfo = (payload) =>
  apiCallHandler(() => api.put('resale-bff/setting/customization/edit/InstitutionalCommunication', payload))
