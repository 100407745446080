import { all, takeLatest, put, call } from 'redux-saga/effects'
import { infoCompanySuccess, infoCompanyFailure } from './actions'
import api from '@/infra/api'

function* requestInfoCompany({ payload }) {
  const { id } = payload
  try {
    const response = yield call(api.get, `resale-bff/companies/${id}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    const info = response.data

    yield put(infoCompanySuccess(info))
  } catch (error) {
    yield put(infoCompanyFailure())
  }
}

export default all([takeLatest('@infoCompany/INFOCOMPANY_REQUEST', requestInfoCompany)])
