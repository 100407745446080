import { useEffect } from 'react'

export const useClarity = () => {
  const injectScript = () => {
    const key = process.env.REACT_APP_CLARITY_KEY
    if (!key) return
    const clarityScript = document.createElement('script')
    clarityScript.innerHTML = `(function(c,l,a,r,i,t,y){
         c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
         t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
         y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
     })(window, document, "clarity", "script", "${key}");`

    clarityScript.type = 'text/javascript'
    clarityScript.id = 'clarityScript'
    document.head.appendChild(clarityScript)
  }

  useEffect(() => {
    injectScript()
  }, [])
}
