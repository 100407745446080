import React, { useEffect, useState } from 'react'
import { Body, Title, SessionButton, Close } from './style'
import { Button, Icon, Input } from '@/presentation/components'
import 'react-responsive-modal/styles.css'
import Modal from 'react-responsive-modal'
import { Controller, useForm } from 'react-hook-form'
import { companyStatus } from '@/main/utils/options'
import { createIntervalStringWithDates, getDatesFromIntervalString } from '@/main/utils/functionUtils'

const MODAL_STYLE = {
  borderRadius: '5px',
  modalContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    position: 'relative',
  },
  modal: {
    maxWidth: '380px',
    height: 'calc(100vh - 100px)',
    margin: '0',
    padding: '0',
    overflow: 'initial',
  },
}

const Filter = ({ actualFilters, onClose, filter, resetFilters, open }) => {
  const [minDate, setMinDate] = useState(null)
  const [maxDate, setMaxDate] = useState(null)

  useEffect(() => {
    const preSelectedDates = getDatesFromIntervalString(actualFilters.creationDate)
    setMinDate(new Date(preSelectedDates.initialDate))
    setMaxDate(new Date(preSelectedDates.finalDate))
  }, [actualFilters])

  const preSelectedDates = getDatesFromIntervalString(actualFilters.creationDate)
  const { control, handleSubmit } = useForm({
    defaultValues: {
      status: actualFilters?.status,
      initialDate: preSelectedDates.initialDate,
      finalDate: preSelectedDates.finalDate,
    },
  })

  function onSubmit(data) {
    const dateInterval = createIntervalStringWithDates(data.initialDate, data.finalDate)
    filter({
      status: data.status,
      creationDate: dateInterval,
    })
  }

  return (
    <Modal open={open} onClose={onClose} showCloseIcon={false} styles={MODAL_STYLE}>
      <Body>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Title>Filtrar</Title>
          <Close onClick={onClose}>
            <Icon name='x' />
          </Close>
        </div>
        <p>Selecione os filtros que deseja utilizar.</p>
        <form onSubmit={handleSubmit(onSubmit)} id='filter'>
          <label>Status</label>
          <Controller
            control={control}
            name='status'
            render={({ field: { onChange, value, ref } }) => (
              <div>
                <Input.Select
                  placeholder='Selecione'
                  value={companyStatus.find((option) => option.value === value)}
                  inputRef={ref}
                  onChange={(e) => onChange(e?.value)}
                  options={companyStatus}
                />
              </div>
            )}
          />
          <label>De</label>
          <Controller
            control={control}
            name='initialDate'
            render={({ field: { onChange, value, ref } }) => (
              <Input.Date
                placeholder='00/00/0000'
                maxDate={maxDate}
                getInputRef={ref}
                value={new Date(value?.replaceAll('-', '/'))}
                onChange={(e) => {
                  onChange(e !== '' ? e?.toISOString().split('T')[0] : e)
                  setMinDate(e)
                }}
              />
            )}
          />
          <label>Até</label>
          <Controller
            control={control}
            name='finalDate'
            render={({ field: { onChange, value, ref } }) => (
              <Input.Date
                placeholder='00/00/0000'
                getInputRef={ref}
                minDate={minDate || null}
                maxDate={new Date()}
                value={new Date(value?.replaceAll('-', '/'))}
                onChange={(e) => {
                  onChange(e !== '' ? e?.toISOString().split('T')[0] : e)
                  setMaxDate(e)
                }}
                disabled={!minDate}
              />
            )}
          />
        </form>
        <SessionButton>
          <Button
            variant='outlined'
            width='200px'
            onClick={() => {
              resetFilters()
            }}
          >
            Limpar
          </Button>
          <div style={{ paddingLeft: '15px' }} />
          <Button width='250px' form='filter'>
            Filtrar
          </Button>
        </SessionButton>
      </Body>
    </Modal>
  )
}

export default Filter
