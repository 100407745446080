import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-grid-system'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { AutoComplete, Button, ErrorMessage, ErrorWrapper, Input, ModalV2, Spinner } from '@/presentation/components'
import { getCompaniesForReportFilter } from '@/services/reportsServices'
import { pushNotification } from '@/store/modules/notification/actions'
import errorHandler from '@/main/utils/errorHandler'
import { applyMask } from '@/main/utils/functionUtils'
import { CustomContainer, InputWrapper, ModalTitle } from '../styles'
import { CheckBoxWrapper, Label } from './styles'

function GenericOrderReportFilter({ open, onClose, title, loading, download }) {
  const dispatch = useDispatch()
  const [companyId, setCompanyId] = useState(null)
  const [companies, setCompanies] = useState([])
  const [isCompanyPresent, setIsCompanyPresent] = useState(false)

  const { control, register, handleSubmit, reset } = useForm({
    mode: 'onChange',
  })

  useEffect(() => {
    // eslint-disable-next-line no-extra-semi
    const fetch = async () => {
      const { error, response } = await getCompaniesForReportFilter()
      if (response) setCompanies(response.data)
      else dispatch(pushNotification(errorHandler(error.response)))
    }

    fetch()
  }, [dispatch])

  const onSubmit = (data) => {
    if (!data.code) return
    download(
      companies.find((company) => company.id === companyId),
      data,
    )
    reset()
  }

  return (
    <ModalV2 open={open} onClose={onClose}>
      {loading ? (
        <Spinner />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} id='genericOrderReportFilter'>
          <CustomContainer fluid>
            <Row>
              <Col>
                <ModalTitle>{title}</ModalTitle>
              </Col>
            </Row>
            <Row>
              <Col>
                <ErrorWrapper error={!isCompanyPresent}>
                  <Label>Empresa</Label>
                  <Controller
                    control={control}
                    name='companyId'
                    render={({ field: { onChange } }) => (
                      <AutoComplete
                        placeholder='Digite aqui o nome, CNPJ ou codigo da empresa'
                        getOptionsLabel={[
                          (option) => option.tradeName,
                          (option) => option.cnpj,
                          (option) => option.code,
                        ]}
                        masks={[null, (option) => applyMask('##.###.###/####-##', option), null]}
                        getOptionValue={(option) => option.id}
                        list={companies}
                        matches={(matches, inputValue) =>
                          matches === 0 && inputValue !== '' ? setIsCompanyPresent(false) : setIsCompanyPresent(true)
                        }
                        onValueChange={(value) => {
                          setCompanyId(value)
                          onChange(value)
                        }}
                      />
                    )}
                  />
                  {!isCompanyPresent && <ErrorMessage>Empresa não encontrada</ErrorMessage>}
                </ErrorWrapper>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <InputWrapper>
                  <Label>N° do Pedido</Label>
                  <Input
                    type='number'
                    placeholder='Insira o número do pedido'
                    disabled={!companyId}
                    {...register('code')}
                  />
                </InputWrapper>
              </Col>
            </Row>
            <Row>
              <Col sm={2}>
                <CheckBoxWrapper>
                  <Input.CheckBox checked controlled />
                  <span>PDF</span>
                </CheckBoxWrapper>
              </Col>
            </Row>
            <Row justify='end'>
              <Col sm='content'>
                <Button>Gerar Relatório</Button>
              </Col>
            </Row>
          </CustomContainer>
        </form>
      )}
    </ModalV2>
  )
}

export default GenericOrderReportFilter
