import { faPencil } from '@fortawesome/free-solid-svg-icons'
import { DualColorSelector } from '@/presentation/components/molecules/dualColorSelector'
import { TitleAndIconButton } from '@/presentation/components/molecules/titleAndIconButton'

export function Colors({ value, onClick }) {
  return (
    <>
      <TitleAndIconButton title='Cor e tema' onClick={onClick} icon={faPencil} />

      <DualColorSelector onClick={onClick} value={value} />
    </>
  )
}
