import React from 'react'
import { Body, Title, SessionButton, Close } from './style'
import { Button, Icon, Input } from '@/presentation/components'
import 'react-responsive-modal/styles.css'
import Modal from 'react-responsive-modal'
import { Controller, useForm } from 'react-hook-form'
import { productStatus } from '@/main/utils/options'

const styles = {
  borderRadius: '5px',
  modalContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    position: 'relative',
  },
  modal: {
    maxWidth: '380px',
    height: 'calc(100vh - 90px)',
    margin: '0',
    padding: '0',
  },
}

const AllCardsFilterModal = ({ actualFilters, onClose, resetFilters, confirm, filter, open }) => {
  const { control, register, handleSubmit } = useForm({
    defaultValues: {
      fullname: actualFilters.fullname,
      number: actualFilters.number,
      registration: actualFilters.registration,
      status: actualFilters.status,
    },
  })

  function onSubmit(data) {
    filter(data)
    confirm()
  }

  return (
    <Modal open={open} onClose={onClose} showCloseIcon={false} styles={styles}>
      <Body>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Title>Filtrar</Title>
          <Close onClick={onClose}>
            <Icon name='x' />
          </Close>
        </div>
        <p>Complete com as informações dos filtros que deseja aplicar.</p>
        <form id='filterAllCards' onSubmit={handleSubmit(onSubmit)}>
          <label>Nº do Cartão</label>
          <Controller
            control={control}
            name='number'
            render={({ field: { onChange, value, ref } }) => (
              <Input.Masked value={value} allowLeadingZeros={true} getInputRef={ref} onChange={onChange} />
            )}
          />
          <label>Nome do Colaborador</label>
          <Input {...register('fullname')} />

          <label>Matrícula</label>
          <Input {...register('registration')} />

          <label>Status</label>
          <Controller
            control={control}
            name='status'
            render={({ field: { onChange, value, ref } }) => (
              <div>
                <Input.Select
                  placeholder='Selecione'
                  value={productStatus.find((option) => option.value === value)}
                  inputRef={ref}
                  onChange={(e) => onChange(e?.value)}
                  options={productStatus}
                />
              </div>
            )}
          />
        </form>

        <SessionButton>
          <Button
            variant='outlined'
            width='200px'
            onClick={() => {
              resetFilters()
              onClose()
            }}
          >
            Limpar
          </Button>
          <div style={{ paddingLeft: '15px' }} />
          <Button width='250px' form='filterAllCards'>
            Filtrar
          </Button>
        </SessionButton>
      </Body>
    </Modal>
  )
}

export default AllCardsFilterModal
