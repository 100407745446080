import { styled } from '@mui/material'

export const StyledLink = styled('a')(() => ({
  textDecoration: 'underline',
  display: 'block',
  textAlign: 'center',
  margin: '16px 0',
}))

export const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  minWidth: '450px',
}))

export const ProjectionInfo = styled('span')(() => ({
  fontSize: '18px',
  '&:last-of-type': {
    marginBottom: '16px',
  },
}))
