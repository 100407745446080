import { all, takeLatest, put, call } from 'redux-saga/effects'
import { companySuccess, companyFailure } from './actions'
import api from '@/infra/api'
// import { queryBuilder } from "@/utils/functionUtils";

function* requestCompany({ payload }) {
  try {
    const response = yield call(api.get, `resale-bff/companies/${payload.query}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    const companys = response.data

    yield put(companySuccess(companys))
  } catch (error) {
    yield put(companyFailure())
  }
}

export default all([takeLatest('@company/COMPANY_REQUEST', requestCompany)])
// https://api.stage.stationkim.com/v1/resale-bff/companies
