import React, { useEffect, useState } from 'react'
import { useAtom } from 'jotai'
import { atomWithHash } from 'jotai-location'
import { useForm } from 'react-hook-form'
import dayjs from 'dayjs'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import { Box } from '@stationkim/front-ui'
import { useFilter, useNotifications, usePagination } from '@/main/hooks'
import { Multiselect } from '@/presentation/components/organisms/multiselect'
import { FormField } from '@/presentation/components/organisms'
import { Panel, TableFooterPagination, PageHeader, Search, AutoTable } from '@/presentation/components/molecules'
import { Button, Grid, Icon } from '@/presentation/components/atoms'
import { useOrders } from './hooks/useOrders'
import { useOperators } from './hooks/useOperators'
import { FilterDialog } from './components/filterDialog/filterDialog'
import { getTableConfiguration } from './utils/getTableConfiguration'
import useDebounce from '@/main/hooks/useDebouce'
import { optionFilter, statusOptions } from './utils/statusOptions'
import { EMessageType } from '@/main/store'

const initialFilter = { OrdenarPor: 'numeroPedido', OrdenarDecrescente: true }
const filterAtom = atomWithHash<object>('ordersFilter', initialFilter)
const paginationAtom = atomWithHash<object>('ordersPagination', null)
const statusOptionAtom = atomWithHash<number>('statusOption', null)
const defaultValues = {
  status: '',
  OrdenarPor: 'numeroPedido',
  OrdenarDecrescente: true,
}

export const ListOrders = ({ listSelector }: { listSelector: React.ReactNode }) => {
  const [statusOption, setStatusOption] = useAtom(statusOptionAtom)
  const [searchFilter, setSearchFilter] = useState('')
  const notifications = useNotifications()
  const debounce = useDebounce()
  const today = dayjs(new Date())
  const { filter, resetFilter, updateFilter } = useFilter({ filterAtom, initialValue: initialFilter })
  const { pagination, goToPage, setPageSize } = usePagination({
    paginationAtom,
    outputMap: {
      page: 'NumeroPagina',
      pageSize: 'TamanhoPagina',
    },
  })
  const form = useForm({
    defaultValues: filter || defaultValues,
  })
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const { orders, isLoading } = useOrders({ filter, pagination })
  const { operators } = useOperators()

  const tableConfiguration = getTableConfiguration({ updateFilter })

  const onFilterSubmit = (data) => {
    if (new Date(data.dataPedidoInicio) > new Date() || new Date(data.dataPedidoFim) > new Date()) {
      notifications.push({
        content: 'Os filtros de data não podem ser superiores a data atual',
        type: EMessageType.Error,
      })
      return
    }
    if (new Date(data.dataPedidoInicio) > new Date(data.dataPedidoFim)) {
      notifications.push({ content: 'A data final não pode ser inferior a inicial', type: EMessageType.Error })
      return
    }

    if (typeof data.status === 'number') {
      setStatusOption(data.status)
      data.statusCompra = ''
      data.tipoPedido = ''
      data = {
        ...data,
        ...optionFilter[data.status],
      }
    }
    goToPage(1)
    updateFilter(data)
    setIsFilterOpen(false)
  }

  const onFilterClear = () => {
    const resetTo = {
      ...defaultValues,
      OperatorCardId: filter.OperatorCardId,
      sort: filter.sort,
      desc: filter.desc,
    }
    resetFilter({ filterValues: resetTo })
    setSearchFilter('') //revisar antes
    form.reset(resetTo)
    setIsFilterOpen(false)
    setStatusOption(null)
  }

  useEffect(() => {
    form.setValue('dataPedidoInicio', filter.dataPedidoInicio)
    form.setValue('dataPedidoFim', filter.dataPedidoFim)
    form.setValue('codigoCompra', filter.codigoCompra)
    form.setValue('status', statusOption)
    setSearchFilter(filter.NumeroPedido)
  }, [filter, form, statusOption])

  return (
    <Panel noPadding>
      <PageHeader pageTitle='Pedidos' />
      <Grid container spacing='16px' sx={{ padding: '16px 32px 32px 32px' }}>
        <Grid item xs={12} md={5}>
          <Search
            onSearch={(value) => {
              updateFilter({
                numeroPedido: value,
              })
            }}
            placeholder='Buscar pelo código'
            value={searchFilter}
            onChange={(e) => {
              setSearchFilter(e === '' ? e : e.replaceAll(/[^0-9]/gi, ''))
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md='auto'>
          <Button
            variant='outlined'
            startIcon={<Icon icon={faFilter} />}
            fullWidth
            onClick={() => setIsFilterOpen(true)}
          >
            Filtrar
          </Button>
        </Grid>
        <Grid item>
          {
            <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
              <Multiselect
                label='Operadora:'
                labelProps={{
                  position: 'left',
                  sx: {
                    fontWeight: '700',
                    color: 'black',
                    alignItems: 'center',
                    justifyContent: 'center',
                  },
                }}
                value={filter.IdsOperadora}
                options={operators}
                getOptionLabel={(option) => option?.tradeName}
                getOptionValue={(option) => option?.id}
                onChange={(value) => {
                  debounce(() => {
                    updateFilter({
                      IdsOperadora: value,
                    })
                  }, 250)
                }}
              />
            </Box>
          }
        </Grid>
        {listSelector && (
          <Grid item sx={{ marginLeft: 'auto' }}>
            {listSelector}
          </Grid>
        )}
        <Grid item xs={12}>
          <AutoTable
            link={() => {
              return `#`
            }}
            columns={tableConfiguration as any}
            rows={orders?.data}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
      <TableFooterPagination
        totalItems={orders.totalItems}
        onRowsPerPageChange={(e) => setPageSize(e.target.value)}
        rowsPerPage={orders.pageSize}
        totalPages={orders.totalPages}
        page={orders.page}
        onPageChange={(e, value) => goToPage(value)}
      />
      <FilterDialog
        open={isFilterOpen}
        form={form}
        onClose={() => setIsFilterOpen(false)}
        onSubmit={onFilterSubmit}
        onClear={onFilterClear}
      >
        <FormField
          type='select'
          options={statusOptions}
          name='status'
          label='Status'
          gridProps={{ xs: 12 }}
          placeholder='Selecione...'
          noStatusBorder
        />
        <FormField
          type='maskedInput'
          mask={Number}
          name='codigoCompra'
          label='N° Carrinho'
          placeholder='Insira o número do carrinho'
          inputProps={{ maxLength: 18 }}
          gridProps={{ xs: 12 }}
          noStatusBorder
        />
        <FormField
          type='date'
          name='dataPedidoInicio'
          label='De'
          maxDate={today}
          gridProps={{ xs: 12 }}
          noStatusBorder
        />
        <FormField type='date' name='dataPedidoFim' label='Até' maxDate={today} gridProps={{ xs: 12 }} noStatusBorder />
      </FilterDialog>
    </Panel>
  )
}
