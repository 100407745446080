import { useState } from 'react'
import { Search } from '@/presentation/components/molecules/search'
import { Button, Grid, Icon } from '@/presentation/components/atoms'
import { useCompanyEmployees } from '@/main/hooks/useCompanyEmployees'
import { AutoTable, TableFooterPagination } from '@/presentation/components/molecules'
import { atomWithHash } from 'jotai-location'
import { usePagination } from '@/main/hooks/usePagination'
import { useFilter } from '@/main/hooks/useFilter'
import { employeesTableConfiguration } from './utils/tableConfiguration'
import { Filter } from '@/presentation/components/organisms/filter'
import { useCompanyEmployeesFilter } from '@/main/hooks'
import { employeesFilterConfiguration } from './utils/filterConfiguration'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import { useTheme } from '@mui/material'

export interface IEmployeesTableColumns {
  fullName: string
  registration: string
  departmentName: string
  benefits: any[]
  status: string | number
  integrationsStatus: number
}
const paginationAtom = atomWithHash<object>('companyEmployeesPagination', null)
const filterAtom = atomWithHash<object>('companyEmployeesFilter', null)
export const CompanyEmployees = () => {
  const theme = useTheme()
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const { employeesFilterOptions } = useCompanyEmployeesFilter()
  const { filter, updateFilter, resetFilter } = useFilter({ filterAtom })
  const { pagination, goToPage, setPageSize } = usePagination({ paginationAtom })
  const { employees, isLoading } = useCompanyEmployees({ filter, pagination })
  const employeesData = employees?.data
  const columnsConfiguration = employeesTableConfiguration({ updateFilter, theme })
  const filterConfiguration = employeesFilterConfiguration({ filterData: employeesFilterOptions })
  return (
    <>
      <Grid container spacing='16px' sx={{ padding: '16px' }}>
        <Grid item xs={12} md={6} lg={5}>
          <Search
            onSearch={(value) =>
              updateFilter({
                filterValue: value,
                filterProps: 'ProfessionalData.Registration,FullName',
              })
            }
            placeholder='Buscar por colaborador, matrícula'
          />
        </Grid>
        <Grid item xs={12} md='auto'>
          <Button
            variant='outlined'
            fullWidth
            onClick={() => setIsFilterOpen(true)}
            startIcon={<Icon icon={faFilter} />}
          >
            Filtrar
          </Button>
        </Grid>
        <Grid item xs={12}>
          <AutoTable<IEmployeesTableColumns>
            columns={columnsConfiguration}
            rows={employeesData}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
      <TableFooterPagination
        totalItems={employees.totalItens}
        onRowsPerPageChange={(e) => setPageSize(e.target.value)}
        rowsPerPage={employees.pageSize}
        page={employees.pageNumber}
        onPageChange={(e, value) => goToPage(value)}
        totalPages={employees.totalPages}
      />
      <Filter
        fields={filterConfiguration}
        isLoading={isLoading}
        onClean={() => resetFilter()}
        onFilter={(value) => {
          updateFilter(value)
          setIsFilterOpen(false)
        }}
        onClose={() => setIsFilterOpen(false)}
        open={isFilterOpen}
        allowEmptyFilter
      />
    </>
  )
}
