import React, { useState } from 'react'
import { Container, Text, SelectPositionHolder } from './style'
import DropdownButton from '@/presentation/components/dropdownButton'

const ResultAmount = ({ setPageSize, paginator, ...props }) => {
  const [options, setOptions] = useState([10, 15, 20, 25, 50, 100])

  function handleOnOptionClick(index, amount) {
    options[index] = options[0]
    const orderedOptions = options.slice(1, options.length).sort((a, b) => a - b)
    setOptions([amount, ...orderedOptions])
    setPageSize && setPageSize(amount)
  }
  return (
    <Container {...props}>
      <Text>Exibir</Text>
      <SelectPositionHolder>
        <DropdownButton isMenuOpen={true} title={options[0]}>
          {options.map((amount, index) => (
            <DropdownButton.Item
              key={index}
              onClick={() => {
                handleOnOptionClick(index, amount)
              }}
            >
              {amount}
            </DropdownButton.Item>
          ))}
        </DropdownButton>
      </SelectPositionHolder>
      <Text>resultados{paginator?.totalItens && ` de ${paginator.totalItens}`}</Text>
    </Container>
  )
}

export default ResultAmount
