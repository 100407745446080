import { Body, Container } from './style'

function Background({ children, ...props }) {
  return (
    <Body {...props}>
      <Container>{children}</Container>
    </Body>
  )
}

export default Background
