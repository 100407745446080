import { useRef } from 'react'
import { useAtom } from 'jotai'
import { atomWithHash } from 'jotai-location'
import { Divider, Grid, Typography } from '@/presentation/components/atoms'
import { Select } from '@/presentation/components/molecules'
import { useDashboard } from './hooks/useDashboard'
import { months } from 'main/utils/options'
import { MONTHS } from '@/main/utils/constants'
import { EarningsChartCard } from './components/earningsChartCard/earningsChartCard'
import { OrdersChartCard } from './components/ordersChartCard/ordersChartCard'
import { OrdersTableCard } from './components/ordersTableCard/ordersTableCard'
import { BalanceProjectionGrid } from './components/balanceProjectionGrid/balanceProjectionGrid'
import { useFeatures } from '@/main/hooks'
import { FinancialTableCard } from './components/financialTableCard/financialTableCard'
import { FinancialStation } from './components/financialStation/financialStation'

export type YearAndMonth = {
  month: string
  year: string | number
}
interface IDashboardAtom {
  selectedDate: YearAndMonth
}

const getActualDate = (): YearAndMonth => {
  const today = new Date()
  return {
    month: MONTHS[today.getMonth()].slice(0, 3),
    year: today.getFullYear(),
  }
}
const dashboardAtom = atomWithHash<IDashboardAtom>('financialManagementDashboard', { selectedDate: getActualDate() })

export const Dashboard = () => {
  const { current: todayDate } = useRef<YearAndMonth>(getActualDate())

  const [dashBoardState, setDashBoardState] = useAtom(dashboardAtom)
  const { features } = useFeatures()
  const { selectedDate } = dashBoardState
  const isMonth = selectedDate.month !== ''
  const { dashboardData, isLoading: isDashboardLoading } = useDashboard({
    initialDate: `${selectedDate.year}01`,
    finalDate: `${selectedDate.year}12`,
  })
  const isLookingActualYear = String(selectedDate.year) === String(todayDate.year)
  const handleMonthSelection = (e) => {
    setDashBoardState((state) => ({
      ...state,
      selectedDate: { ...state.selectedDate, month: e.target.value },
    }))
  }
  const handleYearSelection = (e) => {
    setDashBoardState((state) => ({
      ...state,
      selectedDate: { month: '', year: e.target.value },
    }))
  }

  const getAvailableMonths = () => {
    const monthsOptions = [{ label: 'Todos', value: '' }, ...months]
    if (!isLookingActualYear) return monthsOptions
    const invalidaMonthIndex = months.findIndex((month) => month.value === todayDate.month) + 2
    return monthsOptions.map((month, index) => ({ ...month, disabled: index >= invalidaMonthIndex }))
  }
  const isLoading = isDashboardLoading || !dashboardData

  return (
    <Grid container spacing='16px'>
      <Grid item>
        <Typography variant='label3' sx={{ margin: '20px 0 16px', display: 'inline-block' }}>
          Veja detalhes e métricas do uso da plataforma como valores em vendas, empresas cadastradas, montante de
          pedidos e economia gerada.
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Divider overflow />
      </Grid>

      <Grid item xs={12} sm={6} md={3} lg={2}>
        <Select
          label='Ano'
          options={dashboardData?.availableYears}
          fullWidth
          value={selectedDate.year}
          onChange={handleYearSelection}
          disabled={!(dashboardData?.availableYears.length > 0)}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={2}>
        <Select
          label='Mês'
          options={getAvailableMonths()}
          fullWidth
          value={selectedDate?.month}
          onChange={handleMonthSelection}
        />
      </Grid>
      <Grid item md={6} />
      {features.dashboardEarningsChart && (
        <Grid item xs={12} sm={12} lg={7}>
          <EarningsChartCard
            data={dashboardData}
            isLookingActualYear={isLookingActualYear}
            selectedDate={selectedDate}
            todayDate={todayDate}
            isLoading={isLoading}
          />
        </Grid>
      )}
      {features.dashboardOrdersChart && (
        <Grid item xs={12} sm={12} lg={5}>
          <OrdersChartCard data={dashboardData} selectedDate={selectedDate} isLoading={isLoading} />
        </Grid>
      )}
      {features.dashboardBalanceProjectionGrid && (
        <Grid item xs={12}>
          <BalanceProjectionGrid
            data={dashboardData?.balanceProjectionInfo}
            selectedDate={selectedDate}
            isLoading={isLoading}
            isMonth={isMonth}
          />
        </Grid>
      )}
      {features.dashboardOrdersTable && (
        <Grid item xs={12}>
          <OrdersTableCard data={dashboardData} selectedDate={selectedDate} isLoading={isLoading} />
        </Grid>
      )}
      {features.dashboardFinancialStation && (
        <Grid item xs={12} lg={6}>
          <FinancialStation data={dashboardData} selectedDate={selectedDate} isLoading={isLoading} />
        </Grid>
      )}
      {features.dashboardFinancialTable && (
        <Grid item xs={12} lg={6}>
          <FinancialTableCard data={dashboardData} selectedDate={selectedDate} isLoading={isLoading} />
        </Grid>
      )}
    </Grid>
  )
}
