import { styled } from '@mui/material'
import { Box } from '@/presentation/components/atoms/box'

export const StyledBox = styled(Box)(() => ({
  '& > div ': {
    marginBottom: '42px',
    marginTop: '64px',
  },
  '& > div:first-of-type': {
    marginTop: 0,
  },
  '& > p ': {
    marginTop: '22px',
    marginBottom: '42px',
  },
  '& > div:nth-of-type(3) ': {
    margin: 0,
  },
  '& > h5 + div ': {
    marginTop: '16px',
    marginBottom: '42px',
  },
}))
