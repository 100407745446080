import { Icon } from '@/presentation/components/atoms/Icon'
import { StyledBox } from './styles'
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'

export const OrderSelector = ({ onClickUp, onClickDown, disabled = false, ...props }) => {
  return (
    <StyledBox disabled={disabled} {...props}>
      <Icon icon={faCaretUp} onClick={disabled ? null : onClickUp} />
      <Icon icon={faCaretDown} onClick={disabled ? null : onClickDown} />
    </StyledBox>
  )
}
