import { useState } from 'react'
import Moment from 'react-moment'
import { atomWithHash } from 'jotai-location'
import { DATE_TO_IGNORE } from '@/main/utils/constants'
import AllCardsFilterModal from './filters/allFilter'
import { Grid } from '@/presentation/components/atoms'
import { AutoTable, Search, TableFooterPagination } from '@/presentation/components/molecules'
import { useCompanyCardsCopyHistoric, useFilter, usePagination } from '@/main/hooks'

const paginationAtom = atomWithHash<object>('companyCardsCopyHistoricPagination', { sort: 'number' })
const filterAtom = atomWithHash<object>('companyCardsCopyHistoricFilter', null)
const CompanyCardCopyHistoric = () => {
  const [cardsFilterModal, setCardsFilterModal] = useState(false)

  const { filter, updateFilter, resetFilter } = useFilter({ filterAtom })
  const { pagination, goToPage, setPageSize } = usePagination({ paginationAtom })

  const { historic, isLoading } = useCompanyCardsCopyHistoric({ filter, pagination })
  const historicData = historic?.data

  const columnsConfiguration = [
    {
      columnDisplayName: 'Cartão Antigo',
      columnValue: ({ row }) => row.oldCardNumber,
      onOrder: ({ order }) => updateFilter({ sort: 'oldCardNumber', desc: !order }),
    },
    {
      columnDisplayName: 'Via',
      columnValue: ({ row }) => row.oldCopy,
    },
    {
      columnDisplayName: 'Data Bloqueio',
      columnValue: ({ row }) => row.blockDate,
      customRender: ({ row }) =>
        row.blockDate && !row.blockDate.includes(DATE_TO_IGNORE) ? (
          <Moment format='DD/MM/YYYY'>{row.blockDate}</Moment>
        ) : (
          '-'
        ),
    },
    {
      columnDisplayName: 'Cartão Novo',
      columnValue: ({ row }) => row.newCardNumber,
    },
    {
      columnDisplayName: 'Via',
      columnValue: ({ row }) => row.newCopy,
    },
    {
      columnDisplayName: 'Data Emissão',
      columnValue: ({ row }) => row.emissionDate,
      customRender: ({ row }) =>
        row.emissionDate && !row.emissionDate.includes(DATE_TO_IGNORE) ? (
          <Moment format='DD/MM/YYYY'>{row.emissionDate}</Moment>
        ) : (
          '-'
        ),
    },
  ]

  return (
    <>
      <Grid container spacing='16px' sx={{ padding: '16px' }}>
        <Grid item xs={12} md={6} lg={5}>
          <Search
            onSearch={(value) => {
              goToPage(1)
              updateFilter({
                filterValue: value,
                filterProps: 'oldCardNumber,newCardNumber,employeeName',
              })
            }}
            placeholder='Buscar por cartão antigo ou cartão novo '
          />
        </Grid>
        <Grid item xs={12}>
          <AutoTable<{
            oldCardNumber: string
            oldCopy: string | number
            blockDate: string
            newCardNumber: string
            newCopy: string | number
            emissionDate: string
          }>
            columns={columnsConfiguration}
            rows={historicData}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
      <TableFooterPagination
        totalItems={historic.totalItens}
        onRowsPerPageChange={(e) => setPageSize(e.target.value)}
        rowsPerPage={historic.pageSize}
        totalPages={historic.totalPages}
        page={historic.pageNumber}
        onPageChange={(e, value) => goToPage(value)}
      />
      {cardsFilterModal && (
        <AllCardsFilterModal
          actualFilters={filter}
          open={cardsFilterModal}
          onClose={() => setCardsFilterModal(false)}
          confirm={() => setCardsFilterModal(false)}
          resetFilters={resetFilter}
          filter={(queryObj) => updateFilter(queryObj)}
        />
      )}
    </>
  )
}

export default CompanyCardCopyHistoric
