import { useMutation } from '@tanstack/react-query'
import { AccountModel, AccountPersistentStore, GlobalThemePersistentStore } from '@/main/store'
import api from '@/infra/api'
import { useAtomWithStorageObserver } from './useAtomWithStorageObserver'
import { defaultTheme } from '@/config/themes/defaultTheme'

export interface ILogin {
  email: string
  password: Storage
  isKeycloak?: boolean
}

export const useAuth = (navigate?: any) => {
  const [, setAccountStore] = useAtomWithStorageObserver({
    atomWithStorage: AccountPersistentStore,
    storageName: 'account',
  })
  const [, setTheme] = useAtomWithStorageObserver({
    atomWithStorage: GlobalThemePersistentStore,
    storageName: 'globalTheme',
  })

  const { mutate, isLoading } = useMutation({
    mutationFn: ({ isKeycloak, ...payload }: ILogin) =>
      api.post<AccountModel>(`resale-bff/login${isKeycloak ? '?isKeycloak=true' : ''}`, { ...payload }),
    onSuccess: (res) => {
      const account = Object.assign(res.data)
      const { theme } = account.resale
      const customTheme = JSON.parse(theme)?.palette?.brand
      if (customTheme) defaultTheme.palette.brand = customTheme
      setTheme(defaultTheme)
      setAccountStore(account)
    },
  })

  const logout = (resaleSite: string) => {
    setAccountStore({})
    navigate(resaleSite ? `/${resaleSite}/login` : '/login')
  }

  return {
    login: mutate,
    logout,
    isLoading,
  }
}
