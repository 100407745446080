const ACTION = '@cardOrder/'

export const ACTIONS = {
  GET_LIST_REQUEST: `${ACTION}GET_LIST_REQUEST`,
  GET_LIST_SUCCESS: `${ACTION}GET_LIST_SUCCESS`,
  GET_LIST_FAILURE: `${ACTION}GET_LIST_FAILURE`,
}

export function getCardOrder(cardOrderId) {
  return {
    type: ACTIONS.GET_LIST_REQUEST,
    payload: cardOrderId,
  }
}

export function getListSuccess(data) {
  return {
    type: ACTIONS.GET_LIST_SUCCESS,
    payload: data,
  }
}

export function getListFailure() {
  return {
    type: ACTIONS.GET_LIST_FAILURE,
  }
}
