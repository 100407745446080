import { Cell, ResponsiveContainer, PieChart, Pie, Legend, Tooltip } from 'recharts'
import { Theme, useMediaQuery, useTheme } from '@mui/material'
import { CustomLegend } from './components/customLegend'
import { ORDER_STATUS } from '@/main/utils/constants'
import { TotalOverlay } from './components/totalOverlay'
import { IDashboardOrdersStatusCount } from '../../../../hooks/useDashboardInterfaces'
import { CustomTooltip } from './components/customTooltip'

interface OrdersPieChartProps {
  data: Array<IDashboardOrdersStatusCount>
  selectedMonth?: string
  onBarClick?: (e: any) => void
  lastActiveMonth?: string | null
  ordersFilter?: string
  ordersTotal?: number
}

const renderCells = ({ data, theme }: { data: Array<IDashboardOrdersStatusCount>; theme: Theme }) => {
  const pieColors = [
    { status: ORDER_STATUS.DRAFT, color: theme.palette.grey[500] },
    { status: ORDER_STATUS.FINISHED, color: theme.palette.success.dark },
    { status: ORDER_STATUS.AVAILABLE_PICKUP, color: theme.palette.benefits.food.main },
    { status: ORDER_STATUS.EFFECTING, color: theme.palette.primary.light },
    { status: ORDER_STATUS.RUNNING, color: theme.palette.benefits.transport.light },
    { status: ORDER_STATUS.PROCESSING, color: theme.palette.primary.main },
    { status: ORDER_STATUS.WAITING_FOR_PROCESSING, color: theme.palette.benefits.food.dark },
    { status: ORDER_STATUS.WAITING_PAYMENT, color: theme.palette.warning.light },
    { status: ORDER_STATUS.CANCELED, color: theme.palette.error.dark },
    { status: ORDER_STATUS.CANCELING, color: theme.palette.error.dark },
  ]
  if (data.every((order) => order.orderStatus === null)) {
    return <Cell key={`cell-0`} fill={theme.palette.grey[500]} />
  }
  return data.map((entry, index) => (
    <Cell
      key={`cell-${index}`}
      fill={pieColors.find((pieColor) => pieColor.status === entry.orderStatus)?.color || theme.palette.grey[100]}
    />
  ))
}

export const OrdersPieChart = ({ data, ordersFilter = 'total', ordersTotal = 0 }: OrdersPieChartProps) => {
  const theme = useTheme()
  const isSmallerThanMd = useMediaQuery(theme.breakpoints.down('md'))
  const isEqualsTonMd = useMediaQuery(theme.breakpoints.between('sm', 'lg'))

  return (
    <>
      <TotalOverlay ordersFilter={ordersFilter} ordersTotal={ordersTotal} />
      <ResponsiveContainer height={isSmallerThanMd ? 380 : 208} width={'100%'}>
        <PieChart>
          <Pie
            data={data}
            dataKey='value'
            nameKey='orderStatus'
            outerRadius={80}
            innerRadius={60}
            cx={isSmallerThanMd ? '' : '80'}
            cy='80'
            stroke=''
          >
            {renderCells({ data, theme })}
          </Pie>
          <Tooltip
            allowEscapeViewBox={{ x: true, y: true }}
            content={(props) => {
              return <CustomTooltip containerId='ordersPieContainer' active={props.active} payload={props.payload} />
            }}
          />
          <Legend
            align={'center'}
            iconSize={8}
            content={(entry) => <CustomLegend entry={entry} />}
            wrapperStyle={{
              top: isSmallerThanMd ? 228 : 0,
              right: 0,
              maxWidth: isSmallerThanMd ? '100%' : `calc(100% - ${isEqualsTonMd ? 254 : 192}px)`,
              margin: 'auto',
            }}
            iconType='rect'
          />
        </PieChart>
      </ResponsiveContainer>
    </>
  )
}
