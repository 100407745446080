import api from '@/infra/api'
import { useQuery } from '@tanstack/react-query'

type IApiReturn = Array<{
  id: string
  operatorCardCode: number
  operatorCardId: string
  operatorCardName: string
  operatorId: string
  operatorName: string
}>

export interface IUseOperatorsReturn {
  operators: IApiReturn
}

export const useOperators = (): IUseOperatorsReturn => {
  const { data, ...rest } = useQuery({
    queryKey: ['ordersOperators'],
    queryFn: () => api.get<IApiReturn>(`/resale-bff/orders/operators`),
    refetchOnWindowFocus: false,
  })

  return {
    operators: data?.data || [],
    ...rest,
  }
}
