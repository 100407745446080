import { Navigate, Outlet, createBrowserRouter } from 'react-router-dom'
import {
  ListCompanies,
  ConsultCompany,
  FinancialManagement,
  GeneralParameters,
  Home,
  Login,
  PasswordDefinition,
  PasswordReset,
  Reports,
  TaxasBalcao,
  Users,
  ErrorPage,
  OrderTracking,
} from '@/presentation/pages'
import { Configurations } from '@/presentation/pages/configurations'
import ImportResult from '@/presentation/components/importResults'
import { HeaderAndFooter } from '../components/templates/headerAndFooter'
import { VerifyAuthentication } from './verifyAuthentication'
import { ListOrders } from '../pages/orders/listOrders'

export const router = createBrowserRouter([
  {
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/:resaleSite?/login',
        element: <VerifyAuthentication isPublic element={<Login />} />,
      },
      {
        path: '/:resaleSite?/trocar_senha',
        element: <VerifyAuthentication isPublic element={<PasswordDefinition />} />,
      },
      {
        path: '/:resaleSite?/recuperar_senha',
        element: <VerifyAuthentication isPublic element={<PasswordReset />} />,
      },
    ],
  },

  {
    element: (
      <HeaderAndFooter>
        <Outlet />
      </HeaderAndFooter>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: <VerifyAuthentication element={<Home />} />,
      },
      {
        path: '/financeiro',
        children: [
          {
            index: true,
            element: <VerifyAuthentication element={<FinancialManagement />} />,
          },
        ],
      },
      {
        path: '/empresas',
        children: [
          {
            index: true,
            element: <VerifyAuthentication element={<ListCompanies />} />,
          },
          {
            path: 'consulta_de_empresa',
            element: <VerifyAuthentication element={<ConsultCompany />} />,
          },
          {
            path: 'taxas_balcao',
            element: <VerifyAuthentication element={<TaxasBalcao />} />,
          },
        ],
      },
      {
        path: '/pedidos',
        children: [
          {
            index: true,

            element: <VerifyAuthentication element={<ListOrders />} />,
          },
          {
            path: 'cartao',
            element: <VerifyAuthentication element={<OrderTracking />} />,
          },
          {
            path: 'recarga',
            element: <VerifyAuthentication element={<OrderTracking />} />,
          },
          {
            path: 'erro_importacao',
            element: <VerifyAuthentication element={<ImportResult />} />,
          },
        ],
      },
      {
        path: '/relatorios',
        element: <VerifyAuthentication element={<Reports />} />,
      },
      {
        path: '/configuracoes',
        element: <VerifyAuthentication element={<Configurations />} />,
      },
      {
        path: '/parametros_gerais',
        element: <VerifyAuthentication element={<GeneralParameters />} />,
      },
      {
        path: '/usuarios',
        element: <VerifyAuthentication element={<Users />} />,
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to='/' replace />,
  },
])
