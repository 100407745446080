import { StyledTooltip } from './styles'

export function Tooltip({ arrow = true, ...props }) {
  return (
    <StyledTooltip
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: 'common.white',
            color: 'common.black',
            boxShadow: '4px 4px 30px 0px rgba(19, 19, 19, 0.10)',
            '& .MuiTooltip-arrow': {
              color: 'common.white',
            },
          },
        },
      }}
      arrow={arrow}
      {...props}
    />
  )
}
