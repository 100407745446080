import { useState } from 'react'
import { faChevronDown, faX } from '@fortawesome/free-solid-svg-icons'
import { Checkbox, Chip, Icon, ListSubheader, MenuItem } from '@/presentation/components/atoms'
import { Select, Search } from '@/presentation/components/molecules'

const menuItemStyle = {
  padding: '12px 8px',
  margin: 0,
  gap: '4px',
}

const variants = {
  borderless: {
    '& fieldset': {
      border: 'none',
    },
  },
  outlined: {},
}

export function Multiselect({
  initialValue = [],
  options = [],
  getOptionLabel = (option) => option.label,
  getOptionValue = (option) => option.value,
  hideFilter = false,
  filterByValue = false,
  value,
  onChange,
  fullWidth = false,
  placeholder = 'Selecione...',
  variant = 'borderless',
  ...props
}) {
  const [searchTerm, setSearchTerm] = useState('')
  const [internalValue, setInternalValue] = useState(initialValue)

  const filteredOptions = options.filter((option) => {
    const labelIncludesSearch = String(getOptionLabel(option)).toLowerCase().includes(searchTerm.toLowerCase())
    const valueIncludesSearch = String(getOptionValue(option)).toLowerCase().includes(searchTerm.toLowerCase())
    const isInSearch = labelIncludesSearch || (valueIncludesSearch && filterByValue)
    return isInSearch
  })

  const selectedOptions = value || internalValue
  const setSelectedOptions = value ? onChange : setInternalValue

  const isAllSelected = options.length > 0 && selectedOptions.length === options.length

  const handleChange = (event) => {
    const newValue = event.target.value
    if (newValue[newValue.length - 1] === 'all') {
      const items = selectedOptions.length === options.length ? [] : options.map((option) => getOptionValue(option))
      setSelectedOptions(items)
      if (onChange && !value) onChange(items)
      return
    }
    if (onChange && !value) onChange(newValue)
    setSelectedOptions(newValue)
  }

  const renderSelectedItems = (selectedsValues) => {
    if (selectedOptions.length === 0) {
      return <em>{placeholder}</em>
    }
    const firstThree = [...selectedsValues]
    firstThree.splice(3)

    const toRender = firstThree.map((selectedOptionValue, index) => {
      const actualOption = options.find((option) => getOptionValue(option) === selectedOptionValue)
      if (!actualOption) return null
      return (
        <Chip
          key={String(selectedOptionValue) + index}
          label={getOptionLabel(actualOption)}
          sx={{ marginRight: '4px' }}
          onDelete={() => {
            const newValue = selectedOptions.filter((stateOption) => stateOption !== selectedOptionValue)
            if (onChange && !value) onChange(newValue)
            setSelectedOptions(newValue)
          }}
          deleteIcon={
            <Icon icon={faX} onMouseDown={(event) => event.stopPropagation()} sx={{ height: '13px', width: '9px' }} />
          }
        />
      )
    })

    if (selectedsValues.length > 3) {
      toRender.push(<Chip key='multiple-items-select-aditional' label={`+${selectedsValues.length - 3}`} />)
    }

    return toRender
  }

  return (
    <Select
      id='operatorsToShow'
      multiple
      IconComponent={(className) => <Icon icon={faChevronDown} {...className} />}
      displayEmpty
      value={selectedOptions}
      onChange={handleChange}
      renderValue={renderSelectedItems}
      MenuProps={{
        autoFocus: false,
      }}
      sx={{
        width: fullWidth ? '100%' : 'auto',
        ...variants[variant],
      }}
      {...props}
    >
      {hideFilter ? null : (
        <ListSubheader
          sx={{
            padding: '8px',
          }}
        >
          <Search
            sx={{ width: '100%' }}
            onSearch={(e) => setSearchTerm(e)}
            onKeyDown={(e) => e.stopPropagation()}
            searchOnChange
            placeholder='Pesquisar'
          />
        </ListSubheader>
      )}

      {filteredOptions.length !== options.length ? null : (
        <MenuItem value='all' sx={menuItemStyle}>
          <Checkbox checked={isAllSelected} />
          Todos
        </MenuItem>
      )}
      {filteredOptions.map((option) => (
        <MenuItem key={getOptionValue(option)} value={getOptionValue(option)} sx={menuItemStyle}>
          <Checkbox checked={selectedOptions.indexOf(getOptionValue(option)) > -1} />
          {getOptionLabel(option)}
        </MenuItem>
      ))}
    </Select>
  )
}
