import { faPencil } from '@fortawesome/free-solid-svg-icons'
import { Typography } from '@/presentation/components/atoms/typography'
import { TitleAndIconButton } from '@/presentation/components/molecules/titleAndIconButton'

export function Contacts({ contacts = [], onClick }) {
  return (
    <>
      <TitleAndIconButton title='Contato' icon={faPencil} onClick={onClick} />

      {contacts.map((contact) => (
        <>
          <Typography variant='h5'>{contact.label}</Typography>
          <Typography variant='body1'>{contact.value}</Typography>
        </>
      ))}
    </>
  )
}
