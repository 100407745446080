import { Box } from '@/presentation/components/atoms'
import { styled } from '@mui/material'

export const IconCircularWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '96px',
  height: '96px',
  borderRadius: '50%',
  margin: 'auto auto 32px auto',
  backgroundColor: theme.palette.grey[50],
}))
