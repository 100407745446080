import styled, { css } from 'styled-components'

export const CustomTable = styled.table`
  border-collapse: collapse;
  width: 100%;
`

export const TableContainer = styled.div`
  width: 100%;
  overflow: auto;
  ${({ maxHeight }) =>
    maxHeight &&
    css`
      max-height: ${maxHeight};
    `}
`
