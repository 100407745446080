import { styled } from '@mui/material'
import { NavLink } from 'react-router-dom'

export const StyledNavLink = styled(NavLink)(({ theme }) => ({
  color: theme.palette.common.purple,
  fontSize: '1rem',
  lineHeight: '1.5rem',
  fontWeight: 600,
  textDecoration: 'underline',
}))
