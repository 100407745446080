import api from '../infra/api'
import apiCallHandler from './apiCallHandler'

export const getIssParameters = () => apiCallHandler(() => api.get('resale-bff/parameters/iss/cities'))

export const getBrazilianStatesForIssCreation = () =>
  apiCallHandler(() => api.get('resale-bff/parameters/iss/new/states'))

export const getBrazilianCitiesForIssCreation = (stateId) =>
  apiCallHandler(() => api.get(`resale-bff/parameters/iss/new/${stateId}/cities`))

export const patchChangeIssParametersValue = (cityId, issValue) =>
  apiCallHandler(() => api.patch(`resale-bff/parameters/iss/${cityId}?iss=${issValue}`))

export const patchChangeIssParametersStatus = (cityId, parameterStatus) =>
  apiCallHandler(() => api.patch(`resale-bff/parameters/iss/${cityId}/activation?status=${parameterStatus}`))
