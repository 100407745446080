import React from 'react'
import { Theme, useMediaQuery, useTheme } from '@mui/material'
import _ from 'lodash'
import { ORDER_STATUS } from '@/main/utils/constants'
import { Box, Grid, Typography } from '@/presentation/components/atoms'
import { TypographyWithTooltip } from '@/presentation/components/molecules'

const getLegendTitleStyle = ({ theme }: { theme: Theme }) => {
  return {
    fontWeight: 500,
    color: theme.palette.grey[500],
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  }
}

export const CustomLegend = ({ entry }: { entry: any }) => {
  const { payload } = entry
  const theme = useTheme()
  const isSmallerThanMd = useMediaQuery(theme.breakpoints.down('md'))

  const renderInfo = ({ payload }: { payload: any }) => {
    return payload.map((item: any) => {
      if (item?.payload?.payload?.orderStatus === null) return null
      return (
        <React.Fragment key={'pieChartItem' + _.uniqueId()}>
          <Grid item xs={6} md={7} lg={6} xl={8} sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              sx={{
                minWidth: '8px',
                minHeight: '6px',
                backgroundColor: item.payload.fill,
                display: 'inline-block',
                marginRight: '8px',
              }}
            />
            <TypographyWithTooltip variant='text2' sx={{ whiteSpace: 'nowrap' }}>
              {ORDER_STATUS[item.value] || 'Desconhecido'}
            </TypographyWithTooltip>
          </Grid>
          <Grid item xs={6} md={5} lg={6} xl={4}>
            <Typography variant='text2' sx={{ textAlign: isSmallerThanMd ? 'right' : '', display: 'block' }}>
              {item.payload.value} ({item.payload.percentualValue}%)
            </Typography>
          </Grid>
        </React.Fragment>
      )
    })
  }

  const itemsToRender = renderInfo({ payload })
  if (itemsToRender.every((order) => order === null)) return null

  return (
    <Grid
      container
      spacing='16px'
      sx={(theme: Theme) => ({
        color: theme.palette.grey[700],
        textTransform: 'capitalize',
      })}
    >
      <Grid item xs={6} md={7} lg={6} xl={8}>
        <Typography variant='label3' sx={(theme: Theme) => getLegendTitleStyle({ theme })}>
          Status
        </Typography>
      </Grid>
      <Grid item xs={6} md={5} lg={6} xl={4}>
        <Typography
          variant='label3'
          sx={(theme: Theme) => ({
            ...getLegendTitleStyle({ theme }),
            textAlign: isSmallerThanMd ? 'right' : '',
            display: 'block',
          })}
        >
          Quantidade
        </Typography>
      </Grid>
      {itemsToRender}
    </Grid>
  )
}
