import Moment from 'react-moment'
import { ORDER_STATUS } from '@/main/utils/constants'
import { BenefitsIcons } from '../../../utils/benefitsIcons/benefitsIcons'
import { Box, ColorDot } from '@/presentation/components/atoms'
import { AutoTableProps } from '@/presentation/components/molecules'
import { ICardOrdersColumns } from '../companyCardOrders'

export const companyCardOrderTableConfiguration = ({ updateFilter }) => {
  return [
    {
      columnDisplayName: 'Data Pedido',
      columnValue: ({ row }) => row.orderDate,
      onOrder: ({ order }) => updateFilter({ sort: 'orderDate', desc: !order }),
      customRender: ({ row }) => (row.orderDate ? <Moment format='DD/MM/YYYY'>{row.orderDate}</Moment> : '-'),
    },

    {
      columnDisplayName: 'N° Pedido',
      columnValue: ({ row }) => row.code,
      onOrder: ({ order }) => updateFilter({ sort: 'code', desc: !order }),
    },
    {
      columnDisplayName: 'Via',
      columnValue: ({ row }) => `${row.cardCopy || '?'}ª via`,
    },
    {
      columnDisplayName: 'Qtde. Cartões',
      columnValue: ({ row }) => row.cardsQuantity,
    },
    {
      columnDisplayName: 'Benefício(s)',
      align: 'center',
      columnValue: ({ row }) => row.benefits,
      customRender: ({ row }) => <BenefitsIcons benefits={row.benefits} noCardMessage={false} />,
    },
    {
      columnDisplayName: 'Status',
      columnValue: ({ row }) => row.status,
      customRender: ({ row }) => {
        let dotColor = 'warning'
        if (row.status === ORDER_STATUS.FINISHED) dotColor = 'success'
        if (row.status === ORDER_STATUS.CANCELED) dotColor = 'grey'
        return (
          <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center', textTransform: 'capitalize' }}>
            <ColorDot color={dotColor} status={ORDER_STATUS[row.status]} />
            {ORDER_STATUS[row.status]?.toLowerCase()}
          </Box>
        )
      },
    },
  ] as AutoTableProps<ICardOrdersColumns>['columns']
}
