import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { styled } from '@mui/material'
import { IFieldStatus } from '@/main/interfaces'

export const StyledDatePicker = styled(DatePicker)<IFieldStatus>(({ theme, blocked }) => ({
  '& .MuiInputBase-root': {
    height: 48,
    borderRadius: 8,
    backgroundColor: blocked ? theme.palette.grey[50] : 'initial',
    overflow: 'hidden',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: blocked ? 'none' : '',
  },
  '& .MuiInputBase-input': {
    padding: '12px 8px',
    textFillColor: 'unset !important',
    color: theme.palette.grey[700],
  },
}))
