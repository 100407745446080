import { all, takeLatest, put, call } from 'redux-saga/effects'
import { getDraftRecharge, getRecharge } from '@/services/rechargesServices'
import { ACTIONS, rechargeRequestFailure, rechargeRequestSuccess } from './actions'

function* fetchRecharge({ payload }) {
  const { response, error } = yield call(getRecharge, payload.rechargeOrderId)
  if (response) yield put(rechargeRequestSuccess(response.data))
  else yield put(rechargeRequestFailure(error.response))
}

function* fetchDraftRecharge({ payload }) {
  const { response, error } = yield call(getDraftRecharge, payload.query)
  if (response) yield put(rechargeRequestSuccess(response.data))
  else yield put(rechargeRequestFailure(error.response))
}

export default all([
  takeLatest(ACTIONS.GET_RECHARGE_REQUEST, fetchRecharge),
  takeLatest(ACTIONS.GET_DRAFT_RECHARGE_REQUEST, fetchDraftRecharge),
])
