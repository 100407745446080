import { Box, Paper, styled } from '@mui/material'

export const StyledBox = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  overflow: 'auto hidden',
  background: `radial-gradient(${theme.palette.primary.dark}, ${theme.palette.primary.main})`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 32,
}))

export const StyledPaper = styled(Paper)(({ color }) => ({
  width: 490,
  padding: 32,
  borderRadius: 16,
  '& h1': {
    marginBottom: 16,
  },
  '& p': {
    marginBottom: 48,
  },
  '& form': {
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
  },
  '& a': {
    marginBottom: 44,
  },
}))
