import styled, { css } from 'styled-components'

const STYLES = {
  default: css`
    font-weight: var(--font-weight-semi-bold);
  `,
  underlined: css`
    text-decoration: underline;
  `,
}

export const Decoration = styled.span`
  color: var(--color-primary-01);
  ${({ variant }) => STYLES[variant] || STYLES.default}
`
