import { Select, styled } from '@mui/material'
import { Box } from '@stationkim/front-ui'

export const RowSelectorContainer = styled(Box)(() => ({}))

export const SelectorLabel = styled('span')(({ theme }) => ({
  color: theme.palette.grey[500],
  fontSize: '.75rem',
}))

export const StyledSelected = styled(Select)(() => ({
  fontSize: '.75rem',
  '& .MuiSelect-outlined': {
    padding: '0 32px 0 8px',
  },
  '& fieldset': {
    border: 'none',
  },
}))
