import React, { useRef } from 'react'

import { SearchContainer, Field } from './style'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { Icon } from '../atoms'

function Search({ search, preventEnter, searchOnBlur, ...props }) {
  const ref = useRef(null)

  return (
    <SearchContainer>
      <Field
        ref={ref}
        {...props}
        onKeyDown={(e) => {
          if (!preventEnter && e.key === 'Enter') search(ref.current.value)
        }}
        onBlur={() => searchOnBlur && search(ref.current.value)}
      />
      <Icon
        icon={faMagnifyingGlass}
        sx={(theme) => ({ color: theme.palette.grey[500] })}
        onClick={() => search(ref.current.value)}
      />
    </SearchContainer>
  )
}

export default Search
