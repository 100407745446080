import { useContext } from 'react'
import { GridProps } from '@mui/material'
import { useFieldArray } from 'react-hook-form'
import { faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons'
import { Button, Grid, Icon, IconButton, Typography } from '@/presentation/components/atoms'
import { FormContext } from '../form/form'

interface FormArrayProps {
  name: string
  label?: string
  gridProps?: GridProps
  canDeleteAll?: boolean
  canDeleteFirst?: boolean
  isBlocked?: boolean
  maxAmount?: number
  addText?: string
  render: ({ fieldsValues, indexName, index }) => React.ReactNode | Array<React.ReactNode>
}

const ArrayCounter = ({ isDeletable, index, fields, onRemove }) => {
  return (
    <Grid item xs={12} sx={{ display: 'flex', gap: '32px' }}>
      {fields.length > 1 && <Typography> {`${index + 1}.`}</Typography>}
      {isDeletable && (
        <IconButton color='error' size='sm' onClick={() => onRemove(index)}>
          <Icon icon={faTrash} />
        </IconButton>
      )}
    </Grid>
  )
}

export const FormArray = ({
  name,
  label,
  gridProps = { spacing: '16px' },
  canDeleteAll = false,
  canDeleteFirst = false,
  isBlocked,
  maxAmount = 3,
  addText = 'Adicionar',
  render,
}: FormArrayProps) => {
  const { form, isEditable } = useContext(FormContext)
  const { control } = form
  const { fields, remove, append } = useFieldArray({
    name,
    control,
  })

  const isOnLimit = fields.length >= maxAmount
  const arrayRender = () => {
    return fields.map((group: any, index: number) => {
      const isDeletable = (canDeleteFirst && fields.length > 1) || index !== 0 || canDeleteAll
      return (
        <Grid container {...gridProps} key={group.id}>
          <ArrayCounter index={index} fields={fields} isDeletable={isDeletable} onRemove={onRemoveClick} />
          {render({
            fieldsValues: form.getValues(`${name}.${index}`),
            indexName: `${name}.${index}.`,
            index,
          })}
        </Grid>
      )
    })
  }

  const onAddClick = () => {
    if (isOnLimit) return
    append({})
  }

  const onRemoveClick = (index: number) => {
    if (fields.length <= 1 && !canDeleteAll) return
    remove(index)
  }

  return (
    <>
      {label && (
        <Grid item xs='auto'>
          <Typography variant='subtitle1'>{label}</Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        {arrayRender()}
        {isEditable && !isOnLimit && !isBlocked && (
          <Grid item xs={12}>
            <Button variant='softText' onClick={onAddClick} startIcon={<Icon icon={faPlusCircle} />}>
              {addText}
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  )
}
