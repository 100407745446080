import { StyledSpinner } from './styles'
import { ReactComponent as SpinnerSvg } from '@/presentation/assets/spinner.svg'

export function Spinner({ ...props }) {
  return (
    <StyledSpinner {...props}>
      <SpinnerSvg />
    </StyledSpinner>
  )
}
