import React from 'react'
import { Col, Container } from 'react-grid-system'
import { Controller, useForm } from 'react-hook-form'
import { Button, Input, ModalV2, Spinner } from '@/presentation/components'
import useDownloadReport from '@/main/hooks/useDownloadReport'
import useRange from '@/main/hooks/useRange'
import { getRepassReport } from '@/services/reportsServices'
import { TEMP_OPERATOR_ID } from '@/main/utils/constants'
import { dateToApiStandards } from '@/main/utils/functionUtils'
import objectToQueryString from '@/main/utils/objectToQuerystring'
import { ModalTitle } from '../styles'
import { CheckBoxWrapper, CustomRow, Label, Period } from './styles'

function TransferReportFilter({ open, onClose }) {
  const { initialRangeValue, finalRangeValue } = useRange(null, new Date())
  const [downloadState, download] = useDownloadReport(async (query) => {
    try {
      const response = await getRepassReport(TEMP_OPERATOR_ID, query)

      return response
    } catch (e) {
      return
    }
  })
  const { register, handleSubmit, control, formState } = useForm({
    mode: 'onChange',
    defaultValues: {
      code: '',
      startDate: '',
      endDate: '',
    },
  })

  const onSubmit = (data) => {
    data.startDate = dateToApiStandards(data.startDate)
    data.endDate = dateToApiStandards(data.endDate)
    if (!data) return
    const query = objectToQueryString(data)
    download('Relatório de Repasse para a Operadora.pdf', query)
  }

  return (
    <ModalV2 open={open} onClose={onClose}>
      {downloadState.loading ? (
        <Spinner />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} id='TransferReportFilterForm'>
          <Container>
            <CustomRow>
              <Col>
                <ModalTitle>Relatório de Repasse para a Operadora</ModalTitle>
              </Col>
            </CustomRow>
            <CustomRow>
              <Col sm={12}>
                <Label>N° do Pedido</Label>
                <Input
                  type='number'
                  placeholder='Digite o n° do pedido'
                  {...register('code')}
                  disabled={formState.dirtyFields.startDate || formState.dirtyFields.endDate}
                />
              </Col>
            </CustomRow>
            <Period>
              <Col sm={12}>
                <Label>Período de estocagem:</Label>
              </Col>
            </Period>
            <CustomRow>
              <Col sm={6}>
                <Label>De</Label>
                <Controller
                  control={control}
                  name='startDate'
                  render={({ field: { onChange, value, ref } }) => (
                    <Input.Date
                      value={value}
                      onChange={(e) => {
                        onChange(e)
                        e !== '' && initialRangeValue.set(e)
                      }}
                      getInputRef={ref}
                      maxDate={finalRangeValue.get}
                      disabled={formState.dirtyFields.code}
                    />
                  )}
                />
              </Col>
              <Col sm={6}>
                <Label>Até</Label>
                <Controller
                  control={control}
                  name='endDate'
                  render={({ field: { onChange, value, ref } }) => (
                    <Input.Date
                      value={value}
                      onChange={(e) => {
                        onChange(e)
                        e !== '' && finalRangeValue.set(e)
                      }}
                      maxDate={finalRangeValue.get}
                      getInputRef={ref}
                      minDate={initialRangeValue.get}
                      disabled={formState.dirtyFields.code}
                    />
                  )}
                />
              </Col>
            </CustomRow>
            <CustomRow>
              <Col sm={2}>
                <CheckBoxWrapper>
                  <Input.CheckBox checked controlled />
                  <span>PDF</span>
                </CheckBoxWrapper>
              </Col>
            </CustomRow>
            <CustomRow justify='end'>
              <Col sm={5}>
                <Button>Gerar Relatório</Button>
              </Col>
            </CustomRow>
          </Container>
        </form>
      )}
    </ModalV2>
  )
}

export default TransferReportFilter
