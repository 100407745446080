import api from '../infra/api'
import apiCallHandler from './apiCallHandler'

export const getRecharge = (rechargeId) => apiCallHandler(() => api.get(`resale-bff/orders/recharge/${rechargeId}`))

export const getRecharges = (query) => apiCallHandler(() => api.get(`resale-bff/recharge/orders${query}`))

export const getDraftRecharge = (rechargeId) =>
  apiCallHandler(() => api.get(`resale-bff/rh/recharge/new/informations/${rechargeId}`))

export const getDownloadSlip = (orderId) => apiCallHandler(() => api.get(`resale-bff/orders/recharge/${orderId}/slip`))

// Broker

export const patchStartOrderProcessing = (orderId) =>
  apiCallHandler(() => api.patch(`resale-bff/orders/${orderId}/process`))

export const postSendFileForStartProcessing = (orderId, payload) =>
  apiCallHandler(() => api.post(`resale-bff/orders/${orderId}/import-balance`, payload))

export const postSendBrokerOrderSlip = (orderId, payload) =>
  apiCallHandler(() => api.post(`resale-bff/orders/${orderId}/upload/slip`, payload))

export const postSendProofOfPayment = (orderId, payload) =>
  apiCallHandler(() => api.post(`resale-bff/orders/${orderId}/upload/proof-Payment`, payload))

export const postCalculateBrokerOrderProjection = (orderId) =>
  apiCallHandler(() => api.post(`resale-bff/orders/${orderId}/calculate-projection`))

export const getOrderLayout = (orderId) => apiCallHandler(() => api.get(`resale-bff/orders/${orderId}/operator-import`))

export const getOrderXls = (orderId) =>
  apiCallHandler(() => api.get(`resale-bff/orders/${orderId}/exportexcelorderitems`, { responseType: 'blob' }))

export const getDownloadRechargeOrderSlip = (orderId) =>
  apiCallHandler(() => api.get(`resale-bff/orders/recharge/${orderId}/slip`))

export const getDownloadRechargeOrderOperatorSlip = (orderId) =>
  apiCallHandler(() => api.get(`resale-bff/orders/${orderId}/download/slip`))

export const getDownloadCardOrderSlip = (orderId) =>
  apiCallHandler(() => api.get(`resale-bff/orders/card/${orderId}/slip`))
