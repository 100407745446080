import { Tabs, styled } from '@mui/material'
import { TabsProps } from './tabs'

export const StyledTabs = styled(Tabs)<TabsProps>(({ asGroupButtons, theme }) => {
  if (asGroupButtons)
    return {
      minHeight: '44px',
      '& .MuiButtonBase-root': {
        textTransform: 'capitalize',
        position: 'relative',
        minHeight: '44px',
        padding: '10px 16px',
      },
      '& .MuiButtonBase-root:not(:last-child):after': {
        content: "' '",
        position: 'absolute',
        top: '50%',
        right: 0,
        height: '28px',
        width: '1px',
        backgroundColor: theme.palette.grey[400],
        transform: 'translateY(-50%)',
      },
      '& .MuiButtonBase-root.Mui-selected': {
        backgroundColor: theme.palette.primary.main + '1A',
        color: theme.palette.primary.main,
      },
      '& .MuiTabs-indicator': {
        backgroundColor: 'unset',
      },
    }
  else
    return {
      minHeight: '42px',
      '& .MuiButtonBase-root': {
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '24px',
        minHeight: '42px',
        padding: '9px 16px',
      },
    }
})
