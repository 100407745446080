import styled from 'styled-components'

export const Body = styled.div`
  padding: 32px;
  z-index: 99;

  label {
    margin-top: 32px;
    margin-bottom: 8px;
    display: inline-block;
  }

  form label {
    white-space: nowrap;
  }
`

export const Title = styled.p`
  display: inline-block;
  color: var(--color-primary-01);
  font-weight: var(--font-weight-bold);
  font-size: 1.5rem;
  letter-spacing: 0.29px;
  padding-bottom: 32px;
`

export const ColorDest = styled.span`
  color: #632ecb;
  font-weight: bold;
`

export const SessionButton = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 10vh auto 0 auto;
  padding-bottom: 20px;
`

export const Close = styled.p`
  display: inline-block;
  cursor: pointer;
  text-align: right;
  font-size: 20px;

  svg {
    fill: var(--color-primary-03);
  }
`
