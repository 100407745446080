import React, { useState } from 'react'

import { Content, Breadcrumb, Panel, Title, Spacing } from '@/presentation/components'

import { Category, Categories, Container, ReportsList, Group, SubTitle } from './style'

import Report from './report'
import { REPORTS_CATEGORIES } from '@/main/utils/constants'
import CardOrderReportFilter from './modals/cardOrderReportFilter'
import RechargeOrderReportFilter from './modals/rechargeOrderReportFilter'
import TransferReportFilter from './modals/transferReportFilter'
import GenericCompanyReportFilter from './modals/genericCompanyReportFilter'
import useDownloadReport from '@/main/hooks/useDownloadReport'
import {
  getAnaliticCardBalanceReport,
  getBlockedCardsWithoutSecondCopyReport,
  getCardsLoadedAndNoLoaded,
  getCardsWithoutRechargeReport,
  getSyntheticCardBalanceReport,
} from '@/services/reportsServices'
import GenericCardReportFilter from './modals/genericCardReportFilter'
import GenericOrderReportFilter from './modals/genericOrderReportFilter'
import BalanceProjectionOrderReportFilter from './modals/balanceProjectionOrderReportFilter'
import OrderMovementReportFilter from './modals/orderMovementReportFilter'

function Reports() {
  const [filterBy, setFilterBy] = useState(null)
  const [filterModalsVisibility, setFilterModalsVisibility] = useState({})
  const [cardsWithoutRechargeReport, downloadCardsWithoutRechargeReport] = useDownloadReport(
    async (title, companyId) => {
      try {
        const response = await getCardsWithoutRechargeReport(title, companyId)

        return response
      } catch (e) {
        return
      }
    },
  )
  const [blockedCardsWithoutSecondCopyReport, downloadBlockedCardsWithoutSecondCopyReport] = useDownloadReport(
    async (title, companyId) => {
      try {
        const response = await getBlockedCardsWithoutSecondCopyReport(title, companyId)

        return response
      } catch (e) {
        return
      }
    },
  )
  const [cardLoadedAndNoLoaded, downloadCardLoadedAndNoLoaded] = useDownloadReport(async (title, code, companyId) => {
    try {
      const response = await getCardsLoadedAndNoLoaded(title, code, companyId)

      return response
    } catch (e) {
      return
    }
  })
  const [analiticCardBalanceReport, downloadAnaliticCardBalanceReport] = useDownloadReport(async (title, payload) => {
    try {
      const response = await getAnaliticCardBalanceReport(title, payload)

      return response
    } catch (e) {
      return
    }
  })
  const [syntheticCardBalanceReport, downloadSyntheticCardBalanceReport] = useDownloadReport(async (title, payload) => {
    try {
      const response = await getSyntheticCardBalanceReport(title, payload)

      return response
    } catch (e) {
      return
    }
  })

  const toggleFilterModalVisibility = (filterName) => {
    setFilterModalsVisibility((state) => {
      const newState = { ...state }
      if (newState[filterName]) {
        newState[filterName] = false
      } else newState[filterName] = true
      return newState
    })
  }
  const hideCategory = (category) => filterBy !== category && filterBy !== null
  return (
    <>
      <Content>
        <Title>Relatórios</Title>
        <Spacing top='9px' bottom='9px'>
          <Breadcrumb />
        </Spacing>
        <Panel noPadding>
          <Container>
            <Categories>
              <SubTitle>Categorias</SubTitle>
              <Category active={filterBy === null} onClick={() => setFilterBy(null)}>
                Todos
              </Category>
              {Object.keys(REPORTS_CATEGORIES).map((category, index) => (
                <Category
                  key={`report_category_${index}`}
                  active={filterBy === REPORTS_CATEGORIES[category]}
                  onClick={() => setFilterBy(REPORTS_CATEGORIES[category])}
                >
                  {REPORTS_CATEGORIES[category]}
                </Category>
              ))}
            </Categories>
            <ReportsList>
              <SubTitle>Relatórios</SubTitle>
              <Group hidden={hideCategory(REPORTS_CATEGORIES.ORDERS)}>
                <Report generateReport={() => toggleFilterModalVisibility('orderMovementReport')}>
                  Movimentação de Pedidos
                </Report>
                <Report generateReport={() => toggleFilterModalVisibility('rechargeOrderReport')}>
                  Pedido de Recarga
                </Report>
                <Report generateReport={() => toggleFilterModalVisibility('balanceProjectionOrderReport')}>
                  Pedido de Recarga Com Projeção de Saldo
                </Report>
                <Report generateReport={() => toggleFilterModalVisibility('cardOrderReport')}>Pedido de Cartão</Report>
              </Group>
              <Group hidden={hideCategory(REPORTS_CATEGORIES.CARDS)}>
                <Report generateReport={() => toggleFilterModalVisibility('cardsWithoutRechargeByDaysReport')}>
                  Cartões sem Pedido por 90 Dias
                </Report>
                <Report generateReport={() => toggleFilterModalVisibility('blockedCardsWithoutSecondCopyReport')}>
                  Cartões Bloqueados sem 2ª Via
                </Report>
                <Report generateReport={() => toggleFilterModalVisibility('cardLoadedAndNoLoadedReport')}>
                  Cartões Carregados e Não Carregados
                </Report>
                {/*
                <Report>
                  Cartões Substituídos
                </Report>
                <Report>
                  Status de Cartão
                </Report> */}
              </Group>
              {/*  <Group hidden={hideCategory(REPORTS_CATEGORIES.EMPLOYESS)}>
                <Report>
                  Colaboradores
                </Report>
              </Group>
              <Group hidden={hideCategory(REPORTS_CATEGORIES.BENEFIT)}>
                <Report>
                  Recibo de Benefícios
                </Report>
                <Report>
                  Benefício Médio por Colaborador
                </Report>
              </Group> */}
              <Group hidden={hideCategory(REPORTS_CATEGORIES.BALANCE)}>
                <Report generateReport={() => toggleFilterModalVisibility('cardAnaliticBalanceReport')}>
                  Saldo Analítico
                </Report>
                <Report generateReport={() => toggleFilterModalVisibility('cardSinteticBalanceReport')}>
                  Saldo Sintético
                </Report>
              </Group>
              <Group hidden={hideCategory(REPORTS_CATEGORIES.REPASS)}>
                <Report generateReport={() => toggleFilterModalVisibility('transferReportFilter')}>
                  Relatório de Repasse
                </Report>
              </Group>
            </ReportsList>
          </Container>
        </Panel>
      </Content>
      {filterModalsVisibility.orderMovementReport && (
        <OrderMovementReportFilter
          open={filterModalsVisibility.orderMovementReport}
          onClose={() => toggleFilterModalVisibility('orderMovementReport')}
        />
      )}
      {filterModalsVisibility.cardOrderReport && (
        <CardOrderReportFilter
          open={filterModalsVisibility.cardOrderReport}
          onClose={() => toggleFilterModalVisibility('cardOrderReport')}
        />
      )}
      {filterModalsVisibility.rechargeOrderReport && (
        <RechargeOrderReportFilter
          open={filterModalsVisibility.rechargeOrderReport}
          onClose={() => toggleFilterModalVisibility('rechargeOrderReport')}
        />
      )}
      {filterModalsVisibility.balanceProjectionOrderReport && (
        <BalanceProjectionOrderReportFilter
          open={filterModalsVisibility.balanceProjectionOrderReport}
          loading={false}
          title='Relatório de Pedido de Recarga Com Projeção de Saldo '
          onConfirm={(inputData) => null}
          onClose={() => toggleFilterModalVisibility('balanceProjectionOrderReport')}
        />
      )}
      {filterModalsVisibility.cardAnaliticBalanceReport && (
        <GenericCardReportFilter
          open={filterModalsVisibility.cardAnaliticBalanceReport}
          onClose={() => toggleFilterModalVisibility('cardAnaliticBalanceReport')}
          loading={analiticCardBalanceReport.loading}
          title='Relatório de Saldo Analítico'
          download={(company, payload) =>
            downloadAnaliticCardBalanceReport(`Relatório de Saldo Analítico - ${company.tradeName}.pdf`, payload)
          }
        />
      )}
      {filterModalsVisibility.cardSinteticBalanceReport && (
        <GenericCardReportFilter
          open={filterModalsVisibility.cardSinteticBalanceReport}
          onClose={() => toggleFilterModalVisibility('cardSinteticBalanceReport')}
          loading={syntheticCardBalanceReport.loading}
          title='Relatório de Saldo Sintético'
          download={(company, payload) =>
            downloadSyntheticCardBalanceReport(`Relatório de Saldo Sintético - ${company.tradeName}.pdf`, payload)
          }
        />
      )}
      {filterModalsVisibility.cardLoadedAndNoLoadedReport && (
        <GenericOrderReportFilter
          open={filterModalsVisibility.cardLoadedAndNoLoadedReport}
          onClose={() => toggleFilterModalVisibility('cardLoadedAndNoLoadedReport')}
          loading={cardLoadedAndNoLoaded.loading}
          title='Relatório de Cartões Carregados e Não Carregados'
          download={(company, data) =>
            downloadCardLoadedAndNoLoaded(
              `Relatório de cartões carregados e não carregados - ${company.tradeName} - Pedido #${data.code}.pdf`,
              data.code,
              data.companyId,
            )
          }
        />
      )}
      {filterModalsVisibility.cardsWithoutRechargeByDaysReport && (
        <GenericCompanyReportFilter
          reportName='Relatório de Cartões sem Pedido por 90 Dias'
          requestReport={(companyId, companyName) =>
            downloadCardsWithoutRechargeReport(
              `Relatório de Cartões sem Pedido por 90 Dias - ${companyName}.pdf`,
              `?companyId=${companyId}&days=90`,
            )
          }
          loading={cardsWithoutRechargeReport.loading}
          open={filterModalsVisibility.cardsWithoutRechargeByDaysReport}
          onClose={() => toggleFilterModalVisibility('cardsWithoutRechargeByDaysReport')}
        />
      )}
      {filterModalsVisibility.blockedCardsWithoutSecondCopyReport && (
        <GenericCompanyReportFilter
          reportName='Cartões Bloqueados sem 2ª Via'
          requestReport={(companyId, companyName) =>
            downloadBlockedCardsWithoutSecondCopyReport(
              `Relatório de Cartões Bloqueados sem 2ª Via - ${companyName}.pdf`,
              `?companyId=${companyId}`,
            )
          }
          loading={blockedCardsWithoutSecondCopyReport.loading}
          open={filterModalsVisibility.blockedCardsWithoutSecondCopyReport}
          onClose={() => toggleFilterModalVisibility('blockedCardsWithoutSecondCopyReport')}
        />
      )}
      {filterModalsVisibility.transferReportFilter && (
        <TransferReportFilter
          open={filterModalsVisibility.transferReportFilter}
          onClose={() => toggleFilterModalVisibility('transferReportFilter')}
        />
      )}
    </>
  )
}

export default Reports
