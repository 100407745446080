import { TypographyProps } from '@mui/material'
import { Tooltip, Typography } from '../../atoms'
import { useEffect, useRef, useState } from 'react'

interface TypographyWithTooltipProps extends TypographyProps {
  isTooltipAlwaysVisible?: boolean
}

export const TypographyWithTooltip = ({
  children,
  isTooltipAlwaysVisible,
  sx = {},
  ...props
}: TypographyWithTooltipProps) => {
  const [isOverflowing, setIsOverflowing] = useState<boolean>(isTooltipAlwaysVisible || true)

  const textRef = useRef(null)

  useEffect(() => {
    setIsOverflowing(textRef.current.scrollWidth !== textRef.current.clientWidth)
  }, [textRef])

  return (
    <Tooltip title={isOverflowing ? children : null}>
      <Typography
        ref={textRef}
        sx={{
          ...sx,
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
        {...props}
      >
        {children}
      </Typography>
    </Tooltip>
  )
}
