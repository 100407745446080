import { BoxProps } from '@mui/material'
import { IFieldStatus } from '@/main/interfaces'
import { Typography } from '../typography'
import { StyledBox } from './styles'

export interface FieldWrapperProps extends IFieldStatus, BoxProps {
  helperText?: string
  fullWidth?: boolean
}

export function FieldWrapper({ children, fullWidth = true, helperText, ...props }: FieldWrapperProps) {
  return (
    <StyledBox fullWidth={fullWidth} {...props}>
      {children}
      {helperText && <Typography>{helperText}</Typography>}
    </StyledBox>
  )
}
