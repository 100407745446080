import api from '@/infra/api'
import { useQuery } from '@tanstack/react-query'

export const useContactTypes = () => {
  const { data: addressCodes, ...rest } = useQuery({
    queryKey: ['contactTypes'],
    queryFn: () => api.get(`resale-bff/contact/contact-types`),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })

  return {
    contactTypes: addressCodes?.data?.data || [],
    ...rest,
  }
}
