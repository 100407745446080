import React from 'react'
import { Body } from './style'

function Spacing({ children, top, bottom, left, right, props }) {
  return (
    <Body top={top} bottom={bottom} left={left} right={right} {...props}>
      {children}
    </Body>
  )
}

export default Spacing
