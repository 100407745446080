import styled from 'styled-components'

export const Header = styled.div`
  background-color: ${({ isOpened }) => (isOpened ? 'var(--color-secundary-05);' : 'var(--color-primary-05);')};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 86px;
  width: 100%;
  padding: 16px;
  transition: all 0.15s linear;

  p {
    font-weight: var(--font-weight-bold);
  }

  svg {
    height: 32px;
    width: 32px;
    transform: ${({ isOpened }) => (isOpened ? 'scaleY(-1)' : 'scaleY(1)')};
  }
  :hover {
    background-color: var(--color-secundary-05);
  }
`

export const SpoilerContainer = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  transition: max-height 0.5s linear;
  max-height: ${({ isOpened }) => (isOpened ? 'auto' : '0px')};
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  span,
  svg {
    height: 20px;
  }
`
