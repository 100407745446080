export function companyRequest(query) {
  return {
    type: '@company/COMPANY_REQUEST',
    payload: { query },
  }
}

export function companySuccess(companys) {
  return {
    type: '@company/COMPANY_SUCCESS',
    payload: { companys },
  }
}

export function companyFailure() {
  return {
    type: '@company/COMPANY_ERROR',
  }
}
