import { useCallback, useState } from 'react'
import { Accept, useDropzone } from 'react-dropzone'
import { faFileArrowUp } from '@fortawesome/free-solid-svg-icons'
import { Icon, Typography } from '@/presentation/components/atoms'
import { DropZone, DropZoneIconWrapper } from './style'
import { bytesToHumanFileSize } from '@/main/utils/functions'
import { SxProps } from '@mui/material'

const convertAcceptToFormatsLabel = (accept: Accept) => {
  const extensions = []
  for (const format in accept) {
    accept[format].forEach((extension: string) => extensions.push(extension.toLocaleUpperCase().replace('.', '')))
  }
  return 'Formatos: ' + extensions.join(', ')
}

interface IFileUpload {
  accept?: Accept
  maxSize?: number
  initialValue?: File
  sx: SxProps
  value?: File
  onChange?: (file: File) => void
  inputProps?: any
}

export const FileUploadCore = (params?: IFileUpload) => {
  const { accept, maxSize = Infinity, initialValue, onChange, value, sx, inputProps = {} } = params

  const [innerFile, setInnedFile] = useState<File>(initialValue ?? null)
  const file = value ? value : innerFile
  const setFile = value ? onChange : setInnedFile

  const onDrop = useCallback(
    (acceptedFiles: Array<File>) => {
      setFile(acceptedFiles[0])
      if (onChange && !value) onChange(acceptedFiles[0])
    },
    [onChange, setFile, value],
  )

  const { getRootProps, getInputProps, isDragReject } = useDropzone({
    onDrop,
    accept,
    maxFiles: 1,
    maxSize,
  })

  return (
    <>
      <DropZone hasFile={Boolean(file)} {...getRootProps()} isRejected={isDragReject} sx={sx}>
        <input {...inputProps} {...getInputProps()} />
        <DropZoneIconWrapper hasFile={Boolean(file)}>
          <Icon icon={faFileArrowUp} />
        </DropZoneIconWrapper>
        {isDragReject ? (
          <Typography>Arquivo não suportado, utilize um dos formatos abaixo</Typography>
        ) : (
          <>
            <Typography
              sx={{
                maxWidth: '60%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {file && file.name}
            </Typography>
            <Typography
              sx={{
                padding: '0 16px',
              }}
            >
              <span>Clique para {file ? 'reenviar' : 'enviar'}</span> ou arraste aqui
              {file && ' para substituir o arquivo'}
            </Typography>
          </>
        )}
        <Typography variant='body2' color='grey.600'>
          {convertAcceptToFormatsLabel(accept)}
          {maxSize !== Infinity && ` (max. ${bytesToHumanFileSize(maxSize)})`}
        </Typography>
      </DropZone>
    </>
  )
}
