import { Customization } from '../../tabs/platform/customization/customization'

import { Suspense, lazy } from 'react'

const Register = lazy(() => import('../../tabs/users/register/register'))
const AccessProfile = lazy(() => import('../../tabs/users/accessProfile/accessProfile'))

export const tabs = {
  1: (
    <Suspense>
      <Register />
    </Suspense>
  ),
  2: (
    <Suspense>
      <AccessProfile />
    </Suspense>
  ),
  3: <Customization />,
}
