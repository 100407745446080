import styled from 'styled-components'

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 96px;
  width: 96px;
  border-radius: 50%;
  background-color: var(--color-secundary-02);
  margin: auto;

  svg {
    min-height: 64px;
    min-width: 64px;
    color: var(--color-message-03);
  }
`

export const WarningTitle = styled.p`
  color: var(--color-message-03);
  font-weight: var(--font-weight-bold);
  text-align: center;
  font-size: 1.5rem;
  margin: 32px auto;
`

export const Text = styled.p`
  text-align: center;

  & + & {
    margin-top: 16px;
  }

  strong {
    color: var(--color-primary-06);
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const Button = styled.button`
  height: 48px;
  text-align: center;
  margin-top: 64px;
  padding: 12px 40px;
  border-radius: 8px;
  border: 1px solid;
  background-color: transparent;
  font-weight: var(--font-weight-bold);
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: ${({ red }) => (red ? 'var(--color-message-03)' : '#666666')};
  border-color: ${({ red }) => (red ? 'var(--color-message-03)' : '#999999')};
  cursor: pointer;
  & + & {
    margin-left: 32px;
  }
`
