import produce from 'immer'
import _ from 'lodash'
const INITIAL_STATE = {
  error: null,
  loading: false,
  data: null,
}

export default function user(state = INITIAL_STATE, action) {
  const { type, payload } = action

  return produce(state, (draft) => {
    switch (type) {
      case '@auth/SIGN_IN_SUCCESS': {
        draft.data = payload.user
        break
      }

      case '@auth/SIGN_OUT': {
        draft.data = null
        break
      }

      case '@user/UPDATE': {
        draft.data = _.merge(draft.data, payload)
        break
      }

      default:
    }
  })
}
