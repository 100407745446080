import * as yup from 'yup'

const schema = yup.object().shape({
  password: yup
    .string()
    .required('Esse é um campo requerido')
    .min(8, '')
    .max(100, '')
    .test('atleast-one-lower', '', (value) => value.match(/[a-z]/))
    .test('atleast-one-upper', '', (value) => value.match(/[A-Z]/))
    .test('atleast-one-upper', '', (value) => value.match(/[0-9]/))
    .test('atleast-one-special', '', (value) => value.match(/[^A-Za-z0-9]/)),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'As senhas não são iguais')
    .required('Esse é um campo requerido'),
})

export default schema
