import { faPencil } from '@fortawesome/free-solid-svg-icons'
import { Image } from '@/presentation/components/atoms/image'
import { Typography } from '@/presentation/components/atoms/typography'
import { TitleAndIconButton } from '@/presentation/components/molecules/titleAndIconButton'

export function Branding({ images = [], onClick }) {
  return (
    <>
      <TitleAndIconButton title='Branding' onClick={onClick} icon={faPencil} />
      {images.map((image) => (
        <>
          <Typography variant='h5'>{image.label}</Typography>
          <Image src={`${image.src}?${Date.now()}`} />
        </>
      ))}
    </>
  )
}
