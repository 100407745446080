import { Box } from '@/presentation/components/atoms'
import { styled } from '@mui/material'
import React, { useState } from 'react'

export const Option = styled('button')<{ isActive?: boolean }>(({ theme }) => ({
  all: 'unset',
  display: 'flex',
  padding: '4px 12px',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  color: theme.palette.primary.main,
  border: `2px solid ${theme.palette.grey[500]}`,
  fontSize: '.875rem',
  '&:first-of-type': {
    borderRadius: '4px 0px 0px 4px',
  },
  '&:last-of-type': {
    borderRadius: '0px 4px 4px 0px',
  },
  '&:not(:last-of-type)': {
    borderRight: 0,
  },
  '&.isActive': {
    border: `2px solid ${theme.palette.primary.dark}`,
    cursor: 'initial',
  },
  '&.isActive + &': {
    borderLeft: 0,
  },
  '& svg': {
    color: theme.palette.primary.main,
  },
  cursor: 'pointer',
}))

export const ButtonSelectorOption = ({ isActive, ...props }: { isActive?: boolean; children: any }) => {
  return <Option isActive={isActive} {...props} />
}

interface ButtonSelectorProps {
  children: Array<any>
  value?: number
  onChange?: (targetIndex: number) => void
  defaultSelected?: number | string
}

export const ButtonSelector = ({ children, value, onChange, defaultSelected }: ButtonSelectorProps) => {
  const [innerSelected, setInnerSelected] = useState<number | string>(defaultSelected)

  const selected = value !== undefined ? value : innerSelected
  const setSelected = value !== undefined ? onChange : setInnerSelected

  const renderChildren = () => {
    return children.map((child, index) => {
      return React.cloneElement(child, {
        className: index === selected ? 'isActive' : '',
        onClick: () => {
          value === undefined && setSelected(index)
          onChange && onChange(index)
        },
      })
    })
  }

  return <Box sx={{ display: 'flex', height: '24px' }}>{renderChildren()}</Box>
}
