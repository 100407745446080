import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useAtomValue } from 'jotai'
import { Theme } from '@mui/material'
import { AutoTableVirtualized, Box, Grid, Search, Typography } from '@stationkim/front-ui'
import { useBenefitsTable } from './hooks/useBenefitsTable'
import { VisibleRowsSelector } from './hooks/components/visibleRowsSelector/visibleRowsSelector'
import { EStatusVinculo, useCompanyBenefits } from './hooks/useCompanyBenefits'
import { useManipulateCompanyBenefit } from './hooks/useManipulateCompanyBenefit'
import { companyIdAtom } from '@/main/store'

export const CompanyBenefits = () => {
  const companyId = useAtomValue(companyIdAtom)
  const [visibleRows, setVisibleRows] = useState<number>(0)
  const [searchTerm, setSearchTerm] = useState('')
  const form = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const { benefits, isLoading: isLoadingBenefits } = useCompanyBenefits({ companyId })
  const { editBenefit } = useManipulateCompanyBenefit()
  const { columns, isEditing, rowUnderEdition, setRowUnderEdition } = useBenefitsTable({
    form,
    isLoading: editBenefit.isLoading,
  })
  const isLoading = isLoadingBenefits

  const filteredRows = useMemo(() => {
    return benefits.filter((row) => String(row.operator).toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()))
  }, [searchTerm, benefits])

  const onSubmit = (data) => {
    const dirty = Object.keys(form.formState.dirtyFields)[0]
    const modifiedRow = data[dirty]
    if (modifiedRow) {
      editBenefit.mutate(
        {
          balanceProjectionEnabled: modifiedRow.allowProjection,
          companyId: companyId,
          id: modifiedRow.id,
          password: modifiedRow.password ? String(modifiedRow.password).trim() : null,
          username: modifiedRow.password ? String(modifiedRow.login).trim() : null,
          operatorCardId: modifiedRow.operatorCardId,
          operatorId: modifiedRow.operatorId,
          clientCode: modifiedRow.clientCode,
          status: modifiedRow.status ? EStatusVinculo.Ativo : EStatusVinculo.Inativo,
        },
        {
          onSuccess: () => {
            setRowUnderEdition(null)
          },
        },
      )
    } else setRowUnderEdition(null)
  }

  useEffect(() => {
    form.reset(benefits)
  }, [benefits, form])

  return (
    <Box sx={{ padding: '32px' }}>
      <Typography variant='h5'>Informações do cliente e dados de acesso na operadora</Typography>
      <Typography
        variant='label'
        sx={(theme: Theme) => ({
          color: theme.palette.grey[700],
          fontWeight: theme.typography.fontWeightRegular,
          marginTop: '8px',
          display: 'inline-block',
        })}
      >
        Selecione na listagem abaixo, os benefícios que serão disponibilizados.
      </Typography>
      <Grid container sx={{ marginTop: '16px' }} rowSpacing='16px'>
        <Grid item xs={12} sm={6} md={4}>
          <Search placeholder='Buscar operadora' disabled={isEditing} onSearch={(value) => setSearchTerm(value)} />
        </Grid>
        <Grid
          item
          xs={12}
          sx={(theme: Theme) => ({
            '.BaseTable__row[dataisediting=true]': {
              backgroundColor: theme.palette.grey[100],
            },
            '.BaseTable__row-cell-text': {
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              width: '100%',
            },
          })}
        >
          <form id='company_form_benefit' onSubmit={form.handleSubmit(onSubmit)}>
            <AutoTableVirtualized
              columns={columns}
              rows={filteredRows}
              rowKey='operatorCardId'
              visibleRows={isLoading ? 3 : visibleRows}
              ignoreFunctionInColumnCompare={false}
              variant='edition'
              rowProps={({ rowIndex }: { rowIndex: number }) => ({
                dataIsEditing: String(rowUnderEdition === rowIndex),
              })}
              isLoading={isLoading}
            />
          </form>
        </Grid>
        <Grid item sx={{ marginLeft: 'auto' }}>
          <VisibleRowsSelector value={visibleRows} onChange={setVisibleRows} listSize={benefits.length} />
        </Grid>
      </Grid>
    </Box>
  )
}
