import { useDispatch } from 'react-redux'
import React, { useCallback, useEffect, useState } from 'react'
import { Col, Container } from 'react-grid-system'
import { Controller, useForm } from 'react-hook-form'
import { AutoComplete, Button, ErrorMessage, ErrorWrapper, Input, ModalV2, Spinner } from '@/presentation/components'
import { getCardsForReportFilter, getCompaniesForReportFilter } from '@/services/reportsServices'
import { productStatus } from '@/main/utils/options'
import { pushNotification } from '@/store/modules/notification/actions'
import errorHandler from '@/main/utils/errorHandler'
import { ButtonWrapper, CheckBoxWrapper, CustomRow, InputWrapper, Label, ModalTitle } from './styles'
import useDebounce from '@/main/hooks/useDebouce'
import { applyMask } from '@/main/utils/functionUtils'

function GenericCardReportFilter({ open, onClose, download, loading, title = 'Relatório' }) {
  const [cards, setCards] = useState([])
  const [companyId, setCompanyId] = useState(null)
  const [companies, setCompanies] = useState([])
  const [isCompanyPresent, setIsCompanyPresent] = useState(true)
  const [allCards, setAllCards] = useState(false)
  const [loadingCards, setLoadingCards] = useState(false)
  const dispatch = useDispatch()
  const debounce = useDebounce()

  const { handleSubmit, control, formState, reset } = useForm({
    mode: 'onChange',
    defaultValues: {
      rhCNPJ: null,
      tradeName: null,
      clientCode: '',
      cardIds: [],
      cardStatus: null,
    },
  })

  const isButtonDisabled =
    (!formState.dirtyFields.cardIds && !formState.dirtyFields.cardStatus && !allCards) || !companyId

  const fetchData = useCallback(
    async (companyId, cardNumber) => {
      setLoadingCards(true)
      const { error, response } = await getCardsForReportFilter(companyId, cardNumber)
      if (response) setCards(response.data)
      else dispatch(pushNotification(errorHandler(error.response)))
      setLoadingCards(false)
    },
    [dispatch],
  )

  useEffect(() => {
    ;(async () => {
      const { error, response } = await getCompaniesForReportFilter()
      if (response) setCompanies(response.data)
      else dispatch(pushNotification(errorHandler(error.response)))
    })()
  }, [dispatch])

  useEffect(() => {
    companyId && debounce(() => fetchData(companyId), 600)
  }, [fetchData, companyId, debounce])

  const handleCardSearch = useCallback(
    (inputValue) => {
      if (companyId === '' || !companyId || !inputValue) return
      const isInsideList = cards.some((card) => String(card.number).includes(inputValue))
      if (isInsideList) return
      debounce(() => fetchData(companyId, inputValue), 500)
    },
    [debounce, fetchData, companyId, cards],
  )

  const onSubmit = (data) => {
    if (!data) return
    const payload = { companyId }
    if (!allCards) {
      if (data.cardIds.length > 0) payload.cardIds = data.cardIds.map((card) => card.id)
      if (data.cardStatus) payload.cardStatus = Number(data.cardStatus.value)
    }
    download(
      companies.find((company) => company.id === companyId),
      payload,
    )
    reset()
  }

  return (
    <ModalV2 open={open} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)} id='genericCardReportFilter'>
        {loading ? (
          <Spinner />
        ) : (
          <Container fluid style={{ padding: 0 }}>
            <CustomRow>
              <Col>
                <ModalTitle>{title}</ModalTitle>
              </Col>
            </CustomRow>
            <CustomRow>
              <Col>
                <ErrorWrapper error={!isCompanyPresent}>
                  <Label>Empresa</Label>
                  <AutoComplete
                    placeholder='Digite aqui o nome, CNPJ ou codigo da empresa'
                    getOptionsLabel={[(option) => option.tradeName, (option) => option.cnpj, (option) => option.code]}
                    masks={[null, (option) => applyMask('##.###.###/####-##', option), null]}
                    getOptionValue={(option) => option.id}
                    list={companies}
                    matches={(matches, inputValue) =>
                      matches === 0 && inputValue !== '' ? setIsCompanyPresent(false) : setIsCompanyPresent(true)
                    }
                    onValueChange={(value) => setCompanyId(value)}
                  />
                  {!isCompanyPresent && <ErrorMessage>Empresa não encontrada</ErrorMessage>}
                </ErrorWrapper>
              </Col>
            </CustomRow>
            <CustomRow>
              <Col sm={12} md={6}>
                <Label>N° do Cartão</Label>
                <InputWrapper>
                  <Controller
                    control={control}
                    name='cardIds'
                    render={({ field: { onChange, value } }) => (
                      <Input.MultiSelect
                        placeholder='Selecione os cartões'
                        multiSelectionLabel='cartões selecionados'
                        value={value}
                        onChange={onChange}
                        getOptionValue={(option) => option.id}
                        getOptionLabel={(option) => option.number}
                        options={cards}
                        onInputChange={handleCardSearch}
                        isLoadingCards={loadingCards}
                        isDisabled={!companyId || allCards || formState.dirtyFields.cardStatus}
                      />
                    )}
                  />
                </InputWrapper>
              </Col>
              <Col sm={12} md={6}>
                <Label>Status do Cartão</Label>
                <InputWrapper>
                  <Controller
                    control={control}
                    name='cardStatus'
                    render={({ field: { onChange, value, ref } }) => (
                      <Input.Select
                        placeholder='Selecione o status'
                        value={value}
                        inputRef={ref}
                        onChange={onChange}
                        options={productStatus}
                        isDisabled={!companyId || allCards || formState.dirtyFields.cardIds}
                      />
                    )}
                  />
                </InputWrapper>
              </Col>
            </CustomRow>
            <CustomRow>
              <Col>
                <CheckBoxWrapper>
                  <Input.CheckBox
                    controlled
                    checked={allCards}
                    onClick={() => setAllCards((state) => !state)}
                    disabled={!companyId}
                  />
                  <span>Todos os cartões</span>
                </CheckBoxWrapper>
              </Col>
            </CustomRow>
            <CustomRow>
              <Col sm={2} md={2}>
                <CheckBoxWrapper>
                  <Input.CheckBox checked controlled />
                  <span>PDF</span>
                </CheckBoxWrapper>
              </Col>
            </CustomRow>
            <CustomRow justify='end'>
              <Col sm={12} md={5}>
                <ButtonWrapper>
                  <Button disabled={isButtonDisabled || !isCompanyPresent}>Gerar Relatório</Button>
                </ButtonWrapper>
              </Col>
            </CustomRow>
          </Container>
        )}
      </form>
    </ModalV2>
  )
}

export default GenericCardReportFilter
