import { IconButton, styled } from '@mui/material'

const sizes = {
  sm: {
    box: 24,
    svg: 14,
  },
  md: {
    box: 32,
    svg: 18,
  },
}

export const StyledIconButton = styled(IconButton)(({ color, size, theme }) => {
  let bkgColor = color
  if (bkgColor === 'gray') bkgColor = theme.palette.grey[300]
  else bkgColor = theme.palette[color].light + '26'
  return {
    borderRadius: 8,
    backgroundColor: bkgColor,
    height: sizes[size].box,
    width: sizes[size].box,
    '& svg': {
      height: sizes[size].svg,
      width: sizes[size].svg,
    },
  }
})
