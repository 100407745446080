import { AvailableDialogs } from '@/presentation/components/dialogs/dialogsContainer/dialogsContainer'
import { atom, useAtom } from 'jotai'

type SelectedDialog = {
  dialog: AvailableDialogs | null
  props?: { [key: string]: any }
}

interface IUseDialogsReturn {
  actualDialog: SelectedDialog
  setDialog: (selectedDialog: SelectedDialog) => void
  goBack: (params?: { additionalData?: Record<string, any> }) => void
}

export const dialogsAtom = atom({
  dialog: null,
})

const dialogsHistoryAtom = atom([])

export const useDialogs = (): IUseDialogsReturn => {
  const [actualDialog, setActualDialog] = useAtom(dialogsAtom)
  const [history, setHistory] = useAtom(dialogsHistoryAtom)

  const setDialog = (selectedDialog: SelectedDialog) => {
    setActualDialog && setActualDialog(selectedDialog)
    if (selectedDialog.dialog !== '') setHistory((state) => [...state, selectedDialog])
  }

  const goBack = ({ additionalData = {} }: { additionalData: Record<string, any> }) => {
    if (history.length > 1) {
      const last: any = [...history].slice(-2, -1)
      setActualDialog({
        ...last[0],
        props: {
          ...last[0].props,
          ...additionalData,
        },
      })
    } else setActualDialog(history[0])
  }

  return {
    actualDialog,
    setDialog,
    goBack,
  }
}
