import React, { useEffect, useState } from 'react'
import { Col, Container, Row, useScreenClass } from 'react-grid-system'
import { PaddingBox } from './styles'
import { useDispatch, useSelector } from 'react-redux'
import UsersTable from './usersTable'
import paginatorMethods from '@/main/utils/paginatorMethods'
import { getUsersInRequest } from '@/store/modules/users/actions'
import Create from './create'
import errorHandler from '@/main/utils/errorHandler'
import { pushNotification } from '@/store/modules/notification/actions'
import { Pagination, Search, Spacing, PageHeader, ButtonV2 } from '@/presentation/components'
import useRouteRestriction from '@/main/hooks/useRouteRestriction'
import { Panel } from '@/presentation/components/molecules'

const Users = () => {
  const dispatch = useDispatch()
  const storeData = useSelector((state) => state.users)
  const usersData = storeData?.data?.data
  const [loading, setLoading] = useState(false)
  const [create, setCreate] = useState(false)
  const screenClass = useScreenClass()
  const routeRestriction = useRouteRestriction(['admin'])
  const [paginator, setPaginator] = useState({
    page: 1,
    pageSize: 10,
    doRequest: true,
  })

  useEffect(() => {
    routeRestriction()
  }, [routeRestriction])

  const refresh = async () => {
    dispatch(
      getUsersInRequest(
        paginatorMethods.buildQuery({
          page: 1,
          pageSize: 10,
          doRequest: true,
        }),
      ),
    )
  }

  useEffect(() => {
    paginator.doRequest && dispatch(getUsersInRequest(paginatorMethods.buildQuery(paginator)))
    // eslint-disable-next-line
  }, [paginator])

  useEffect(() => {
    setPaginator(
      paginatorMethods.updatePaginator(paginator, {
        page: storeData.data?.data?.pageNumber,
        totalItens: storeData.data?.data?.totalItens,
        totalPages: storeData.data?.data?.totalPages,
      }),
    )
    // eslint-disable-next-line
  }, [storeData])

  useEffect(() => {
    if (storeData.failure) dispatch(pushNotification(errorHandler(storeData.failure)))
  }, [storeData.failure, dispatch])

  return (
    <Panel style={{ padding: 0 }}>
      <Container fluid style={{ padding: 0 }}>
        <Row align='center' gutterWidth={16} justify={['sm', 'md', 'lg'].includes(screenClass) ? 'center' : 'start'}>
          <Col sm={['sm'].includes(screenClass) ? 12 : null}>
            <PageHeader>Usuários</PageHeader>
          </Col>
          <Col sm='content' style={{ marginRight: '35px' }}>
            <ButtonV2 onClick={() => setCreate(true)}>Novo Usuário</ButtonV2>
          </Col>
        </Row>
      </Container>
      <PaddingBox>
        <Container fluid>
          <Row justify={['xs', 'sm'].includes(screenClass) ? 'center' : 'between'}>
            <Col xs={12} sm={12} md={7} lg={6} xl={5}>
              <Search
                search={(value) =>
                  setPaginator(
                    paginatorMethods.setFilters(paginator, {
                      filterValue: value,
                      filterProps: 'name,email',
                    }),
                  )
                }
                placeholder='Buscar na tabela abaixo ...'
              />
            </Col>
          </Row>
          <Spacing top='32px' />
          <Row>
            <Col>
              <UsersTable
                loading={loading || storeData.loading}
                rechargesInfo={usersData}
                clickUp={paginator.orderListAsc}
                clickDown={paginator.orderListDesc}
                refresh={refresh}
                changeOrder={(field, direction) => setPaginator(paginatorMethods.setOrder(paginator, field, direction))}
                setLoading={setLoading}
              />
            </Col>
          </Row>
          <Spacing top='32px' />
          <Row justify={['xs', 'sm'].includes(screenClass) ? 'center' : 'between'} align='center'>
            <Col xs={12} md='content' style={{ display: 'flex', justifyContent: 'center' }}>
              <Pagination.ResultAmount
                paginator={paginator}
                setPageSize={(newPageSize) => setPaginator(paginatorMethods.setPageSize(paginator, newPageSize))}
              />
            </Col>
            <Col
              xs='content'
              style={{
                marginTop: ['xs', 'sm'].includes(screenClass) ? '16px' : '0',
              }}
            >
              <Pagination
                paginator={paginator}
                setPage={(wantedPage) => setPaginator(paginatorMethods.setPage(paginator, wantedPage))}
              />
            </Col>
          </Row>
        </Container>
      </PaddingBox>
      {create && <Create open={create} close={() => setCreate(false)} paginator={paginator} refresh={refresh} />}
    </Panel>
  )
}

export default Users
