import { InputBase } from '@/presentation/components/atoms'
import { styled } from '@mui/material'

export const StyledInput = styled(InputBase)(({ theme }) => ({
  color: theme.palette.grey[900],
  borderColor: theme.palette.grey[500],
  '&::placeholder': {
    color: theme.palette.grey[500],
  },
  margin: '0px',
  border: '1px solid' + theme.palette.grey[500],
  padding: '0 1rem',
  outline: 'none',
  height: '48px',
  width: '100%',
  borderRadius: '8px',
}))
